import { Collection } from '@/collection/Collection';
import { PoiModel } from '@/models/poi/PoiModel';
import { ICollection } from '@/collection/ICollection';

export class PoiCollection extends Collection implements ICollection {
  get collection(): PoiModel[] {
    return this._collection;
  }

  set collection(collection: PoiModel[]) {
    this._collection.splice(0, this._collection.length);
    collection.forEach((model) => this._collection.push(model));
  }

  private readonly _collection: PoiModel[] = [];

  update(pois: PoiModel[]): void {
    const ids = this._collection.map((p) => p.id);
    this._collection.splice(0, this._collection.length);
    pois.filter((p) => ids.includes(p.id)).forEach((m) => this._collection.push(m));
  }

  constructor(pois: PoiModel[]) {
    super();
    this._collection = pois;
  }
}
