import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex-row flex-row-justify-end flex-align-center fw" }
const _hoisted_2 = { class: "MapListContent_Body" }
const _hoisted_3 = {
  key: 0,
  class: "ph-10"
}
const _hoisted_4 = {
  key: 1,
  class: "MapListContent_Body-info"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_item_map = _resolveComponent("item-map")
  const _component_LeftPanel = _resolveComponent("LeftPanel")

  return (_openBlock(), _createBlock(_component_LeftPanel, { title: "Карты" }, {
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_tooltip, {
          class: "box-item",
          effect: "dark",
          content: "Открыть очередность карт",
          placement: "right"
        }),
        (_ctx.PermissionsList.hasPermission('raster', 'add') || _ctx.user.hasPermission('vector', 'add'))
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              class: "box-item",
              effect: "dark",
              content: "Добавить новый растр или вектор",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  icon: _ctx.Plus,
                  round: "",
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.openAddMap()))
                }, null, 8, ["icon"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.AdminService.loading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_skeleton, {
                rows: 5,
                animated: ""
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.computedListMap.length === 0 && !_ctx.AdminService.loading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Карт для выбранного подразделения не найдено"))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.computedListMap, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "MapListContent_Body-item",
                onClick: $event => (_ctx.openEdit(item.id,item.path))
              }, [
                _createVNode(_component_item_map, {
                  name: item.layername,
                  alias: item.alias,
                  uuid: item.source,
                  chek: item.path
                }, null, 8, ["name", "alias", "uuid", "chek"])
              ], 8, _hoisted_5))
            }), 256))
      ])
    ]),
    _: 1
  }))
}