import axios from 'axios';
import { useAuth } from '@/composables/useAuth';
import { AddressDto } from '@/services/api/dto/streetMap/AddressDto';

export const instance = axios.create({
  baseURL: 'https://nominatim.openstreetmap.org',
  headers: {
    Authorization: `Bearer ${useAuth().accessToken.value}`,
  },
});

export class ApiStreetMap {
  public getAddress(params: { 'q': string; }) {
    return instance.get<AddressDto[]>('/search', {
      params: {
        ...params,
        format: 'json',
        'accept-language': 'ru',
      },
    });
  }
}
