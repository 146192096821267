/**
 * Важно. Получение id текущей структуры, часто используемо.
 * Чтобы избежать циклического импорта, не нужно нагружать этот composable!
 * Импорты НЕ должны содержать любые модули приложения.
 * (можно использовать хелпер функции, типа isNumber, которые сами ничего не импортируют)
 */
import { computed, ref } from 'vue';
import { isNumber } from '@/utils/isNumber';

const _structId = ref<number>(0);

export const useStruct = () => {
  const structId = computed({
    get: () => _structId.value,
    set: (v: number) => {
      if (!isNumber(v)) {
        console.log(`Try to set structId but is not a number, ${v}`);
      } else {
        _structId.value = v;
      }
    },
  });

  return {
    structId,
  };
};
