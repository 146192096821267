import { PermissionModel } from '@/models/permission/PermissionModel';
import { UserPropertiesDto } from '@/services/api/dto/user/UserPropertiesDto';
import { BillingContractDto } from '@/services/api/dto/billing/BillingContractDto';
import { PermissionActionType } from '@/constants/types/permission/PermissionActionType';
import LoggerService from '@/services/logger/LoggerService';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';

class PermissionsList {
  private _permissions: PermissionModel[] = [];

  hasPermission(target: string, action: PermissionActionType, field: number | undefined = undefined): boolean {
    const model = this._permissions.find((v) => v.target === target);

    LoggerService.from(LoggerGroupsEnum.PERMISSIONS).group(`Try to check permission ${target}:${action}.${field ? ` For field: ${field}.` : ''}
    ${model ? `Permission model found: ${JSON.stringify(model)}` : 'Specified permission model is not found.'} `);

    if (model) {
      return model.hasPermission(action, field);
    }
    return false;
  }

  setUserPermissions(data: UserPropertiesDto) {
    if (data.permissions) {
      if (data.permissions) {
        data.permissions.forEach((perm) => {
          const m = this._permissions.find((p) => p.target === perm.target);
          if (m) {
            m.setPermission(perm.action);
          } else {
            const newPerm = new PermissionModel(perm.target);
            newPerm.setPermission(perm.action);
            this._permissions.push(newPerm);
          }
        });
      }
    }
  }

  setContractPermissions(data: BillingContractDto) {
    if (data.functional_quota.permissions) {
      data.functional_quota.permissions.forEach((perm) => {
        const m = this._permissions.find((p) => p.target === perm.target);
        if (m) {
          m.setPermission(perm.action);
        } else {
          const newPerm = new PermissionModel(perm.target);
          newPerm.setPermission(perm.action);
          this._permissions.push(newPerm);
        }
      });
      data.paid_area.forEach((row) => {
        row.permissions.forEach((perm) => {
          const m = this._permissions.find((p) => p.target === perm.target);
          if (m) {
            m.setPermission(perm.action, row.linked_fields);
          } else {
            const newPerm = new PermissionModel(perm.target);
            newPerm.setPermission(perm.action, row.linked_fields);
            this._permissions.push(newPerm);
          }
        });
      });
    }
  }
}

export default new PermissionsList();
