<template lang="pug" src="./VectorPaletteHistogram.pug"/>
<style lang="scss" src="./VectorPaletteHistogram.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { Goblet } from '@element-plus/icons-vue';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';

export default defineComponent({
  name: 'VectorPaletteHistogram',
  components: { Goblet },
  props: {
    layer: {
      type: Object as PropType<MapLayerUnifiedVectorModel>,
      required: true,
    },
  },

  setup(props) {
    const { user } = useUser();

    const computedAccuracy = computed(() => {
      const { vectorData, property } = props.layer.palette;
      return vectorData[property].values.length;
    });

    const stepValue = computed(() => (props.layer.palette.range.diff / (computedAccuracy.value - 1)));

    const position = ref({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    });
    // region tooltip
    const tooltipVisible = ref(false);
    const triggerRef = ref({
      getBoundingClientRect() {
        return position.value;
      },
    });

    const tooltipCount = ref(0);
    const tooltipValues = ref(0);

    const barHover = (value: number, count: number) => {
      tooltipCount.value = count;
      tooltipValues.value = value;
      tooltipVisible.value = true;
    };

    const mousemoveHandler = (e: MouseEvent) => {
      position.value = DOMRect.fromRect({
        width: 0,
        height: 0,
        x: e.clientX,
        y: e.clientY,
      });
    };
    onMounted(() => {
      document.addEventListener('mousemove', mousemoveHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('mousemove', mousemoveHandler);
    });
    // endregion

    const matrixChart = computed(() => {
      if (!props.layer?.palette?.vectorData || !props.layer.palette.property) {
        return [];
      }
      const { vectorData, property, range } = props.layer.palette;
      const result: number[] = [];

      const k = 10 ** (props.layer.palette.precision + 3);
      const step = Math.round(k * ((range.max - range.min) / (computedAccuracy.value - 1)));

      for (let i = range.min * k; i < range.max * k; i += step) {
        let c = 0;

        vectorData[property].values.forEach((v) => {
          if (Number(v.value) * k >= Math.floor(i) && Number(v.value) * k < Math.floor(i) + step) {
            c += Number(v.count);
          }
        });
        result.push(c);
      }
      return result;
    });

    const labels = computed(() => [
      props.layer.palette.range.min,
      props.layer.palette.range.min + (props.layer.palette.range.max - props.layer.palette.range.min) / 4,
      props.layer.palette.range.min + (props.layer.palette.range.max - props.layer.palette.range.min) / 2,
      props.layer.palette.range.min + (3 * (props.layer.palette.range.max - props.layer.palette.range.min)) / 4,
      props.layer.palette.range.max,
    ]);

    const reactivePaint = (v: number) => {
      let color = '#f00';
      props.layer.palette.borderData.values.forEach((c) => {
        if (v >= c.range.from && v < c.range.to) {
          color = c.color;
        }
      });
      if (v === props.layer.palette.range.max) {
        color = props.layer.palette.borderData.values[props.layer.palette.borderData.values.length - 1].color;
      }
      return color;
    };

    const sum = computed(() => {
      const { vectorData, property } = props.layer.palette;
      return vectorData[property].values.reduce((acc, v) => (acc + v.count), 0);
    });

    const maxCount = computed(() => {
      const { vectorData, property } = props.layer.palette;
      return Math.max(...vectorData[property].values.map((v) => v.count));
    });

    const sortedValues = computed(() => {
      const { vectorData, property } = props.layer.palette;
      return vectorData[property].values.sort((a, b) => (Number(a.value) - Number(b.value)));
    });

    const barHeight = (count: number) => {
      const padding = 15;
      const maxHeight = 200 - padding;
      return maxHeight * (count / maxCount.value);
    };

    const soloBarWidth = computed(() => 1000 * (1 / sortedValues.value.length));

    const barWidth = (idx: number) => {
      if (idx < sortedValues.value.length - 1) {
        return (1000 - soloBarWidth.value) * ((Number(sortedValues.value[idx + 1].value) - Number(sortedValues.value[idx].value)) / props.layer.palette.range.diff);
      }
      return soloBarWidth.value;
    };
    const calculatePositionX = (idx: number) => {
      let pos = 0;
      for (let i = 0; i < idx; i++) {
        pos += barWidth(i);
      }
      return pos;
    };

    return {
      computedAccuracy,
      user,
      matrixChart,
      reactivePaint,
      maxCount,
      barHeight,
      barWidth,
      barHover,
      labels,
      triggerRef,
      tooltipVisible,
      tooltipCount,
      tooltipValues,
      sortedValues,
      calculatePositionX,
    };
  },
});
</script>
