import { CropRotationSeasonDto } from '@/services/api/dto/cropRotation/CropRotationSeasonDto';

export class CropRotationSeasonModel {
  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  set name(value: string) {
    this._name = value;
  }

  get name(): string {
    return this._name;
  }

  set note(value: string) {
    this._note = value;
  }

  get note(): string {
    return this._note;
  }

  get end(): Date {
    return this._end;
  }

  get start(): Date {
    return this._start;
  }

  get id(): number {
    return this._id;
  }

  private readonly _id: number;

  private _name: string;

  private _start: Date;

  private _end: Date;

  private _note: string;

  private _active = true;

  constructor(dto: CropRotationSeasonDto) {
    this._id = dto.id;
    this._name = dto.name;
    this._start = new Date(dto.period_start);
    this._end = new Date(dto.period_stop);
    this._note = dto.note;
  }

  update(dto: CropRotationSeasonDto) {
    this._name = dto.name;
    this._start = new Date(dto.period_start);
    this._end = new Date(dto.period_stop);
    this._note = dto.note;
  }
}
