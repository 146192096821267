<template lang="pug" src="./CandidateSelector.pug"/>
<style lang="scss" src="./CandidateSelector.scss"/>

<script lang="ts">
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUser } from '@/composables/useUser';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskCandidateModel } from '@/models/taskMap/TaskCandidateModel';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { formatRuDate } from '@/utils/formatRuDate';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CandidateSelector',
  components: {
    RightPanel,
    NotFound,
  },
  emits: ['select'],
  setup() {
    const { user } = useUser();
    const {
      candidates,
      activeCandidate,
      step,
    } = useCreateBaseMap();
    const {
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const loading = ref(true);

    const showCandidateOnMap = async (candidate: TaskCandidateModel) => {
      await candidate.fetchTaskBase();
      candidate.isActive = true;
    };

    const selectCandidate = async (candidate: TaskCandidateModel) => {
      await candidate.fetchTaskBase();
      candidate.isActive = true;
      activeCandidate.value = candidate;
      step.value = 'zones';
    };

    return {
      activeField,
      PermissionsList,
      loading,
      user,
      formatRuDate,
      showCandidateOnMap,
      candidates,
      selectCandidate,
    };
  },
});
</script>
