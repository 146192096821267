import { reactive } from 'vue';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { PublicCropDto } from '@/services/api/dto/crop/PublicCropDto';

class PublicCropList {
  public publicCrops = reactive<PublicCropDto[]>([]);

  async fetchPublicCrop(force = false) {
    if (LoadingStatus.isNone.value(LoadingNamesEnum.PUBLIC_CROP_LIST) || force) {
      LoadingStatus.loading(LoadingNamesEnum.PUBLIC_CROP_LIST);
      try {
        const { data } = await ApiService.crop.getPublicCrop();
        data.forEach((dto) => {
          this.publicCrops.push(dto);
        });
        LoadingStatus.success(LoadingNamesEnum.PUBLIC_CROP_LIST);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.PUBLIC_CROP_LIST, e);
      }
    }
  }

  getCropColor(cropId: number) {
    return this.publicCrops.find((v) => v.id === cropId)?.color || '#FF0000';
  }
}

export default new PublicCropList();
