<template lang="pug" src="./POIAddCompactBlock.pug"/>
<style lang="scss" src="./POIAddCompactBlock.scss"/>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  shallowRef,
  toRefs,
} from 'vue';
import { useUser } from '@/composables/useUser';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import { useFormatter } from '@/composables/useFormatter';
import PoisList from '@/modules/poi/PoisList';
import { FormInstance, FormRules } from 'element-plus';
import { RulePoi } from '@/constants/rules/RulePoi';
import { usePoi } from '@/composables/usePoi';
import { RulePoiGroupName } from '@/constants/rules/RulePoiGroupName';
import { PoiGroupModel } from '@/models/poi/PoiGroupModel';
import { Plus } from '@element-plus/icons-vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useMapContainers } from '@/composables/useMapContainers';
import { PoiModel } from '@/models/poi/PoiModel';
import { ScoutingPoiDto } from '@/services/api/dto/scouting/ScoutingPoiDto';
import { v4 as uuid } from 'uuid';
import PoiEvents from '@/modules/poi/PoiEvents';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'POIAddCompactBlock',

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
  },
  components: { UiDrawer },
  emits: ['update:open', 'close'],
  setup(props, { emit }) {
    const { user } = useUser();
    const formPOIRef = shallowRef<FormInstance>();
    const { open } = toRefs(props);
    const { mapModel } = useMapContainers(props.mapContainer);

    const {
      formPoi,
    } = usePoi();
    const { formatPointEwkt } = useFormatter();
    const addGroupActive = ref(false);

    const formGroupAddRef = shallowRef<FormInstance>();
    const groupAddForm = ref({
      name: '',
      type: 0,
    });

    const computedOpen = computed(() => open.value);

    const poi = ref<PoiModel>();

    const closeDrawer = () => {
      open.value = false;
      formPoi.value = {
        name: '',
        poi: undefined,
        poitype: 0,
        group: undefined,
      };
      emit('update:open', false);
      emit('close');
    };

    const uniqueNameGroupAdd = (rule: any, value: any, callback: any) => {
      if (PoisList.groups.value?.some((f) => f.name === groupAddForm.value.name)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };
    const groupAddRules = reactive<FormRules>({
      name: [
        ...RulePoiGroupName,

        {
          validator: uniqueNameGroupAdd,
          trigger: ['blur', 'change'],
        },
      ],
    });

    const rules = reactive<FormRules>({
      ...RulePoi,

      group: [{
        required: true,
        message: 'Введите группу',
        trigger: 'change',
      }],
    });

    const savePoi = async () => {
      const formData = new FormData();
      const params = {
        x: formPoi.value.poi?.x,
        y: formPoi.value.poi?.y,
        n_prob: formPoi.value.name,
      };
      formData.append('farmunit', (StructList.activeStruct.value?.id || 0).toString());
      formData.append('name', formPoi.value.name || '');
      // @ts-ignore
      formData.append('geom', formatPointEwkt([formPoi.value.poi.x, formPoi.value.poi.y]));
      formData.append('poigroup', (formPoi.value.group || 0).toString());
      formData.append('descr', formPoi.value.poi?.description || '');
      formData.append('params', JSON.stringify(params));

      const { data } = await ApiService.scouting.addPoi(formData);
      const newPoi = new PoiModel(data);
      PoisList.addPoi(newPoi);

      closeDrawer();
    };
    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          savePoi();
        }
      });
    };
    const groupAddSave = async () => {
      const formData = new FormData();
      formData.append('name', groupAddForm.value.name);
      formData.append('grouptype', groupAddForm.value.type.toString());
      formData.append('farmunit', (StructList.activeStruct.value?.id || 0).toString());
      const { data } = await ApiService.scouting.addPoigroup(formData);
      PoisList.addGroups(new PoiGroupModel(data));
      addGroupActive.value = false;
    };

    const onGroupAddSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          groupAddSave();
        }
      });
    };

    onMounted(() => {
      const center = mapModel.value?.map?.getCenter();
      const struct = useStruct().structId.value;

      const p: ScoutingPoiDto = {
        farmunit: struct,
        id: -1,
        uuid: uuid(),
        name: '',
        descr: null,
        poitype: 0,
        geom: {
          type: 'Point',
          coordinates: [center?.lat || 0, center?.lat || 0],
        },
        geometry: {
          type: 'Point',
          coordinates: [center?.lng || 0, center?.lat || 0],
        },
        params: {},
        field: null,
        poigroup: null,
        photos_list: [],

      };
      poi.value = new PoiModel(p);

      poi.value.params.create = true;
      if (poi.value !== undefined) {
        poi.value.moveMode = true;
        PoiEvents.emitAddPoi(poi.value);
      }
      formPoi.value.poi = poi.value;
    });

    return {
      user,
      StructList,
      formPoi,
      savePoi,
      PoisList,
      onSubmit,
      rules,
      formPOIRef,
      addGroupActive,
      formGroupAddRef,
      groupAddForm,
      groupAddRules,
      onGroupAddSubmit,
      Plus,
      closeDrawer,
      computedOpen,
    };
  },
});
</script>
