import { reactive, ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

const loadingScreen = reactive<Record<string, string>>({
  'app-loading': 'Инициализация приложения',
});

EventBus.$on(EventsEnum.UserLogout, () => {
  Object.keys(loadingScreen).forEach((key) => {
    delete loadingScreen[key];
  });
});

export const useApp = () => {
  const setLoadingScreen = (source: string, message: string | undefined = undefined) => {
    if (loadingScreen['app-loading']) {
      delete loadingScreen['app-loading'];
    }
    if (!message) {
      delete loadingScreen[source];
    } else {
      loadingScreen[source] = message;
    }
  };
  return {
    loadingScreen,
    setLoadingScreen,
  };
};
