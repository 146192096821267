/**
 * Индексы поля полученные мониторингом. (NDVI, PVI, etc..)
 */
import { FieldIndexModel } from '@/models/field/FieldIndexModel';
import { SatelliteFieldIndexFileDto } from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';

export class FieldIndexMonitoringModel extends FieldIndexModel {
  get dataDateAt(): Date | undefined {
    return this._dataDateAt;
  }

  get sceneId(): number {
    return this._sceneId;
  }

  private _sceneId: number;

  /** Дата сцены по которой вычислен индекс */
  private _dataDateAt: Date | undefined;

  constructor(dto: SatelliteFieldIndexFileDto) {
    super(dto);

    this._sceneId = Number(dto.scene);

    if (dto.data_date) {
      this._dataDateAt = new Date(dto.data_date);
    }
  }
}
