import { MapDrawerModel } from '@/models/map/data/MapDrawerModel';

export class MapAreaModel extends MapDrawerModel {
  get area(): number {
    return this._area;
  }

  set area(value: number) {
    this._area = value;
  }

  private _area: number

  constructor() {
    super();
    this._area = 0;
  }
}
