import { computed, ref } from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlockNameType } from '@/constants/types/BlockNameType';
import { SatelliteFieldIndexFileDto } from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';
import { SatelliteFetchProcess } from '@/constants/types/SatelliteFetchProcess';
import { SatellitesTaskDto } from '@/services/api/dto/satellites/SatellitesTaskDto';

const _showingBlock = ref < 'search' | 'progress' | 'estimation' | undefined>();

const _tasks = ref<SatellitesTaskDto[]>([]);
const _activeIndexes = ref<SatelliteFieldIndexFileDto[]>([]);

const fetchingIndexesProcesses = ref<SatelliteFetchProcess[]>([]);

const indexesFiles = ref<SatelliteFieldIndexFileDto[]>([]);

const isActive = ref(false);

const getBlockType = (block: string | undefined): BlockNameType | undefined => {
  switch (block) {
  case 'search': return 'SatelliteSearchBlock';
  case 'progress': return 'SatelliteProgressBlock';
  case 'estimation': return 'SatelliteEstimationBlock';
  default: return undefined;
  }
};

export const useSatellites = () => {
  const showingBlock = computed({
    get: () => _showingBlock.value,
    set: async (v: 'search' | 'progress' | 'estimation' | undefined) => {
      if (isActive.value && v) {
        const block = getBlockType(v);
        if (block) {
          await useMapLayout().showBlock(block);
        }
      }
      _showingBlock.value = v;
    },
  });

  const activeMsfFile = computed({
    get: () => indexesFiles.value.find((f) => f.index_name === 'msf'),
    set: (v: SatelliteFieldIndexFileDto | undefined) => {
      indexesFiles.value = [
        ...indexesFiles.value.filter((f) => f.index_name !== 'msf'),
      ];
      if (v) {
        indexesFiles.value.push(v);
      }
    },
  });
  // @ts-ignore
  const activeIndexes = computed({
    get: () => _activeIndexes.value,
    set: (v: SatelliteFieldIndexFileDto[]) => {
      _activeIndexes.value = v;
    },
  });
  const tasks = computed({
    get: () => _tasks.value,
    set: (v) => {
      _tasks.value = v;
    },
  });

  return {
    isActive,
    tasks,
    activeMsfFile,
    showingBlock,
    indexesFiles,
    fetchingIndexesProcesses,
    activeIndexes,
  };
};
