export const techType = [
  { name: 'Агроноут', value: 0, type: 'SHP' },
  { name: 'Агронавигатор', value: 2, type: 'KML' },
  { name: 'Агроглобал', value: 5, type: 'KML' },
  { name: 'Пегас', value: 109, type: 'SHP' },
  { name: 'Amatron 3', value: 103, type: 'SHP' },
  { name: 'Amatron 4', value: 4, type: 'ISOXML' },
  { name: 'Bravo', value: 108, type: 'SHP' },
  { name: 'Case New Holland', value: 203, type: 'ISOXML' },
  { name: 'Claas', value: 104, type: 'SHP' },
  { name: 'Claas', value: 206, type: 'ISOXML' },
  { name: 'Horsch Touch 500/800', value: 110, type: 'SHP' },
  { name: 'Horsch Touch 500/800', value: 204, type: 'ISOXML' },
  { name: 'John Deere', value: 3, type: 'RX SHP' },
  { name: 'Kverneland', value: 205, type: 'ISOXML' },
  { name: 'Leaf Agrotronics', value: 105, type: 'SHP' },
  { name: 'Muller', value: 8, type: 'ISOXML' },
  { name: 'Precision Planting', value: 106, type: 'SHP' },
  { name: 'TeeJet', value: 1, type: 'SHP' },
  { name: 'TeeJet', value: 202, type: 'TeeJetData' },
  { name: 'Topcon', value: 107, type: 'AgData' },
  { name: 'Trimble FMX, FMX+, CFX-750', value: 9, type: 'AgGPS' },
  { name: 'Trimble GFX, TMX, XCN-1050', value: 6, type: 'AgData' },
];
