import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "RightPanel_Header",
  ref: "headerRef"
}
const _hoisted_2 = { class: "RightPanel_Header-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "RightPanel_Actions",
  ref: "actionsRef"
}
const _hoisted_5 = {
  key: 1,
  class: "RightPanel_Filters",
  ref: "filtersRef"
}
const _hoisted_6 = ["data-style"]
const _hoisted_7 = {
  key: 2,
  class: "RightPanel_Footer",
  ref: "footerRef"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["RightPanel", { compact: _ctx.compact }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.hasTitle)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.hasTitle)
          ? _renderSlot(_ctx.$slots, "title", { key: 1 })
          : _createCommentVNode("", true)
      ]),
      (_ctx.closeable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "RightPanel_Header-close",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }))
        : _createCommentVNode("", true)
    ], 512),
    (_ctx.hasActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "actions")
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.hasFilters)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "filters")
        ], 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "RightPanel_Body",
      style: _normalizeStyle({height: _ctx.bodyHeight}),
      "data-style": JSON.stringify(_ctx.bodyHeight)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 12, _hoisted_6),
    (_ctx.hasFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer")
        ], 512))
      : _createCommentVNode("", true)
  ], 2))
}