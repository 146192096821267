<template lang="pug" src="./UiSlider.pug"/>
<style lang="scss" src="./UiSlider.scss"/>

<script lang="ts">
import { pythonRound } from '@/utils/pythonRound';
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { getClientXY } from '@/utils/mouseTouchEvent';

export default defineComponent({
  name: 'UiSlider',
  components: {},
  props: {
    modelValue: {
      type: Array as PropType<Array<number>>,
      default: () => [0, 50],
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 99,
    },
    precision: {
      type: Number,
      default: 2,
    },
    stops: {
      type: Array as PropType<Array<number>>,
      default: () => [],
      required: false,
    },
  },
  emits: ['update', 'update:modelValue'],
  setup(props, { emit }) {
    const { user } = useUser();
    const dragStartPosition = ref({
      startX: 0,
      startY: 0,
      newPosition: 0,
      currentX: 0,
    });
    const activeIndex = ref<number>();
    const sliderRef = ref();

    const emitValue = (value: number) => {
      const realValue = pythonRound(props.min + (value * (props.max - props.min)) / 100, props.precision);
      emit('update:modelValue', props.modelValue.reduce((acc, v, idx) => {
        acc.push(idx === activeIndex.value ? realValue : pythonRound(v, props.precision));
        return acc;
      }, [] as number[]));
    };

    const diff = computed(() => (100 * (dragStartPosition.value.currentX - dragStartPosition.value.startX)) / (sliderRef.value.offsetWidth || 0));

    const dragging = (e: MouseEvent | TouchEvent) => {
      window.getSelection()?.removeAllRanges();
      const { clientX } = getClientXY(e);

      dragStartPosition.value.currentX = clientX;

      if (activeIndex.value !== undefined) {
        const newPosition = dragStartPosition.value.newPosition + diff.value;

        // Приведение newPosition в диапазон [0, 100]
        const clampedPosition = Math.min(100, Math.max(0, newPosition));

        const realValue = pythonRound(
          props.min + (clampedPosition * (props.max - props.min)) / 100,
          props.precision,
        );

        const leftNeighbor = activeIndex.value > 0 ? props.modelValue[activeIndex.value - 1] : props.min;
        const rightNeighbor = activeIndex.value < props.modelValue.length - 1
          ? props.modelValue[activeIndex.value + 1]
          : props.max;

        if (realValue >= leftNeighbor && realValue <= rightNeighbor) {
          // Если значение не пересекается, обновляем его
          emitValue(clampedPosition);
        } else if (realValue < leftNeighbor && activeIndex.value > 0) {
          // Если пересечение с левым соседом, меняем их местами
          const swappedValues = [...props.modelValue];
          [swappedValues[activeIndex.value - 1], swappedValues[activeIndex.value]] = [
            swappedValues[activeIndex.value],
            swappedValues[activeIndex.value - 1],
          ];
          emit('update:modelValue', swappedValues);
          activeIndex.value -= 1; // Перемещаем активный индекс влево
        } else if (realValue > rightNeighbor && activeIndex.value < props.modelValue.length - 1) {
          // Если пересечение с правым соседом, меняем их местами
          const swappedValues = [...props.modelValue];
          [swappedValues[activeIndex.value + 1], swappedValues[activeIndex.value]] = [
            swappedValues[activeIndex.value],
            swappedValues[activeIndex.value + 1],
          ];
          emit('update:modelValue', swappedValues);
          activeIndex.value += 1; // Перемещаем активный индекс вправо
        }
      }
    };

    const stop = () => {
      window.removeEventListener('mousemove', dragging);
      window.removeEventListener('touchmove', dragging);
      window.removeEventListener('mouseup', stop);
      window.removeEventListener('touchend', stop);
    };

    const sliderPosition = computed(() => (p: number) => 100 * ((p - props.min) / (props.max - props.min)));

    const hand = (e: MouseEvent | TouchEvent, idx: number) => {
      const { clientX } = getClientXY(e);
      activeIndex.value = idx;
      const left = sliderPosition.value(props.modelValue[idx]);

      dragStartPosition.value.startX = clientX;
      dragStartPosition.value.newPosition = left;

      window.addEventListener('mousemove', dragging);
      window.addEventListener('touchmove', dragging);
      window.addEventListener('mouseup', stop);
      window.addEventListener('touchend', stop);
    };

    return {
      sliderRef,
      sliderPosition,
      user,
      hand,
      pythonRound,
    };
  },
});
</script>
