import { ChartPlotTypeNameEnum } from '@/constants/enums/ChartPlotTypeNameEnum';

const MonitoringChartPlotConfig = {
  plots: [
    ChartPlotTypeNameEnum.NDVI,
    ChartPlotTypeNameEnum.CLOUD,
    ChartPlotTypeNameEnum.PRECIPTION,
    ChartPlotTypeNameEnum.TEMPERATURE_MAX,
    ChartPlotTypeNameEnum.TEMPERATURE_MIN,
  ],
  options: {
    stroke: {
      curve: ['straight', 'straight', 'straight', 'smooth', 'smooth'],
      width: [5, 1, 1, 3, 3],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left',
      onItemHover: {
        highlightDataSeries: false,
      },
      showForSingleSeries: true,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM ',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },

  },

};

export default MonitoringChartPlotConfig;
