import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import type { FieldModel } from '@/models/field/FieldModel';

export const FieldModelEvents = (field: FieldModel) => {
  EventBus.$on(EventsEnum.SetSelectedTaskMap, (model: FieldTaskMapBaseModel | FieldTaskMapFactModel | FieldTaskMapHarvestModel) => {
    if (model.field === field.id) {
      if (model instanceof FieldTaskMapBaseModel) {
        field.baseTaskMaps
          .filter((v) => v.selected && v.uuid !== model.uuid)
          .forEach((v) => v.setSelected(false));
      }
      if (model instanceof FieldTaskMapFactModel) {
        field.factTaskMaps
          .filter((v) => v.selected && v.uuid !== model.uuid)
          .forEach((v) => v.setSelected(false));
      }
      if (model instanceof FieldTaskMapHarvestModel) {
        field.harvestTaskMaps
          .filter((v) => v.selected && v.uuid !== model.uuid)
          .forEach((v) => v.setSelected(false));
      }
    }
  });
};
