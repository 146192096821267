<template src="./ViewTaskTabsContent.pug" lang="pug"/>
<style src="./ViewTaskTabsContent.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { formatRuDate } from '@/utils/formatRuDate';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import {
  DataAnalysis, Delete, Download, Files, View,
} from '@element-plus/icons-vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import EventBus from '@/services/eventBus/EventBus';
import downloadTaskForm from '@/modules/viewTask/ui/downloadTaskForm.vue';

export default defineComponent({
  name: 'ViewTaskTabsContent',
  props: {
    tasks: {
      type: Object as PropType<FieldTaskMapBaseModel[] | FieldTaskMapFactModel[] | FieldTaskMapWorkModel[] | FieldTaskMapHarvestModel[]>,
      required: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowDownloadType: {
      type: Boolean,
      default: false,
    },
    allowStatistic: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    mapUuid: {
      type: String,
    },
  },
  components: {
    RightPanel,
    View,
    Download,
    Delete,
    NotFound,
    DataAnalysis,
    downloadTaskForm,
  },
  emits: ['view', 'delete', 'stats', 'download'],
  setup(props, { emit }) {
    const selectedTask = ref<FieldTaskMapBaseModel | FieldTaskMapFactModel | FieldTaskMapWorkModel | FieldTaskMapHarvestModel | undefined>();

    EventBus.$on('view-task-tab-content-select-file', () => {
      selectedTask.value = undefined;
    });

    EventBus.$on('compare-navigation-active-menu-change', (uuid: string, name: string) => {
      if (uuid === props.mapUuid && !['importFile', 'taskFile'].includes(name)) {
        selectedTask.value = undefined;
      }
    });

    const selectTask = (task: FieldTaskMapBaseModel | FieldTaskMapFactModel | FieldTaskMapWorkModel | FieldTaskMapHarvestModel) => {
      EventBus.$emit('view-task-tab-content-select-file');
      if (task.id !== selectedTask.value?.id) {
        selectedTask.value = task;
        emit('view', task.id);
      }
    };

    return {
      Delete,
      Download,
      formatRuDate,
      selectTask,
      selectedTask,
      DataAnalysis,
      Files,
    };
  },
});
</script>
