import { Geometry, MultiPolygon, Position } from 'geojson';

export const mapFeatureCenter = (geometry: Geometry) => {
  const bbox = [[Infinity, Infinity], [-Infinity, -Infinity]];
  (geometry as MultiPolygon).coordinates.forEach((a) => {
    a.forEach((b) => {
      b.forEach((c) => {
        bbox[0][0] > c[0] && (bbox[0][0] = c[0]);
        bbox[1][0] < c[0] && (bbox[1][0] = c[0]);
        bbox[0][1] > c[1] && (bbox[0][1] = c[1]);
        bbox[1][1] < c[1] && (bbox[1][1] = c[1]);
      });
    });
  });
  return [bbox[0][0] + (bbox[1][0] - bbox[0][0]) / 2, bbox[0][1] + (bbox[1][1] - bbox[0][1]) / 2] as Position;
};
