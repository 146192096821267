import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ZoomControlBlock" }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_icon, {
      name: "mdiMagnify",
      size: 13
    }),
    _createElementVNode("span", null, _toDisplayString(_ctx.zoom), 1)
  ]))
}