import { ref } from 'vue';

export const useForceUpdate = () => {
  const updateKey = ref(0);

  const forceUpdate = () => {
    updateKey.value += 1;
  };

  return {
    updateKey,
    forceUpdate,
  };
};
