import { computed, reactive, ref } from 'vue';
import { ExperimentModel } from '@/models/ExperimentModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import type { MapLayerExperimentsModel } from '@/models/map/Layers/MapLayerExperimentsModel';
import { ExperimentsCollection } from '@/collection/ExperimentsCollection';
import type { MapModel } from '@/models/map/MapModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';

const experiments = reactive<ExperimentModel[]>([] as ExperimentModel[]);

const map = ref<MapModel | undefined>();

const layer = computed(() => map.value?.getLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT) as MapLayerExperimentsModel);

const refreshMap = () => {
  (map.value?.getLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT) as MapLayerExperimentsModel)?.refresh();
};

const refreshContour = () => {
  (map.value?.getLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT) as MapLayerExperimentsModel)?.refreshContour();
};

const refreshPlus = () => {
  (map.value?.getLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT) as MapLayerExperimentsModel)?.refreshPlus();
};

const hideBorders = () => {
  (map.value?.getLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT) as MapLayerExperimentsModel)?.hideBorders();
};

const selectTask = ref<Record<number, FieldTaskMapBaseModel>>({});

export const useExperiments = () => {
  const setMap = (mapModel: MapModel | undefined) => {
    map.value = mapModel;
  };

  const addNewExperiment = (exp: ExperimentModel) => {
    experiments.push(exp);
    layer.value?.setCollection(new ExperimentsCollection(experiments as ExperimentModel[]));
  };

  const clearExperiments = () => {
    experiments.splice(0, experiments.length);
    layer.value?.setCollection(new ExperimentsCollection(experiments as ExperimentModel[]));
  };

  const activeExperiment = computed(() => experiments.find((v) => !!v.active) as ExperimentModel | undefined);

  const selectedExperiments = computed(() => experiments.filter((v) => v.selected));

  // region Функции по работе с массивом выбранных опытов

  const selectExperiment = (uuid: string) => {
    if (activeExperiment.value) {
      activeExperiment.value.active = false;
      refreshPlus();
      hideBorders();
    }

    const found = experiments.find((v) => v.uuid === uuid);
    if (found) found.selected = true;
    refreshContour();
  };

  const unselectExperiment = (uuid: string) => {
    const found = experiments.find((v) => v.uuid === uuid);
    if (found) found.selected = false;
    refreshContour();
  };

  const toggleSelectedExperiment = (uuid: string) => {
    const found = experiments.find((v) => v.uuid === uuid);
    if (!found?.selected) {
      selectExperiment(uuid);
    } else {
      unselectExperiment(uuid);
    }
  };

  const clearSelectedExperiments = () => {
    selectedExperiments.value.forEach((v) => {
      v.selected = false;
    });
    refreshContour();
  };
  // endregion

  // region Функции по работе с активным опытом

  const setActiveExperiment = (uuid: string | undefined = undefined) => {
    clearSelectedExperiments();
    if (activeExperiment.value && uuid && activeExperiment.value?.uuid === uuid) {
      activeExperiment.value.active = false;
    } else {
      if (activeExperiment.value) {
        activeExperiment.value.active = false;
      }
      const found = experiments.find((v) => v.uuid === uuid);
      if (found) {
        found.active = true;
      }
    }
    refreshContour();
    refreshPlus();
  };
  // endregion

  const removeExperiment = (uuid: string) => {
    const found = experiments.find((v) => v.uuid === uuid);
    if (found) {
      if (found.active) {
        setActiveExperiment();
      }
      if (found.selected) {
        unselectExperiment(found.uuid);
      }
    }
    const idx = experiments.findIndex((v) => v.uuid === uuid);

    if (idx >= 0) {
      experiments.splice(idx, 1);
    }

    layer.value?.setCollection(new ExperimentsCollection(experiments as ExperimentModel[]));
  };

  const calculateTitle = () => `opt_${Math.max(0, ...experiments.filter((v) => /^opt_\d*$/.test(v.title)).map((v) => Number(v.title.replace('opt_', '')))) + 1}`;

  return {
    refreshMap,
    experiments,
    addNewExperiment,
    removeExperiment,
    clearExperiments,
    selectedExperiments,
    selectExperiment,
    unselectExperiment,
    clearSelectedExperiments,
    activeExperiment,
    setActiveExperiment,
    toggleSelectedExperiment,
    calculateTitle,
    setMap,
    refreshContour,
    selectTask,
  };
};
