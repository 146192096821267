import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "StatusBarBlock" }
const _hoisted_2 = { class: "StatusBarBlock-left" }
const _hoisted_3 = { class: "StatusBarBlock-center" }
const _hoisted_4 = { class: "StatusBarBlock-right" }

export function render(_ctx, _cache) {
  const _component_VersionStatus = _resolveComponent("VersionStatus")
  const _component_MapHoverRasterValue = _resolveComponent("MapHoverRasterValue")
  const _component_ProgressBarStatus = _resolveComponent("ProgressBarStatus")
  const _component_TotalArea = _resolveComponent("TotalArea")
  const _component_NotificationStatus = _resolveComponent("NotificationStatus")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VersionStatus)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MapHoverRasterValue)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ProgressBarStatus),
      _createVNode(_component_TotalArea),
      _createVNode(_component_NotificationStatus)
    ])
  ]))
}