import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

export class FieldTaskMapHarvestModel extends FieldTaskMapModel {
  async delete() {
    await ApiService.taskMap.deleteTaskMap('harvest', this.id);
  }

  async fetchData(): Promise<void> {
    if (!this.geojson) {
      const { data } = await ApiService.taskMap.getData('harvest', this.id);
      this.setGeoJson(data.geojson);
    }
  }

  setSelected(value: boolean): void {
    if (value) {
      EventBus.$emit(EventsEnum.SetSelectedTaskMap, this);
    }
    super.setSelected(value);
  }
}
