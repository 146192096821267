import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { AuthTokenDto } from '@/services/api/dto/auth/AuthTokenDto';
import { AuthRefreshTokenDto } from '@/services/api/dto/auth/AuthRefreshTokenDto';

export const endpoints = {
  getToken: (): string => '/o/token/',
  refreshToken: (): string => '/o/token/',
  logout: (): string => '/o/revoke_token/',
};

export class ApiAuth extends ApiHandler {
  public async getToken(data: {
    'grant_type': 'password',
    'client_id': string,
    'client_secret': string,
    'username': string,
    'password': string
  }): Promise<ApiResponse<AuthTokenDto>> {
    return await this.request().post<AuthTokenDto>(endpoints.getToken(), data);
  }

  public async refreshToken(data: {
    'grant_type': 'refresh_token',
    'refresh_token': string,
    'client_id': string,
    'client_secret': string
  }): Promise<ApiResponse<AuthRefreshTokenDto>> {
    return await this.request().post<AuthRefreshTokenDto>(endpoints.refreshToken(), data);
  }

  public async logout(data: {
    'token': string,
    'client_id': string,
    'client_secret': string,
  }): Promise<ApiResponse<void>> {
    return await this.request().post<void>(endpoints.logout(), data);
  }
}
