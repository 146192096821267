import { CustomPaintEnum } from '@/constants/enums/CustomPaintEnum';
import { VectorPaletteEnum } from '@/constants/enums/VectorPalleteEnum';
import { VectorColorType } from '@/constants/types/VectorColorType';

export const VectorColors: VectorColorType[] = [
  {
    name: 'hg',
    alias: 'Гидролитическая кислотность',
    type: VectorPaletteEnum.RANGE,
    units: 'ммоль/100 г',
    palette: [
      {
        value: 2,
        color: '#781129',
      },
      {
        value: 3,
        color: '#f75f28',
      },
      {
        value: 4,
        color: '#ffd900',
      },
      {
        value: 5,
        color: '#49F883',
      },
      {
        value: 6,
        color: '#0277bf',
      },
      {
        value: 6,
        color: '#31123B',
      },
    ],
  },
  {
    name: 'w',
    alias: 'Запас продуктивной влаги до 100 см.',
    type: VectorPaletteEnum.RANGE,
    units: 'мм',
    palette: [
      {
        value: 60,
        color: '#86eaf1',
      },
      {
        value: 90,
        color: '#48d1f0',
      },
      {
        value: 130,
        color: '#1290f0',
      },
      {
        value: 160,
        color: '#0051ff',
      },
      {
        value: 160,
        color: '#002fb9',
      },
    ],
  },
  {
    name: 'p2o5_ch',
    alias: 'P2O5 по Чирикову',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 20,
        color: '#3EF3FF',
      },
      {
        value: 50,
        color: '#1CD6FD',
      },
      {
        value: 100,
        color: '#03BBF7',
      },
      {
        value: 150,
        color: '#00A0F5',
      },
      {
        value: 200,
        color: '#066ED1',
      },
      {
        value: 200,
        color: '#04539E',
      },
    ],
  },
  {
    name: 'p2o5_m',
    alias: 'P2O5 по Мачигину',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 10,
        color: '#E43717',
      },
      {
        value: 15,
        color: '#FF9F00',
      },
      {
        value: 30,
        color: '#FEDC00',
      },
      {
        value: 45,
        color: '#399729',
      },
      {
        value: 60,
        color: '#22B6E4',
      },
      {
        value: 60,
        color: '#5B66B5',
      },
    ],
  },
  {
    name: 'p2o5_k',
    alias: 'P2O5 по Кирсановой',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 30,
        color: '#E43717',
      },
      {
        value: 80,
        color: '#FF9F00',
      },
      {
        value: 150,
        color: '#FEDC00',
      },
      {
        value: 200,
        color: '#399729',
      },
      {
        value: 300,
        color: '#22B6E4',
      },
      {
        value: 300,
        color: '#5B66B5',
      },
    ],
  },
  {
    name: 'k2o',
    alias: 'K2O по Чирикову',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 20,
        color: '#FEDC00',
      },
      {
        value: 40,
        color: '#FCB608',
      },
      {
        value: 80,
        color: '#FE9901',
      },
      {
        value: 120,
        color: '#B95006',
      },
      {
        value: 180,
        color: '#822D02',
      },
      {
        value: 180,
        color: '#471902',
      },
    ],
  },
  {
    name: 'h2so4',
    alias: 'H2SO4 по методу Тюрина-Кононовой',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 30,
        color: '#FFF502',
      },
      {
        value: 40,
        color: '#BDFE00',
      },
      {
        value: 50,
        color: '#39CD05',
      },
      {
        value: 70,
        color: '#029D04',
      },
      {
        value: 100,
        color: '#206411',
      },
      {
        value: 100,
        color: '#064A01',
      },
    ],
  },
  {
    name: 'no3',
    alias: 'NO3 по методу Кравкову',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 5,
        color: '#FEDC00',
      },
      {
        value: 8,
        color: '#45C018',
      },
      {
        value: 30,
        color: '#2B9612',
      },
      {
        value: 45,
        color: '#1C5D0F',
      },
      {
        value: 60,
        color: '#B95006',
      },
      {
        value: 60,
        color: '#471902',
      },
    ],
  },
  {
    name: 'k2o_m',
    alias: 'K2O по Мачигину',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 100,
        color: '#E43717',
      },
      {
        value: 200,
        color: '#FF9F00',
      },
      {
        value: 300,
        color: '#FEDC00',
      },
      {
        value: 400,
        color: '#399729',
      },
      {
        value: 600,
        color: '#22B6E4',
      },
      {
        value: 600,
        color: '#5B66B5',
      },
    ],
  },
  {
    name: 'k2o_k',
    alias: 'K2O по Кирсанова',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 100,
        color: '#E43717',
      },
      {
        value: 200,
        color: '#FF9F00',
      },
      {
        value: 300,
        color: '#FEDC00',
      },
      {
        value: 400,
        color: '#399729',
      },
      {
        value: 600,
        color: '#22B6E4',
      },
      {
        value: 600,
        color: '#5B66B5',
      },
    ],
  },
  {
    name: 'n_tk',
    alias: 'Легкогидролизуемый азот',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 5,
        color: '#E43717',
      },
      {
        value: 8,
        color: '#FF9F00',
      },
      {
        value: 15,
        color: '#FEDC00',
      },
      {
        value: 30,
        color: '#399729',
      },
      {
        value: 60,
        color: '#22B6E4',
      },
      {
        value: 60,
        color: '#5B66B5',
      },
    ],
  },
  {
    name: 'n_k',
    alias: 'Азот по Корнфилду',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг ',
    palette: [
      {
        value: 100,
        color: '#E43717',
      },
      {
        value: 150,
        color: '#FF9F00',
      },
      {
        value: 200,
        color: '#FEDC00',
      },
      {
        value: 200,
        color: '#22B6E4',
      },
    ],
  },
  {
    name: 'humus_%',
    alias: 'Гумус',
    type: VectorPaletteEnum.RANGE,
    units: '%',
    palette: [
      {
        value: 2,
        color: '#FFEC3F',
      },
      {
        value: 3,
        color: '#c5d910',
      },
      {
        value: 4,
        color: '#9FAC05',
      },
      {
        value: 5,
        color: '#55a000',
      },
      {
        value: 6,
        color: '#454205',
      },
      {
        value: 6,
        color: '#BB4F04',
      },
    ],
  },

  {
    name: 's',
    alias: 'Сера',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 6,
        color: '#FFEC3F',
      },
      {
        value: 12,
        color: '#D58525',
      },
      {
        value: 12,
        color: '#763802',
      },

    ],
  },
  {
    name: 'land_gr',
    alias: 'Группа земель',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: '1a',
        color: '#9D7843',
      },
      {
        value: '1b',
        color: '#7F5B40',
      },
      {
        value: '2a',
        color: '#fdea35',
      },
      {
        value: '2b',
        color: '#debe59',
      },
      {
        value: '2c',
        color: '#EBAE70',
      },
      {
        value: '3a',
        color: '#56ccff',
      },
      {
        value: '3b',
        color: '#2e94d5',
      },
      {
        value: '3c',
        color: '#3272CA',
      },
      {
        value: '4a',
        color: '#c7c7c7',
      },
      {
        value: '4b',
        color: '#a2a2a2',
      },
      {
        value: '4c',
        color: '#777777',
      },
      {
        value: '5a',
        color: '#f3aab9',
      },
      {
        value: '5b',
        color: '#f8819e',
      },
      {
        value: '5c',
        color: '#D4506F',
      },
      {
        value: '6',
        color: '#D7EF04',
      },
      {
        value: '7',
        color: '#E3753B',
      },
      {
        value: '8',
        color: '#038305',
      },

    ],
  },
  {
    name: 'ph_kcl',
    alias: 'pH солевой',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 4,
        color: '#7b0013',
      },
      {
        value: 4.5,
        color: '#db001c',
      },
      {
        value: 5,
        color: '#b0490e',

      },
      {
        value: 5.5,
        color: '#ffe100',
      },
      {
        value: 6,
        color: '#82eb00',

      },
      {
        value: 7.4,
        color: '#00920e',

      },
      {
        value: 8,
        color: '#01aa92',

      },
      {
        value: 8.5,
        color: '#003fff',

      },
      {
        value: 8.5,
        color: '#4d00cb',

      },

    ],
  },
  {
    name: 'ph_h2o',
    alias: 'pH водный',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 4,
        color: '#7b0013',
      },
      {
        value: 4.5,
        color: '#db001c',
      },
      {
        value: 5,
        color: '#b0490e',

      },
      {
        value: 5.5,
        color: '#ffe100',
      },
      {
        value: 6,
        color: '#82eb00',

      },
      {
        value: 7.4,
        color: '#00920e',

      },
      {
        value: 8,
        color: '#01aa92',

      },
      {
        value: 8.5,
        color: '#003fff',

      },
      {
        value: 8.5,
        color: '#4d00cb',

      },

    ],
  },
  {
    name: 'zone',
    alias: 'Зона плодородия',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: '1.1',
        color: '#BE0000',
      },
      {
        value: '1.2',
        color: '#E51F02',
      },
      {
        value: '1.3',
        color: '#FD5502',
      },
      {
        value: '2.1',
        color: '#FCE502',
      },
      {
        value: '2.2',
        color: '#FBFC02',
      },
      {
        value: '2.3',
        color: '#E2FB03',
      },
      {
        value: '3.1',
        color: '#65B424',
      },
      {
        value: '3.2',
        color: '#2A7F01',
      },
      {
        value: '3.3',
        color: '#236400',
      },
    ],
  },
  {
    name: 'mgo',
    alias: 'Магний',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0.3,
        color: '#24d403',
      },
      {
        value: 0.6,
        color: '#a7f02d',
      },
      {
        value: 0.9,
        color: '#ffcc00',
      },
      {
        value: 0.11,
        color: '#ff9500',
      },
      {
        value: 0.11,
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'cu',
    alias: 'Медь',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0.2,
        color: '#ee95f4',
      },
      {
        value: 0.5,
        color: '#eb24f5',
      },
      {
        value: 0.5,
        color: '#a202b0',
      },
    ],
  },
  {
    name: 'zn',
    alias: 'Цинк',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 2,
        color: '#ee95f4',
      },
      {
        value: 5,
        color: '#eb24f5',
      },
      {
        value: 5,
        color: '#a202b0',
      },
    ],
  },
  {
    name: 'mn',
    alias: 'Марганец',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 10,
        color: '#ee95f4',
      },
      {
        value: 20,
        color: '#eb24f5',
      },
      {
        value: 20,
        color: '#a202b0',
      },
    ],
  },
  {
    name: 'co',
    alias: 'Кобальт',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0.2,
        color: '#ee95f4',
      },
      {
        value: 0.5,
        color: '#eb24f5',
      },
      {
        value: 0.5,
        color: '#a202b0',
      },
    ],
  },
  {
    name: 'b',
    alias: 'Бор',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0.33,
        color: '#fb7474',
      },
      {
        value: 0.70,
        color: '#f52424',
      },
      {
        value: 0.70,
        color: '#ca0303',
      },
    ],
  },
  {
    name: 'mo',
    alias: 'Молибден',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0.11,
        color: '#fb7474',
      },
      {
        value: 0.22,
        color: '#f52424',
      },
      {
        value: 0.22,
        color: '#ca0303',
      },
    ],
  },
  {
    name: 'lim_fact',
    alias: 'Лимитирующие факторы',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: 1,
        color: 'rgb(252,252,252)',
      },
      {
        value: 2,
        color: '#ffd900',
      },
      {
        value: 3,
        color: '#ffa443',
      },
      {
        value: 4,
        color: '#db7104',
      },
      {
        value: 5,
        color: '#2478f5',
      },
      {
        value: 6,
        color: '#7bf168',
      },

      {
        value: 7,
        color: '#ada5a5',
      },
      {
        value: 8,
        color: '#f154bf',
      },
      {
        value: 9,
        color: '#965f35',
      },
      {
        value: 10,
        color: '#3d3da9',
      },
    ],
  },

  {
    name: 'сompact_l',
    alias: 'Нижняя граница переуплотнения',
    type: VectorPaletteEnum.RANGE,
    units: 'см',
    palette: [
      {
        value: 0,
        color: '#f9cf91',
      },
      {
        value: 15,
        color: '#ffae83',
      },
      {
        value: 20,
        color: '#f2813f',
      },
      {
        value: 25,
        color: '#ed7042',
      },
      {
        value: 30,
        color: '#c04e0b',
      },
      {
        value: 35,
        color: '#d42c07',
      },
      {
        value: 40,
        color: '#952803',
      },
      {
        value: 40,
        color: '#721802',
      },
    ],
  },

  {
    name: 'сompact_h',
    alias: 'Верхняя граница переуплотнения',
    type: VectorPaletteEnum.RANGE,
    units: 'мг/кг',
    palette: [
      {
        value: 0,
        color: '#f9cf91',
      },
      {
        value: 15,
        color: '#ffae83',
      },
      {
        value: 20,
        color: '#f2813f',
      },
      {
        value: 25,
        color: '#ed7042',
      },
      {
        value: 30,
        color: '#c04e0b',
      },
      {
        value: 35,
        color: '#d42c07',
      },
      {
        value: 40,
        color: '#952803',
      },
      {
        value: 40,
        color: '#721802',
      },
    ],
  },

  {
    name: 'rating',
    alias: 'Рейтинг полей',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: 'A',
        color: '#15a203',
      },
      {
        value: 'B',
        color: '#ffd900',
      },
      {
        value: 'C',
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'deacid',
    alias: 'Раскисление почвы',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: '1',
        color: '#15a203',
      },
      {
        value: '2',
        color: '#ffd900',
      },
      {
        value: '3',
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'winter/spr',
    alias: 'Пригодности под яровые озимые',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: '1',
        color: '#ffd900',
      },
      {
        value: '2',
        color: '#b29702',
      },
      {
        value: '3',
        color: '#006fff',
      },
    ],
  },
  {
    name: 'sevooborot',
    alias: 'Размещение культур',
    type: VectorPaletteEnum.UNIQUE,
    palette: [
      {
        value: 1,
        color: '#b3e72e',
      },
      {
        value: 2,
        color: '#ff5e00',
      },
      {
        value: 3,
        color: '#ffe100',
      },
      {
        value: 4,
        color: '#4af204',
      },
      {
        value: 5,
        color: '#d5996b',
      },
      {
        value: 6,
        color: '#8000ff',
      },
      {
        value: 7,
        color: '#006fff',
      },
      {
        value: 8,
        color: '#106e02',
      },
      {
        value: 9,
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'htrg',
    alias: 'Гетерогенности почвы',
    type: VectorPaletteEnum.RANGE,
    palette: [
      {
        value: 0.00,
        color: '#e7e7cb',
      },
      {
        value: 0.03,
        color: '#deddb0',
      },
      {
        value: 0.05,
        color: '#d8d293',
      },
      {
        value: 0.07,
        color: '#d2c874',
      },
      {
        value: 0.09,
        color: '#cfbc54',
      },
      {
        value: 0.11,
        color: '#b69525',
      },
      {
        value: 0.15,
        color: '#9d7a1b',
      },
      {
        value: 0.19,
        color: '#826012',
      },
      {
        value: 0.22,
        color: '#826012',
      },
      {
        value: 0.25,
        color: '#65460b',
      },
    ],
  },
  {
    name: 'hms_t_cls',
    alias: 'Запас гумуса',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    units: 'т/га',
    classText: ['н/д', ' очень низкое', 'низкое', 'среднее', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fff825',
      },
      {
        value: 2,
        color: '#f4c32c',
      },
      {
        value: 3,
        color: '#e88e32',
      },
      {
        value: 4,
        color: '#d45e35',
      },
      {
        value: 5,
        color: '#a43b2b',
      },
    ],
  },
  {
    name: 'hms_p_cls',
    alias: 'Содержание гумуса',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    units: '%',
    classText: ['н/д', 'очень низкое', 'низкое', 'среднее', 'повышенное', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fff825',
      },
      {
        value: 2,
        color: '#f4c32c',
      },
      {
        value: 3,
        color: '#e88e32',
      },
      {
        value: 4,
        color: '#d45e35',
      },
      {
        value: 5,
        color: '#a43b2b',
      },
      {
        value: 6,
        color: '#751822',
      },
    ],
  }, {
    name: 'ph_kcl_cls',
    alias: 'Кислотность солевая',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень сильнокислая', 'сильнокислая', 'среднекислая', 'слабокислая', 'близкая к нейтральной', 'нейтральная', 'слабощелочная', 'щелочная', 'сильнощелочная'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#98000a',
      },
      {
        value: 2,
        color: '#e41515',
      },
      {
        value: 3,
        color: '#eb7c13',
      },
      {
        value: 4,
        color: '#f7f703',
      },
      {
        value: 5,
        color: '#a2fb36',
      },
      {
        value: 6,
        color: '#16d31c',
      },
      {
        value: 7,
        color: '#48b2b4',
      },
      {
        value: 8,
        color: '#4777ef',
      },
      {
        value: 9,
        color: '#6c477a',
      },
    ],
  },
  {
    name: 'ph_h2o_cls',
    alias: 'Кислотность водная',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень сильнокислая', 'сильнокислая', 'среднекислая', 'слабокислая', 'близкая к нейтральной', 'нейтральная', 'слабощелочная', 'щелочная', 'сильнощелочная'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#98000a',
      },
      {
        value: 2,
        color: '#e41515',
      },
      {
        value: 3,
        color: '#eb7c13',
      },
      {
        value: 4,
        color: '#f7f703',
      },
      {
        value: 5,
        color: '#a2fb36',
      },
      {
        value: 6,
        color: '#16d31c',
      },
      {
        value: 7,
        color: '#48b2b4',
      },
      {
        value: 8,
        color: '#4777ef',
      },
      {
        value: 9,
        color: '#6c477a',
      },
    ],

  },
  {
    name: 'hr_cls',
    alias: 'Гидролитическая кислотность',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'нейтральная', 'близкая к нейтральной', 'слабокислая', 'среднекислая', 'сильнокислая', 'очень сильнокислая'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#7a0403',
      },
      {
        value: 2,
        color: '#ef5911',
      },
      {
        value: 3,
        color: '#e2dc38',
      },
      {
        value: 4,
        color: '#48f882',
      },
      {
        value: 5,
        color: '#3e9cfe',
      },
      {
        value: 6,
        color: '#30123b',
      },
    ],
  },
  {
    name: 'k2o_cls',
    alias: 'Содержание обменного калия',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень низкое', 'низкое', 'среднее', 'повышенное', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffffd4',
      },
      {
        value: 2,
        color: '#ffe19c',
      },
      {
        value: 3,
        color: '#feb351',
      },
      {
        value: 4,
        color: '#f0821e',
      },
      {
        value: 5,
        color: '#cc560c',
      },
      {
        value: 6,
        color: '#993404',
      },
    ],
  },
  {
    name: 'p2o5_cls',
    alias: 'Содержание подвижного фосфора',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень низкое', 'низкое', 'среднее', 'повышенное', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#76fff6',
      },
      {
        value: 2,
        color: '#66d5fc',
      },
      {
        value: 3,
        color: '#51c6e4',
      },
      {
        value: 4,
        color: '#3ca8c8',
      },
      {
        value: 5,
        color: '#2b70b1',
      },
      {
        value: 6,
        color: '#253494',
      },
    ],
  },
  {
    name: 's_cls',
    alias: 'Содержание подвижной серы',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fff347',
      },
      {
        value: 2,
        color: '#feb231',
      },
      {
        value: 3,
        color: '#d8731b',
      },
      {
        value: 4,
        color: '#993404',
      },

    ],
  },
  {
    name: 'mgo_cls',
    alias: 'Содержание обменного магния',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень низкое', 'низкое', 'среднее', 'повышенное', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#d3ffbf',
      },
      {
        value: 2,
        color: '#85db7d',
      },
      {
        value: 3,
        color: '#46b859',
      },
      {
        value: 4,
        color: '#1c944e',
      },
      {
        value: 5,
        color: '#00734c',
      },

    ],
  },
  {
    name: 'cu_cls',
    alias: 'Содержание подвижной меди',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfe9',
      },
      {
        value: 2,
        color: '#ff61f7',
      },
      {
        value: 3,
        color: '#c300ff',
      },
    ],
  },
  {
    name: 'zn_cls',
    alias: 'Содержание подвижного цинка',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfe9',
      },
      {
        value: 2,
        color: '#ff61f7',
      },
      {
        value: 3,
        color: '#c300ff',
      },
    ],
  },
  {
    name: 'mn_cls',
    alias: 'Содержание подвижного марганца',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfe9',
      },
      {
        value: 2,
        color: '#ff61f7',
      },
      {
        value: 3,
        color: '#c300ff',
      },
    ],
  },
  {
    name: 'co_cls',
    alias: 'Содержание подвижного кобальта',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfe9',
      },
      {
        value: 2,
        color: '#ff61f7',
      },
      {
        value: 3,
        color: '#c300ff',
      },
    ],
  },
  {
    name: 'mo_cls',
    alias: 'Содержание подвижного молибдена',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfbf',
      },
      {
        value: 2,
        color: '#ff6161',
      },
      {
        value: 3,
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'b_cls',
    alias: 'Содержание подвижного бора',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкое', 'среднее', 'высокое', 'очень высокое'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#ffbfbf',
      },
      {
        value: 2,
        color: '#ff6161',
      },
      {
        value: 3,
        color: '#ff0000',
      },
    ],
  },
  {
    name: 'sno_cls',
    alias: 'Нуждаемость в известковании',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'очень низкая', 'низкая', 'средняя', 'повышенная', 'высокая', 'очень высокая'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fbb51a',
      },
      {
        value: 2,
        color: '#ed6925',
      },
      {
        value: 3,
        color: '#bb3755',
      },
      {
        value: 4,
        color: '#781c6d',
      },
      {
        value: 5,
        color: '#330a5e',
      },
      {
        value: 6,
        color: '#140528',
      },
    ],
  },
  {
    name: 'ups_cls',
    alias: 'Поглотительная способность',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'низкая', 'средняя', 'высокая', 'очень высокая'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fbb51a',
      },
      {
        value: 2,
        color: '#ed6925',
      },
      {
        value: 3,
        color: '#bb3755',
      },
      {
        value: 4,
        color: '#781c6d',
      },

    ],
  },
  {
    name: 'compact_dg',
    alias: 'Степень уплотнения',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['отсутствует', 'низкая', 'средняя', 'высокая'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#fbb51a',
      },
      {
        value: 2,
        color: '#ed6925',
      },
      {
        value: 3,
        color: '#bb3755',
      },

    ],
  },

  {
    name: 'soiltexture',
    alias: 'Гранулометрический состав',
    type: VectorPaletteEnum.UNIQUE,
    customType: CustomPaintEnum.CLASS,
    classText: ['н/д', 'песок', 'супесь', 'лёгкий суглинок', 'средний суглинок', 'тяжёлый суглинок', 'глина'],
    palette: [
      {
        value: 0,
        color: '#ffffff',
      },
      {
        value: 1,
        color: '#faf564',
      },
      {
        value: 2,
        color: '#dec45b',
      },
      {
        value: 3,
        color: '#c49a51',
      },
      {
        value: 4,
        color: '#ab7648',
      },
      {
        value: 5,
        color: '#91563d',
      },
      {
        value: 6,
        color: '#783d32',
      },

    ],
  },
];
