import { v4 as uuid } from 'uuid';

export class Model {
  get uuid(): string {
    return this._uuid;
  }

  private readonly _uuid: string;

  constructor() {
    this._uuid = uuid();
  }
}
