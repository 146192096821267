import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import ApiService from '@/services/api/ApiService';
import { fetcher } from '@/lib/tools/fetcher';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

export class FieldTaskMapWorkModel extends FieldTaskMapModel {
  get show(): boolean {
    return this._show;
  }

  set show(value: boolean) {
    this._show = value;
  }

  private _show = false;

  async delete() {
    await ApiService.taskMap.deleteTaskMap('work', this.id);
  }

  async fetchData() {
    await fetcher(LoadingNamesEnum.TASK_GEOJSON, this.id, false, async () => {
      const { data } = await ApiService.taskMap.getTaskWorkId(this.id);
      if (data.geojson) {
        super.setGeoJson(data.geojson);
      }
    });
  }
}
