<template lang="pug" src="./Content.pug"/>
<style lang="scss" src="./Content.scss"/>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { ElMessageBox } from 'element-plus';
import { defineComponent, Slots, useSlots } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Content',
  components: { UiIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    confirmClose: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
    },
  },
  setup(props) {
    const slots = useSlots();
    const {
      showContent,
    } = useMapLayout();
    const router = useRouter();

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const onClose = () => {
      if (props.confirmClose) {
        ElMessageBox.confirm(
          props.confirmText,
          'Уверены, что хотите закрыть?',
          {
            confirmButtonText: 'Закрыть',
            cancelButtonText: 'Отмена',
            type: 'warning',
          },
        )
          .then(() => {
            router.push({
              name: 'struct',
            });
          });
      } else {
        router.push({
          name: 'struct',
        });
      }
    };

    return {
      hasSlot,
      onClose,
      showContent,
    };
  },
});
</script>
