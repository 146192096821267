import { pythonRound } from '@/utils/pythonRound';

export const formatDistance = (distance: number) => {
  const meters = pythonRound(distance * 1000);
  if (meters < 1000) {
    return `${meters} м`;
  } if (meters < 100000) {
    return `${(meters / 1000).toString().slice(0, 4)} км`;
  }
  return `${pythonRound(meters / 1000)} км`;
};
