<template lang="pug" src="./NavigationSubmenu.pug"/>
<style lang="scss" src="./NavigationSubmenu.scss"/>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import { useNavigation } from '@/composables/useNavigation';
import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlockNameType } from '@/constants/types/BlockNameType';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useUser } from '@/composables/useUser';
import { useBilling } from '@/composables/useBilling';
import PermissionsList from '@/modules/permissions/PermissionsList';

export default defineComponent({
  name: 'NavigationSubmenu',
  components: {},
  setup() {
    const { activeSector } = useNavigation();
    const { user } = useUser();
    const { contract } = useBilling();
    const { showBlock, areaComponents, clearArea } = useMapLayout();
    const submenuHovered = ref(false);
    const hovered = ref<string>('');
    const menu = computed(() => activeSector.value?.children || []);

    const open = (item: NavigationMenuItemType) => {
      submenuHovered.value = false;
      if (item.block) {
        clearArea(MapLayoutAreaEnum.LEFT);
        clearArea(MapLayoutAreaEnum.RIGHT);

        setTimeout(() => {
          item.block && showBlock(item.block);
        },
        0);
      }
    };

    const isActiveBlock = computed(() => (block: BlockNameType) => (areaComponents.value[MapLayoutAreaEnum.LEFT].includes(block) || areaComponents.value[MapLayoutAreaEnum.RIGHT].includes(block)));

    const submenuActive = computed(() => menu.value.length > 0 && (areaComponents.value[MapLayoutAreaEnum.LEFT].length + areaComponents.value[MapLayoutAreaEnum.RIGHT].length) === 0);

    const menuItemPermission = computed(() => (item: NavigationMenuItemType) => (item.permission ? PermissionsList.hasPermission(item.permission.target, item.permission.action) : true));

    const over = (name: string) => {
      hovered.value = name;
    };
    const mouseOut = () => {
      hovered.value = '';
    };

    watch(menu, (v) => {
      if (v.length === 0) {
        document.documentElement.style.setProperty('--map-area-submenu-width', '0px');
      } else {
        document.documentElement.style.setProperty('--map-area-submenu-width', '50px');
      }
      EventBus.$emit(EventsEnum.BlockReRender);
      EventBus.$emit(EventsEnum.MapLayoutChanged);
    });

    watch(submenuActive, (v) => {
      if (v) {
        document.documentElement.style.setProperty('--map-area-inner-left-padding', '200px');
      } else {
        document.documentElement.style.setProperty('--map-area-inner-left-padding', '0px');
      }
      EventBus.$emit(EventsEnum.BlockReRender);
      EventBus.$emit(EventsEnum.MapLayoutChanged);
    });

    EventBus.$on(EventsEnum.UserLogout, () => {
      document.documentElement.style.setProperty('--map-area-submenu-width', '0px');
      document.documentElement.style.setProperty('--map-area-inner-left-padding', '0px');
    });

    return {
      menu,
      over,
      mouseOut,
      open,
      hovered,
      user,
      submenuHovered,
      isActiveBlock,
      submenuActive,
      menuItemPermission,
    };
  },
});
</script>
