<template lang="pug" src="./UiChart.pug"/>
<style lang="scss" src="./UiChart.scss"/>

<script lang="ts">
import {
  computed,
  defineComponent, PropType,
} from 'vue';
import { useUser } from '@/composables/useUser';

export default defineComponent({
  name: 'UiChart',
  components: {},
  props: {
    values: {
      type: Array as PropType<number[]>,
      required: true,
    },
    height: {
      type: Number,
      default: 200,
    },
    borders: {
      type: Array as PropType<number[]>,
      required: true,
    },
    accuracy: {
      type: Number,
      default: 100,
    },
  },

  setup(props) {
    const { user } = useUser();
    // const { arr, height, borders } = toRefs(props);

    const matrixChart = computed(() => {
      const sortedValues = props.values.filter((n: number) => !Number.isNaN(n)).sort((a, b) => a - b);
      const matrix: number[] = Array(props.accuracy).fill(0);
      const min = sortedValues[0];
      const stepValue = (sortedValues[sortedValues.length - 1] - min) / props.accuracy;
      sortedValues.forEach((v) => {
        matrix[Math.floor((v - min) / stepValue)] += 1;
      });
      return matrix;
    });

    const labels = computed(() => {
      const sortedValues = props.values.filter((n: number) => !Number.isNaN(n)).sort((a, b) => a - b);

      const min = sortedValues[0];
      const max = sortedValues[sortedValues.length - 1];

      return [
        min,
        min + (max - min) / 4,
        min + (max - min) / 2,
        min + (3 * (max - min)) / 4,
        max,
      ];
    });

    const maxCount = computed(() => matrixChart.value.reduce((acc, v) => {
      if (!Number.isNaN(v) && v > acc) {
        acc = v;
      }
      return acc;
    }, -Infinity));

    const reactivePaint = (v: number) => {
      let color;
      if (props.borders.length === 2) {
        if (v < Number(props.borders[0])) {
          color = '#A50000';
        } else if (v <= Number(props.borders[1])) {
          color = '#F0F700';
        } else if (v > Number(props.borders[1])) {
          color = '#60AF00';
        }
      }
      if (props.borders?.length === 3) {
        if (v < Number(props.borders[0])) {
          color = '#A50000';
        } else if (v < Number(props.borders[1])) {
          color = '#E8A500';
        } else if (v <= Number(props.borders[2])) {
          color = '#F0F700';
        } else if (v > Number(props.borders[2])) {
          color = '#60AF00';
        }
      }
      return color;
    };

    return {
      user,
      matrixChart,
      reactivePaint,
      maxCount,
      labels,
    };
  },
});
</script>
