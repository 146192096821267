import { computed, ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

const _serverTime = ref<Date>();

const _serverVersion = ref<string>('');

export const useStatus = () => {
  const serverTime = computed({
    get: () => _serverTime.value,
    set: (v) => {
      _serverTime.value = v;
      EventBus.$emit(EventsEnum.ServerTime, v);
    },
  });
  const serverVersion = computed({
    get: () => _serverVersion.value,
    set: (v) => {
      if (_serverVersion.value !== v) {
        _serverVersion.value = v;
      }
    },
  });

  return {
    serverTime,
    serverVersion,
  };
};
