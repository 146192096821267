<template src="./UserListContent.pug" lang="pug"/>
<style src="./UserListContent.scss" lang="scss"/>
<script lang="ts">
import { DArrowLeft, Plus } from '@element-plus/icons-vue';
import { defineComponent, onMounted } from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { useUser } from '@/composables/useUser';
import ApiService from '@/services/api/ApiService';
import LeftPanel from '@/components/shared/LeftPanel/LeftPanel.vue';
import AdminService from '@/modules/admin/AdminService';

export default defineComponent({
  name: 'UserListContent',
  components: { Plus, LeftPanel },
  setup() {
    const {
      showBlock, hideBlock,
    } = useMapLayout();
    const { user } = useUser();
    const { subject } = useUser();
    const openEdit = async (id: number) => {
      const { data } = await ApiService.user.readSubjectsUser({ user_id: id.toString() });
      await useUser().setSubjectUser(data);
      await hideBlock('UserEditBlock');
      await showBlock('UserEditBlock');
    };
    const openAddUser = () => {
      showBlock('AddUserBlock');
    };
    const goBack = () => {
      hideBlock('UserEditBlock');
      showBlock('AdminListBlock');
    };
    const fetch = () => {
      AdminService.loadSubject();
    };

    onMounted(() => {
      fetch();
    });
    return {
      openAddUser,
      Plus,
      DArrowLeft,
      goBack,
      openEdit,
      subject,
      user,
    };
  },
});
</script>
