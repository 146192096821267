<template lang="pug" src="./NavigationMobile.pug"/>
<style lang="scss" src="./NavigationMobile.scss"/>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useUser } from '@/composables/useUser';
import AuthService from '@/services/auth/AuthService';
import { useMapLayout } from '@/composables/useMapLayout';
import StructList from '@/modules/struct/StructList';
import { useTolgee } from '@tolgee/vue';
import { useNotifications } from '@/composables/useNotifications';
import { notificationFormatMessage } from '@/utils/notificationFormatMessage';
import { formatRuDateTime } from '@/utils/formatRuDateTime';
import { ElTree } from 'element-plus';
import { Tree } from 'element-plus/lib/components/tree-v2/src/types';

export default defineComponent({
  name: 'NavigationMobile',
  components: { UiIcon },
  setup() {
    const { user } = useUser();
    const { showBlock } = useMapLayout();

    const active = ref(false);

    const activeCollapse = ref();

    const showNotifications = ref(false);

    const showStructList = ref(false);

    const searchStruct = ref('');

    const confirmStructChange = ref({
      active: false,
      value: null,
    });

    const treeRef = ref<InstanceType<typeof ElTree>>();

    watch(searchStruct, (a) => {
      treeRef.value!.filter(a);
    });

    const filterNode = (value: string, data: Tree) => {
      if (!value) return true;
      // @ts-ignore
      return data.label.toLowerCase().includes(value);
    };
    const logout = () => {
      AuthService.forceLogout();
    };

    const profile = () => {
      active.value = false;
      showBlock('UserProfileBlock');
    };

    const structTitle = computed(() => {
      if (StructList.activeStruct.value) {
        return `<span>${StructList.structPath(StructList.activeStruct.value).join('</span><span>')}</span>`;
      }
      return useTolgee().value?.t('struct-is-not-selected', { ns: 'navigation' });
    });

    const dayMonth = (date: Date) => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleString('ru-RU', options);
    };

    const computedList = computed(() => {
      const arr: any[] = [];
      let index = -1;
      const sortElem = [...useNotifications().notifications.value].sort((a, b) => (a.createDate > b.createDate ? -1 : 1));
      sortElem.forEach((a, i) => {
        if (a.title === sortElem[i - 1]?.title) {
          arr[index].push(a);
        } else {
          index++;
          arr.push([]);
          arr[index].push(a);
        }
      });
      return arr;
    });

    const structTree = computed(() => {
      const rootItems = [];
      const lookup = {};

      // Создаем хэш-таблицу для быстрого доступа к узлам
      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const { id, name } = item;
        lookup[id] = { id, label: name, children: [] };
      }

      // Строим дерево
      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const { id, parent } = item;
        const treeItem = lookup[id];

        if (parent === null) {
          // Если у элемента нет родителя, добавляем его в корневые элементы
          rootItems.push(treeItem);
        } else {
          // Если у элемента есть родитель, добавляем его в список детей родителя
          lookup[parent].children.push(treeItem);
        }
      }

      return rootItems;
    });

    const structTreeProps = {
      children: 'children',
      label: 'label',
    };

    const goToStructConfirmation = (node: { id: number, label: string }) => {
      const unit = StructList.structs.value.find((s) => s.id === node.id);
      if (unit?.isSelectable) {
        confirmStructChange.value.value = node;
        confirmStructChange.value.active = true;
      }
    };

    const goToStruct = () => {
      const unit = StructList.structs.value.find((s) => s.id === confirmStructChange.value.value.id);
      if (unit.isSelectable) {
        window.location.href = `/app/struct/${unit.id}`;
      }
    };
    return {
      StructList,
      user,
      active,
      logout,
      profile,
      structTitle,
      showNotifications,
      dayMonth,
      computedList,
      notificationFormatMessage,
      formatRuDateTime,
      activeCollapse,
      showStructList,
      structTree,
      structTreeProps,
      goToStructConfirmation,
      goToStruct,
      confirmStructChange,
      searchStruct,
      filterNode,
      treeRef,
    };
  },
});
</script>
