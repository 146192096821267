<template lang="pug" src="./HoverLayer.pug"/>
<style lang="scss" src="./HoverLayer.scss"/>

<script lang="ts">
import {
  defineComponent, ref, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { useMapHover } from '@/composables/useMapHover';
import { useMapLayout } from '@/composables/useMapLayout';

export default defineComponent({
  name: 'HoverLayer',
  components: {},

  setup() {
    const { user } = useUser();
    const { cords, hoverActive, hoverLayer } = useMapHover();
    const { paddings } = useMapLayout();

    const style = ref({});

    const calculateStyle = () => {
      const width = window.document.querySelector('.MapLayout_inner-workspace')?.clientWidth || 0;
      const height = window.document.querySelector('.MapLayout_inner-workspace')?.clientHeight || 0;

      const popupWidth = window.document.querySelector('.HoverLayer')?.clientWidth || 0;
      const popupHeight = window.document.querySelector('.HoverLayer')?.clientHeight || 0;

      if (cords.value[1] + popupHeight > height && cords.value[0] + popupWidth - paddings.value.top > width) {
        style.value = {
          top: `${cords.value[1] - paddings.value.top - popupHeight}px`,
          left: `${cords.value[0] - popupWidth - paddings.value.left}px`,
        };
        return;
      }
      if (cords.value[0] + popupWidth - paddings.value.left > width) {
        style.value = {
          top: `${cords.value[1] - paddings.value.top}px`,
          left: `${cords.value[0] - popupWidth - paddings.value.left}px`,
        };
        return;
      }

      if (cords.value[1] + popupHeight - paddings.value.top > height) {
        style.value = {
          top: `${cords.value[1] - paddings.value.top - popupHeight}px`,
          left: `${cords.value[0] - paddings.value.left}px`,
        };
        return;
      }
      style.value = {
        top: `${cords.value[1] - paddings.value.top}px`,
        left: `${cords.value[0] - paddings.value.left}px`,
      };
    };

    watch(cords, () => {
      calculateStyle();
    });

    return {
      hoverActive,
      user,
      style,
      hoverLayer,
    };
  },
});
</script>
