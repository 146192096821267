import { Ref, ref, UnwrapRef } from 'vue';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { CropModel } from '@/models/crop/CropModel';
import StructEvents from '@/modules/struct/StructEvents';
import { CropItemDto } from '@/services/api/dto/crop/CropItemDto';

class CropList {
  get selected(): Ref<CropModel | undefined> {
    return this._selected;
  }

  set selected(value: Ref<CropModel | undefined>) {
    this._selected = value;
  }

  get crops(): Ref<UnwrapRef<CropModel[]>> {
    return this._crops;
  }

  private _crops = ref<CropModel[]>([]);

  private _selected = ref<CropModel|undefined>();

  constructor() {
    StructEvents.onChangeStructUnit(async () => {
      await this.fetchCrops(true);
    });
  }

  async patchCrop(dto: CropItemDto) {
    this._crops.value.find((v) => v.id === dto.id)?.patch(dto);
  }

  async fetchCrops(force = false) {
    if (LoadingStatus.isNone.value(LoadingNamesEnum.CROP_LIST) || force) {
      LoadingStatus.loading(LoadingNamesEnum.CROP_LIST);
      try {
        const { data } = await ApiService.crop.getCrops();
        this._crops.value.splice(0, this._crops.value.length);
        data.forEach((dto) => {
          if (dto.entity) {
            this._crops.value.push(new CropModel(dto));
          }
        });
        LoadingStatus.success(LoadingNamesEnum.CROP_LIST);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.CROP_LIST, e);
      }
    }
  }

  public hasCropEntityId = (entityId: number) => !!this._crops.value.find((r) => r.entityId === entityId);

  getCropById(id: number) {
    return this._crops.value.find((v) => v.id === id);
  }

  toggleSelected(item: CropModel) {
    if (this._selected.value?.id === item.id) {
      this._selected.value = undefined;
    } else {
      this._selected.value = item;
    }
  }
}

export default new CropList();
