import { Router } from 'vue-router';

const DEFAULT_TITLE = 'True Fields';

export const titleMiddleware = (router: Router) => {
  router.afterEach((to) => {
    // @ts-ignore
    window.document.title = to.meta.title || DEFAULT_TITLE;
  });
};
