<template lang='pug' src='./GroupPalettePanel.pug'/>
<style lang='scss' src='./GroupPalettePanel.scss'/>

<script lang="ts">
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { ImageNodeType } from '@/constants/types/ImageNodeType';
import SettingsPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/SettingsPalettePanel.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'GroupPalettePanel',
  components: { SettingsPalettePanel },
  props: {
    node: {
      type: Object as PropType<ImageNodeType>,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
  },

  emits: ['delete-palette', 'save-name'],
  setup() {
    return {};
  },
});
</script>
