import { ASFSourceZones } from '@/assets/data/ASFSourceZones';
import { BASE_MAP_IMAGE_SCALE_SIZE } from '@/constants/constants/constants';
import { ASFSourceZoneType } from '@/constants/types/ASFSourceZoneType';
import { TaskImageZoneType } from '@/constants/types/TaskImageZoneType';
import tiffResize from '@/lib/tiff/tiffResize';
import tiffUnpacker from '@/lib/tiff/tiffUnpacker';
import { generalization } from '@/lib/tools/generalization';
import { TaskIndexDto } from '@/services/api/dto/taskMap/TaskIndexDto';
import EventBus from '@/services/eventBus/EventBus';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import FieldsList from '@/modules/fields/FieldsList';
import MaskProcessor from '@/models/taskMap/MaskProcessor';

export class TaskImageModel {
  private readonly _bbox: [number, number, number, number] = [0, 0, 0, 0];

  private readonly _bounds: LngLatBounds;

  private readonly _sourceWidth: number = 0;

  private readonly _sourceHeight: number = 0;

  private readonly _sourceData: Uint8ClampedArray = new Uint8ClampedArray();

  private fieldGeoJSON;

  private _clearMaskPacked: string[] | undefined;

  constructor(dto: TaskIndexDto) {
    this._bbox = dto.bbox;
    this._sourceData = new Uint8ClampedArray(tiffUnpacker(dto.payload));
    this._sourceWidth = dto.width;
    this._sourceHeight = dto.height;
    this._scaledData = tiffResize(this._sourceData, this._sourceWidth, BASE_MAP_IMAGE_SCALE_SIZE);
    this.fieldGeoJSON = FieldsList.getFieldModel(dto.field).dto.geometry;
    this.computeImageDataZoned();
    this.clearPixelsOutsidePolygon();

    const sw = new LngLat(this.bbox[0], this.bbox[3]);
    const ne = new LngLat(this.bbox[2], this.bbox[1]);
    this._bounds = new LngLatBounds(sw, ne);
  }

  private _clearMask: Uint8Array | undefined;

  get clearMask(): Uint8Array | undefined {
    return this._clearMask;
  }

  get bbox(): [number, number, number, number] {
    return this._bbox;
  }

  get bounds(): LngLatBounds {
    return this._bounds;
  }

  get sourceData(): Uint8ClampedArray {
    return this._sourceData;
  }

  get sourceHeight(): number {
    return this._sourceHeight;
  }

  get sourceWidth(): number {
    return this._sourceWidth;
  }

  get scaledWidth(): number {
    return this._sourceWidth * BASE_MAP_IMAGE_SCALE_SIZE;
  }

  get scaledHeight(): number {
    return this._sourceHeight * BASE_MAP_IMAGE_SCALE_SIZE;
  }

  // eslint-disable-next-line camelcase
  get formattedZones(): { value: number, zone: string, proc: number, proc_seed: number}[] {
    return this._zones.map((z) => ({
      value: z.indexColor,
      zone: (this._sourceZones.find((tz) => tz.value === z.indexColor) || { zone: '0' }).zone,
      proc: z.proc,
      proc_seed: z.procSeed,
    }));
  }

  private _scaledData: Uint8ClampedArray = new Uint8ClampedArray();

  get scaledData(): Uint8ClampedArray {
    return this._scaledData;
  }

  set scaledData(value: Uint8ClampedArray) {
    this._scaledData = value;
  }

  private _imageDataZoned: Uint8ClampedArray = new Uint8ClampedArray();

  get imageDataZoned(): Uint8ClampedArray {
    return this._imageDataZoned;
  }

  set imageDataZoned(value: Uint8ClampedArray) {
    this._imageDataZoned = value;
  }

  private _alreadyCreated = false;

  get alreadyCreated(): boolean {
    return this._alreadyCreated;
  }

  set alreadyCreated(value: boolean) {
    this._alreadyCreated = value;
  }

  private _zones: TaskImageZoneType[] = [
    {
      value: 3, min: 1, indexColor: 1, proc: 90, procSeed: 90,
    },
    {
      value: 6, min: 4, indexColor: 5, proc: 100, procSeed: 100,
    },
    {
      value: 9, min: 7, indexColor: 9, proc: 110, procSeed: 100,
    },
  ];

  get zones(): TaskImageZoneType[] {
    return this._zones;
  }

  set zones(value: TaskImageZoneType[]) {
    this._zones = value;
    this.computeImageDataZoned();
  }

  private _sourceZones: ASFSourceZoneType[] = JSON.parse(JSON.stringify(ASFSourceZones));

  get sourceZones(): ASFSourceZoneType[] {
    return this._sourceZones;
  }

  getZoneByValue(value: number): TaskImageZoneType {
    return this._zones.find((z) => z.min <= value && z.value >= value);
  }

  public computeImageDataZoned(): void {
    const data = this.modifyDataByZones(this._scaledData);
    this._imageDataZoned = new Uint8ClampedArray(data);
    this.clearPixelsOutsidePolygon();
  }

  /**
   * Удаляет мелкие фигуры с поля указанного размера
   * @param source Boolean - использовать исходную картинку
   * @param size Number - размер генерализации в гектарах
   */
  public generalization(source = false, size: number): void {
    if (source) {
      this._imageDataZoned = generalization(new Uint8ClampedArray(this.modifyDataByZones(this._scaledData)), this.scaledWidth, this.scaledHeight, size * 10000);
    } else {
      this._imageDataZoned = generalization(this._imageDataZoned, this.scaledWidth, this.scaledHeight, size * 10000);
    }
    this.clearPixelsOutsidePolygon();
    EventBus.$emit('BaseMap-editor-update-image');
  }

  pasteZones(data: string | null): void {
    if (data) {
      this._zones = [];
      JSON.parse(data).forEach((row: TaskImageZoneType) => {
        this._zones.push({
          value: row.value,
          min: row.min,
          indexColor: row.indexColor,
          proc: row.proc,
          procSeed: row.procSeed,
        });
      });
      this.computeImageDataZoned();
    }
  }

  pasteSourceZones(data: string | null): void {
    if (data) {
      JSON.parse(data).forEach((row: ASFSourceZoneType) => {
        const sz = this._sourceZones.find((v) => v.value === row.value);
        if (sz) {
          sz.proc = row.proc;
          sz.proc_seed = row.proc_seed;
        }
      });
    }
  }

  public clearPixelsOutsidePolygon(): void {
    if (!this._clearMask) {
      const processor = new MaskProcessor(this.bbox, this.fieldGeoJSON, this.sourceWidth, this.sourceHeight, this.scaledWidth, this.scaledHeight);
      const { clearMask, packedMask } = processor.computeMasks();
      this._clearMask = clearMask;
      this._clearMaskPacked = packedMask;
    }

    // Применяем маску
    MaskProcessor.applyPackedMask(this._clearMaskPacked, this._imageDataZoned);
  }

  private modifyDataByZones(imageData: Uint8ClampedArray) {
    const data = Array.from(imageData);
    const sz = new Array(10).fill(0);
    data.forEach((v, i) => {
      if (v) {
        if (!sz[v]) {
          const zone = this.getZoneByValue(v);
          if (zone) {
            sz[v] = zone.indexColor;
          }
        }
        data[i] = sz[v];
      }
    });
    return data;
  }
}
