import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.hoverActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "HoverLayer",
          style: _normalizeStyle(_ctx.style)
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hoverLayer)))
        ], 4))
      : _createCommentVNode("", true)
  ]))
}