<template src="./SortCaret.pug" lang="pug"/>
<style src="./SortCaret.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { SortDirectionEnum } from '@/constants/enums/SortDirectionEnum';
import {
  CaretBottom, CaretTop, SortUp, SortDown, Sort,
} from '@element-plus/icons-vue';

export default defineComponent({
  name: 'SortCaret',
  props: {
    sort: {
      type: Number as PropType<SortDirectionEnum>,
      required: true,
    },
    inactiveColor: {
      type: String,
      default: '#CCC',
    },
    activeColor: {
      type: String,
      default: '#0F8764',
    },
  },
  components: {
    CaretTop,
    CaretBottom,
    SortUp,
    SortDown,
    Sort,
  },
  setup() {
    // component code here
    return {
      SortDirectionEnum,
    };
  },
});
</script>
