import { AnyObject } from '@/constants/types/BasedTypes';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { LoggerConsoleProvider } from './providers/console';

class LoggerService {
  public origin: LoggerGroupsEnum | undefined;

  public active = false;

  private providers = {
    console: new LoggerConsoleProvider(),
  };

  constructor(origin: LoggerGroupsEnum | undefined = undefined) {
    this.origin = origin;

    if (origin) {
      this.active = (window.localStorage.getItem('console') || '').split(',').includes(Object.keys(LoggerGroupsEnum)[Object.values(LoggerGroupsEnum).indexOf(origin as unknown as LoggerGroupsEnum)]);
    }
  }

  private send(
    type: 'log' | 'warn' | 'error' | 'group',
    args: {
      message: string;
      args: any;
    },
  ) {
    if (type !== 'error' && this.origin && !this.active) {
      return;
    }
    if (this.providers.console && this.providers.console[type]) {
      this.providers.console[type]({
        ...args,
        origin: (this.origin || '').toString(),
      });
    }
  }

  public log(message: string, ...args: any[]) {
    this.send('log', {
      message,
      args,
    });
  }

  public warn(message: string, ...args: any[]) {
    this.send('warn', {
      message,
      args,
    });
  }

  public error(error: string | unknown | Error | AnyObject, ...args: any[]) {
    const message = () => {
      if (error instanceof Error) {
        return error.message;
      }
      return JSON.stringify(error);
    };

    this.send('error', {
      message: message(),
      args,
    });
  }

  public group(message: string, ...args: any[]) {
    this.send('group', {
      message,
      args,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public from(origin: LoggerGroupsEnum) {
    return new LoggerService(origin);
  }
}

export default new LoggerService();
