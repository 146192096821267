import Coordinates from '@/constants/types/mapbox/Coordinates';
import { Model } from '@/models/Model';

export class MapCanvasModel extends Model {
  /**
   * @param canvasId Id canvas
   * @param bboxCanvas Bbox canvas
   */
  constructor(canvasId: string, bboxCanvas: Coordinates) {
    super();
    this._canvasId = canvasId;
    this._bbox = bboxCanvas;
    this._canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    this._ctx = this._canvas.getContext('2d');
  }

  private _canvasId: string

  get canvasId(): string {
    return this._canvasId;
  }

  private _bbox: Coordinates

  get bbox(): Coordinates {
    return this._bbox;
  }

  set bbox(value: Coordinates) {
    this._bbox = value;
  }

  private _canvas: HTMLCanvasElement | undefined;

  get canvas(): HTMLCanvasElement | undefined {
    return this._canvas;
  }

  set canvas(value: HTMLCanvasElement | undefined) {
    this._canvas = value;
  }

  private _ctx: CanvasRenderingContext2D | null = null;

  get ctx(): CanvasRenderingContext2D | null {
    return this._ctx;
  }

  set ctx(value: CanvasRenderingContext2D | null) {
    this._ctx = value;
  }
}
