import { ColorsKitTypeEnum } from '@/constants/enums/ColorsKitTypeEnum';
import { ColorsKitColorType } from '@/constants/types/ColorsKitColorType';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';
import { ColorKitDto } from '@/services/api/dto/dictionary/ColorKitDto';
import { SimpleType } from '@/constants/types/BasedTypes';

export default class DictionaryColorsKitModel {
  get properties(): Record<string, SimpleType> {
    return this._properties;
  }

  get name(): string {
    return this._name;
  }

  get key(): string {
    return this._key;
  }

  get version(): number {
    return this._version;
  }

  get type(): ColorsKitTypeEnum {
    return this._type;
  }

  get units(): string | undefined {
    return this._units;
  }

  get opacity(): number {
    return this._opacity;
  }

  get colors(): ColorsKitColorType[] {
    return this._colors;
  }

  private _name: string; // Наименование набор цветов

  private _key: string; // ключ

  private _version = 0;

  private _type: ColorsKitTypeEnum;

  private _units: string | undefined;

  private _opacity: number;

  private _colors: ColorsKitColorType[]; // array of HEX colors (e.g. ['#FFF']

  private _properties: Record<string, SimpleType>;

  constructor(dto: ColorKitDto) {
    this._name = dto.name;

    this._key = dto.key;

    this._version = dto.version;

    this._type = dto.type;

    this._colors = dto.colors.map((c) => ({
      value: c.value,
      label: c.label,
      rgb: c.rgb,
      rgbHex: rgbaToHex(c.rgb),
    })) as ColorsKitColorType[];

    this._properties = dto.properties;

    this._opacity = dto.opacity || 1;
  }
}
