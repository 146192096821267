<template src="./UiButton.pug" lang="pug"/>
<style src="./UiButton.scss" lang="scss"/>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { UiSizesType } from '@/constants/types/UiSizesType';
import { UiColorsType } from '@/constants/types/UiColorsType';

export default defineComponent({
  name: 'UiButton',
  props: {
    label: {
      type: String,
    },
    radius: {
      type: String as PropType<'flat' | 'default' | 'round'>,
      default: 'default',
    },
    width: {
      type: String as PropType<'compact' | 'default' | 'extended' | 'full'>,
      default: 'default',
    },
    size: {
      type: String as PropType<UiSizesType>,
      default: 'm',
    },
    color: {
      type: String as PropType<UiColorsType>,
      default: 'primary',
    },
    icon: String,
    iconPrepend: String,
    iconAppend: String,
  },
  emits: ['click'],

  setup(props) {
    const classList = () => ({
      [`--width-${props.width}`]: true,
      [`--radius-${props.radius}`]: true,
      [`--size-${props.size}`]: true,
      [`--color-${props.color}`]: true,
    });

    return {
      classList,
    };
  },
});
</script>
