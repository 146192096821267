<template src="./LeftPanel.pug" lang="pug"/>
<style src="./LeftPanel.scss" lang="scss"/>

<script lang="ts">
import { Close } from '@element-plus/icons-vue';
import {
  computed, onMounted, ref,
  useSlots, defineComponent, shallowRef,
} from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';

export default defineComponent({
  name: 'LeftPanel',
  props: {
    title: {
      type: String,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  // @ts-ignore
  setup(props: { title: string, closeable: boolean }, { emit }) {
    const { clearArea } = useMapLayout();
    const slots = useSlots();
    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: any[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type.toString() !== 'Symbol(Comment)').length > 0;
    };
    const hasActions = computed(() => hasSlot('actions'));
    const hasFilters = computed(() => hasSlot('filters'));
    const hasFooter = computed(() => hasSlot('footer'));

    const onClose = () => {
      clearArea([MapLayoutAreaEnum.LEFT, MapLayoutAreaEnum.RIGHT]);
      emit('close');
    };

    const headerRef = shallowRef<HTMLElement|null>(null);
    const actionsRef = shallowRef<HTMLElement|null>(null);
    const filtersRef = shallowRef<HTMLElement|null>(null);
    const footerRef = shallowRef<HTMLElement|null>(null);

    const bodyHeight = ref('100%');

    onMounted(() => {
      const calcHeight = () => {
        let height = 0;
        height += headerRef.value?.clientHeight || 0;
        height += actionsRef.value?.clientHeight || 0;
        height += filtersRef.value?.clientHeight || 0;
        height += footerRef.value?.clientHeight || 0;
        return height;
      };
      setInterval(() => {
        bodyHeight.value = `calc(100% - ${calcHeight()}px)`;
      }, 300);
    });

    return {
      headerRef,
      actionsRef,
      filtersRef,
      footerRef,
      bodyHeight,
      Close,
      onClose,
      hasActions,
      hasFilters,
      hasFooter,
    };
  },
});
</script>
