<template lang="pug" src="./StatusBarBlock.pug"/>
<style lang="scss" src="./StatusBarBlock.scss"/>

<script lang="ts">
import VersionStatus from '@/modules/statusBar/ui/VersionStatus/VersionStatus.vue';
import NotificationStatus from '@/modules/statusBar/ui/NotificationStatus/NotificationStatus.vue';
import ProgressBarStatus from '@/modules/statusBar/ui/ProgressBarStatus/ProgressBarStatus.vue';
import MapHoverRasterValue
  from '@/modules/statusBar/ui/MapHoverRasterValue/MapHoverRasterValue.vue';
import TotalArea from '@/modules/statusBar/ui/TotalArea/TotalArea.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatusBarBlock',
  components: {
    VersionStatus,
    NotificationStatus,
    ProgressBarStatus,
    MapHoverRasterValue,
    TotalArea,
  },
  setup() {
    return {};
  },
});
</script>
