<template src="./NotFound.pug" lang="pug"/>
<style src="./NotFound.scss" lang="scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    message: {
      type: String,
    },
  },
  name: 'NotFound',
});
</script>
