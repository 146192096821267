import { MonitoringIndexKindDto } from '@/services/api/dto/monitoring/MonitoringIndexKindDto';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { MonitoringIndexGroupType } from '@/constants/types/monitoring/MonitoringIndexGroupType';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import MyTrackerService from '@/services/myTracker/MyTrackerService';

export class MonitoringIndexesList {
  get kinds(): Readonly<MonitoringIndexKindType[]> {
    return this._kinds;
  }

  get groups(): MonitoringIndexGroupType[] {
    return this._groups;
  }

  private _kinds: Readonly<MonitoringIndexKindType[]> = []

  private _groups: MonitoringIndexGroupType[] = [];

  private _activeKind: MonitoringIndexKindType | undefined;

  constructor(dto: MonitoringIndexKindDto[]) {
    let groupId = 0;
    dto.forEach((data) => {
      this._groups.push({
        name: data.name,
        label: data.label,
        id: groupId,
      });
      const _kinds: MonitoringIndexKindType[] = [];
      data.kinds.forEach((k) => {
        _kinds.push({
          name: k.name,
          label: k.label,
          value: k.value,
          hasContrast: k.has_contrast,
          colors: [],
          contrastColors: [],
          groupId,
        });
      });
      this._kinds = Object.freeze([
        ...this._kinds,
        ..._kinds,
      ]);
      groupId += 1;
    });
  }

  /**
   * Найти kind.
   * @param kind - принимает одно из трех значений:
   *  1. kind.value (as number);
   *  2. kind.name (as string);
   *  3. kind (as MonitoringIndexKindType).
   */
  getKind(kind: number | string | MonitoringIndexKindType) {
    if (typeof kind === 'number') {
      return this._kinds.find((k) => k.value === kind);
    } if (typeof kind === 'string') {
      return this._kinds.find((k) => k.name === kind);
    }
    return this._kinds.find((k) => k.value === kind.value);
  }

  /**
   * Получить активный индекс
   * @return MonitoringIndexKindType
   */
  getActiveKind() {
    return this._activeKind;
  }

  /**
   * Установить флаг активности для указанного kind. Флаг активности для текущего активного kind при этом снимается.
   * @param kind - принимает одно из трех значений:
   *  1. kind.value (as number);
   *  2. kind.name (as string);
   *  3. kind (as MonitoringIndexKindType).
   * @param flag - boolean to set active or inactive
   */
  setActiveKind(kind: number | string | MonitoringIndexKindType, flag = true) {
    this._activeKind = flag ? this.getKind(kind) : undefined;
    if (flag) {
      MyTrackerService.send('Select index (calendar block)', { name: this._activeKind?.name || '', label: this._activeKind?.label || '' });
      EventBus.$emit('fetchIndexes', this._activeKind);
    } else {
      EventBus.$emit(EventsEnum.MapToggleMonitoringIndex);
    }
  }
}
