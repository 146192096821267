<template src="./ProgressCard.pug" lang="pug"/>
<style src="./ProgressCard.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';

export default defineComponent({
  name: 'ProgressCard',
  props: {
    /** color accepts array (r, g, b, alpha) */
    color: {
      type: Array,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    summary: {
      type: String,
    },
  },
  setup() {
    // component code here
    return {};
  },
});
</script>
