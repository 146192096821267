<template src="./MapListContent.pug" lang="pug"/>
<style src="./MapListContent.scss" lang="scss"/>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { DArrowLeft, Operation, Plus } from '@element-plus/icons-vue';
import { useMapLayout } from '@/composables/useMapLayout';
import LeftPanel from '@/components/shared/LeftPanel/LeftPanel.vue';
import AdminService from '@/modules/admin/AdminService';
import { RasterTypeEnum } from '@/constants/enums/RasterTypeEnum';
import { VectorTypeEnum } from '@/constants/enums/VectorTypeEnum';
import { useAdmin } from '@/modules/admin/useAdmin';
import MapVectorsList from '@/modules/map/MapVectorsList';
import MapRastersList from '@/modules/map/MapRastersList';
import PermissionsList from '@/modules/permissions/PermissionsList';
import ItemMap from './itemMap/itemMap.vue';

export default defineComponent({
  name: 'MapListContent',
  components: { LeftPanel, ItemMap, Operation },
  setup() {
    // region const
    const { showBlock } = useMapLayout();
    const { editMap } = useAdmin();
    const img = ref();
    // endregion

    // region actionLayouts
    const openEdit = (id: number, chek: any) => {
      MapRastersList.activeEditRaster.value = undefined;
      MapVectorsList.activeEditVector.value = undefined;

      editMap.value = chek ? MapRastersList.setEditRaster(id)
        : MapVectorsList.setEditVector(id);
      showBlock('MapEditBlock');
    };

    const openAddMap = () => {
      // showBlock('AddMapBlock');
    };

    const goBack = () => {
      showBlock('AdminListBlock');
    };

    const computedListMap = computed(() => [...MapRastersList.rasters.value.filter((r) => r.raster_type === RasterTypeEnum.user), ...MapVectorsList.vectors.value.filter((v) => v.type === VectorTypeEnum.USER)]);

    // endregion

    const openLayerControl = () => {
      showBlock('ControlRasterLayersBlock');
    };

    return {
      PermissionsList,
      AdminService,
      computedListMap,
      openAddMap,
      goBack,
      openEdit,
      img,
      DArrowLeft,
      Plus,
      Operation,
      openLayerControl,
    };
  },
});
</script>
