import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["color", "src", "height", "width"]

export function render(_ctx, _cache) {
  const _directive_svg_inline = _resolveDirective("svg-inline")

  return _withDirectives((_openBlock(), _createElementBlock("img", {
    color: _ctx.color,
    src: _ctx.name,
    height: _ctx.size + 'px',
    width: _ctx.size + 'px'
  }, null, 8, _hoisted_1)), [
    [_directive_svg_inline]
  ])
}