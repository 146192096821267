/* eslint-disable no-console */

import Day from '@/modules/core/models/Day';
import { isDevMode } from '@/lib/tools/isDevMode';

const DEFAULT_METHODS = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  groupCollapsed: console.groupCollapsed,
  groupEnd: console.groupEnd,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const formatter = ({ message, origin, type = 'info' }: { message: string, origin: string, type?: 'info' | 'warn' | 'error' }) => {
  const timestamp = `${Day.format('HH:mm:ss.SSS')}`;
  const colors = {
    info: '#008bdf',
    warn: '#ed9d03',
    error: '#ff0000',
  };

  return [
    `%c${timestamp} %c${type}%c ${origin} %c${message}`,
    'color: #aaa; font-weight: 400',
    `padding: 1px 3px; color: #fff; background-color: ${colors[type]}; font-weight: 400; text-transform: uppercase;`,
    'font-weight: 700',
    'font-weight: 400',
  ];
};

export class LoggerConsoleProvider {
  private _log;

  private _warn;

  private _error;

  private _groupCollapsed;

  private _groupEnd;

  constructor() {
    this._log = DEFAULT_METHODS.log;
    this._warn = DEFAULT_METHODS.warn;
    this._error = DEFAULT_METHODS.error;
    this._groupCollapsed = DEFAULT_METHODS.groupCollapsed;
    this._groupEnd = DEFAULT_METHODS.groupEnd;

    const emptyFn = () => {
      /* empty */
    };

    if (!isDevMode()) {
      console.log = emptyFn;
    }

    console.groupCollapsed = emptyFn;
    console.groupEnd = emptyFn;
  }

  public log({ message = '', origin = '', args = [] }) {
    this._log(...formatter({ message, origin }), ...args);
  }

  public warn({ message = '', origin = '', args = [] }) {
    this._warn(...formatter({ message, origin, type: 'warn' }), ...args);
  }

  public error({ message = '', origin = '', args = [] }) {
    this._error(...formatter({ message, origin, type: 'error' }), ...args);
  }

  public group({ message = '', origin = '', args = [] }) {
    this._groupCollapsed(...formatter({ message, origin }));
    this._log(...args);
    this._groupEnd();
  }
}
