import LoggerService from '@/services/logger/LoggerService';
import ApiService from '@/services/api/ApiService';
import { useMap } from '@/composables/useMap';
import { Map } from 'mapbox-gl';
import MapStyleSpecification from '@/constants/types/mapbox/MapStyleSpecification';
import MapLayerSpecification from '@/constants/types/mapbox/MapLayerSpecification';

const excludeLayers = [
  'agro_struct_field_contour',
  'agro_map_taskplot',
  'agro_struct_field_rating_fill',
  'fields_labels',
  'circle',
];

const setStyle = async (map: Map) => {
  try {
    if (useMap().mapStyle.value) {
      map.setStyle(useMap().mapStyle.value as MapStyleSpecification);
    } else {
      const { data } = await ApiService.gis.readStyle({ id: process.env.VUE_APP_MAP_STYLES_URL || 3 });

      data.layers = data.layers.filter((layer: MapLayerSpecification) => !excludeLayers.includes(layer.id) && !excludeLayers.includes(layer.type)) as MapLayerSpecification[];

      data.layers.forEach((layer: MapLayerSpecification) => {
        if (layer.source === 'agro_vector_data') {
          map.removeLayer(layer.id);
        }
      });

      data.layers.forEach((layer: MapLayerSpecification) => {
        if (layer.type === 'raster' && layer.metadata?.farmunit_id && layer.layout) {
          map.removeLayer(layer.id);
        }
      });
      // data.glyphs = 'https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/resources/fonts/{fontstack}/{range}.pbf';
      // data.sprite = 'https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/resources/sprites/sprite';
      map.setStyle(data as MapStyleSpecification);
      useMap().mapStyle.value = data;
    }
  } catch (e) {
    LoggerService.error('Load style error:', e);
    map.setStyle('https://demotiles.maplibre.org/style.json');
  }
};

export const InitStyle = async (map: Map) => {
  await setStyle(map);
};
