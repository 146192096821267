import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import type { TaskFieldDto } from '@/services/api/dto/gis/TaskFieldDto';
import type { TaskMapListDto } from '@/services/api/dto/gis/TaskMapListDto';
import type { TaskCandidateDto } from '@/services/api/dto/taskMap/TaskCandidateDto';
import type { TaskIndexDto } from '@/services/api/dto/taskMap/TaskIndexDto';
import { TaskMapDataDto } from '@/services/api/dto/taskMap/TaskMapDataDto';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import { TaskMapReportDto } from '@/services/api/dto/taskMap/TaskMapReportDto';
import { TaskMapsDto } from '@/services/api/dto/taskMap/TaskMapsDto';
import type { TaskMapWorkDto } from '@/services/api/dto/taskMap/TaskMapWorkDto';
import type { Polygon } from 'geojson';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getCandidates: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/candidate/`,
  getCandidateField: (farmUnitId: number, fieldId: number): string => `/api/v1/task-map/${farmUnitId}/candidate/${fieldId}`,
  getTaskBase: (farmUnitId: number, id: number, fieldId: number): string => `/api/v1/task-map/${farmUnitId}/candidate/${fieldId}/${id}/`,
  getAllTasks: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/base/field/`,
  getTasksField: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/base/${id}/`,
  createBaseMap: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/base/from-payload/`,
  getViewTasks: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/work/`,
  postCreateTaskMap: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/work/general/`,
  getTaskWorkId: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/work/${id}/`,
  deleteTaskWorkId: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/work/${id}/`,
  deleteTaskBaseId: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/base/${id}/`,
  createFeatureExperiment: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/base/${id}/copy-with-experiment/`,
  createTaskFromPayload: (farmUnitId: number): string => `/api/v1/task-map/${farmUnitId}/work/from-payload/`,
  getFieldTaskMaps: (farmUnitId: number, id: number): string => `/api/v1/task-map/${farmUnitId}/field/${id}/`,
  getData: (farmUnitId: number, type: string, id: number) => `/api/v1/task-map/${farmUnitId}/${type}/${id}/`,
  deleteTaskMap: (farmUnitId: number, type: string, id: number) => `/api/v1/task-map/${farmUnitId}/${type}/${id}/`,
  getOverlap: (farmUnitId: number, type: 'work' | 'base', taskId: number) => `/api/v1/task-map/${farmUnitId}/${type}/${taskId}/overlap/`,
  getTaskMapReport: (farmUnitId: number) => `/api/v1/task-map/${farmUnitId}/work/report/`,
};

export class ApiTaskMap extends ApiHandler {
  public async getCandidateField(farmUnitId: number, fieldId: number): Promise<ApiResponse<TaskCandidateDto[]>> {
    return await this.request({ auth: true }).get<TaskCandidateDto[]>(endpoints.getCandidateField(farmUnitId, fieldId));
  }

  public async getCandidates(farmUnitId: number): Promise<ApiResponse<TaskCandidateDto[]>> {
    return await this.request({ auth: true }).get<TaskCandidateDto[]>(endpoints.getCandidates(farmUnitId));
  }

  public async getTaskBase(farmUnitId: number, id: number, fieldId: number): Promise<ApiResponse<TaskIndexDto>> {
    return await this.request({ auth: true }).get<TaskIndexDto>(endpoints.getTaskBase(farmUnitId, id, fieldId));
  }

  public async createBaseMap(farmUnitId: number, data: any): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).post<void>(endpoints.createBaseMap(farmUnitId), data);
  }

  public async getAllTasks(): Promise<ApiResponse<TaskMapListDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<TaskMapListDto[]>(endpoints.getAllTasks(unit));
  }

  public async getTasksField(farmUnitId: number, id: number): Promise<ApiResponse<TaskFieldDto>> {
    return await
    this.request({ auth: true }).get<TaskFieldDto>(endpoints.getTasksField(farmUnitId, id));
  }

  public async getViewTasks(farmUnitId: number, page = 0): Promise<ApiResponse<TaskMapWorkDto[]>> {
    return await
    this.request({ auth: true, pagination: 3000 }).get<TaskMapWorkDto[]>(endpoints.getViewTasks(farmUnitId));
  }

  public async postCreateTask(farmUnitId: number, data: any): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postCreateTaskMap(farmUnitId), data);
  }

  public async getTaskWorkId(id: number): Promise<ApiResponse<TaskMapWorkDto>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).get<TaskMapWorkDto>(endpoints.getTaskWorkId(unit, id));
  }

  public async getFieldTaskMaps(fieldId: number): Promise<ApiResponse<TaskMapsDto>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).get<TaskMapsDto>(endpoints.getFieldTaskMaps(unit, fieldId));
  }

  public async getOverlap(taskId: number, taskType: 'work' | 'base', indexId: number): Promise<ApiResponse<TaskMapIndexOverlapDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, freeze: false }).post<TaskMapIndexOverlapDto>(endpoints.getOverlap(unit, taskType, taskId), {
      index: indexId,
    });
  }

  public async deleteTaskWorkId(farmUnitId: number, id: number): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deleteTaskWorkId(farmUnitId, id));
  }

  public async deleteTaskBaseId(farmUnitId: number, id: number): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deleteTaskBaseId(farmUnitId, id));
  }

  public async getData(type: 'base' | 'work' | 'fact' | 'harvest', id: number): Promise<ApiResponse<TaskMapDataDto>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).get<TaskMapDataDto>(endpoints.getData(unit, type, id));
  }

  public async deleteTaskMap(type: 'base' | 'work' | 'fact' | 'harvest', id: number): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).delete<void>(endpoints.deleteTaskMap(unit, type, id));
  }

  public async createFeatureExperiment(id: number, data: {
    zone: string,
    proc: number,
    // eslint-disable-next-line camelcase
    proc_seed: number,
    geom: Polygon
  }[]): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).post<any>(endpoints.createFeatureExperiment(unit, id), { experiment_plots: data });
  }

  public async createTaskFromPayload(data: {
    // eslint-disable-next-line camelcase
    task_name: string,
    type: number,
    field: number,
    index: number,
    payload: string[],
    bbox: [number, number, number, number],
    width: number,
    height: number,
    // eslint-disable-next-line camelcase
    zones: {value: number, zone: string, proc: number, proc_seed: number}[]
    material: {norma: number, name: string, unit: string},
    user: {email: string}}, structId: number): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.createTaskFromPayload(structId), data);
  }

  public async getTaskMapReport(): Promise<ApiResponse<TaskMapReportDto[]>> {
    const unit = useStruct().structId.value;
    return await
    this.request({ auth: true }).get<TaskMapReportDto[]>(endpoints.getTaskMapReport(unit));
  }
}
