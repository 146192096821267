<template src="./StructListContent.pug" lang="pug"/>
<style src="./StructListContent.scss" lang="scss"/>
<script lang="ts">
import { DArrowLeft, Plus } from '@element-plus/icons-vue';
import { useMapLayout } from '@/composables/useMapLayout';
import ApiService from '@/services/api/ApiService';
import LeftPanel from '@/components/shared/LeftPanel/LeftPanel.vue';
import { useUser } from '@/composables/useUser';
import StructList from '@/modules/struct/StructList';
import { useAdminStruct } from '@/composables/admin/useAdminStruct';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StructListContent',
  components: { Plus, LeftPanel },
  setup() {
    const { hideBlock, showBlock } = useMapLayout();
    const { user } = useUser();
    const { editStruct } = useAdminStruct();

    const openEdit = async (id: number) => {
      const { data } = await ApiService.struct.readStructUnits(id);
      editStruct.value = data;
      await hideBlock('StructEditBlock');
      await showBlock('StructEditBlock');
    };

    const goBack = () => {
      hideBlock('StructEditBlock');
      showBlock('AdminListBlock');
    };

    const openAddStruct = () => {
      showBlock('AddStructBlock');
    };

    return {
      DArrowLeft,
      goBack,
      openEdit,
      StructList,
      Plus,
      openAddStruct,
      user,
    };
  },
});
</script>
