import { computed, ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { ReadStruct } from '@/services/api/dto/struct/ReadStruct';

const _editStruct = ref<ReadStruct>();

EventBus.$on(EventsEnum.UserLogout, () => {
  _editStruct.value = undefined;
});

export const useAdminStruct = () => {
  const editStruct = computed({
    get: () => _editStruct.value,
    set: (v) => {
      _editStruct.value = v;
    },
  });

  function setEditStruct(struct: ReadStruct) {
    _editStruct.value = struct;
  }

  return {
    editStruct,
    setEditStruct,
  };
};
