<template lang="pug" src="./FieldEditBlock.pug"/>
<style lang="scss" src="./FieldEditBlock.scss"/>

<script lang="ts">
import { LandCategory } from '@/assets/data/LandCategory';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import UiInput from '@/components/ui/Input/UiInput.vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import { useAuth } from '@/composables/useAuth';
import { useFormatter } from '@/composables/useFormatter';
import { useMap } from '@/composables/useMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { useNavigation } from '@/composables/useNavigation';
import { FIELD_MAX_AREA_SIZE } from '@/constants/constants/fields';
import { createHelperHtml, editHelperHtml } from '@/constants/constants/helpers';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { RuleFieldName } from '@/constants/rules/RuleFieldName';
import { FieldModel } from '@/models/field/FieldModel';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldsList from '@/modules/fields/FieldsList';
import MonitoringChartBlock
  from '@/modules/monitoring/ui/MonitoringChartBlock/MonitoringChartBlock.vue';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import type { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import EventBus from '@/services/eventBus/EventBus';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { fieldSquare } from '@/utils/fieldSquare';
import {
  Delete, Download, EditPen, Place,
} from '@element-plus/icons-vue';
import { area } from '@turf/turf';
import {
  ElMessageBox, ElNotification, FormInstance, FormRules,
} from 'element-plus';
import { MultiPolygon, Polygon } from 'geojson';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'FieldEditBlock',
  components: {
    UiIcon,
    Download,
    EditPen,
    Delete,
    Place,
    RightPanel,
    UiInput,
    MonitoringChartBlock,
    NotFound,
  },
  props: {
    field: {
      type: Object as PropType<FieldModel>,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const { hideBlock, showBlock } = useMapLayout();
    const {
      fields,
      fitStruct,
      mapModel,
      helper,
      informationMode,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { accessToken } = useAuth();
    const { formatEwkt } = useFormatter();
    const { activeSector } = useNavigation();
    const { drawerStatus } = useMap();
    const active = ref<boolean>(false);
    const activeDrag = ref<boolean>(false);
    const square = (sq: number) => fieldSquare(sq, 'g-w');
    const exportDialog = ref(false);
    const checkSq = ref<number>(0);
    const copeFeature = ref<FieldFeatureDto>();
    const weather = ref();

    const form = ref({
      name: '',
      name_rus: '',
      farmunit: StructList.activeStruct.value?.id,
      descr: '',
      sq_acc: 0,
      rates: [] as string[],
    });
    const polygon = ref<Polygon>({
      type: 'Polygon',
      coordinates: [],
    });

    const disabled = computed(() => drawerStatus.value.mode !== 'inactive');

    const editFeature = computed(() => props.field?.dto);

    const setMapOnPolygonDraw = () => {
      if (editFeature.value?.geometry.coordinates !== undefined) {
        const p: MultiPolygon = {
          type: 'MultiPolygon',
          coordinates: editFeature.value?.geometry.coordinates,
        };
        checkSq.value = Math.round(area(p) / 100) / 100;
      }
      EventBus.$emit(EventsEnum.MapPolygonDrawStart, editFeature.value);
    };

    const toggleEdit = () => {
      active.value = !active.value;
      if (active.value) {
        form.value.name = editFeature.value?.properties.name || '';
        form.value.name_rus = editFeature.value?.properties.name_rus || '';
        form.value.descr = editFeature.value?.properties.descr || '';
        form.value.sq_acc = editFeature.value?.properties.sq_acc || 0;
        form.value.rates = editFeature.value?.properties.rating ? editFeature.value?.properties.rating.split('-') : [];
        if (form.value.rates.length === 0) form.value.rates = ['', ''];
        if (form.value.rates.length === 1) form.value.rates.push('');
      }
      editFeature.value && EventBus.$emit(EventsEnum.MapFeatureEdit, { feature: editFeature.value, edit: active.value });
    };

    const editModel = ref<MapAreaModel>(new MapAreaModel());
    const editLayer = ref<MapLayerDrawerModel>();
    const toggleEditDrag = () => {
      activeDrag.value = !activeDrag.value;
      if (activeDrag.value) {
        setMapOnPolygonDraw();
        copeFeature.value = editFeature.value;
        if ('coordinates' in editModel.value.polygon.geometry) {
          editModel.value.polygon.geometry.coordinates = [...copeFeature.value?.geometry.coordinates[0]];
        }
        editLayer.value = mapModel.value.render(editModel.value as MapAreaModel) as MapLayerDrawerModel;
        editLayer.value.setActiveMode('edit');
      } else {
        setTimeout(() => {
          editLayer.value.setActiveMode('none');
          helper.value = '';
          mapModel.value.removeLayer(editLayer.value.uuid);
          copeFeature.value && EventBus.$emit(EventsEnum.MapEditOutline, { feature: copeFeature.value, edit: activeDrag.value });
          EventBus.$emit(EventsEnum.MapPolygonDrawStop);
        });
      }
    };

    const exportRequest = () => {
      exportDialog.value = true;
    };

    const validateName = (rule: any, value: any, callback: any) => {
      if (fields.value.some((f) => f.name === form.value.name && f.id !== Number(editFeature.value?.properties.pk))) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const fieldEditRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      name: [
        ...RuleFieldName,
        {
          validator: validateName,
          trigger: 'blur',
        },
      ],
      name_rus: [
        {
          min: 0,
          max: 256,
          message: 'Длина полного наименования поля не должна превышать 256 символов',
          trigger: ['blur', 'change'],
        },
      ],
    });

    const isMultipolygon = computed(() => editFeature.value?.geometry.coordinates[0].length > 1 || editFeature.value?.geometry.coordinates.length > 1);

    const save = async () => {
      active.value = false;
      const formData = new FormData();
      Object.keys(form.value).forEach((key) => {
        if (key === 'rates') {
          formData.append('rating', form.value.rates.filter((v) => v).join('-'));
        } else {
          // @ts-ignore
          formData.append(key, form.value[key]);
        }
      });
      const fieldId = Number(editFeature.value?.properties.pk);
      if (fieldId) {
        await ApiService.struct.putFields(fieldId, formData);
        await FieldsList.fetchField(fieldId);
          mapModel.value?.events.emitFieldUpdated();
      }
    };
    const saveOutline = () => {
      if (checkSq.value > FIELD_MAX_AREA_SIZE) {
        ElNotification({
          title: 'Детали',
          message: `Поле должно быть меньше ${FIELD_MAX_AREA_SIZE} га`,
          type: 'warning',
          position: 'bottom-right',
        });
        return;
      }
      const formData = new FormData();
      // @ts-ignore
      formData.append('geom', formatEwkt(editModel.value.polygon.geometry.coordinates[0]));
      ApiService.struct.putFields(Number(editFeature.value?.properties.pk), formData)
        .then(async (a) => {
          await FieldsList.getFieldModel(Number(editFeature.value?.properties.pk))?.update(a.data.geojson);
          mapModel.value.removeLayer(editLayer.value.uuid);
          FieldsEvents.emitFeatureUpdated();
        });
      toggleEditDrag();
    };

    const onSubmit = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          save();
        }
      });
    };

    const closeEdit = () => {
      hideBlock('FieldEditBlock');
      setTimeout(() => {
        if (activeSector.value?.block === 'FieldsListBlock') {
          showBlock('StructViewBlock');
          fitStruct();
        }
      }, 100);

      EventBus.$emit(EventsEnum.FieldToggleActivate, editFeature.value?.properties.pk, false);
      EventBus.$emit(EventsEnum.MapFeatureEdit, { feature: editFeature.value, edit: false });
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
    };

    const deleteField = () => {
      ElMessageBox.prompt(
        `Чтобы удалить поле, введите delete/${editFeature.value?.properties.name}`,
        'Удаление поля',
        {
          inputValidator: (text) => text === `delete/${editFeature.value?.properties.name}`,
          confirmButtonText: 'OK',
          cancelButtonText: 'Отмена',
          type: 'warning',
          confirmButtonClass: 'waning',
          inputErrorMessage: `Введите delete/${editFeature.value?.properties.name}`,
        },
      )
        .then(() => {
          if (editFeature.value) {
            FieldsList.deleteField(Number(editFeature.value.properties.pk)).then(() => {
              EventBus.$emit(EventsEnum.MapFeaturesUpdated);
              closeEdit();
              informationMode.value = 'none';
            });
          }
        });
    };

    const landCategories = computed(() => [
      {
        value: '',
        label: 'Не выбрано',
      },
      ...Object.keys(LandCategory).sort().map((key) => ({
        value: key.toString(),
        label: key,
      })),
    ]);

    const file = (type: 'contour', format: 'esri' | 'kml') => {
      if (type === 'contour') {
        const structId = useStruct().structId.value;
        return `${process.env.VUE_APP_API_URL}/gis/export_fields/?farmunit=${structId}&fieldlist=${editFeature.value?.properties.pk}&format=${format}&access_token=${accessToken.value}`;
      }
      return '';
    };

    const structTitle = computed(() => (StructList.activeStruct.value ? `<span>${StructList.structPath(StructList.activeStruct.value).join('</span> <span>')}</span>` : ''));

    const nameError = computed(() => 'yes');

    const isNumber = (evt: KeyboardEvent): void => {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      }
    };

    const stopDrag = (e: KeyboardEvent): void => {
      if (e.keyCode === 27 && activeDrag.value) {
        toggleEditDrag();
      }
    };

    const fetchWeatherData = async () => {
      if (props.field !== undefined) {
        const { data } = await ApiService.weather.fetchData(props.field.bounds.getCenter());
        weather.value = data;
      } else {
        weather.value = undefined;
      }
    };

    // region хуки
    watch(editFeature, () => {
      active.value = false;
      activeDrag.value && toggleEditDrag();
      fetchWeatherData();
    });

    onBeforeUnmount(() => {
      closeEdit();
    });

    document.addEventListener('keydown', stopDrag);

    onUnmounted(() => {
      EventBus.$emit(EventsEnum.FieldToggleActivate, editFeature.value?.properties.pk);
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
      EventBus.$emit(EventsEnum.MapEditOutline, { feature: editFeature.value, edit: false });
      useMap().activeFieldId.value = undefined;
      document.removeEventListener('keydown', stopDrag);
      activeDrag.value && toggleEditDrag();
    });

    const handlerUpdateDrawerMode = (mode: string) => {
      if (activeDrag.value) {
        if (mode === 'edit') {
          helper.value = editHelperHtml;
        } if (mode === 'create') {
          helper.value = createHelperHtml;
        } if (mode === 'none') {
          helper.value = '';
        }
      }
    };

    onMounted(() => {
      fetchWeatherData();
    });
    mapModel.value.events.onUpdateDrawerMode(handlerUpdateDrawerMode);
    onUnmounted(() => {
      mapModel.value.events.offUpdateDrawerMode(handlerUpdateDrawerMode);
    });

    // endregion
    return {
      FIELD_MAX_AREA_SIZE,
      disabled,
      saveOutline,
      activeDrag,
      toggleEditDrag,
      Download,
      EditPen,
      Delete,
      deleteField,
      editFeature,
      isMultipolygon,
      active,
      toggleEdit,
      save,
      closeEdit,
      structTitle,
      form,
      onSubmit,
      nameError,
      rules,
      fieldEditRef,
      exportRequest,
      exportDialog,
      landCategories,
      square,
      file,
      isNumber,
      weather,
      capitalizeFirstLetter,
    };
  },
});
</script>
