import { CropModel } from '@/models/crop/CropModel';
import { CropRotationFieldDto } from '@/services/api/dto/cropRotation/CropRotationFieldDto';

export class CropRotationModel {
  get perennialYear(): number {
    return this._perennialYear;
  }

  get sowingId(): number {
    return this._sowingId;
  }

  get harvestDate(): Date {
    return this._harvestDate;
  }

  get plannedYield(): number {
    return this._plannedYield;
  }

  get actualYield(): number {
    return this._actualYield;
  }

  get note(): string {
    return this._note;
  }

  get seasonId(): number {
    return this._seasonId;
  }

  get cropItem(): CropModel {
    return this._cropItem;
  }

  get fieldId(): number {
    return this._fieldId;
  }

  get hybridId(): number | undefined {
    return this._hybridId;
  }

  get hybridName(): string | undefined {
    return this._hybridName;
  }

  get isPerennial(): boolean {
    return this._isPerennial;
  }

  set isPerennial(value: boolean) {
    this._isPerennial = value;
  }

  set isGreenManure(value: boolean) {
    this._isGreenManure = value;
  }

  get isGreenManure(): boolean {
    return this._isGreenManure;
  }

  private _cropItem: CropModel;

  private _fieldId: number;

  private _hybridId: number | undefined;

  private _hybridName: string | undefined;

  private _sowingDate: Date;

  private _harvestDate: Date;

  private _plannedYield: number;

  private _actualYield: number;

  private _note: string;

  private _seasonId: number;

  private _sowingId: number;

  private _isGreenManure: boolean;

  private _isPerennial: boolean;

  private _perennialYear: number;

  constructor(dto: CropRotationFieldDto) {
    this._sowingId = dto.id;
    this._fieldId = dto.field;
    this._seasonId = dto.season;
    this._cropItem = new CropModel(dto.crop_item);
    if (dto.hybrid_item) {
      this._hybridId = dto.hybrid_item.id;
      this._hybridName = dto.hybrid_item.name;
    }
    this._sowingDate = new Date(dto.sowing_date);
    this._harvestDate = new Date(dto.harvest_date);
    this._plannedYield = dto.planned_yield;
    this._actualYield = dto.actual_yield;
    this._isGreenManure = dto.is_green_manure;
    this._isPerennial = dto.is_perennial;
    this._perennialYear = dto.perennial_year;
    this._note = dto.note;
    if (!this._cropItem.color) {
      this._cropItem.color = dto.crop_item.entity.color;
    }
  }

  update(dto: CropRotationFieldDto) {
    this._fieldId = dto.field;
    this._seasonId = dto.season;
    this._cropItem = new CropModel(dto.crop_item);
    if (dto.hybrid_item) {
      this._hybridId = dto.hybrid_item.id;
      this._hybridName = dto.hybrid_item.name;
    } else {
      this._hybridId = undefined;
      this._hybridName = undefined;
    }
    this._sowingDate = new Date(dto.sowing_date);
    this._harvestDate = new Date(dto.harvest_date);
    this._plannedYield = dto.planned_yield;
    this._actualYield = dto.actual_yield;
    this._isGreenManure = dto.is_green_manure;
    this._isPerennial = dto.is_perennial;
    this._perennialYear = dto.perennial_year;
    this._note = dto.note;
    if (!this._cropItem.color) {
      this._cropItem.color = dto.crop_item.entity.color;
    }
  }
}
