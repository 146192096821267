<template src="./ProgressBarStatus.pug" lang="pug"/>
<style src="./ProgressBarStatus.scss" lang="scss" />

<script lang="js">
import { ref, defineComponent } from 'vue';
import { ElNotification } from 'element-plus';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

export default defineComponent({
  name: 'ProgressBarStatus',
  setup() {
    const loadingValue = ref(0);
    const status = ref();
    let timer;
    EventBus.$on(EventsEnum.ProgressBarValue, ({ loaded, total }) => {
      clearTimeout(timer);
      const num = Number(((loaded / total) * 100).toFixed(0));
      loadingValue.value = num;
      if (num === 100) {
        status.value = 'success';
        setTimeout(() => {
          // ElNotification({
          //   title: 'Выгрузка завершена',
          //   type: 'success',
          //   position: 'bottom-right',
          // });
          loadingValue.value = 0;
          status.value = '';
        }, 2000);
        return;
      }
      timer = setTimeout(async () => {
        ElNotification({
          title: 'Ошибка загрузки',
          type: 'error',
          position: 'bottom-right',
          duration: 0,
        });
        loadingValue.value = 0;
        status.value = '';
      }, 7000);
    });
    return {
      status,
      loadingValue,
    };
  },
});
</script>
