<template src="./TaskProgressCard.pug" lang="pug"/>
<style src="./TaskProgressCard.scss" lang="scss"/>

<script lang="ts">
import { MAX_TASK_MAP_NORMA } from '@/constants/constants/constants';
import {
  defineComponent, ref, watch,
} from 'vue';
import { SimpleType } from '@/constants/types/BasedTypes';

export default defineComponent({
  name: 'TaskProgressCard',
  props: {
    /** color accepts array (r, g, b, alpha) */
    color: {
      type: Array,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      type: Number,
    },
    summary: {
      type: String,
    },
    editValue: {
      type: Boolean,
      required: false,
    },
    valuePostfix: {
      type: String,
      required: false,
    },
    progressName: {
      type: String,
      required: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: MAX_TASK_MAP_NORMA,
    },
  },
  emits: ['update-value'],
  setup(props, { emit }) {
    const progressValue = ref<SimpleType>(props.value);
    watch(() => props.value, (a) => {
      progressValue.value = a;
    });

    const formatter = (v: string) => {
      if (v === '') return null;

      let value = Number(v);
      if (value) {
        value = Number(value.toFixed(0));
        if (value < props.min) {
          emit('update-value', props.min);
          return props.min;
        }
        if (value > props.max) {
          emit('update-value', props.max);
          return props.max;
        }
        return value;
      }
      emit('update-value', 0);
      return 0;
    };
    const blur = () => {
      if ([null, '', undefined].some((a) => a === progressValue.value)) {
        progressValue.value = props.min;
      }
    };

    const updateValue = () => {
      emit('update-value', progressValue.value);
    };
    const parser = (v: string) => v.replace(/[^\d]/g, '');
    return {
      progressValue,
      updateValue,
      formatter,
      parser,
      blur,
    };
  },
});
</script>
