<template lang="pug" src="./NotificationStatus.pug"/>
<style lang="scss" src="./NotificationStatus.scss"/>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useNotifications } from '@/composables/useNotifications';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageEnums } from '@/constants/enums/SocketMessageEnums';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import { StorageNotificationsType } from '@/constants/types/storage/StorageNotificationsType';
import { NotificationModel } from '@/models/NotificationModel';
import StructEvents from '@/modules/struct/StructEvents';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import StorageService from '@/services/storage/StorageService';
import { formatRuDateTime } from '@/utils/formatRuDateTime';
import { notificationFormatMessage } from '@/utils/notificationFormatMessage';
import {
  Bell,
  CircleCloseFilled,
  InfoFilled,
  MessageBox,
  MuteNotification,
  WarningFilled,
} from '@element-plus/icons-vue';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'NotificationStatus',
  components: {
    UiIcon,
    MessageBox,
    InfoFilled,
    WarningFilled,
    CircleCloseFilled,
  },
  setup() {
    const { notifications } = useNotifications();

    const active = ref(false);

    const activeCollapse = ref();
    const activeFilterMessage = ref<boolean>(true);

    const computedList = computed(() => {
      const arr: any[] = [];
      let index = -1;
      const sortElem = [...useNotifications().notifications.value].sort((a, b) => (a.createDate > b.createDate ? -1 : 1));
      sortElem.forEach((a, i) => {
        if (a.title === sortElem[i - 1]?.title) {
          arr[index].push(a);
        } else {
          index++;
          arr.push([]);
          arr[index].push(a);
        }
      });
      return arr;
    });

    const fetchNotifications = async () => {
      if (StructList.activeStruct.value?.id) {
        const { data } = await ApiService.struct.getNotifications(StructList.activeStruct.value?.id);
        notifications.value = [...data].sort((a, b) => (a.id < b.id ? 1 : -1)).map((dto) => new NotificationModel(dto));
      } else {
        notifications.value = [];
      }
    };

    const unreadMessagesCount = computed(() => notifications.value.length);

    onMounted(async () => {
      await fetchNotifications();
      EventBus.$on(EventsEnum.SocketMessage, (message: SocketMessageType) => {
        if (message.event === SocketMessageEnums.NOTIFICATION) {
          setTimeout(() => {
            fetchNotifications();
          }, 500);
        }
      });
      StructEvents.onChangeStructUnit(() => {
        fetchNotifications();
      });
    });

    watch(active, () => {
      const n: StorageNotificationsType = {
        lastId: notifications.value.reduce((acc, v) => Math.max(v.id, acc), 0),
      };
      // @ts-ignore
      StorageService.setItem(StorageKeyEnum.NOTIFICATIONS, n);
    });

    onMounted(() => {
      activeFilterMessage.value = (window.localStorage.getItem('message') as 'on' | 'off' | undefined) === 'on';
    });

    watch(activeFilterMessage, (a, b) => {
      if (a !== b) {
        window.localStorage.setItem('message', activeFilterMessage.value ? 'on' : 'off');
      }
    });

    return {
      active,
      notifications,
      notificationFormatMessage,
      formatRuDateTime,
      unreadMessagesCount,
      computedList,
      activeCollapse,
      activeFilterMessage,
      MuteNotification,
      Bell,
    };
  },
});
</script>
