import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import type { App } from 'vue';
import UiButton from '@/components/ui/Button/UiButton.vue';
import List from '@/components/ui/OrderedList/OrderedList.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';

export const RegisterUiComponents = (app: App) => {
  app.component('ui-button', UiButton);
  app.component('ui-list', List);
  app.component('ui-icon', UiIcon);
  app.component('ui-dialog', UiDialog);
};
