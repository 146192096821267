import { computed, ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { PoiModel } from '@/models/poi/PoiModel';

const createPoiCoordinates = ref<number[]>([]);

const poiMoveMode = ref(false);

const poiMoveId = ref<number>();
const _showText = ref(JSON.parse(window.localStorage.getItem('poi-show-text') || JSON.stringify({ label: true, name: true })));

const formPoi = ref<{
  name: string
  poi: PoiModel | undefined
  poitype: number,
  group: undefined | number,
}>({
  name: '',
  poi: undefined,
  poitype: 0,
  group: undefined,
});

const groupEditForm = ref({
  name: '',
  type: 0,
});

const groupAddForm = ref({
  name: '',
  type: 0,
});

EventBus.$on(EventsEnum.UserLogout, () => {
  createPoiCoordinates.value = [];
  poiMoveMode.value = false;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePoi = () => {
  const showText = computed({
    get: () => _showText.value,
    set: (s) => {
      _showText.value = s;
    },
  });

  return {
    poiMoveId,
    poiMoveMode,
    createPoiCoordinates,
    formPoi,
    showText,
    groupEditForm,
    groupAddForm,
  };
};
