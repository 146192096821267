import { AnyLayer, LayerSpecification } from 'mapbox-gl';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';

const formatProperties = (properties: GeoJsonProperties, id: number, model: FieldTaskMapModel) => {
  const labels: string[] = [];

  const overlap = model.overlap;
  if (overlap) {
    const fp = overlap.properties.find((prop) => prop.feature_id === id);
    if (fp) {
      labels.push(`${overlap.index_label ? `${overlap.index_label}: ` : ''}${Math.round(fp.avg * 100) / 100}`);
    }
  }

  if (model.products.length > 0) {
    model.products.forEach((prod) => {
      if (prod.show) {
        const pf = properties.prod.find((v: any) => v.name === prod.name);
        if (pf) {
          labels.push(model.showLabels ? `${pf.name}: ${pf.rate}` : `${pf.rate}`);
        }
      }
    });
  }

  return {
    label: labels.join('\n'),
    ...properties,
    id,
  } as GeoJsonProperties;
};

export const taskMapBaseSourceDef = (model: FieldTaskMapBaseModel | FieldTaskMapWorkModel) => {
  if (model.geojson) {
    return {
      type: model.geojson?.type,
      bbox: model.geojson?.bbox,
      features: model.geojson?.features.map((feature) => ({
        type: 'Feature',
        geometry: feature.geometry,
        id: Number(feature.properties?.id || 0),
        properties: formatProperties(feature.properties, Number(feature.properties?.id || 0), model),
      })),
    } as FeatureCollection;
  }
  return {
    type: 'FeatureCollection',
    features: [],
  } as FeatureCollection;
};

export const taskMapBaseLayerDef = (sourceId: string, layerId: string): LayerSpecification => ({
  id: layerId,
  type: 'fill',
  source: sourceId,
  layout: {},
  metadata: { type: 'task-map-base' },
  paint: {
    'fill-color':
      ['case',
        ['==', ['get', 'zone'], '1.1'], '#BE0000',
        ['==', ['get', 'zone'], '1.2'], '#E51F02',
        ['==', ['get', 'zone'], '1.3'], '#FD5502',
        ['==', ['get', 'zone'], '2.1'], '#FCE502',
        ['==', ['get', 'zone'], '2.2'], '#FBFC02',
        ['==', ['get', 'zone'], '2.3'], '#E2FB03',
        ['==', ['get', 'zone'], '3.1'], '#65B424',
        ['==', ['get', 'zone'], '3.2'], '#2A7F01',
        ['==', ['get', 'zone'], '3.3'], '#236400',
        ['==', ['get', 'zone'], 1.1], '#BE0000',
        ['==', ['get', 'zone'], 1.2], '#E51F02',
        ['==', ['get', 'zone'], 1.3], '#FD5502',
        ['==', ['get', 'zone'], 2.1], '#FCE502',
        ['==', ['get', 'zone'], 2.2], '#FBFC02',
        ['==', ['get', 'zone'], 2.3], '#E2FB03',
        ['==', ['get', 'zone'], 3.1], '#65B424',
        ['==', ['get', 'zone'], 3.2], '#2A7F01',
        ['==', ['get', 'zone'], 3.3], '#236400',
        ['get', 'color'],
      ],
    'fill-opacity':
      ['case',
        ['boolean', ['feature-state', 'inactive'], false], 0,
        ['boolean', ['feature-state', 'active'], false], 1,
        ['boolean', ['feature-state', 'hover'], false], 1,
        0,
      ],
    'fill-opacity-transition': { duration: 500 },
    'fill-outline-color': '#000000',
  },
});

export const taskMapBaseLabelsLayerDef = (sourceId: string, layerId: string): AnyLayer => ({
  id: layerId,
  source: sourceId,
  type: 'symbol',
  metadata: { type: 'task-map-contour' },
  layout: {
    'text-field': ['get', 'label'],
    'text-anchor': 'center',
    'text-size': [
      'interpolate', ['linear'], ['zoom'],
      8, 0,
      9, 6,
      10, 7,
      12, 12,
      17, 15,
    ],
    'text-justify': 'center',
    'text-font': [
      'literal',
      ['Inter Bold'],
    ],
  },
  paint: {
    'text-color': '#222222',
    'text-halo-width': 1,
    'text-halo-color': 'rgba(255, 255, 255, 0.8)',
    'text-halo-blur': 1,
  },
});
