export const BaseMapColors = [
  [0, 0, 0, 0],
  [190, 0, 0, 255],
  [229, 31, 2, 255],
  [253, 85, 2, 255],
  [252, 229, 2, 255],
  [251, 252, 2, 255],
  [226, 251, 3, 255],
  [101, 180, 36, 255],
  [42, 127, 1, 255],
  [35, 100, 0, 255],
];
