import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { FileModel } from '@/models/file/FileModel';
import type { MapModel } from '@/models/map/MapModel';
import ApiService from '@/services/api/ApiService';
import { UnifiedVectorDto } from '@/services/api/dto/gis/UnifiedVectorDto';
import { ElNotification } from 'element-plus';
import { FeatureCollection } from 'geojson';
import { reactive } from 'vue';
import { analyzeVector } from '@/lib/tools/analyzeVector';
import { VectorKeyType } from '@/constants/types/VectorKeyType';

export class UnifiedVectorModel extends FileModel {
  /** Все ключи найденные в векторе */
  public keys = reactive<Record<string, VectorKeyType>>({});

  private readonly _scope: string;

  constructor(dto: UnifiedVectorDto) {
    super(dto.update_date);
    this._id = dto.id;
    this._bbox = dto.bbox;
    this._groupName = dto.group_name;
    this._isProcessed = dto.is_processed;
    this._layerId = dto.layer_id;
    this._name = dto.name;
    this._order = dto.order;
    this._preview = dto.preview || Math.random() < 0.5 ? null : 'https://cdn.onlinewebfonts.com/svg/img_98042.png';
    this._type = dto.type;
    this._updateDate = new Date(dto.update_date);
    this._scope = dto.scope;
    if (dto.vector) {
      this._vector = {
        id: dto.vector.id,
        filename: dto.vector.filename,
        vectorType: dto.vector.vector_type,
      };
    }
  }

  get scope(): string {
    return this._scope;
  }

  private _id: number;

  get id(): number {
    return this._id;
  }

  private _bbox: [number, number, number, number];

  get bbox(): [number, number, number, number] {
    return this._bbox;
  }

  private _groupName: string;

  get groupName(): string {
    return this._groupName;
  }

  private _isProcessed: boolean;

  get isProcessed(): boolean {
    return this._isProcessed;
  }

  private _layerId: number;

  get layerId(): number {
    return this._layerId;
  }

  private _name: string;

  get name(): string {
    return this._name;
  }

  private _order: number;

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  private _preview: string | null;

  get preview(): string | null {
    return this._preview;
  }

  private _type: string;

  get type(): string {
    return this._type;
  }

  private _updateDate: Date;

  get updateDate(): Date {
    return this._updateDate;
  }

  private _vector: {
    id: number,
    filename: string | null,
    vectorType: number
  } | undefined = undefined

  get vector(): { id: number; filename: string | null; vectorType: number } | undefined {
    return this._vector;
  }

  private _active = false;

  get active(): boolean {
    return this._active;
  }

  private _geojson: FeatureCollection | undefined;

  get geojson(): FeatureCollection | undefined {
    return this._geojson;
  }

  async fetchGeojson(force = false): Promise<void> {
    return fetcher(LoadingNamesEnum.VECTOR_GEOJSON, this.id, force, async () => {
      const { data } = await ApiService.gis.getVectorGeojson(this.id);
      if (data.geojson) {
        this._geojson = data.geojson;
        this.keys = analyzeVector(data.geojson.features);
      }
    });
  }

  async render(map: MapModel, treeKey: string, treeName: string, paletteId: string): Promise<boolean> {
    await this.fetchGeojson();
    if (this.geojson.features?.length === 0) {
      ElNotification({
        title: 'Ошибка файла.',
        message: 'Файл вектора не имеет данных для отображения.',
        type: 'error',
        duration: 2000,
        position: 'bottom-right',
      });
      return false;
    }
    map.render(this, { treeKey, treeName, paletteId });
    return true;
  }
}
