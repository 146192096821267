import PoisList from '@/modules/poi/PoisList';
import { usePoi } from '@/composables/usePoi';
import { FormItemRule } from 'element-plus/es/components/form/src/types';
import { InternalRuleItem, Value } from 'async-validator';
import { SyncValidateResult } from 'async-validator/dist-types/interface';

const uniqueNamePOI = (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void => {
  if (PoisList.pois.value.find((poi) => poi.group === usePoi().formPoi.value.group && poi.name === usePoi().formPoi.value.name)) {
    callback(new Error('Данное имя не уникально'));
  } else {
    callback();
  }
};

export const RulePoi: Record<string, FormItemRule[]> = {
  name: [
    {
      required: true,
      message: 'Введите название точки',
      trigger: ['blur', 'change'],
    },
    {
      validator: uniqueNamePOI,
      trigger: ['blur', 'change'],
    },
    {
      min: 1,
      max: 80,
      message: 'Длина поля не должна превышать  80 символов',
      trigger: ['blur', 'change'],
    }],
};
