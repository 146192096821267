import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ExperimentCard theme-dark" }
const _hoisted_2 = { class: "ExperimentCard-Form" }
const _hoisted_3 = { class: "ExperimentCard-Body" }
const _hoisted_4 = { class: "ExperimentCard-form" }
const _hoisted_5 = { class: "fw" }
const _hoisted_6 = { class: "ta-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ColorGradientPicker = _resolveComponent("ColorGradientPicker")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeTab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: _ctx.$t('experiment-card-form', 'Свойства опыта', { ns: 'experiment' }),
              name: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, { "label-position": "top" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_form_item, {
                          label: "Наименование",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: _ctx.experiment.title,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.experiment.title) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "Цвет опыта",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_ColorGradientPicker, {
                                modelValue: _ctx.experiment.color,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.experiment.color) = $event)),
                                palettes: _ctx.palettes,
                                "active-palette": _ctx.activePalette,
                                "onUpdate:activePalette": _ctx.setPalette,
                                "prefix-colors": ['#FBFC02'],
                                count: 15
                              }, null, 8, ["modelValue", "palettes", "active-palette", "onUpdate:activePalette"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_form_item, {
                          size: "small",
                          label: "Удобрения"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input_number, {
                              modelValue: _ctx.experiment.proc,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.experiment.proc) = $event)),
                              max: 100000,
                              min: 0,
                              precision: 0
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "Семена",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input_number, {
                              modelValue: _ctx.experiment.procSeed,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.experiment.procSeed) = $event)),
                              max: 100000,
                              min: 0,
                              precision: 0
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_el_popconfirm, {
                        title: _ctx.$t('delete-confirmation'),
                        "confirm-button-text": _ctx.$t('delete'),
                        "cancel-button-text": _ctx.$t('no'),
                        onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete')))
                      }, {
                        reference: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            type: "danger",
                            icon: _ctx.Delete,
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('delete-experiment', 'Удалить опыт', { ns: 'experiments' })), 1)
                            ]),
                            _: 1
                          }, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["title", "confirm-button-text", "cancel-button-text"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}