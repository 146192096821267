import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import ApiService from '@/services/api/ApiService';
import {
  reactive, Ref, ref, UnwrapRef,
} from 'vue';
import { CropRotationSeasonModel } from '@/models/cropRotation/CropRotationSeasonModel';
import { CropRotationModel } from '@/models/cropRotation/CropRotationModel';
import CropRotationEvents from '@/modules/cropRotation/CropRotationEvents';
import DictionaryEvents from '@/modules/dictionary/DictionaryEvents';
import { useApp } from '@/composables/useApp';
import StructEvents from '@/modules/struct/StructEvents';
import { fetcher } from '@/lib/tools/fetcher';

class CropRotationList {
  get activeSeason(): Ref<CropRotationSeasonModel | undefined> {
    return this._activeSeason;
  }

  get seasons(): Ref<UnwrapRef<CropRotationSeasonModel[]>> {
    return this._seasons;
  }

  private readonly _activeSeason = ref<CropRotationSeasonModel | undefined>();

  private readonly _seasons = ref<CropRotationSeasonModel[]>([]);

  public readonly rotations = reactive<CropRotationModel[]>([]);

  constructor() {
    DictionaryEvents.onCropDeleted(async () => {
      useApp().setLoadingScreen('crop-rotation', 'Обновление данных о севообороте');
      await this.fetchRotation(true);
      useApp().setLoadingScreen('crop-rotation');
    });
    StructEvents.onChangeStructUnit(async () => {
      await this.fetchSeasons(true);
      await this.fetchRotation(true);
    });
  }

  public setActiveSeason(id: number) {
    this._activeSeason.value = this._seasons.value.find((s) => s.id === id) as CropRotationSeasonModel | undefined;
    CropRotationEvents.emitSelectActiveSeason(this._activeSeason.value);
  }

  public getFieldData = (fieldId: number, seasonId: number | undefined = undefined) => {
    if (!seasonId) {
      seasonId = this.activeSeason.value?.id;
    }
    if (seasonId) {
      return this.rotations.find((v) => v.fieldId === fieldId && v.seasonId === seasonId);
    }
    return undefined;
  };

  async fetchRotation(force = false) {
    await fetcher(LoadingNamesEnum.CROP_ROTATION, 'default', force, async () => {
      this.rotations.splice(0, this.rotations.length);
      const { data } = await ApiService.cropRotation.getField();
      data.forEach((dto) => this.rotations.push(new CropRotationModel(dto)));
    });
  }

  async fetchSeasons(force = false) {
    await fetcher(LoadingNamesEnum.CROP_ROTATION_SEASONS, 'default', force, async () => {
      const { data } = await ApiService.cropRotation.getSeasons();

      const ids: number[] = [];
      data.forEach((dto) => {
        ids.push(dto.id);
        const season = this._seasons.value.find((v) => v.id === dto.id);
        if (season) {
          season.update(dto);
        } else {
          this._seasons.value.push(new CropRotationSeasonModel(dto));
        }
      });
      this._seasons.value.forEach((v) => {
        if (!ids.includes(v.id)) {
          this._seasons.value.splice(this._seasons.value.indexOf(v), 1);
        }
      });
      let closetStartDate: Date | undefined;
      let season: CropRotationSeasonModel | undefined;
      const currentDate = new Date();

      this._seasons.value.forEach((s) => {
        if (!closetStartDate || (closetStartDate < s.start && s.start < currentDate)) {
          closetStartDate = s.start as Date;
          season = s as CropRotationSeasonModel | undefined;
        }
      });

      this._seasons.value.sort((a, b) => (a.start < b.start ? -1 : 1));
      if (season) {
        this.setActiveSeason(season.id);
      }
    });
  }
}

export default new CropRotationList();
