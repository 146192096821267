import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ExperimentSelected theme-dark" }
const _hoisted_2 = { class: "ExperimentSelected-Form" }
const _hoisted_3 = { class: "ExperimentSelected-Header" }
const _hoisted_4 = { class: "flex-row flex-row-justify-between" }
const _hoisted_5 = { class: "ExperimentSelected-Body" }
const _hoisted_6 = { class: "mv-15" }
const _hoisted_7 = { class: "ExperimentSelected-Header" }
const _hoisted_8 = { class: "flex-row flex-row-align-center flex-row-justify-between" }
const _hoisted_9 = { class: "ml-8" }
const _hoisted_10 = { class: "ExperimentSelected-Info" }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ColorGradientPicker = _resolveComponent("ColorGradientPicker")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_popover = _resolveDirective("popover")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('selected-experiments', 'Выбранные эксперименты', { ns: 'experiments' })), 1),
          _createVNode(_component_el_button, {
            link: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear'))),
            type: "warning"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('clear')) + " (Esc)", 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedExperiments, (exp) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "ExperimentSelected-item",
            style: _normalizeStyle({ backgroundColor: _ctx.tagBackground(exp.color) })
          }, [
            _createElementVNode("div", {
              class: "ExperimentSelected-item-name",
              style: _normalizeStyle({ borderColor: exp.color })
            }, _toDisplayString(exp.title), 5)
          ], 4))
        }), 256))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('selected-experiments-set-color-header', 'Установить цвет для выбранных опытов', { ns: 'experiments' })), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ColorGradientPicker, {
            modelValue: _ctx.selectedColor,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedColor) = $event)),
            gradients: _ctx.gradients,
            "active-gradient": _ctx._activeGradient,
            "onUpdate:activeGradient": _cache[2] || (_cache[2] = ($event: any) => ((_ctx._activeGradient) = $event)),
            "onUpdate:active-": _ctx.setGradient,
            "prefix-colors": ['#FBFC02'],
            count: 15
          }, null, 8, ["modelValue", "gradients", "active-gradient", "onUpdate:active-"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_popover, {
              ref: "popoverRef",
              trigger: "hover",
              "virtual-triggering": "",
              persistent: "",
              "show-after": 500,
              placement: "top",
              width: 300
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('selected-experiments-apply-gradient', 'Нажав кнопку применить, опыты будут раскрашены по выбранному градиенту цветов по порядку выбора опытов.', { ns: 'experiments' })), 1)
              ]),
              _: 1
            }, 512),
            _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              onClick: _ctx.applyPalette,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('apply')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [_directive_popover, _ctx.popoverRef],
              [_directive_click_outside, _ctx.onClickOutside]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          innerHTML: _ctx.$t('action-copy', 'Нажав комбинацию кнопок Ctrl+C, вы скопируете выбранные эксперементы во временный буфер. Буфер доступен пока открыто окно "Добавление опытов на шаблон карты". Для вставки скопируемых опытов используйте Ctrl+V, Опыты будут вставлены в позицию нахождения курсора на карте.', { ns: 'experiments' })
        }, null, 8, _hoisted_11)
      ])
    ])
  ]))
}