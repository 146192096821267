<template lang="pug" src="./TotalArea.pug"/>
<style lang="scss" src="./TotalArea.scss"/>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import StructList from '@/modules/struct/StructList';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';

export default defineComponent({
  name: 'TotalArea',
  components: { UiIcon },
  setup() {
    const dayMonth = (date: Date) => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleString('ru-RU', options);
    };

    return {
      StructList,
      LoadingStatus,
      LoadingNamesEnum,
      dayMonth,
    };
  },
});
</script>
