import { Collection } from '@/collection/Collection';
import { ICollection } from '@/collection/ICollection';
import type { ExperimentModel } from '@/models/ExperimentModel';

export class ExperimentsCollection extends Collection implements ICollection {
  get collection(): ExperimentModel[] {
    return this._collection;
  }

  set collection(collection: ExperimentModel[]) {
    this._collection.splice(0, this._collection.length);
    collection.forEach((model) => this._collection.push(model));
  }

  private readonly _collection: ExperimentModel[] = [];

  update(experiments: ExperimentModel[]): void {
    const ids = this._collection.map((p) => p.uuid);
    this._collection.splice(0, this._collection.length);
    experiments.filter((p) => ids.includes(p.uuid)).forEach((m) => this._collection.push(m));
  }

  constructor(experiments: ExperimentModel[]) {
    super();
    this._collection = experiments;
  }
}
