import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerTaskMapModel } from '@/models/map/Layers/MapLayerTaskMapModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';

export class MapLayerTaskMapBaseModel extends MapLayerTaskMapModel {
  constructor(type: MapLayerTypeEnum, mapModel: MapModel, indexFile: MapInputType) {
    super(mapModel, type, 'task-map-base', indexFile.uuid, indexFile as FieldTaskMapBaseModel);
  }
}
