import Coordinates from '@/constants/types/mapbox/Coordinates';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { FieldIndexHistoryModel } from '@/models/field/FieldIndexHistoryModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';

export class MapLayerHistoryIndexModel extends MapLayerModel {
  get indexFile(): FieldIndexHistoryModel {
    return this._indexFile;
  }

  private _indexFile: FieldIndexHistoryModel;

  constructor(mapModel: MapModel, indexFile: MapInputType) {
    super(mapModel, MapLayerTypeEnum.HISTORY_INDEX, 'history-index', indexFile.uuid);
    this._indexFile = indexFile as FieldIndexHistoryModel;
    this.createSourceIndexFile();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  createSourceIndexFile = () => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'image',
      url: this._indexFile.imgColoredFile,
      coordinates: this._indexFile.geomCoordinates as Coordinates,
    });
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      source: this.sourceId,
      type: 'raster',
    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.HISTORY_INDEX_FILE);
  }
}
