import { computed, ref } from 'vue';
import { BillingContractModel } from '@/models/BillingContractModel';

const _contract = ref<BillingContractModel | undefined>(undefined);

export const useBilling = () => {
  const contract = computed({
    get: () => _contract.value,
    set: (v) => {
      _contract.value = v;
    },
  });

  return {
    contract,
  };
};
