import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskCandidateModel } from '@/models/taskMap/TaskCandidateModel';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';

export class TaskCandidatesList {
  get fieldsLoaded(): Readonly<number[]> {
    return this._fieldsLoaded;
  }

  get fieldsLoading(): Readonly<number[]> {
    return this._fieldsLoading;
  }

  get candidates(): Readonly<TaskCandidateModel[]> {
    return this._candidates;
  }

  get fieldCandidates(): Readonly<TaskCandidateModel[]> {
    return this._candidates.filter((c) => c.fieldId === useMapContainers(MapContainerEnum.MAIN_MAP).activeField.value?.id);
  }

  private _candidates: Readonly<TaskCandidateModel[]> = [];

  private _fieldsLoaded: number[] = [];

  private _fieldsLoading: number[] = [];

  clearState() {
    this._candidates = [];
    this._fieldsLoaded = [];
  }

  async fetchCandidates() {
    const fieldId = useMapContainers(MapContainerEnum.MAIN_MAP).activeField.value?.id;
    if (fieldId && !this._fieldsLoaded.includes(fieldId)) {
      this._fieldsLoading.push(fieldId);
      this._fieldsLoaded.push(fieldId);
      const { data } = await ApiService.taskMap.getCandidateField(StructList.activeStruct.value?.id || 0, fieldId);
      const models = data.map((dto) => new TaskCandidateModel(dto));
      const dub: string[] = [];
      this._candidates = [
        ...this._candidates,
        ...models,
      ].reduce((acc, value) => {
        if (!dub.includes(`${value.id}:${value.fieldId}`)) {
          acc.push(value);
          dub.push(`${value.id}:${value.fieldId}`);
        }
        return acc;
      }, [] as TaskCandidateModel[]);
      this._fieldsLoading.splice(this._fieldsLoading.indexOf(fieldId), 1);
    }
  }
}
