<template lang="pug" src="./UiSelector.pug"/>
<style lang="scss" src="./UiSelector.scss"/>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, ref, toRefs, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { ArrowDown } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'UiSelector',
  components: { ArrowDown },
  props: {
    optionsList: {
      type: Array as PropType<Array<{value: string | number, title?: string}>>,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { user } = useUser();
    const active = ref(false);
    const { value, placeholder } = toRefs(props);

    const selectValue = ref<{value: string | number, title?: string}>({ value: '' });

    const selectThis = (option: { value: string | number; title?: string }) => {
      selectValue.value = option;
      emit('update', option.value);
    };
    const toggle = () => {
      active.value = !active.value;
    };

    onMounted(() => {
      value.value && (selectValue.value = { value: value.value });
    });

    const timeout = ref(0);

    const enter = () => {
      clearTimeout(timeout.value);
    };

    const leave = () => {
      timeout.value = setTimeout(() => {
        active.value && toggle();
      }, 500);
    };
    return {
      user,
      active,
      selectThis,
      selectValue,
      toggle,
      leave,
      enter,
    };
  },
});
</script>
