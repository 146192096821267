import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';

const prefix = '/api/v1/communications';

export const endpoints = {
  createOrder: (): string => `${prefix}/orders/order/`,
};

export class ApiCommunications extends ApiHandler {
  public async createOrder(data: {
    subject: string,
    comment: string,
    payload: {
      [key: string]: any
    },
    unit: number,
  }): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).post<void>(endpoints.createOrder(), data);
  }
}
