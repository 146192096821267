<template lang="pug" src="./NoActiveField.pug"/>
<style lang="scss" src="./NoActiveField.scss"/>

<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useTranslate } from '@tolgee/vue';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({

  props: {
    message: {
      type: String,
      default: 'default-message',
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
  },
  name: 'NoActiveField',
  setup(props) {
    const { t } = useTranslate('placeholder');
    const {
      selectState,
    } = useMapContainers(props.mapContainer);

    const computedMessage = computed(() => {
      if (selectState.value === 'multiple') {
        return t.value('no-active-field-multiple-selector');
      }
      return t.value(`no-active-field-${props.message}`);
    });

    return {
      computedMessage,
      selectState,
    };
  },
});
</script>
