import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { ImageSource } from 'mapbox-gl';

export class MapLayerNirModel extends MapLayerModel implements IMapLayerModel {
  get data(): FieldNirModel {
    return this._data;
  }

  readonly _data: FieldNirModel;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, data: MapInputType) {
    super(mapModel, type, 'nir', data.uuid);
    this._data = data as FieldNirModel;
    this.createSourceNirFile();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  updateImage = (file: FieldNirModel): void => {
    const source = this._mapModel?.map?.getSource(this.sourceId) as ImageSource;
    source.updateImage({
      url: file.url,
      coordinates: file.geomCoordinates,
    });
  }

  createSourceNirFile = () => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'image',
      url: this._data.url,
      coordinates: this._data.geomCoordinates,
    });
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      source: this.sourceId,
      type: 'raster',
    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.NIR_FILE);
  }
}
