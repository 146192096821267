import { ElNotification } from 'element-plus';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';

import { useNotifications } from '@/composables/useNotifications';
import ApiService from '@/services/api/ApiService';
import { NotificationModel } from '@/models/NotificationModel';
import { notificationFormatMessage } from '@/utils/notificationFormatMessage';
import { useStruct } from '@/composables/useStruct';

const events = {
  notification: 'NOTIFICATION',
};

export const SocketNotificationEvents = (): void => {
  EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
    if (message.event === events.notification) {
      const structId = useStruct().structId.value;
      const settings: 'on' | 'off' = (window.localStorage.getItem('message') as 'on' | 'off' | undefined) || 'on';
      if (settings === 'on' && message.unit === structId) {
        ElNotification(
          {
            title: message.payload.title,
            message: notificationFormatMessage(message.payload.text),
            type: 'info',
            position: 'bottom-right',
            dangerouslyUseHTMLString: true,
          },
        );
      }

      const { data } = await ApiService.struct.getNotification(message.payload.id);
      if (data) {
        const model = new NotificationModel(data);
        useNotifications().notifications.value.push(model);
      }
    }
  });
};
