import {
  BillingContractFunctionalQuotaType,
  BillingContractLimitedQuotaType,
} from '@/constants/types/billing/BillingContractQuotaType';
import { BillingContractDto } from '@/services/api/dto/billing/BillingContractDto';
import { PermissionActionType } from '@/constants/types/permission/PermissionActionType';
import LoggerService from '@/services/logger/LoggerService';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import PermissionsList from '@/modules/permissions/PermissionsList';

export class BillingContractModel {
  get id(): number {
    return this._id;
  }

  get uuid(): string {
    return this._uuid;
  }

  get key(): string {
    return this._key;
  }

  get name(): string {
    return this._name;
  }

  get expireDate(): Date {
    return this._expireDate;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get functionalQuotas(): BillingContractFunctionalQuotaType | null {
    return this._functionalQuotas;
  }

  get limitedQuotas(): BillingContractLimitedQuotaType[] {
    return this._limitedQuotas;
  }

  get isDemo(): boolean {
    return this._key.toLowerCase() === 'demo';
  }

  get isFundamental(): boolean {
    return this._key.toLowerCase() === 'fundamental';
  }

  get isExpert(): boolean {
    return this._key.toLowerCase() === 'expert';
  }

  get isEnterprise(): boolean {
    return this._key.toLowerCase() === 'enterprise';
  }

  private readonly _id: number;

  private readonly _uuid: string;

  private readonly _key: string;

  private readonly _name: string;

  private readonly _expireDate: Date;

  private readonly _isActive: boolean;

  private readonly _limitedQuotas: BillingContractLimitedQuotaType[];

  private readonly _functionalQuotas: BillingContractFunctionalQuotaType | null;

  constructor(contract: BillingContractDto) {
    this._id = contract.id;
    this._uuid = contract.uuid;
    this._key = contract.key;
    this._name = contract.name;
    this._expireDate = new Date(contract.expire_date);
    this._isActive = contract.is_active;
    this._limitedQuotas = contract.limited_quotas || [];
    this._functionalQuotas = contract.functional_quota || null;

    PermissionsList.setContractPermissions(contract);
  }
}
