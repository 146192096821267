<template lang="pug" src="./MonitoringChartBlock.pug"/>
<style lang="scss" src="./MonitoringChartBlock.scss"/>

<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import ApexCharts from 'apexcharts';
import { NameChartWeatherType } from '@/constants/types/NameChartWeatherType';
import { useMonitoring } from '@/composables/useMonitoring';
import { ChartPlotTypeNameEnum } from '@/constants/enums/ChartPlotTypeNameEnum';
import MonitoringChartPlotConfig from '@/assets/data/MonitoringChartPlotConfig';
import ru from 'apexcharts/dist/locales/ru.json';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

const nameChartList: Array<NameChartWeatherType> = ['Облачность', 'Средний NDVI', 'Сумма осадков', 'Температура'];

export default defineComponent({
  name: 'MonitoringChartBlock',
  components: {},
  setup() {
    const { user } = useUser();
    const { showChart } = useMonitoring();

    const {
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const myChart = ref<ApexCharts>();
    const loading = ref(false);

    const container = ref<HTMLElement>();
    const activeTemp = ref(true);

    const computedChartX = computed(() => {
      let arr: any[] = [];

      activeField.value?.plot.forEach((item) => {
        if (item.points.length && MonitoringChartPlotConfig.plots.includes(item.plot_type_name)) {
          arr = item.points.map((p) => (new Date(p.x).toUTCString()));
        }
      });

      arr.reverse();
      return arr;
    });

    /** калькуляция для промежуточных точек индекса */
    const calculateNdvi = (points: {
      'y': number,
      'x': string,
      's': number
    }[], type: string) => {
      const bufferArr: {
        firstIndex: number,
        firstValue: number,
        lastIndex?: number,
        lastValue?: number,
        differenceIndex?: number,
        differenceValue?: number,
      }[] = [];

      let y = 0;

      const maxLengthXArr: number[] = activeField.value?.plot.map((c) => c.points.length) || [];

      const index = maxLengthXArr.indexOf(Math.max(...maxLengthXArr));

      const calculateMaxX: string[] = activeField.value?.plot[index].points.map((p) => p.x) || [];

      let calculateArr: any[] = calculateMaxX.map((item) => (
         points.find((p) => p.x === item)?.y
      )) || [];

      calculateArr = calculateArr.map((x, i) => {
        if (x) {
          if (bufferArr.length === y && y !== 0) {
            bufferArr[y - 1].lastIndex = i;
            bufferArr[y - 1].lastValue = x;
          }
          bufferArr.push({
            firstIndex: i,
            firstValue: x,
          });
          y++;
          return x;
        }

        return null;
      });
      if (type === 'ndvi_avg') {
        bufferArr.forEach((item, i) => {
          if (item.lastIndex && item.lastValue) {
            bufferArr[i].differenceValue = Number((item?.lastValue - item.firstValue).toFixed(3));
            bufferArr[i].differenceIndex = Number((item?.lastIndex - item.firstIndex).toFixed(3));
          }
        });
        let BufferIndex = 0;
        let zoneIndex = 1;
        let inZone = false;

        calculateArr = calculateArr.map((c, i) => {
          let value = c;
          if (c === bufferArr[BufferIndex].lastValue) {
            BufferIndex++;
            zoneIndex = 1;
            inZone = false;
          } if (c === bufferArr[BufferIndex].firstValue) {
            inZone = true;
          } else if (inZone && bufferArr[BufferIndex].differenceValue && bufferArr[BufferIndex].differenceIndex) {
            // @ts-ignore
            const calculateValue = bufferArr[BufferIndex].firstValue + bufferArr[BufferIndex].differenceValue * (zoneIndex / bufferArr[BufferIndex].differenceIndex);

            zoneIndex++;
            value = Number((calculateValue).toFixed(3));
          }
          return value;
        });
      }

      return calculateArr;
    };

    /**
     * Формирование yaxis
     */
    const computedChartY = computed(() => {
      const arr: any[] = [];

      activeField.value?.plot.forEach((item) => {
        let type = 'line';
        let data = [];
        let name = item.name;

        if ([ChartPlotTypeNameEnum.PRECIPTION, ChartPlotTypeNameEnum.CLOUD].some((s) => s === item.plot_type_name)) {
          type = 'bar';
        }

        if (
          true
          // MonitoringChartPlotConfig.plots.includes(item.plot_type_name)
        ) {
          if ([ChartPlotTypeNameEnum.NDVI, ChartPlotTypeNameEnum.CLOUD].some((s) => s === item.plot_type_name) && item.points.length) {
            data = calculateNdvi(item.points, item.plot_type_name);
          }

          if ([ChartPlotTypeNameEnum.TEMPERATURE_MAX, ChartPlotTypeNameEnum.TEMPERATURE_MIN].some((i) => i === item.plot_type_name)) {
            name = 'Температура';
            if (activeTemp.value) {
              data = item.points.map((p, i) => p.y) || [];
            }
          }

          if (item.plot_type_name === ChartPlotTypeNameEnum.PRECIPTION) {
            data = item.points.map((p) => p.y);
          }

          arr.push({
            name,
            type,
            data: data.reverse(),
          });
        }
      });
      return arr;
    });

    const tempMax = computed(() => {
      const arr = activeField.value?.plot.find((item) => item.plot_type_name === ChartPlotTypeNameEnum.TEMPERATURE_MAX)?.points.map((p) => p.y) || [0];
      return Math.max(...arr);
    });

    const tempMin = computed(() => {
      const arr = activeField.value?.plot.find((item) => item.plot_type_name === ChartPlotTypeNameEnum.TEMPERATURE_MIN)?.points.map((p) => p.y) || [0];
      return Math.min(...arr);
    });

    const computedLabelX = computed(() => {
      const arr: any[] = [];
      activeField.value?.plot.forEach((item) => {
        if (ChartPlotTypeNameEnum.TEMPERATURE_MAX === item.plot_type_name || ChartPlotTypeNameEnum.TEMPERATURE_MIN === item.plot_type_name) {
          if (ChartPlotTypeNameEnum.TEMPERATURE_MIN === item.plot_type_name) {
            arr.push({
              show: false,
              title: {
                text: 'Температура',
              },
              min: tempMin.value,
              max: tempMax.value,
              labels: {
                formatter(val: number) {
                  if (![null, undefined, NaN].some((a) => val === a)) return `${val.toFixed(0)} °C`;
                  return undefined;
                },
              },
            });
          } else {
            arr.push({
              show: false,
              title: {
                text: 'Температура',
              },
              min: tempMin.value,
              max: tempMax.value,
              labels: {
                formatter(val: number) {
                  if (![null, undefined, NaN].some((a) => val === a)) return `${val.toFixed(0)} °C`;
                  return undefined;
                },

              },
            });
          }
        } else if ([ChartPlotTypeNameEnum.CLOUD].some((i) => i === item.plot_type_name)) {
          arr.push({
            show: false,
            title: {
              text: item.name,
            },
            min: 0,
            labels: {
              formatter(val: number) {
                if (![null, undefined, NaN].some((a) => val === a)) {
                  return `${val.toFixed(0)} %`;
                }
                return undefined;
              },
            },
          });
        } else if (ChartPlotTypeNameEnum.PRECIPTION === item.plot_type_name) {
          arr.push({
            show: false,
            title: {
              text: item.name,
            },
            min: 0,
            labels: {
              formatter(val: number) {
                if (![null, undefined, NaN].some((a) => val === a)) {
                  return `${val.toFixed(2)} мм`;
                }
                return undefined;
              },
            },
          });
        } else if (ChartPlotTypeNameEnum.NDVI === item.plot_type_name) {
          arr.push({
            show: false,
            opposite: true,
            title: {
              text: item.name,
            },
            labels: {
              formatter(val: number) {
                if (![null, undefined, NaN].some((a) => val === a)) return val.toFixed(3);
                return undefined;
              },
            },
          });
        }
      });
      return arr;
    });
    const showElement = () => {
      const element = container.value?.querySelectorAll('[rel="5"]');

      element?.forEach((e: any) => {
        e.remove();
      });
    };

    const options = computed(() => ({
      chart: {
        foreColor: '#C9C9C9',
        height: 250,
        type: 'line',
        animations: {
          enabled: false,
        },
        locales: [ru],
        defaultLocale: 'ru',
        events: {
          mounted: () => {
            showElement();
          },
          updated: () => {
            showElement();
          },
          legendClick: (e: any, chart: any, config: any) => {
            if (chart === 3) {
              activeTemp.value = !activeTemp.value;
              myChart.value?.updateOptions({ series: computedChartY.value });
            }
          },

        },
      },
      ...MonitoringChartPlotConfig.options,
      colors: activeField.value?.plot.filter((v) => MonitoringChartPlotConfig.plots.includes(v.plot_type_name)).map((ch) => ch.color),
      series: computedChartY.value,
      labels: computedChartX.value,
      yaxis: computedLabelX.value,
      tooltip: {
        theme: 'dark',
      },

    }));

    const createChart = async () => {
      myChart.value !== undefined && myChart.value.destroy();
      loading.value = true;
      activeField.value?.fetchPlot();
      await LoadingStatus.awaitLoad(LoadingNamesEnum.FIELD_PLOT, activeField.value?.id);
      if (activeField.value && activeField.value.plot.flatMap((a) => a.points).length > 0) {
        (myChart.value = new ApexCharts(container.value, options.value));
        await myChart.value?.render();
      }
      loading.value = false;
    };

    const computedHasPlot = computed(() => (activeField.value?.plot.flatMap((a) => a.points).length > 0));

    onMounted(async () => {
      await createChart();
    });

    watch(activeField, createChart);
    return {
      container,
      user,
      nameChartList,
      showChart,
      myChart,
      loading,
      showElement,
      activeField,
      computedHasPlot,
    };
  },
});
</script>
