export enum MapLayoutAreaEnum {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
  WIDGETS = 'widgets',
  TOOLS_RIGHT = 'toolsRight',
  TOOLS_LEFT = 'toolsLeft',
  MODAL = 'modal',

  MAP_TOP_LEFT = 'mapTopLeft',
  MAP_TOP_CENTER = 'mapTopCenter',
  MAP_TOP_RIGHT = 'mapTopRight',
  MAP_RIGHT_TOP = 'mapRightTop',
  MAP_RIGHT_CENTER = 'mapRightCenter',
  MAP_RIGHT_BOTTOM = 'mapRightBottom',
  MAP_BOTTOM_LEFT = 'mapBottomLeft',
  MAP_BOTTOM_CENTER = 'mapBottomCenter',
  MAP_BOTTOM_RIGHT = 'mapBottomRight',
  MAP_LEFT_TOP = 'mapLeftTop',
  MAP_LEFT_CENTER = 'mapLeftCenter',
  MAP_LEFT_BOTTOM = 'mapLeftBottom',
  MAP_WORKSPACE = 'mapWorkspace',
  MAP_CALENDAR = 'mapCalendar',
}
