export const DateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  month: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const DateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  month: 'numeric',
};

export const TimeShortOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const TimeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};
