export enum LoadingNamesEnum {
  SERVICE_TOKEN,
  ASSETS_GRADIENTS_LIST,
  ASSETS_DEFAULT_PALETTES,
  ASSETS_VECTOR_PALETTE,
  DICTIONARY_TASK_MAP_TYPES,
  MONITORING_CALENDAR,
  TASK_MAP_CREATE_SUBMIT,
  FIELD,
  VECTOR_GEOMETRY,
  FIELDS_LIST,
  FIELD_TASK_MAPS,
  FIELD_NIR_FILES,
  FIELD_SATELLITE_RGB_FILES,
  FIELD_HISTORY_INDEXES,
  FIELD_INDEXES,
  FIELD_RASTER_CROP,
  STRUCT_LIST,
  STRUCT_LIST_DETAILS,
  STRUCT_INTEGRATION,
  STRUCT_PALETTE,
  POIS_LIST,
  POIS_GROUPS_LIST,
  PUBLIC_CROP_LIST,
  CROP_LIST,
  CROP_ROTATION,
  CROP_ROTATION_TABLE,
  CROP_ROTATION_SEASONS,
  CROP_ROTATION_FIELD,
  CROP_RATES,
  INTEGRATIONS,
  INTEGRATION_TECH,
  SATELLITE,
  COLOR_KITS,
  MONITORING_KINDS,
  MONITORING_INDEXES,
  ALL_BASE_TASK,
  ALL_WORK_TASK,
  LAST_NIR,
  AVERAGE_INDEX_NDVI,
  FIELD_PLOT,
  STRUCT_VECTORS,
  MAP_CONTAINER,
  VECTOR_GEOJSON,
  STRUCT_FILES_GROUPS,
  STRUCT_FILES_VECTORS,
  STRUCT_FILES_RASTERS,
  TASK_GEOJSON,
  STORAGE,
  STORAGE_STRUCT,
}
