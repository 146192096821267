<template lang="pug" src="./TaskMapNormaFormBlock.pug"/>
<style lang="scss" src="./TaskMapNormaFormBlock.scss"/>

<script lang="ts">
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useUser } from '@/composables/useUser';
import { MAX_TASK_MAP_NORMA } from '@/constants/constants/constants';
import { techType } from '@/constants/constants/techTupe';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';
import { RuleMaterialName } from '@/constants/rules/RuleMaterialName';
import { formatNumber } from '@/lib/convertors/formatNumber';
import hexToRgba from '@/lib/convertors/hexToRgba';
import type { FieldModel } from '@/models/field/FieldModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { CreatingTaskMapModel } from '@/models/taskMap/CreatingTaskMapModel';
import { useMapContainers } from '@/composables/useMapContainers';
import TaskFieldsStats from '@/modules/taskMap/ui/TaskFieldsStats.vue';
import TaskProgressCard from '@/modules/taskMap/ui/TaskProgressCard/TaskProgressCard.vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { Delete, Plus } from '@element-plus/icons-vue';
import { useTranslate } from '@tolgee/vue';
import { InternalRuleItem, Value } from 'async-validator';
import { SyncValidateResult } from 'async-validator/dist-types/interface';
import { ElMessageBox, FormInstance, FormRules } from 'element-plus';
import { FormItemRule } from 'element-plus/es/components/form/src/types';
import {
  computed, defineComponent, onMounted, onUnmounted, ref, shallowRef, watch,
} from 'vue';
import Content from '@/components/shared/Content/Content.vue';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import IntegrationsList from '@/modules/integration/IntegrationsList';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import CreateTaskMapFormDialog
  from '@/modules/taskMap/ui/CreateTaskMapFormDialog/CreateTaskMapFormDialog.vue';

interface ZoneConfig {
  zone: string;
  'is_exp': boolean;
  value: number;
  color: string;
}
interface MaterialConfig {
  name: string;
  'zone_config': ZoneConfig[];
}

export default defineComponent({
  name: 'TaskMapNormaFormBlock',
  components: {
    UiDialog,
    UiIcon,
    RightPanel,
    Delete,
    Plus,
    TaskProgressCard,
    TaskFieldsStats,
    CreateTaskMapFormDialog,
    Content,
  },
  setup() {
    const { user } = useUser();
    const {
      selectedFields,
      activeField,
      cleanDisabledFields,
      addDisabledField,
      fields,
      setSelectState,
      setActiveField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const {
      activeModeCreateTask,
      selectBaseTaskMaps,
    } = useTaskMap();
    const activeTabs = ref('');
    const openTable = ref(false);
    const isMinMaxMod = ref(false);
    const { t } = useTranslate('taskMap');

    const ruleFormRef = shallowRef<FormInstance>();
    const minMaxValue = ref<{ material: string, min: number, max: number, sliderValue: [number, number]}[]>([]);
    const slider = ref<[number, number]>([0, MAX_TASK_MAP_NORMA]);
    const min = ref(0);
    const max = ref(MAX_TASK_MAP_NORMA);
    const isModalSetLimit = ref(false);

    const data = ref<CreatingTaskMapModel[]>([]);
    const isModal = ref(false);
    const modalActiveFormMaterial = ref(false);
    const materialForm = ref<{
        norma: number,
        name: string,
        unit: string,
        type: TaskMapMaterialTypeEnum,
    }>(
      {
        norma: 100,
        name: '',
        unit: 'кг',
        type: 0,
      },
    );

    const form = ref<{
        name: string,
        fieldsId: number[],
        materials: {
            norma: number,
            name: string,
            unit: string,
            type: TaskMapMaterialTypeEnum,
        }[]
    }>({
      name: 'Task',
      fieldsId: [] as number[],
      materials: [],
    });

    const uniqueName = (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void => {
      if (form.value.materials.some((a) => a.name === materialForm.value.name)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const rules = ref<FormRules>({
      ...RuleMaterialName,
    });

    (rules.value.name as FormItemRule[]).push({
      validator: uniqueName,
      trigger: ['blur', 'change'],
    } as FormItemRule);

    const calculateZoneMaterial = (task: CreatingTaskMapModel): number => {
      const dp: number[] = [];

      task.zones.forEach((v) => {
        dp.push(v.area * (v.value || 0));
      });
      task.opts.forEach((v) => {
        dp.push(v.area * (v.value || 0));
      });
      return dp.reduce((acc, v) => acc + v, 0);
    };
    const checkingByLimitsValue = (value: number) => {
      if (value > slider.value[1]) {
        return slider.value[1];
      }
      if (value < slider.value[0]) {
        return slider.value[0];
      }
      return value;
    };

    const computedActiveMaterial = computed(() => form.value.materials.find((a) => a.name === activeTabs.value));

    const computedStats = computed(() => {
      const stats: {
          fieldId: number,
          materialName: string,
          normaUsage: number, // Абсолютные значения расхода материала по норме
          diffUsage: number, // Абсолютные значения расхода материала с учетом корректировки по зоне
          economy: number, // Экономия или перерасход материала между расходом по норме и с учетом корректировки по зоне
      }[] = [];
      selectBaseTaskMaps.value.forEach((f) => {
        form.value.materials.forEach((material) => {
          data.value.forEach((value) => {
            if (value.material.name === material.name && f.field === value.field.id) {
              const zonaMaterial = calculateZoneMaterial(value as CreatingTaskMapModel) || 0;
              stats.push({
                fieldId: f.field,
                materialName: value.material.name || '',
                diffUsage: zonaMaterial,
                normaUsage: value.baseMap.area * (value.material.norma || 0),
                economy: zonaMaterial - (value.baseMap.area * (value.material.norma || 0)) || 0,
              });
            }
          });
        });
      });
      return stats;
    });

    const computedLimitedStats = computed(() => {
      const stats: {
       fieldId: number,
       materialName: string,
       normaUsage: number, // Абсолютные значения расхода материала по норме
       diffUsage: number, // Абсолютные значения расхода материала с учетом корректировки по зоне
       economy: number, // Экономия или перерасход материала между расходом по норме и с учетом корректировки по зоне
     }[] = [];
      selectedFields.value.forEach((f) => {
        form.value.materials.forEach((material) => {
          data.value.forEach((value) => {
            const valueCorect = value.zones.map((zone) => ({
              ...zone,
              value: checkingByLimitsValue(zone.value as number),
            }));
            const copyValue = { ...value };
            copyValue.zones = valueCorect;
            if (copyValue.material.name === material.name && f.id === copyValue.field.id) {
              const zonaMaterial = calculateZoneMaterial(copyValue as CreatingTaskMapModel) || 0;
              stats.push({
                fieldId: f.id,
                materialName: copyValue.material.name || '',
                diffUsage: zonaMaterial,
                normaUsage: copyValue.baseMap.area * (copyValue.material.norma || 0),
                economy: zonaMaterial - (copyValue.baseMap.area * (copyValue.material.norma || 0)) || 0,
              });
            }
          });
        });
      });
      return stats;
    });

    const computedTotalLimitStats = computed(() => computedLimitedStats.value.reduce((acc, v) => {
      if (v.materialName === computedActiveMaterial.value?.name) {
        acc.normaUsage += v.normaUsage;
        acc.diffUsage += v.diffUsage;
        acc.economy += v.economy;
      }

      return acc;
    }, { normaUsage: 0, diffUsage: 0, economy: 0 }));

    const computedFieldStats = computed(() => computedStats.value.find((v) => v.fieldId === activeField.value?.id && v.materialName === computedActiveMaterial.value?.name));

    const computedAllFieldsSq = computed(() => selectBaseTaskMaps.value.reduce((acc, f) => acc + (f.area || 0), 0));

    const computedTotalStats = computed(() => computedStats.value.reduce((acc, v) => {
      if (v.materialName === computedActiveMaterial.value?.name) {
        acc.normaUsage += v.normaUsage;
        acc.diffUsage += v.diffUsage;
        acc.economy += v.economy;
      }

      return acc;
    }, { normaUsage: 0, diffUsage: 0, economy: 0 }));

    const calculateData = () => {
      selectBaseTaskMaps.value.forEach((f) => {
        form.value.materials.forEach((m) => {
          const field = selectedFields.value.find((a) => a.id === f.field);
          if (field) {
            const model = new CreatingTaskMapModel(field as FieldModel, f as FieldTaskMapBaseModel, m);

            if (!data.value.some((v) => v.material.name === model.material.name && v.field.id === model.field.id)) {
              data.value.push(model);
            }
          }
        });
      });
    };

    const pushMaterial = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          form.value.materials.push(materialForm.value);

          calculateData();

          const minValue = Math.min(...data.value.filter((b) => b.material.name === materialForm.value.name).map((b) => Math.min(...b.zones.map((z) => z.value || 0))));
          const maxValue = Math.max(...data.value.filter((b) => b.material.name === materialForm.value.name).map((b) => Math.max(...b.zones.map((z) => z.value || 0))));

          minMaxValue.value.push({
            material: materialForm.value.name,
            min: minValue,
            max: maxValue,
            sliderValue: [minValue, maxValue],
          });

          activeTabs.value = materialForm.value.name;

          materialForm.value = {
            norma: 100,
            name: '',
            unit: 'кг',
            type: 0,
          };

          modalActiveFormMaterial.value = false;
          ruleFormRef.value = undefined;
        }
      });
    };

    watch(activeTabs, (a, b) => {
      const element = minMaxValue.value.find((v) => v.material === a);
      const prevElement = minMaxValue.value.find((v) => v.material === b);

      if (prevElement) {
        prevElement.sliderValue = slider.value;
      }
      if (element) {
        slider.value = element.sliderValue;
        min.value = element.min;
        max.value = element.max;
      }
    });

    const computedFieldSquare = computed(() => activeField.value?.sq || 0);

    const nextStep = () => {
      isModal.value = true;
    };

    const prewStep = () => {
      ElMessageBox.confirm(
        t.value('go-back-to-selection'),
        t.value('go-back-to-selection-title'),
        {
          confirmButtonText: t.value('confirm-go-back'),
          cancelButtonText: t.value('cancel'),
        },
      ).then(() => {
        activeModeCreateTask.value = 0;
      });
    };

    onMounted(() => {
      calculateData();
      cleanDisabledFields();
      setSelectState('single');
      setActiveField(selectBaseTaskMaps.value[0].field || undefined);
      fields.value.forEach((a) => {
        if (!selectedFields.value.some((b) => b.id === a.id)) {
          addDisabledField(a as FieldModel);
        }
      });
    });
    onUnmounted(() => {
      setSelectState('multiple');
    });

    const updateValueZone = (value: number, zone: string) => {
      const zones = data.value.find((a) => a.field.id === activeField.value?.id && a.material.name === computedActiveMaterial.value?.name);
      zones?.setValueZone(value, zone);
    };

    const updateValueOPT = (value: number, optName: string) => {
      const opt = data.value.find((a) => a.field.id === activeField.value?.id && a.material.name === computedActiveMaterial.value?.name);
        opt?.setValueOpt(value, optName);
    };

    const removeTab = (targetName: string) => {
      ElMessageBox.confirm(
        t.value('remove-material'),
        t.value('remove-material-title'),
        {
          confirmButtonText: t.value('confirm-remove-material'),
          cancelButtonText: t.value('cancel'),
        },
      ).then(() => {
        data.value = data.value.filter((a) => a.material.name !== targetName);
        minMaxValue.value = minMaxValue.value.filter((a) => a.material !== targetName);
        const index = form.value.materials.findIndex((a) => a.name === targetName);
        form.value.materials.splice(index, 1);
      });
    };

    const updateSlider = (material: string, sliderValue: [number, number]) => {
      data.value.forEach((a) => {
        if (a.material.name === material) {
          a.zones.forEach((b) => {
            if (b.value !== null && b.value <= sliderValue[0]) {
              a.setValueZone(sliderValue[0], b.zone);
            }
            if (b.value !== null && b.value >= sliderValue[1]) {
              a.setValueZone(sliderValue[1], b.zone);
            }
          });
        }
      });
      isModalSetLimit.value = false;
    };

    const getUnitLabel = computed(() => (value: string) => (['п.е', 'шт', 'тыс.шт'].includes(value) ? `${value}.` : value));

    const showModalSetLimit = () => {
      isModalSetLimit.value = true;
    };

    return {
      activeField,
      form,
      rules,
      Delete,
      Plus,
      nextStep,
      pushMaterial,
      formatNumber,
      computedFieldSquare,
      prewStep,
      activeTabs,
      openTable,
      updateValueZone,
      computedFieldStats,
      computedTotalStats,
      techType,
      LoadingNamesEnum,
      LoadingStatus,
      isModal,
      data,
      hexToRgba,
      computedAllFieldsSq,
      TaskMapMaterialTypeEnum,
      updateValueOPT,
      modalActiveFormMaterial,
      materialForm,
      removeTab,
      isMinMaxMod,
      slider,
      minMaxValue,
      min,
      max,
      updateSlider,
      ruleFormRef,
      getUnitLabel,
      MAX_TASK_MAP_NORMA,
      showModalSetLimit,
      isModalSetLimit,
      computedTotalLimitStats,
      user,
      IntegrationsList,
    };
  },
});

</script>
