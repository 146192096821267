import { Bus } from '@/lib/classes/Bus';

enum Events {
  DELETE_CROP
}

class DictionaryEvents extends Bus {
  onCropDeleted(callback: () => void, once = false) {
    this.registerListener(Events.DELETE_CROP, callback, once);
  }

  offCropDeleted(callback: () => void) {
    this.unregisterListener(Events.DELETE_CROP, callback);
  }

  emitCropDeleted() {
    this.emitListeners(Events.DELETE_CROP);
  }
}

export default new DictionaryEvents();
