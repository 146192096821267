import Coordinates from '@/constants/types/mapbox/Coordinates';
import { Position } from 'geojson';

export const getCorrectCoordinates = (coordinates: Position[]) => {
  const xMin: number = coordinates[0][0];
  const yMin: number = coordinates[0][1];
  const xMax: number = coordinates[2][0];
  const yMax: number = coordinates[2][1];

  // TODO: Южное полушарие
  if (xMax >= xMin) {
    if (yMax >= yMin) {
      return [[xMin, yMax], [xMax, yMax], [xMax, yMin], [xMin, yMin]] as Coordinates;
    }
    return [[xMin, yMin], [xMax, yMin], [xMax, yMax], [xMin, yMax]] as Coordinates;
  }
  if (yMax > yMin) {
    return [[xMax, yMax], [xMin, yMax], [xMin, yMin], [xMax, yMin]] as Coordinates;
  }
  return [[xMax, yMin], [xMin, yMin], [xMin, yMax], [xMax, yMax]] as Coordinates;
};
