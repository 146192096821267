<template src="./UiLoading.pug" lang="pug"/>
<style src="./UiLoading.scss" lang="scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiLoading',
});
</script>
