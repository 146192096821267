import { Collection } from '@/collection/Collection';
import { ICollection } from '@/collection/ICollection';
import { FieldModel } from '@/models/field/FieldModel';

export class FieldsCollection extends Collection implements ICollection {
  get collection(): FieldModel[] {
    return this._collection;
  }

  set collection(collection: FieldModel[]) {
    this._collection.splice(0, this._collection.length);
    collection.forEach((model) => this._collection.push(model));
  }

  private readonly _collection: FieldModel[] = [];

  update(fields: FieldModel[]): void {
    const ids = this._collection.map((p) => p.id);
    const newIds = fields.map((f) => f.id);
    const removeIds = ids.filter((id) => !newIds.includes(id));
    fields.filter((p) => !ids.includes(p.id)).forEach((m) => this._collection.push(m));
    removeIds.forEach((id) => {
      this._collection.splice(this._collection.findIndex((f) => f.id === id), 1);
    });
  }

  constructor(fields: FieldModel[]) {
    super();
    this._collection = fields;
  }
}
