import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "POIAddBlock" }
const _hoisted_2 = { class: "flex-row flex-align-start" }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_RightPanel = _resolveComponent("RightPanel")

  return (_openBlock(), _createBlock(_component_RightPanel, {
    onClose: _ctx.closeEdit,
    title: _ctx.$t('add-poi','add-poi',{ns:'poi'})
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          model: _ctx.formPoi,
          "label-width": "150px",
          "label-position": "top",
          rules: _ctx.rules,
          ref: "formPOIRef"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('name','name',{ns:'poi'}),
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: _ctx.$t('input-name','input-name',{ns:'poi'}),
                  modelValue: _ctx.formPoi.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.formPoi.name) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('note','note',{ns:'poi'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: "3",
                  type: "textarea",
                  placeholder: _ctx.$t('input-params','input-params',{ns:'poi'}),
                  modelValue: _ctx.formPoi.descr,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.formPoi.descr) = $event)),
                  "show-word-limit": "",
                  maxlength: "127"
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_button, {
                type: "primary",
                disabled: _ctx.disabled,
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.onSubmit(_ctx.formPOIRef)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('add', 'add')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_el_button, { onClick: _ctx.closeEdit }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('close', 'close')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}