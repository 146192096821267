<template lang="pug" src="./EulaBlock.pug"/>
<style lang="scss" src="./EulaBlock.scss"/>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import axios from 'axios';
import Markdown from 'vue3-markdown-it';
import { useUser } from '@/composables/useUser';
import ApiService from '@/services/api/ApiService';

export default defineComponent({
  name: 'EulaBlock',
  components: {
    Markdown,
  },
  setup() {
    const { user } = useUser();
    const text = ref('');
    const accepted = ref(false);

    const fetchEula = async () => {
      const response = await axios.get('https://storage.agronote.ru/public-docs/docs/eula.md');
      text.value = response.data;
    };

    onMounted(async () => {
      if (user.value && !user.value?.isEulaAccepted) {
        await fetchEula();
      }
    });

    const accept = () => {
      if (user.value) {
        const date = new Date(user.value.eulaDate);
        const strDate = date.toISOString().substring(0, 10);
        ApiService.user.acceptEulaDate(strDate);
        user.value.isEulaAccepted = true;
      }
    };

    watch(user, () => {
      if (user.value && !user.value?.isEulaAccepted) {
        fetchEula();
      }
    });

    const isEulaAccepted = () => user.value?.isEulaAccepted || true;

    return {
      user,
      text,
      accept,
      accepted,
      isEulaAccepted,
    };
  },
});
</script>
