import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "NavigationMenu" }
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter((v) => v.placement === 'left'), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["NavigationMenu_item", { active: _ctx.activeSector?.name === item.name }]),
        onClick: $event => (_ctx.onClick(item))
      }, _toDisplayString(_ctx.$t(`${item.name}`, item.name, { ns: 'navigation' })), 11, _hoisted_2))
    }), 256))
  ]))
}