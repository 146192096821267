import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import FieldsList from '@/modules/fields/FieldsList';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import { FieldModel } from '@/models/field/FieldModel';

const events = {
  add: 'FIELD_ADD',
  change: 'FIELD_CHANGE',
  delete: 'FIELD_DELETE',
};

export const SocketFieldEvents = () => {
  EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
    if (events.add === message.event) {
      if (!FieldsList.fields.some((a) => a.id === message.payload.id)) {
        await FieldsList.fetchField(message.payload.id);
      }
    } else if (events.change === message.event) {
      await FieldsList.fetchField(message.payload.id);
    } else if (events.delete === message.event) {
      if (FieldsList.getFieldModel(message.payload.id)) {
        FieldsList.removeField(message.payload.id);
        EventBus.$emit(EventsEnum.MapRedrawFields);
      }
    }
  });
};
