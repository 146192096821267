function breaks(data: number[], lowerClassLimits: number[][], nClasses: number) {
  let k = data.length - 1;
  const kclass: number[] = [];
  let countNum = nClasses;

  kclass[nClasses] = data[data.length - 1];
  kclass[0] = data[0];

  while (countNum > 1) {
    kclass[countNum - 1] = data[lowerClassLimits[k][countNum] - 2];
    k = lowerClassLimits[k][countNum] - 1;
    countNum--;
  }

  return kclass;
}

function getMatrices(data: number[], nClasses: number) {
  const lowerClassLimits: number[][] = [];
  const varianceCombinations: number[][] = [];
  let i; let j;
  let variance = 0;

  // Initialize matrix's
  for (i = 0; i < data.length + 1; i++) {
    const tmp1 = [];
    const tmp2 = [];
    for (j = 0; j < nClasses + 1; j++) {
      tmp1.push(0);
      tmp2.push(0);
    }
    lowerClassLimits.push(tmp1);
    varianceCombinations.push(tmp2);
  }

  for (i = 1; i < nClasses + 1; i++) {
    lowerClassLimits[1][i] = 1;
    varianceCombinations[1][i] = 0;
    for (j = 2; j < data.length + 1; j++) {
      varianceCombinations[j][i] = Infinity;
    }
  }

  for (let l = 2; l < data.length + 1; l++) {
    let sum = 0;
    let sumSquares = 0;
    let w = 0;
    let i4 = 0;

    for (let m = 1; m < l + 1; m++) {
      const lowerClassLimit = l - m + 1;
      const val = data[lowerClassLimit - 1];
      w++;
      sum += val;
      sumSquares += val * val;
      variance = sumSquares - ((sum * sum) / w);
      i4 = lowerClassLimit - 1;

      if (i4 === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }

      for (j = 2; j < nClasses + 1; j++) {
        if (varianceCombinations[l][j] >= (variance + varianceCombinations[i4][j - 1])) {
          lowerClassLimits[l][j] = lowerClassLimit;
          varianceCombinations[l][j] = variance + varianceCombinations[i4][j - 1];
        }
      }
    }

    lowerClassLimits[l][1] = 1;
    varianceCombinations[l][1] = variance;
  }

  return {
    lowerClassLimits,
    varianceCombinations,
  };
}
export const jenks = (data: number[], nClasses: number): number[] => {
  if (nClasses > data.length) return [];

  data = data.slice().sort((a, b) => a - b);

  const matrices = getMatrices(data, nClasses);
  const lowerClassLimit = matrices.lowerClassLimits;

  return breaks(data, lowerClassLimit, nClasses);
};
