<template lang='pug' src='./TaskMapCard.pug'/>
<style lang='scss' src='./TaskMapCard.scss'/>

<script lang='ts'>
import { SliderMarks0to100 } from '@/assets/settings/SliderMarks0to100';
import UiLoading from '@/components/ui/Loading/UiLoading.vue';
import { useAuth } from '@/composables/useAuth';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import StructList from '@/modules/struct/StructList';
import LoggerService from '@/services/logger/LoggerService';
import { formatRuDate } from '@/utils/formatRuDate';
import { DataAnalysis, Delete, Download } from '@element-plus/icons-vue';
import {
  computed, defineComponent, onMounted, PropType, ref, watch,
} from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { techType } from '@/constants/constants/techTupe';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import ApiService from '@/services/api/ApiService';
import IntegrationsList from '@/modules/integration/IntegrationsList';
import { mdiSend } from '@mdi/js/commonjs/mdi';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { MapLayerTaskMapModel } from '@/models/map/Layers/MapLayerTaskMapModel';
import { TaskCollection } from '@/collection/TaskCollection';

export default defineComponent({
  name: 'TaskMapCard',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    taskMap: {
      type: Object as PropType<FieldTaskMapModel | undefined>,
      required: true,
    },
    /**
     * Кнопки действий доступных в карте - добавляет кнопки в карту, при клике на который
     * отправляется одноименное действие (emit).
     * 'select-map' - выбор карты задания
     * 'stats' - отображение статистики по карте
     * 'download' - скачать карту задания
     * 'delete' - удалить карту задания
     * 'opacity' - устанавить прозрачность
     * 'select-fill' - настройка заливки
     * 'job' - отправить на технику
     */
    actions: {
      type: Array as PropType<string[]>,
      default: [] as string[],
    },
    render: {
      type: Boolean,
      default: false,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    // Флаг ожидания загрузки данных
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiIcon,
    UiLoading,
    UiDialog,
  },
  emits: ['select-map', 'download', 'delete', 'click-card', 'click-name', 'click-preview', 'stats', 'click-info', 'click-unselected'],
  setup(props, { emit }) {
    const { accessToken } = useAuth();

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const { activeField } = useMapContainers(props.mapContainer);

    const activeTypeTech = ref();
    const selectFill = ref< 1 | 2 | 0>(2);
    const products = ref<{name: string, rate: number, type: string, unit: string, show: boolean}[]>([]);
    const layer = ref();
    const techJob = ref<number>();

    const _showLabels = ref(false);

    const opacity = computed({
      get: () => props.taskMap?.opacity,
      set: (v) => {
        props.taskMap?.setOpacity(v);
        if (layer.value) {
          layer.value.setOpacity(
            opacity.value,
          );
          layer.value.setSettingsFill(selectFill.value);
        }
      },
    });

    const showLabels = computed({
      get: () => _showLabels.value,
      set: (v: boolean) => {
        _showLabels.value = v;
        props.taskMap.setShowLabels(v);
      },
    });
    const isModalDownload = ref(false);
    const isModalPostJob = ref(false);

    watch(() => props.taskMap, () => {
      _showLabels.value = props.taskMap?.showLabels;
    });

    const computedShowBord = computed(() => {
      const v2 = (['select-fill'].some((a) => props.actions.includes(a)) && layer.value !== undefined) || products.value.length > 0;
      return v2;
    });

    // Какие действия нужно отобразить в выпадающем меню
    const actionsToCollapse = ['download', 'delete', 'stats', 'job'];

    // Массив входных действий, которые должнгы быть в выпадающем меню
    const collapsedActions = computed<string[]>(() => [...props.actions.filter((v) => actionsToCollapse.includes(v))]);

    const findTaskModel = (id: number) => [...activeField.value?.baseTaskMaps || [],
      ...activeField.value?.workTaskMaps || [],
      ...activeField.value?.factTaskMaps || []].find((v) => v.id === id);

    const calculateProd = () => {
      setTimeout(() => {
        LoadingStatus.awaitLoad(LoadingNamesEnum.TASK_GEOJSON, props.taskMap?.id).then(() => {
          products.value = [];
          if ((props.taskMap.geojson?.features?.length || 0) > 0 && props.taskMap.geojson?.features[0].properties?.prod) {
            props.taskMap.geojson.features[0].properties.prod.forEach((p: {name: string, rate: number, type: string, unit: string}) => {
              products.value.push({
                name: p.name,
                rate: p.rate,
                type: p.type,
                unit: p.unit,
                show: true,
              });
            });
          }
        });
      }, 200);
    };

    watch(() => props.taskMap, () => {
      calculateProd();
    });

    watch(products, (a) => {
      if (props.taskMap && layer.value) {
        props.taskMap.setProducts(a);
        layer.value.update();
      }
    }, { deep: true });

    const isActive = computed(() => mapModel.value?.allLayers.some((v) => ((v.data as TaskCollection)?.collection?.some((a) => a.uuid === props.taskMap?.uuid)
      || v.data.uuid === props.taskMap?.uuid)));

    const doDownload = () => {
      if (props.taskMap instanceof FieldTaskMapBaseModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/base/${props.taskMap.id}/download/?access_token=${accessToken.value}`;
      } else if (props.taskMap instanceof FieldTaskMapWorkModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/work/${props.taskMap.id}/download/${activeTypeTech.value}/?access_token=${accessToken.value}`;
      } else if (props.taskMap instanceof FieldTaskMapFactModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/fact/${props.taskMap.id}/download/?access_token=${accessToken.value}`;
      } else {
        LoggerService.error('Download curl is not implemented yet for', props.taskMap);
      }
    };

    const toggleRender = (paramRender = false) => {
      if ((props.render || paramRender) && props.taskMap) {
        try {
          mapModel.value?.removeLayer([
            MapLayerTypeEnum.TASK_MAP_BASE,
            MapLayerTypeEnum.TASK_MAP_WORK,
            MapLayerTypeEnum.TASK_MAP_FACT,
            MapLayerTypeEnum.TASK_MAP_HARVEST,
          ]);
        } catch (e) {
          //
        }

        layer.value = undefined;

        const show = !isActive.value;
        if (props.taskMap instanceof FieldTaskMapBaseModel) {
          if (show) {
            layer.value = mapModel.value?.render(props.taskMap as FieldTaskMapBaseModel);
          }
        }
        if (props.taskMap instanceof FieldTaskMapWorkModel) {
          if (show) {
            layer.value = mapModel.value?.render(props.taskMap as FieldTaskMapWorkModel);
          }
        }
        if (props.taskMap instanceof FieldTaskMapFactModel) {
          if (show) {
            layer.value = mapModel.value?.render(props.taskMap as FieldTaskMapFactModel);
          }
        }
        if (props.taskMap instanceof FieldTaskMapHarvestModel) {
          if (show) {
            layer.value = mapModel.value?.render(props.taskMap as FieldTaskMapHarvestModel);
          }
        }
        if (layer.value !== undefined) {
          const interval = setInterval(() => {
            if (mapModel.value.map.getLayer(layer.value.layerId)) {
              clearInterval(interval);
              layer.value?.setOpacity(opacity.value);
              layer.value?.setSettingsFill(selectFill.value);
            }
          }, 50);
        }
        calculateProd();
      }
    };

    const clickName = () => {
      emit('click-name');
      toggleRender();
    };

    const clickPreview = () => {
      emit('click-preview');
      toggleRender();
    };

    watch(selectFill, (a) => {
      if (layer.value) {
        layer.value.setSettingsFill(a);
      }
    });

    const toggleShowLabels = () => {
      layer.value.update();
    };

    const postJob = () => {
      ApiService.tech.postJob(
        {
          description: '',
          device: techJob.value,
          work_task_map: props.taskMap.id,
          field: props.taskMap.field,
        },
      );
    };

    const doDelete = async (id: number) => {
      const model = findTaskModel(id);
      if (model) {
        await model.delete();
        await activeField.value?.fetchTaskMaps(true);
      }
    };

    onMounted(() => {
      if (props.taskMap !== undefined) {
        layer.value = mapModel.value.getLayer(props.taskMap?.uuid);
      }
      if (layer.value !== undefined) {
        products.value = [...props.taskMap.products];
        showLabels.value = props.taskMap.showLabels;
        selectFill.value = (layer.value as MapLayerTaskMapModel).fillSettings;
      }
    });

    return {
      layer,
      isActive,
      clickName,
      clickPreview,
      doDelete,
      doDownload,
      formatRuDate,
      collapsedActions,
      DataAnalysis,
      Download,
      Delete,
      FieldTaskMapWorkModel,
      opacity,
      SliderMarks0to100,
      mapModel,
      isModalDownload,
      activeTypeTech,
      techType,
      products,
      toggleShowLabels,
      selectFill,
      postJob,
      isModalPostJob,
      IntegrationsList,
      techJob,
      mdiSend,
      PermissionsList,
      toggleRender,
      showLabels,
      FieldTaskMapBaseModel,
      computedShowBord,
    };
  },
});
</script>
