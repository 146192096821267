import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "PaletteGradient" }
const _hoisted_2 = { class: "PaletteGradient-select" }
const _hoisted_3 = { class: "MapFactMapLayerSettings-gradient-label" }
const _hoisted_4 = ["data-stop", "onMousedown"]

export function render(_ctx, _cache) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        "label-position": "left",
        "label-width": "150px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('select-range-color', { ns: 'palette' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.activeGradient,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.activeGradient) = $event)),
                "value-key": "name",
                "fit-input-width": ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gradients, (g) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      label: g.name,
                      value: g
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "MapFactMapLayerSettings-gradient",
                          style: _normalizeStyle({ background: `linear-gradient(90deg, ${g.positions.map((p) => 'rgba(' + _ctx.hexToRgba(p.color) + ') ' + p.position +'%').join(', ')})`})
                        }, null, 4),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(g.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "value"]))
                  }), 256))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      })
    ]),
    (_ctx.gradient)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "PaletteGradient-view",
          ref: "sliderRef",
          style: _normalizeStyle({ background: `linear-gradient(90deg, ${_ctx.gradient.positions.map((s) => 'rgba(' + _ctx.hexToRgba(s.color) + ') ' + s.position +'%').join(', ')})`})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops, (stop, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "PaletteGradient-slider",
              "data-stop": stop,
              style: _normalizeStyle({ left: `${stop}%` }),
              onMousedown: $event => (_ctx.hand($event, index))
            }, null, 44, _hoisted_4))
          }), 256))
        ], 4))
      : _createCommentVNode("", true)
  ]))
}