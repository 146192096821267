import { useMap } from '@/composables/useMap';
import type { MapLayerVectorModel } from '@/models/map/Layers/MapLayerVectorModel';
import type { MapModel } from '@/models/map/MapModel';
import { MapMouseEvent } from 'mapbox-gl';

export class MapVectorEvent {
  constructor(mapModel: MapModel, layer: MapLayerVectorModel) {
    mapModel.events.onContextMenu((e: MapMouseEvent) => {
      const features = mapModel.map?.queryRenderedFeatures(e.point);

      const { vectorData } = useMap();

      vectorData.value = features?.find((f) => f.layer?.id === layer.layerId);
    });
  }
}
