import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MapContainer-Button" }
const _hoisted_2 = { class: "TaskMapReportMapContainer" }
const _hoisted_3 = { class: "TaskMapReportMapContainer-item-taskName" }
const _hoisted_4 = { class: "TaskMapReportMapContainer-item-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "right-start",
    width: 320,
    teleported: false,
    "hide-after": 0,
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_icon, {
          name: "mdiAdjust",
          size: 24
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([..._ctx.reports].reverse(), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "TaskMapReportMapContainer-item",
            key: item.id
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.task_name), 1),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.formatRuDateTime(item.create_date)), 1),
              _createVNode(_component_el_button, {
                icon: "Download",
                size: "small",
                type: "primary",
                onClick: ($event: any) => (_ctx.download(item.file))
              }, null, 8, ["onClick"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}