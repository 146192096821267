import { Bus } from '@/lib/classes/Bus';
import type { PoiModel } from '@/models/poi/PoiModel';
import { Feature } from 'geojson';

enum Events{
    UPDATE_POI_GROUP,
    UPDATE_POI_LABEL,
    CLICK_POI,
    ADD_POI,
}
class PoiEvents extends Bus {
  onUpdatePoiGroup(callback: () => void, once = false) {
    this.registerListener(Events.UPDATE_POI_GROUP, callback);
  }

  offUpdatePoiGroup(callback: () => void) {
    this.unregisterListener(Events.UPDATE_POI_GROUP, callback);
  }

  emitUpdatePoiGroup() {
    this.emitListeners(Events.UPDATE_POI_GROUP);
  }

  onUpdatePoiLabel(callback: () => void, once = false) {
    this.registerListener(Events.UPDATE_POI_LABEL, callback);
  }

  offUpdatePoiLabel(callback: () => void) {
    this.unregisterListener(Events.UPDATE_POI_LABEL, callback);
  }

  emitUpdatePoiLabel() {
    this.emitListeners(Events.UPDATE_POI_LABEL);
  }

  onClick(callback: (feature: Feature, mapContainer: string) => void, once = false) {
    this.registerListener(Events.CLICK_POI, callback);
  }

  emitClick(feature: Feature, mapContainer: string) {
    this.emitListeners(Events.CLICK_POI, feature, mapContainer);
  }

  onAddPoi(callback: (poi: PoiModel) => void, once = false) {
    this.registerListener(Events.ADD_POI, callback);
  }

  emitAddPoi(poi: PoiModel) {
    this.emitListeners(Events.ADD_POI, poi);
  }
}

export default new PoiEvents();
