import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import PoisList from '@/modules/poi/PoisList';
import ApiService from '@/services/api/ApiService';
import { PoiModel } from '@/models/poi/PoiModel';

const events = {
  add: 'SCOUTING_POI_ADD',
  change: 'SCOUTING_POI_UPDATE',
  delete: 'SCOUTING_POI_DELETE',
};

export const SocketPoiEvents = () => {
  EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
    if (message.event === events.add) {
      const { data } = await ApiService.scouting.getPoi(message.payload.id);
      PoisList.addPoi(new PoiModel(data));
    } else if (message.event === events.change) {
      const { data } = await ApiService.scouting.getPoi(message.payload.id);
      PoisList.getPoiModel(message.payload.id)?.update(data);
    } else if (message.event === events.delete) {
      if (PoisList.pois.value.some((a) => a.id === message.payload.id)) {
        await PoisList.deletePoi(message.payload.id as number);
      }
    }
  });
};
