const tiffPacker = (data: number[]) => {
  const packedData: string[] = [];
  let k = data[0];
  let c = 1;
  for (let i = 1; i < data.length; i++) {
    if (data[i] === k) {
      c += 1;
    } else {
      packedData.push(`${k}:${c}`);
      k = data[i];
      c = 1;
    }
  }
  packedData.push(`${k}:${c}`);
  return packedData;
};

export default tiffPacker;
