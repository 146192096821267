export const getClientXY = (event: MouseEvent | TouchEvent): { clientX: number, clientY: number } => {
  let clientX: number;
  let clientY: number;
  if (event.type === 'touchend') {
    clientX = (event as TouchEvent).changedTouches[0].clientX;
    clientY = (event as TouchEvent).changedTouches[0].clientY;
  } else if (event.type.startsWith('touch')) {
    clientX = (event as TouchEvent).touches[0].clientX;
    clientY = (event as TouchEvent).touches[0].clientY;
  } else {
    clientX = (event as MouseEvent).clientX;
    clientY = (event as MouseEvent).clientY;
  }
  return {
    clientX,
    clientY,
  };
};
