import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export class ApiResponse<T> implements AxiosResponse<T> {
  public status: number;

  public statusText: string;

  public data: T;

  public headers: any;

  public config: InternalAxiosRequestConfig;

  public request?: any;

  constructor(response: AxiosResponse) {
    this.status = response.status;
    this.statusText = response.statusText || '';
    this.data = typeof response.data === 'undefined' ? null : response.data;
    this.headers = response.headers;
    this.config = response.config;
    this.request = response.request;
  }
}
