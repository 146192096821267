import { useMapContainers } from '@/composables/useMapContainers';
import { useMonitoring } from '@/composables/useMonitoring';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { fetcher } from '@/lib/tools/fetcher';
import { MonitoringIndexesList } from '@/models/monitoring/MonitoringIndexesList';
import ApiService from '@/services/api/ApiService';
import { MonitoringIndexColorDto } from '@/services/api/dto/monitoring/MonitoringIndexColorDto';
import EventBus from '@/services/eventBus/EventBus';

class MonitoringService {
  constructor() {
    EventBus.$on('fetchIndexes', async (kind: MonitoringIndexKindType) => {
      if (kind.value) {
        await this.fetchIndexes(kind.value);
      }
      setTimeout(() => {
        EventBus.$emit(EventsEnum.MapToggleMonitoringIndex);
      });
    });
  }

  async fetchIndexes(kind: number) {
    await fetcher(LoadingNamesEnum.MONITORING_INDEXES, 'default', false, async () => {
      const { indexesFiles } = useMonitoring();
      if (kind && useMapContainers().activeField.value && !indexesFiles.value.hasFiles(useMapContainers().activeField.value?.id || 0, kind)) {
        const { data } = await ApiService.monitoring.getKindIndexes(useMapContainers().activeField.value?.id || 0, kind);
        indexesFiles.value.addFiles(data);
      }
    });
  }

  async fetchKinds() {
    await fetcher(LoadingNamesEnum.MONITORING_KINDS, 'default', false, async () => {
      const { data } = await ApiService.monitoring.getKinds();
      useMonitoring().indexesKinds.value = new MonitoringIndexesList(data);
      const colorsResponse = await ApiService.monitoring.getColors();
      colorsResponse.data.forEach((c: MonitoringIndexColorDto) => {
        c.kinds.forEach((k) => {
          const kind = useMonitoring().indexesKinds.value.getKind(k.value);

          if (kind) {
            kind.max = c.max_value;
            kind.min = c.min_value;
            if (c.is_contrast && kind.contrastColors !== undefined) {
              kind.contrastColors[c.id] = c.colors;
            } else {
              kind.colors[c.id] = c.colors;
            }
          }
        });
      });
    });
  }
}

export default new MonitoringService();
