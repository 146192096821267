import { useUser } from '@/composables/useUser';
import { RasterTypeEnum } from '@/constants/enums/RasterTypeEnum';
import { RasterModel } from '@/models/RasterModel';
import { VectorModel } from '@/models/VectorModel';
import MapRastersList from '@/modules/map/MapRastersList';
import MapVectorsList from '@/modules/map/MapVectorsList';
import PermissionsList from '@/modules/permissions/PermissionsList';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import { ref } from 'vue';

class AdminService {
  public imgParameters = ref({
    solt: Date.now(),
    show: true,
  });

  loading = ref(false);

  fetchingVectors = false;

  fetchingRasters = false;

  async fetchRasters(): Promise<void> {
    try {
      await new Promise((r) => setTimeout(r, Math.random() * 1000));
      if (this.fetchingRasters) {
        return;
      }
      this.fetchingRasters = true;
      if (StructList.activeStruct.value?.id) {
        const { data } = await ApiService.gis.getRasters();
        MapRastersList.rasters.value = (this.filter(data.map(((raster) => new RasterModel(raster)))));
        EventBus.$emit('EventRasterUpdate');
      }
    } catch (e) {
      LoggerService.error(e);
      MapRastersList.rasters.value = [];
    }
    this.fetchingRasters = false;
  }

  async fetchVectors(): Promise<void> {
    await new Promise((r) => setTimeout(r, Math.random() * 1000));

    if (this.fetchingVectors) {
      return;
    }
    this.fetchingVectors = true;
    if (StructList.activeStruct.value?.id) {
      try {
        const { data } = await ApiService.gis.getVectorName({ structId: StructList.activeStruct.value?.id });
        MapVectorsList.vectors.value = [...data.map((vector) => new VectorModel(vector))];
      } catch (e) {
        MapVectorsList.vectors.value = [];
        LoggerService.error(e);
      }
    }
    this.fetchingVectors = false;
  }

  updateImg=() => {
    this.imgParameters.value.show = false;
    this.imgParameters.value.solt = Date.now();
    this.imgParameters.value.show = true;
  }

  async loadSubject() {
    if (PermissionsList.hasPermission('user', 'view')) {
      const { data } = await ApiService.user.readSubjects();
      useUser().setSubject(data);
    }
  }

  filter = (arr: Array<RasterModel>): Array<RasterModel> => {
    const Array: Array<RasterModel> = [...arr];
    const expert: Array<RasterModel> = [];
    const msf: Array<RasterModel> = [];
    Array.forEach((r, index) => {
      if (r.raster_type === RasterTypeEnum.expert) {
        expert.push(r);
        Array.splice(index, 1);
      }
      if (r.raster_type === RasterTypeEnum.autoMSF) {
        msf.push(r);
        Array.splice(index, 1);
      }
    });
    return [...Array, ...expert, ...msf];
  };
}

export default new AdminService();
