import type { MapModel } from '@/models/map/MapModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerTaskMapModel } from '@/models/map/Layers/MapLayerTaskMapModel';

export class MapLayerTaskMapWorkModel extends MapLayerTaskMapModel {
  constructor(type: MapLayerTypeEnum, mapModel: MapModel, indexFile: MapInputType) {
    super(mapModel, type, 'task-map-work', indexFile.uuid, indexFile as FieldTaskMapWorkModel);
  }
}
