export const prepareValue = (value: string | number | null | undefined): string | number | null => {
  if (value === null || value === 'null' || value === 'undefined' || value === undefined || value === '') {
    return null;
  }
  if (typeof value === 'string' && Number(value).toString() === value) {
    return Number(value);
  }
  if (typeof value === 'number') {
    return Number(value);
  }
  return value.toString();
};
