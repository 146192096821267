import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ExperimentAddForm theme-dark"
}
const _hoisted_2 = { class: "ExperimentAddForm-block" }
const _hoisted_3 = { class: "ExperimentAddForm-form" }
const _hoisted_4 = { class: "ExperimentAddForm-form-left" }
const _hoisted_5 = { class: "ExperimentAddForm-form-right" }
const _hoisted_6 = {
  key: 0,
  class: "ExperimentAddForm-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ColorGradientPicker = _resolveComponent("ColorGradientPicker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_ctx.experiment && _ctx.isActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form, { "label-position": "top" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_form_item, {
                    label: "Наименование",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.experiment.title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.experiment.title) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "Цвет",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.localActiveGradient?.name), 1),
                      (_ctx.mounted)
                        ? (_openBlock(), _createBlock(_component_ColorGradientPicker, {
                            key: 0,
                            modelValue: _ctx.experiment.color,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.experiment.color) = $event)),
                            gradients: _ctx.gradients,
                            "active-gradient": _ctx.localActiveGradient,
                            "onUpdate:activeGradient": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localActiveGradient) = $event)),
                            "onUpdate:active": _ctx.setGradient,
                            "prefix-colors": ['#FBFC02'],
                            count: 19
                          }, null, 8, ["modelValue", "gradients", "active-gradient", "onUpdate:active"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { size: "small" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('add', _ctx.experiment))),
                        disabled: _ctx.experiment.isSticky,
                        type: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('add')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_component_el_button, {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel', _ctx.experiment)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_form_item, {
                    label: "Высота (м)",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: _ctx.experiment.height,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.experiment.height) = $event)),
                        min: 1,
                        onChange: _ctx.refreshMap
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "Ширина (м)",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: _ctx.experiment.width,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.experiment.width) = $event)),
                        min: 1,
                        onChange: _ctx.refreshMap
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "Угол поворота",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: _ctx.experiment.turn,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.experiment.turn) = $event)),
                        min: -180,
                        max: 180,
                        onChange: _ctx.refreshMap
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }),
          (_ctx.experiment.isSticky)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('add-experiment-instruction', { ns: 'experiments' })), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}