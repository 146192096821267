const tiffUnPacker = (data: string[]) => {
  const unpackedData: number[] = [];
  for (let i = 0; i < data.length; i++) {
    const [k, c] = data[i].toString().split(':');
    const row: number[] = new Array(Number(c));
    row.fill(Number(k));
    unpackedData.push(...row);
  }
  return unpackedData;
};

export default tiffUnPacker;
