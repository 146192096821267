import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { Bus } from '@/lib/classes/Bus';
import type { FieldModel } from '@/models/field/FieldModel';

enum Events {
  CLICK,
  CHANGE_ACTIVE_FIELD,
  CHANGE_SELECTED_FIELDS,
  FIELD_LOADER,
  CHANGE_CROP_RATE,
  TRY_CHANGE_ACTIVE_FIELD,
  FEATURE_UPDATED,
  REPAINT_FIELD,
  SET_ANIMATE_EFFECT,
}

class FieldsEvents extends Bus {
  onClick(callback: (fieldId: number, mapContainer: string) => void, once = false) {
    this.registerListener(Events.CLICK, callback, once);
  }

  emitClick(fieldId: number, mapContainer: string) {
    this.emitListeners(Events.CLICK, fieldId, mapContainer);
  }

  onChangeActiveField(callback: (fieldId: number | undefined) => void, once = false) {
    this.registerListener(Events.CHANGE_ACTIVE_FIELD, callback, once);
  }

  offChangeActiveField(callback: (fieldId: number | undefined) => void) {
    this.unregisterListener(Events.CHANGE_ACTIVE_FIELD, callback);
  }

  emitChangeActiveField(fieldId: string | number | undefined) {
    this.emitListeners(Events.CHANGE_ACTIVE_FIELD, fieldId ? Number(fieldId) : undefined);
  }

  onSelectedFieldsChange(callback: (fields: FieldModel[]) => void, once = false) {
    this.registerListener(Events.CHANGE_SELECTED_FIELDS, callback, once);
  }

  offSelectedFieldsChange(callback: (fields: FieldModel[]) => void) {
    this.unregisterListener(Events.CHANGE_SELECTED_FIELDS, callback);
  }

  emitSelectedFieldsChange(fields: FieldModel[]) {
    this.emitListeners(Events.CHANGE_SELECTED_FIELDS, fields);
  }

  onFieldLoader(callback: (fieldId: number, active: boolean) => void, once = false) {
    this.registerListener(Events.FIELD_LOADER, callback, once);
  }

  emitFieldLoader(fieldId: number, active: boolean) {
    this.emitListeners(Events.FIELD_LOADER, fieldId, active);
  }

  onChangeCropRate(callback: () => void, once = false) {
    this.registerListener(Events.CHANGE_CROP_RATE, callback, once);
  }

  emitChangeCropRate() {
    this.emitListeners(Events.CHANGE_CROP_RATE);
  }

  onTryChangeActiveField(callback: (fieldId: number | undefined) => void, once = false) {
    this.registerListener(Events.TRY_CHANGE_ACTIVE_FIELD, callback, once);
  }

  offTryChangeActiveField(callback: (fieldId: number | undefined) => void) {
    this.unregisterListener(Events.TRY_CHANGE_ACTIVE_FIELD, callback);
  }

  emitTryChangeActiveField(fieldId: string | number | undefined) {
    this.emitListeners(Events.TRY_CHANGE_ACTIVE_FIELD, fieldId);
  }

  onFeatureUpdated(callback: () => void, once = false) {
    this.registerListener(Events.FEATURE_UPDATED, callback, once);
  }

  offFeatureUpdated(callback: () => void) {
    this.unregisterListener(Events.FEATURE_UPDATED, callback);
  }

  emitFeatureUpdated() {
    this.emitListeners(Events.FEATURE_UPDATED);
  }

  onRepaintField(callback: () => void, once = false) {
    this.registerListener(Events.REPAINT_FIELD, callback, once);
  }

  emitRepaintField() {
    this.emitListeners(Events.REPAINT_FIELD);
  }

  offRepaintField(callback: () => void) {
    this.unregisterListener(Events.REPAINT_FIELD, callback);
  }

  onSetAnimateEffect(callback: (animate: boolean, field: FieldModel) => void, once = false) {
    this.registerListener(Events.SET_ANIMATE_EFFECT, callback, once);
  }

  emitSetAnimateEffect(animate: boolean, field: FieldModel) {
    this.emitListeners(Events.SET_ANIMATE_EFFECT, animate, field);
  }
}

export default new FieldsEvents();
