import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "MonitoringChartBlock" }
const _hoisted_2 = { class: "MonitoringChartBlock" }
const _hoisted_3 = {
  key: 0,
  class: "MonitoringChartBlock-info"
}
const _hoisted_4 = {
  key: 1,
  class: "MonitoringChartBlock-info"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  class: "MonitoringChartBlock-chart",
  id: "chart",
  ref: "container"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Идет получение данных... Пожалуйста подождите"))
        : (!(_ctx.computedHasPlot))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode("По данному полю нет графиков"),
              _createElementVNode("span", null, _toDisplayString(_ctx.activeField.value), 1)
            ]))
          : (_ctx.computedHasPlot && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("dt", _hoisted_5, "Графики по полю"))
            : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, null, 512)
    ])
  ]))
}