import { CropRecordDto } from '@/services/api/dto/dictionary/CropRecordDto';

export class DictionaryCropRecordModel {
  set order(value: number) {
    this._order = value;
  }

  get order(): number {
    return this._order;
  }

  get catalogId(): number {
    return this._catalogId;
  }

  get updateDate(): Date {
    return this._updateDate;
  }

  get createDate(): Date {
    return this._createDate;
  }

  get id(): number {
    return this._id;
  }

  get color(): string {
    return this._color;
  }

  get name(): string {
    return this._name;
  }

  private _id: number;

  private _createDate: Date;

  private _updateDate: Date;

  private _name: string;

  private _color = '';

  private _catalogId: number;

  private _order: number;

  constructor(dto: CropRecordDto) {
    this._id = dto.id;

    this._createDate = new Date(dto.create_date);

    this._updateDate = new Date(dto.update_date);

    this._name = dto.name;

    this._color = dto.color;

    this._catalogId = dto.catalog;

    this._order = dto.order;
  }
}
