import { ExperimentsCollection } from '@/collection/ExperimentsCollection';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { Bus } from '@/lib/classes/Bus';
import { emptyFeatureCollection } from '@/lib/map/emptyFeatureCollection';
import { invertColor } from '@/lib/map/invertColor';
import { ExperimentModel } from '@/models/ExperimentModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import {
  booleanOverlap,
  center,
  distance,
  featureCollection,
  intersect,
  lineString,
  nearestPoint,
  nearestPointOnLine,
  point,
  polygon,
  transformRotate,
} from '@turf/turf';
import {
  Feature, GeoJsonProperties, Geometry, Position,
} from 'geojson';
import {
  FeatureIdentifier,
  GeoJSONSource,
  LngLat,
  MapMouseEvent,
  MapWheelEvent,
  Point,
} from 'mapbox-gl';
import { useExperiments } from '@/pages/task-map/create/experiments/composables/useExperiments';

const {
  activeExperiment,
  selectedExperiments,
  toggleSelectedExperiment,
  setActiveExperiment,
} = useExperiments();

export class MapLayerExperimentsModel extends MapLayerModel implements IMapLayerModel {
  get events(): Bus {
    return this._events;
  }

  get data(): ExperimentsCollection {
    return this._data;
  }

  private _data: ExperimentsCollection;

  private _events: Bus = new Bus();

  private _drag: LngLat | undefined;

  public dragging = false;

  // флаг включения режима трансформации(при перемещении бореда опыта)
  public transformation = false;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'experiments', input.uuid);
    this._data = input as ExperimentsCollection;
    this.layerIds.push(this.layerId, `${this.layerId}-label`, `${this.layerId}-contour`, `${this.layerId}-borders`, `${this.layerId}-plus`);
    this.sourceIds.push(this.sourceId, `${this.sourceId}-label`, `${this.sourceId}-contour`, `${this.sourceId}-borders`, `${this.sourceId}-plus`);
    this.create();
    this.hoverEvents();
    this.clickEvents();
    this.dragEvents();
    this.rotateEvents();
    this.hoverBordersEvents();
    this.dragBorderEvents();
    this.clickPlusEvents();
  }

  create(): void {
    if (!this._mapModel?.map) {
      LoggerService.error('Try to create experiment layer, while map is not loaded');
    }

    // region add source and layer для отображения полигона эксперимента
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      layout: {},
      metadata: { type: 'TaskMapExperiment' },
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': ['get', 'opacity'],
      },
    });
    // endregion

    // region add source and layer для отображения наименование опыта
    this._mapModel?.map?.addSource(`${this.sourceId}-label`, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-label`,
      type: 'symbol',
      source: `${this.sourceId}-label`,
      layout: {
        'text-field': ['get', 'label'],
        'text-anchor': 'center',
        'text-size': [
          'interpolate', ['linear'], ['zoom'],
          8, 0,
          9, 6,
          10, 7,
          12, 12,
          17, 15,
        ],
        'text-justify': 'center',
        'text-font': [
          'literal',
          ['Inter Bold'],
        ],
      },
      paint: {
        'text-color': ['get', 'color'],
      },
    });

    // endregion

    // region add source and layer для контура эксперимента
    this._mapModel?.map?.addSource(`${this.sourceId}-contour`, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-contour`,
      type: 'line',
      source: `${this.sourceId}-contour`,
      minzoom: 12.5,
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 3,
        'line-opacity': 1,
      },
    });
    // endregion

    // region add source and layer для отображения линий изменения полигона эксперимента
    this._mapModel?.map?.addSource(`${this.sourceId}-borders`, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-borders`,
      type: 'line',
      source: `${this.sourceId}-borders`,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      metadata: { type: 'TaskMapExperiment' },
      minzoom: 12.5,
      paint: {
        'line-color': '#0F8764',
        'line-width': 10,
        'line-opacity': 1,

      },
    });
    // endregion

    // region add source and layer для отображения функции дублирования опыта
    this._mapModel?.map?.addSource(`${this.sourceId}-plus`, {
      type: 'geojson',
      data: emptyFeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-plus`,
      type: 'symbol',
      source: `${this.sourceId}-plus`,
      minzoom: 12.5,
      layout: {
        'icon-image': 'plus-circle',
        'icon-size': [
          'interpolate', ['linear'], ['zoom'],
          12, 0.5,
          20, 2,
        ],
      },
    });

    // endregion

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP_EXPERIMENT);
    this._mapModel?.map?.moveLayer(`${this.layerId}-borders`, MapAnchorEnum.TASK_MAP_EXPERIMENT);
    this._mapModel?.map?.moveLayer(`${this.layerId}-contour`, MapAnchorEnum.TASK_MAP_EXPERIMENT);
    this._mapModel?.map?.moveLayer(`${this.layerId}-label`, MapAnchorEnum.TASK_MAP_EXPERIMENT);
  }

  /** Создания полигона дубликата эксперимента
   * @param exp - дублируемый эксперимент
   * @param idx - индекс грани от 0 до 3
   */
  duplicateExperiment(exp: ExperimentModel, idx: number): number[][] {
    const pntIdx = (i: number) => (i > 3 ? i - 4 : i);
    const p1 = exp.points[idx];
    const p2 = exp.points[pntIdx(idx + 1)];
    const p3 = exp.points[pntIdx(idx + 2)];
    const p4 = exp.points[pntIdx(idx + 3)];
    const np3 = new Point(p1.x + (p1.x - p4.x), p1.y + (p1.y - p4.y));
    const np4 = new Point(p2.x + (p2.x - p3.x), p2.y + (p2.y - p3.y));
    return [[p1.x, p1.y], [p2.x, p2.y], [np4.x, np4.y], [np3.x, np3.y]];
  }

  // region refresh - обновить и отобразить эксперименте на карте
  refresh(): void {
    // region полигоны эксперементов
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(featureCollection(this.data.collection.map((exp) => exp.feature)));
    // endregion

    // region наименование эксперементов
    (this._mapModel?.map?.getSource(`${this.sourceId}-label`) as GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features: this.data.collection.filter((v) => !v.isCreating && !v.dragging).map((exp) => ({
        id: exp.id,
        type: 'Feature',
        geometry: center(exp.feature).geometry,
        properties: {
          label: exp.title,
          uuid: exp.uuid,
          color: invertColor(exp.color, true),
        },
      } as Feature)),
    });
    // endregion
  }

  refreshBorders(): void {
    const lineCoords = (exp: ExperimentModel, idx: number) => {
      const nextIdx = idx === 3 ? 0 : idx + 1;
      return [[exp.points[idx].x, exp.points[idx].y], [exp.points[nextIdx].x, exp.points[nextIdx].y]];
    };

    const exp = activeExperiment.value as ExperimentModel;
    (this._mapModel?.map?.getSource(`${this.sourceId}-borders`) as GeoJSONSource)?.setData(featureCollection(
      [0, 1, 2, 3].map((idx) => lineString(lineCoords(exp, idx), { uuid: exp.uuid }, { id: idx })),
    ));
  }

  showBorders(): void {
    if (!this.dragging) {
      const lineCoords = (exp: ExperimentModel, idx: number) => {
        const nextIdx = idx === 3 ? 0 : idx + 1;
        return [[exp.points[idx].x, exp.points[idx].y], [exp.points[nextIdx].x, exp.points[nextIdx].y]];
      };

      if (!activeExperiment.value) {
        (this._mapModel?.map?.getSource(`${this.sourceId}-borders`) as GeoJSONSource)?.setData(emptyFeatureCollection);
      } else {
        const exp = activeExperiment.value as ExperimentModel;
        (this._mapModel?.map?.getSource(`${this.sourceId}-borders`) as GeoJSONSource)?.setData(featureCollection(
          [0, 1, 2, 3].map((idx) => lineString(lineCoords(exp, idx), { uuid: exp.uuid }, { id: idx })),
        ));
      }
    }
  }

  hideBorders(): void {
    (this._mapModel?.map?.getSource(`${this.sourceId}-borders`) as GeoJSONSource)?.setData(emptyFeatureCollection);
  }

  refreshContour() {
    (this._mapModel?.map?.getSource(`${this.sourceId}-contour`) as GeoJSONSource)?.setData(
      this.dragging ? emptyFeatureCollection : featureCollection(
        this.data.collection
          .filter((v) => v.active || v.selected)
          .map((exp) => lineString([
            [exp.points[0].x, exp.points[0].y],
            [exp.points[1].x, exp.points[1].y],
            [exp.points[2].x, exp.points[2].y],
            [exp.points[3].x, exp.points[3].y],
            [exp.points[0].x, exp.points[0].y],
          ], {
            color: exp.selected ? '#CB7E00' : '#0F8764',
          }, {
            id: exp.id,
          })),
      ),
    );
  }

  refreshPlus(): void {
    const plusCoords = (exp: ExperimentModel, idx: number) => {
      const nextIdx = idx === 3 ? 0 : idx + 1;
      return [(exp.points[idx].x + exp.points[nextIdx].x) / 2, (exp.points[idx].y + exp.points[nextIdx].y) / 2];
    };

    const checkDuplicate = (exp: ExperimentModel, idx: number) => {
      const _points = this.duplicateExperiment(exp, idx);
      const _polygon = polygon([[_points[0], _points[1], _points[3], _points[2], _points[0]]]);
      return this.data.collection
        .filter((v) => v.uuid !== exp.uuid)
        .some((checkExp) => intersect(_polygon, checkExp.feature)?.geometry.type === 'MultiPolygon' || false);
    };

    (this._mapModel?.map?.getSource(`${this.sourceId}-plus`) as GeoJSONSource)?.setData(emptyFeatureCollection);

    if (activeExperiment.value && !this.dragging && !this.transformation) {
      const exp = activeExperiment.value as ExperimentModel;
      const points: Feature<Geometry, GeoJsonProperties>[] = [0, 1, 2, 3].reduce((acc, idx) => {
        if (!checkDuplicate(exp, idx)) {
          acc.push(point(plusCoords(exp, idx), { uuid: exp.uuid, name: 'plus' }, { id: idx }));
        }
        return acc;
      }, [] as Feature[]);
      if (points.length > 0) {
        (this._mapModel?.map?.getSource(`${this.sourceId}-plus`) as GeoJSONSource)?.setData(
          featureCollection(
            points,
          ),
        );
      }
    }
  }
  // endregion

  refreshAll() {
    this.refresh();
    this.refreshPlus();
    this.refreshContour();
  }

  setCollection(collection: ExperimentsCollection): void {
    this._data = collection;
    this.refreshAll();
  }

  private hasSelection(): boolean {
    return this.data.collection.some((v) => v.selected);
  }

  private getEventData(event: MapMouseEvent, selected = false) {
    const _features = this._mapModel?.map?.queryRenderedFeatures(event.point, {
      layers: [this.layerId],
    });
    if (selected && this.hasSelection()) {
      return {
        features: _features,
        experiments: this.data.collection
          .filter((exp) => exp.selected)
          .filter((exp: ExperimentModel) => _features?.map((f) => f.id).includes(exp.id)),
      };
    }
    return {
      features: _features,
      experiments: this.data.collection.filter((exp: ExperimentModel) => _features?.map((f) => f.id).includes(exp.id)),
    };
  }

  // region dragEvents - обработка событий и перемещение экспериментов
  dragEvents(): void {
    this._mapModel?.map?.on('mousedown', (event: MapMouseEvent) => {
      if (event.originalEvent.ctrlKey) {
        if (this._mapModel?.map?.getLayer(this.layerId)) {
          this.dragging = true;
          this.refreshPlus();
          this.refreshContour();
          this.hideBorders();
          const _data = this.getEventData(event, true);
          if (_data.experiments.length > 0 && _data.experiments.some((v) => v.active || v.selected)) {
            if (this.hasSelection()) {
              this._drag = event.lngLat;
              this.data.collection
                .filter((v) => v.selected)
                .forEach((exp) => {
                  exp.dragging = exp.points.map((p) => new Point(p.x, p.y));
                });
            } else if (activeExperiment.value) {
              this._drag = event.lngLat;
              activeExperiment.value.dragging = activeExperiment.value.points.map((p) => new Point(p.x, p.y));
            }
          }
        }
      }
    });

    this._mapModel?.map?.on('mousemove', (event: MapMouseEvent) => {
      if (event.originalEvent.ctrlKey) {
        if (this.hasSelection()) {
          const exp = this.data.collection.find((v) => v.dragging !== null && v.selected);

          if (exp) {
            this.data.collection
              .filter((v) => v.selected)
              .forEach((v) => {
                v.points.forEach((p, idx) => {
                  if (this._drag && v.dragging) {
                    p.x = v.dragging[idx].x + (event.lngLat.lng - this._drag.lng);
                    p.y = v.dragging[idx].y + (event.lngLat.lat - this._drag.lat);
                  }
                });
              });
          }
        } else {
          this.data.collection
            .filter((exp) => exp.dragging !== null)
            .forEach((exp: ExperimentModel) => {
              if (this._drag && exp.dragging) {
                const _positions: Position[] = exp.points.map((p, idx) => [
                  // @ts-ignore
                  exp.dragging[idx].x + (event.lngLat.lng - this._drag.lng),
                  // @ts-ignore
                  exp.dragging[idx].y + (event.lngLat.lat - this._drag.lat),
                ] as Position);
                _positions.push(_positions[0]);

                const shadow = polygon([_positions], {});

                const intersectExp = this.data.collection.filter((v) => v.uuid !== exp.uuid).find((v) => booleanOverlap(shadow, v.feature));

                if (!intersectExp) {
                  exp.points.forEach((p, idx) => {
                    // @ts-ignore
                    p.x = exp.dragging[idx].x + (event.lngLat.lng - this._drag.lng);
                    // @ts-ignore
                    p.y = exp.dragging[idx].y + (event.lngLat.lat - this._drag.lat);
                  });
                } else {
                  const delta = {
                    x: 0,
                    y: 0,
                    d: Infinity,
                  };
                  exp.points.forEach((p) => {
                    const np = event.originalEvent.shiftKey
                      ? nearestPoint([p.x, p.y], featureCollection(intersectExp.points.map((ip) => point([ip.x, ip.y])))) // Прилепание по углам опыта
                      : nearestPointOnLine(lineString([...intersectExp.points.map((ip) => [ip.x, ip.y]), [intersectExp.points[0].x, intersectExp.points[0].y]]), [p.x, p.y]); // Прилепание к сторонам опыта

                    if (distance(np, [p.x, p.y]) < delta.d) {
                      delta.x = np.geometry.coordinates[0] - p.x;
                      delta.y = np.geometry.coordinates[1] - p.y;
                      delta.d = distance(np, [p.x, p.y]);
                    }
                  });
                  exp.points.forEach((p) => {
                    p.x += delta.x;
                    p.y += delta.y;
                  });
                }
              }
            });
        }

        this._mapModel?.map?.dragRotate.disable();
        this.refresh();
      }
      const sticky = this.data.collection.filter((exp) => exp.isSticky);
      if (sticky.length > 0) {
        sticky.forEach((exp) => {
          exp.center.x = event.lngLat.lng;
          exp.center.y = event.lngLat.lat;
        });
        this.refresh();
      }
    });
    window.addEventListener('mouseup', () => {
      this.data.collection.forEach((exp) => {
        exp.dragging = null;
        if (!exp.isCreating) {
          exp.center.x = center(exp.feature).geometry.coordinates[0];
          exp.center.y = center(exp.feature).geometry.coordinates[1];
        }
        this._mapModel?.map?.dragRotate.enable();
        this.dragging = false;
        this.refreshPlus();
        this.refreshContour();
      });
    });
  }
  // endregion

  // region rotateEvents - обработка событий и вращение экспериментов
  rotateEvents(): void {
    const prevent = (event: MapWheelEvent) => {
      event.originalEvent.preventDefault();
      event.originalEvent.stopImmediatePropagation();
      event.preventDefault();
    };
    this._mapModel?.map?.on('wheel', (event: MapWheelEvent) => {
      if (event.originalEvent.ctrlKey) {
        let angle = (event.originalEvent as WheelEvent).deltaY > 0 ? -5 : 5;
        if (event.originalEvent.shiftKey) {
          angle *= 3;
          // todo не работает ивент прокрутка при альт и ктрл на линуксе
        } else if (event.originalEvent.altKey) {
          angle *= 0.2;
        }

        if (selectedExperiments.value.length > 0) {
          prevent(event);
          this.hideBorders();
          const exp = this.data.collection.find((v) => v.isHovered && v.selected);
          if (exp) {
            const cp = center(featureCollection(this.data.collection
              .filter((v) => v.selected)
              .map((v) => v.feature)));
            this.data.collection
              .filter((v) => v.selected)
              .forEach((v) => {
                const _polygon = transformRotate(v.feature, angle, {
                  pivot: cp,
                });
                [0, 1, 2, 3].forEach((i) => {
                  v.points[i].x = _polygon.geometry.coordinates[0][i][0];
                  v.points[i].y = _polygon.geometry.coordinates[0][i][1];
                });
              });
            this.refresh();
            this.refreshContour();
            this.refreshPlus();
          }
          return;
        }

        if (activeExperiment.value) {
          prevent(event);
          this.hideBorders();
          const cp = center(activeExperiment.value.feature);
          const _polygon = transformRotate(activeExperiment.value.feature, angle, {
            pivot: cp,
          });
          [0, 1, 2, 3].forEach((i) => {
            (activeExperiment.value as ExperimentModel).points[i].x = _polygon.geometry.coordinates[0][i][0];
            (activeExperiment.value as ExperimentModel).points[i].y = _polygon.geometry.coordinates[0][i][1];
          });
          this.refresh();
          this.refreshContour();
          this.refreshPlus();
          return;
        }

        const creatingExperiment = this.data.collection.find((exp) => exp.isCreating);
        if (creatingExperiment) {
          prevent(event);
          creatingExperiment.turn += angle;
          this.refresh();
          this.refreshContour();
          this.refreshPlus();
        }
      }
    });
  }
  // endregion

  // region clickEvents - обработка событий клика по эксперементу
  clickEvents(): void {
    if (this._mapModel?.map) {
      const map = this._mapModel.map;
      map.on('click', this.layerId, (event: MapMouseEvent) => {
        const data = this.getEventData(event);
        const uuids: string[] = data.experiments.map((f) => f.uuid) || [];
        if (uuids.length > 0) {
          if (event.originalEvent.shiftKey && !event.originalEvent.ctrlKey) {
            uuids.forEach((uuid: string) => toggleSelectedExperiment(uuid));
          } else {
            setActiveExperiment(uuids[0]);
          }
        }
      });
    }
  }
  // endregion

  // region hoverEvents - обработка событий наведения курсора на эксперименты
  hoverEvents(): void {
    if (this._mapModel?.map) {
      const map = this._mapModel.map;
      map.on('mousemove', this.layerId, (event: MapMouseEvent) => {
        const data = this.getEventData(event);
        const ids: number[] = data.experiments.map((f) => Number(f.id)) || [];
        this.data.collection.forEach((m) => {
          m.isHovered = ids.includes(m.id);
          map.setFeatureState({ source: this.sourceId, id: m.id }, { hover: ids.includes(Number(m.id)) });
        });
      });
    }
  }
  // endregion

  // region hoverBordersEvents - обработка событий наведения курсора на бордеры эксперимента
  private _hoveredBordersStack: Array<string | number | undefined> = [];

  hoverBordersEvents(): void {
    const ignoreKeys = ['Control', 'GroupNext', 'Alt'];
    window.document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Shift' && !event.altKey && !event.ctrlKey && this._mapModel?.cursorPosition && activeExperiment.value) {
        this.showBorders();
        this.transformation = true;
        this.refreshPlus();
      }
      if (ignoreKeys.includes(event.key) && event.shiftKey) {
        this.hideBorders();
        this.transformation = false;
        this.refreshPlus();
      }
    });

    window.document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        this.hideBorders();
        this.transformation = false;
        this.refreshPlus();
      }
      if (ignoreKeys.includes(event.key) && event.shiftKey && this._mapModel?.cursorPosition && activeExperiment.value) {
        this.showBorders();
        this.transformation = true;
        this.refreshPlus();
      }
    });
  }
  // endregion

  // region dragBorderEvents - обработа событий и пермещение бордеров эксперимента

  private _dragBorderData: {
    id: number,
    exp: ExperimentModel | undefined,
    startCoords: number[],
    experimentCoords: number[],
  } = {
    id: 0,
    exp: undefined,
    startCoords: [],
    experimentCoords: [],
  }

  dragBorderEvents(): void {
    const _layerId = `${this.layerId}-borders`;
    this._mapModel?.map?.on('mousedown', _layerId, (event: MapMouseEvent) => {
      if (event.originalEvent.shiftKey && activeExperiment.value) {
        this.dragging = true;
        this.refreshPlus();
        const _features = this._mapModel?.map?.queryRenderedFeatures(event.point, {
          layers: [_layerId],
        });
        if (_features?.length === 1) {
          this._mapModel?.map?.dragPan.disable();
          this._mapModel?.map?.setFeatureState({ source: `${this.sourceId}-borders`, id: _features[0].id } as FeatureIdentifier, { drag: true });
          this._dragBorderData.id = Number(_features[0].id);
          this._dragBorderData.exp = activeExperiment.value as ExperimentModel;
          this._dragBorderData.startCoords = [event.lngLat.lng, event.lngLat.lat];
          const borderIdx = Number(this._dragBorderData.id.toString().slice(-1));
          this._dragBorderData.experimentCoords = [
            this._dragBorderData.exp?.points[borderIdx].x || 0,
            this._dragBorderData.exp?.points[borderIdx].y || 0,
            this._dragBorderData.exp?.points[borderIdx === 3 ? 0 : borderIdx + 1].x || 0,
            this._dragBorderData.exp?.points[borderIdx === 3 ? 0 : borderIdx + 1].y || 0,
          ];
        }
      }
    });
    this._mapModel?.map?.on('mousemove', (event: MapMouseEvent) => {
      if (this._dragBorderData.exp) {
        const deltaX = event.lngLat.lng - this._dragBorderData.startCoords[0];
        const deltaY = event.lngLat.lat - this._dragBorderData.startCoords[1];
        const borderIdx = this._dragBorderData.id;
        this._dragBorderData.exp.points[borderIdx].x = this._dragBorderData.experimentCoords[0] + deltaX;
        this._dragBorderData.exp.points[borderIdx].y = this._dragBorderData.experimentCoords[1] + deltaY;
        this._dragBorderData.exp.points[borderIdx === 3 ? 0 : borderIdx + 1].x = this._dragBorderData.experimentCoords[2] + deltaX;
        this._dragBorderData.exp.points[borderIdx === 3 ? 0 : borderIdx + 1].y = this._dragBorderData.experimentCoords[3] + deltaY;
        this.refresh();
        this.refreshContour();
        this.refreshBorders();
      }
    });
    window.addEventListener('mouseup', () => {
      if (this._dragBorderData.exp) {
        this._mapModel?.map?.setFeatureState({ source: `${this.sourceId}-borders`, id: this._dragBorderData.id }, { drag: false });
        this._dragBorderData.id = 0;
        this._dragBorderData.exp = undefined;
        this._mapModel?.map?.dragPan.enable();
        this.dragging = false;
      }
    });
  }
  // endregion

  // region clickPlusEvents - обработка событий клика по иконки плюса
  clickPlusEvents(): void {
    if (this._mapModel?.map) {
      const map = this._mapModel.map;
      map.on('click', `${this.layerId}-plus`, (event: MapMouseEvent) => {
        const _features = map.queryRenderedFeatures(event.point, {
          layers: [`${this.layerId}-plus`],
        });
        if (_features.length > 0) {
          const expUuid = _features[0].properties?.uuid;
          const exp = this.data.collection.find((v) => v.uuid === expUuid);
          const pntId = Number(_features[0].id);
          if (exp) {
            const points = this.duplicateExperiment(exp, pntId);
            EventBus.$emit('AddExperimentByCoords', points);
          }
        }
      });
    }
  }
  // endregion
}
