import { Map } from 'mapbox-gl';
import plusCircleSVG from '@/assets/icons/plus-circle.svg';

const loadImages = (map: Map) => {
  const plusCircleImg = new Image(20, 20);
  plusCircleImg.onload = () => map.addImage('plus-circle', plusCircleImg);
  plusCircleImg.src = plusCircleSVG;
};

export default loadImages;
