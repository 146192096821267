import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import type { MapModel } from '@/models/map/MapModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapRulerModel } from '@/models/map/data/MapRulerModel';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import mapboxgl, { GeoJSONSource, Popup } from 'mapbox-gl';
import { Feature, Point } from 'geojson';
import { length } from '@turf/turf';
import { formatDistance } from '@/utils/formatDistance';

export class MapLayerRulerModel extends MapLayerModel implements IMapLayerModel {
  get data(): MapRulerModel {
    return this._data;
  }

  private _data: MapRulerModel

  private activeState: 'inActive' | 'active' |'drag' = 'active'

  private popup: Popup | undefined

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'ruler', input.uuid);
    this._data = input as MapRulerModel;
    this.createSourceLayer();
    this.handlerClick();
    this.layerIds.push(...[this.layerId, `${this.layerId}-points`]);
    this.sourceIds.push(this.sourceId);
    this._mapModel.map.on('mouseenter', `${this.layerId}-points`, (e) => {
      this._mapModel.map.getCanvas().style.cursor = 'pointer';
    });
    this._mapModel.map.on('mouseleave', `${this.layerId}-points`, (e) => {
      this._mapModel.map.getCanvas().style.cursor = '';
    });
  }

  createSourceLayer() {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this._data.FeatureCollection,
    }).addLayer(
      {
        id: `${this.layerId}-points`,
        type: 'circle',
        source: this.sourceId,
        paint: {
          'circle-radius': 5,
          'circle-color': '#6a4c93',
        },
        filter: ['in', '$type', 'Point'],
      },
    ).addLayer({
      id: this.layerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#6a4c93',
        'line-width': 3.5,
      },
      filter: ['in', '$type', 'LineString'],
    });

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.RULER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-points`, MapAnchorEnum.RULER);
  }

  handlerClick() {
    const linestring: Feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
      properties: {},
    };
       this._mapModel?.map?.on('click', (e) => {
         if (this.activeState === 'inActive') return;
         const features = this._mapModel?.map?.queryRenderedFeatures(e.point, {
           layers: [`${this.layerId}-points`],
         });
         if (this._data.FeatureCollection.features.length > 1) this._data.FeatureCollection.features.pop();
         if (features?.length) {
           const id = features[0].properties?.id;
           this._data.FeatureCollection.features = this._data.FeatureCollection.features.filter(
             (point) => point.properties?.id !== id,
           );
           if (this._data.FeatureCollection.features.length < 2 && this.popup) {
             this.popup.remove();
           }
         } else {
           const point: Feature = {
             type: 'Feature',
             geometry: {
               type: 'Point',
               coordinates: [e.lngLat.lng, e.lngLat.lat],
             },
             properties: {
               id: String(new Date().getTime()),
             },
           };

           this._data.FeatureCollection.features.push(point);
         }

         if (this._data.FeatureCollection.features.length > 1) {
           linestring.geometry = {
             type: 'LineString',
             coordinates: this._data.FeatureCollection.features.map(
               (feature: Feature) => (feature.geometry as Point).coordinates,
             ),
           };

           this._data.FeatureCollection.features.push(linestring);
           const points = this._data.FeatureCollection.features.filter((f) => f.geometry.type === 'Point');
           const lastPoint = points[points.length - 1].geometry as Point;
           const distance = length(linestring);
           if (this.popup) {
             this.popup.remove();
           }
           this.popup = new Popup({ closeOnClick: false })
             .setLngLat([lastPoint.coordinates[0], lastPoint.coordinates[1]])
             .setHTML(`${formatDistance(distance)} <i id='map-ruler-close'></i>`)
             .addTo(this._mapModel?.map as mapboxgl.Map);
           this.popup.addClassName('ruler-popup');
         }

         const source: GeoJSONSource = this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource;
         source.setData(this._data.FeatureCollection);
       });
  }

  closePopup() {
      this.popup?.remove();
      this.activeState = 'inActive';
  }
}
