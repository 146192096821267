import { ScoutingPoigroupDto } from '@/services/api/dto/scouting/ScoutingPoigroupDto';
import { POIGroupTypeEnum } from '@/constants/enums/POIGroupTypeEnum';

export class PoiGroupModel {
  set type(value: POIGroupTypeEnum) {
    this._type = value;
  }

  get type(): POIGroupTypeEnum {
    return this._type;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get id(): number {
    return this._id;
  }

  get farmunit(): number {
    return this._farmunit;
  }

  private _id: number;

  private _name: string;

  private _version: number;

  private _farmunit: number;

  private _type: POIGroupTypeEnum;

  constructor(dto: ScoutingPoigroupDto) {
    this._id = dto.id;
    this._name = dto.name;
    this._version = dto.version;
    this._farmunit = dto.farmunit;
    this._type = dto.grouptype;
  }
}
