import { AnyLayer } from 'mapbox-gl';

export const taskMapContourLayerDef = (sourceId: string, layerId: string): AnyLayer => ({
  id: layerId,
  source: sourceId,
  type: 'fill',
  layout: {},
  metadata: { type: 'task-map-contour' },
  paint: {
    'fill-color':
      ['case',
        ['==', ['get', 'zone'], '1.1'], '#BE0000',
        ['==', ['get', 'zone'], '1.2'], '#E51F02',
        ['==', ['get', 'zone'], '1.3'], '#FD5502',
        ['==', ['get', 'zone'], '2.1'], '#FCE502',
        ['==', ['get', 'zone'], '2.2'], '#FBFC02',
        ['==', ['get', 'zone'], '2.3'], '#E2FB03',
        ['==', ['get', 'zone'], '3.1'], '#65B424',
        ['==', ['get', 'zone'], '3.2'], '#2A7F01',
        ['==', ['get', 'zone'], '3.3'], '#236400',
        ['get', 'color'],
      ],
    'fill-opacity':
      ['case',
        ['boolean', ['feature-state', 'inactive'], false], 0,
        ['boolean', ['feature-state', 'active'], false], 1,
        ['boolean', ['feature-state', 'hover'], false], 1,
        0,
      ],
    'fill-opacity-transition': { duration: 500 },
    'fill-outline-color': '#000000',
  },
});

export const taskMapContourLinesLayerDef = (sourceId: string, layerId: string): AnyLayer => ({
  id: layerId,
  source: sourceId,
  type: 'line',
  metadata: { type: 'task-map-contour' },
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#333',
    'line-width': 1.5,
  },
});

export const taskMapContourLabelsLayerDef = (sourceId: string, layerId: string): AnyLayer => ({
  id: layerId,
  source: sourceId,
  type: 'symbol',
  metadata: { type: 'task-map-contour' },
  layout: {
    'text-field': ['get', 'label'],
    'text-anchor': 'center',
    'text-size': [
      'interpolate', ['linear'], ['zoom'],
      8, 0,
      9, 6,
      10, 7,
      12, 12,
      17, 15,
    ],
    'text-justify': 'center',
    'text-font': [
      'literal',
      ['Inter Bold'],
    ],
  },
  paint: {
    'text-color': '#222222',
    'text-halo-width': 1,
    'text-halo-color': 'rgba(255, 255, 255, 0.8)',
    'text-halo-blur': 1,
  },
});
