import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { CropRotationDto } from '@/services/api/dto/cropRotation/CropRotationDto';
import { CropRotationFieldDto } from '@/services/api/dto/cropRotation/CropRotationFieldDto';
import { CropRotationSeasonDto } from '@/services/api/dto/cropRotation/CropRotationSeasonDto';
import { formatQueryDate } from '@/utils/formatQueryDate';
import { useStruct } from '@/composables/useStruct';

export type SaveSowingType = {
  'hybrid_item': number,
  'hybrid': string,
  'crop_item': number,
  'name'?: string,
  'is_green_manure': boolean,
  'is_perennial': boolean,
  'planned_yield': number,
  'actual_yield': number,
  'field': number,
  'season': number
  'yield_unit_name'?: unknown,
  'sowing_date'?: string,
  'harvest_date'?: string,
  'order'?: number,
  'note'?: string,
}

export const endpoints = {
  getTable: (farmunit: number): string => `/api/v1/books/rotation/${farmunit}/table/`,
  getField: (farmunit: number): string => `/api/v1/books/rotation/${farmunit}/field/`,
  getSeasons: (farmunit: number): string => `/api/v1/books/rotation/${farmunit}/season/`,
  deleteSeason: (farmunit: number, seasonId: number): string => `/api/v1/books/rotation/${farmunit}/season/${seasonId}`,
  updateSeason: (farmunit: number, seasonId: number): string => `/api/v1/books/rotation/${farmunit}/season/${seasonId}`,
  addSeason: (farmunit: number): string => `/api/v1/books/rotation/${farmunit}/season/`,
  saveSowing: (farmunit: number): string => `/api/v1/books/rotation/${farmunit}/field/`,
  patchSowing: (farmunit: number, sowingId: number): string => `/api/v1/books/rotation/${farmunit}/field/${sowingId}/`,
  deleteSowing: (farmunit: number, sowingId: number): string => `/api/v1/books/rotation/${farmunit}/field/${sowingId}/`,
};

export class ApiCropRotation extends ApiHandler {
  public async getTable(): Promise<ApiResponse<CropRotationDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<CropRotationDto[]>(endpoints.getTable(unit));
  }

  public async getField(): Promise<ApiResponse<CropRotationFieldDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<CropRotationFieldDto[]>(endpoints.getField(unit));
  }

  public async getSeasons(): Promise<ApiResponse<CropRotationSeasonDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<CropRotationSeasonDto[]>(endpoints.getSeasons(unit));
  }

  public async deleteSeason(seasonId: number): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).delete<void>(endpoints.deleteSeason(unit, seasonId));
  }

  // eslint-disable-next-line camelcase
  public async updateSeason(seasonId: number, data: { name: string, period_start: string, period_stop: string, note: string }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<void>(endpoints.updateSeason(unit, seasonId), data);
  }

  public async addSeason(data: {name: string, start: Date, stop: Date, note: string}): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;

    return await this.request({ auth: true }).post<void>(endpoints.addSeason(unit), {
      name: data.name,
      period_start: formatQueryDate(data.start),
      period_stop: formatQueryDate(data.stop),
      note: data.note,
    });
  }

  public async saveSowing(data: SaveSowingType): Promise<ApiResponse<CropRotationFieldDto>> {
    const unit = useStruct().structId.value;

    return await this.request({ auth: true }).post<CropRotationFieldDto>(endpoints.saveSowing(unit), data);
  }

  public async patchSowing(sowingId: number, data: SaveSowingType): Promise<ApiResponse<CropRotationFieldDto>> {
    const unit = useStruct().structId.value;

    return await this.request({ auth: true }).patch<CropRotationFieldDto>(endpoints.patchSowing(unit, sowingId), data);
  }

  public async deleteSowing(sowingId: number): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).delete<void>(endpoints.deleteSowing(unit, sowingId));
  }
}
