import { CropItemDto } from '@/services/api/dto/crop/CropItemDto';

export class CropModel {
  set hybrids(value: { id: number; name: string }[]) {
    this._hybrids = value;
  }

  get hybrids(): { id: number; name: string }[] {
    return this._hybrids;
  }

  get isPerennial(): boolean {
    return this._isPerennial;
  }

  set isPerennial(value: boolean) {
    this._isPerennial = value;
  }

  set isGreenManure(value: boolean) {
    this._isGreenManure = value;
  }

  get isGreenManure(): boolean {
    return this._isGreenManure;
  }

  set order(value: number) {
    this._order = value;
  }

  get order(): number {
    return this._order;
  }

  get updateAt(): Date {
    return this._updateAt;
  }

  get createAt(): Date {
    return this._createAt;
  }

  get groupName(): string {
    return this._groupName;
  }

  get groupId(): number {
    return this._groupId;
  }

  get entityName(): string {
    return this._entityName;
  }

  get entityId(): number {
    return this._entityId;
  }

  get name(): string {
    return this._name;
  }

  get uuid(): string {
    return this._uuid;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get catalogId(): number {
    return this._catalogId;
  }

  get id(): number {
    return this._id;
  }

  private readonly _id: number;

  private readonly _catalogId: number;

  private readonly _uuid: string;

  private readonly _name: string;

  private _order: number;

  private readonly _entityId: number;

  private readonly _entityName: string;

  private readonly _groupId: number;

  private readonly _groupName: string;

  private _isGreenManure: boolean;

  private _isPerennial: boolean;

  private readonly _createAt: Date;

  private readonly _updateAt: Date;

  private _color: string;

  private _hybrids: { id: number, name: string }[] = [];

  constructor(dto: CropItemDto) {
    this._id = dto.id;
    this._catalogId = dto.catalog;
    this._uuid = dto.uuid;
    this._name = dto.name;
    this._order = dto.order;
    this._entityId = dto.entity.id;
    this._entityName = dto.entity.name;
    this._groupId = dto.entity.group.id;
    this._groupName = dto.entity.group.name;
    this._isGreenManure = dto.is_green_manure;
    this._isPerennial = dto.is_perennial;
    this._createAt = new Date(dto.create_date);
    this._updateAt = new Date(dto.update_date);
    this._color = dto.color || '#FF0000';
    this._hybrids = dto.hybrids.map((v) => ({ id: v.id, name: v.name }));
  }

  patch(dto: CropItemDto) {
    this._order = dto.order;
    this._isGreenManure = dto.is_green_manure;
    this._isPerennial = dto.is_perennial;
    this._color = dto.color || '#FF0000';
    this._hybrids = dto.hybrids.map((v) => ({ id: v.id, name: v.name }));
  }
}
