import type { MapModel } from '@/models/map/MapModel';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import EventBus from '@/services/eventBus/EventBus';

export class MapContextEvents {
  private isRotate = false;

  constructor(mapModel: MapModel) {
    mapModel.map?.on('mousedown', () => {
      this.isRotate = false;
    });

    mapModel.map?.on('rotate', () => {
      this.isRotate = true;
    });

    mapModel.map?.on('contextmenu', (e) => {
      if (!this.isRotate) {
        mapModel.events.emitContextMenu(e);

        EventBus.$emit(EventsEnum.ContextMenu, mapModel.container, e);
      }
    });
  }
}
