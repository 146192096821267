import { useMap } from '@/composables/useMap';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapContextEvents } from '@/models/map/Events/MapContextEvents';
import type { MapModel } from '@/models/map/MapModel';
import { LngLat, MapMouseEvent } from 'mapbox-gl';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import EventBus from '@/services/eventBus/EventBus';

export class MapEvents {
  private _map: MapModel

  private _clickStack: { type: MapLayerTypeEnum, layer: string, source: string}[] = []

  private _center: LngLat = new LngLat(0, 0);

  constructor(map: MapModel) {
    this._map = map;

    // eslint-disable-next-line no-new
    new MapContextEvents(map);

    map.map?.on('click', (e: MapMouseEvent) => {
      map.events.emitClick(e);
    });

    map.map?.on('mousedown', () => {
      this._center = map.map?.getCenter() || new LngLat(0, 0);
      this._clickStack = [];
    });

    map.map?.on('mouseup', (e: MapMouseEvent) => {
      const isMove = this._center ? Math.floor(this._center.lng * 1000 - (map.map?.getCenter().lng || 0) * 1000) !== 0 || Math.floor(this._center.lat * 1000 - (map.map?.getCenter().lat || 0) * 1000) !== 0 : false;

      if (e.originalEvent.button === 0 && !isMove) {
        if (this._clickStack.some((s) => s.type === MapLayerTypeEnum.POI)) {
          map.events.emitClick(e, {
            layerType: 'poi',
          });
          // this.onPoiClick(e);
        } else if (this._clickStack.some((s) => s.type === MapLayerTypeEnum.FIELDS)) {
          map.events.emitClick(e, {
            layerType: 'field',
          });
        }
      }
    });

    map.map?.on('mousemove', (e) => {
      if (map.map) {
        if (useMap().isToolActivated()) {
          return;
        }
        map.cursorPosition = {
          lngLat: e.lngLat,
          point: e.point,
        };

        // const features = map.map.queryRenderedFeatures(e.point);

        // const POIFeature = features.find((f) => f.layer?.id === POI_LAYER_ID);
        // if (POIFeature) {
        //   if (useMap().hoveredPOIId.value !== POIFeature.id) {
        //     if (useMap().hoveredPOIId.value) {
        //       map.map.setFeatureState(
        //         { source: POI_SOURCE, id: useMap().hoveredPOIId.value } as FeatureSelector,
        //         { hover: false },
        //       );
        //     }
        //     useMap().hoveredPOIId.value = POIFeature.id;
        //     map.map.setFeatureState(
        //       { source: POI_SOURCE, id: useMap().hoveredPOIId.value } as FeatureSelector,
        //       { hover: true },
        //     );
        //   }
        // }
        EventBus.$emit(EventsEnum.MapMouseMove, e);
      }
    });
    map.map?.on('mouseout', () => {
      map.cursorPosition = undefined;
    });
  }

  pushClickStack(obj: { type: MapLayerTypeEnum, layer: string, source: string }) {
    this._clickStack.push(obj);
  }
}
