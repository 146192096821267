import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MapHoverRasterValue" }
const _hoisted_2 = { class: "MapHoverRasterValue-value" }

export function render(_ctx, _cache) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_ctx.valueComputed.length)
    ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 0,
        content: "Значение индекса по растру",
        placement: "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.valueComputed.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.valueComputed[0].band_values.length === 1)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.valueComputed[_ctx.valueComputed.length-1].band_values[0].value), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}