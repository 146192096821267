<template src="./PaletteGradientColorPicker.pug" lang="pug"/>
<style src="./PaletteGradientColorPicker.scss" lang="scss"/>

<script lang="ts">
import { GradientModel } from '@/models/assets/GradientModel';
import { PaletteModel } from '@/models/assets/PaletteModel';
import AssetsGradients from '@/services/assets/AssetsGradients';
import {
  computed, defineComponent, PropType, reactive, ref, watch,
} from 'vue';
import hexToRgba from '@/lib/convertors/hexToRgba';
import chroma from 'chroma-js';
import { getClientXY } from '@/utils/mouseTouchEvent';

export default defineComponent({
  name: 'PaletteGradientColorPicker',
  props: {
    palette: {
      type: Object as PropType<PaletteModel>,
      required: true,
    },
  },
  emit: ['colors'],
  setup(props) {
    const stops = ref<number[]>([]);

    const paletteData = computed(() => (props.palette?.type === 'uniqValues' ? props.palette.uniqData : props.palette.borderData));

    const activeGradient = computed<GradientModel | undefined>({
      get() {
        return AssetsGradients.data.find((m) => m.id === paletteData.value.gradient?.id) as GradientModel | undefined;
      },
      set(v: GradientModel | undefined) {
        if (paletteData.value.gradient) {
          paletteData.value.gradient.id = v?.id || '';
        }
      },
    });

    // region emit updated colors
    const updateColors = () => {
      const f = chroma.scale(activeGradient.value?.positions.map((c) => c.color)).domain(activeGradient.value?.positions.map((c) => c.position));
      const colors = paletteData.value.gradient?.stops.map((v) => f(v).toString());
      paletteData.value.values.forEach((v, i) => {
        if (colors && colors.length >= i) {
          v.color = colors[i];
        } else {
          v.color = '#666666';
        }
      });
    };

    watch(activeGradient, updateColors);

    watch(stops, updateColors);
    // endregion

    const activeIndex = ref();
    const sliderRef = ref();
    const min = 0;
    const max = 1;
    const dragStartPosition = ref({
      startX: 0,
      startY: 0,
      newPosition: 0,
      currentX: 0,
    });
    const diff = computed(() => (100 * (dragStartPosition.value.currentX - dragStartPosition.value.startX)) / (sliderRef.value.offsetWidth || 0));

    const emitValue = (value: number) => {
      const realValue = min + (value * (max - min)) / 100;
      stops.value = stops.value.reduce((acc, v, idx) => {
        acc.push(idx === activeIndex.value ? realValue : v);
        return acc;
      }, [] as number[]);
    };

    const dragging = (e: MouseEvent | TouchEvent) => {
      window.getSelection()?.removeAllRanges();
      const { clientX } = getClientXY(e);

      dragStartPosition.value.currentX = clientX;

      if (activeIndex.value !== undefined) {
        if (stops.value[activeIndex.value] <= max && stops.value[activeIndex.value] >= min) {
          // Если значение справа от активного индекса больше текущего значения,
          // или значение справа не определено (undefined),
          // И если значение слева от активного индекса меньше текущего значения,
          // или значение слева не определено (undefined),
          if (
            (stops.value[activeIndex.value + 1] > stops.value[activeIndex.value] || stops.value[activeIndex.value + 1] === undefined)
              && (stops.value[activeIndex.value - 1] < stops.value[activeIndex.value] || stops.value[activeIndex.value - 1] === undefined)
          ) {
            emitValue(dragStartPosition.value.newPosition + diff.value);
          } else if (stops.value[activeIndex.value + 1] <= stops.value[activeIndex.value]) {
            activeIndex.value += 1;
            emitValue(dragStartPosition.value.newPosition + diff.value);
          } else if (stops.value[activeIndex.value - 1] >= stops.value[activeIndex.value]) {
            activeIndex.value -= 1;
            emitValue(dragStartPosition.value.newPosition + diff.value);
          }
        }
      }
    };

    const stop = () => {
      window.removeEventListener('mousemove', dragging);
      window.removeEventListener('touchmove', dragging);
      window.removeEventListener('mouseup', stop);
      window.removeEventListener('touchend', stop);
    };
    const hand = (e: MouseEvent | TouchEvent, idx: number) => {
      const { clientX } = getClientXY(e);
      activeIndex.value = idx;
      const left = stops.value[idx];

      dragStartPosition.value.startX = clientX;
      dragStartPosition.value.newPosition = left;

      window.addEventListener('mousemove', dragging);
      window.addEventListener('touchmove', dragging);
      window.addEventListener('mouseup', stop);
      window.addEventListener('touchend', stop);
    };

    const gradients = reactive<GradientModel[]>(AssetsGradients.data as GradientModel[]);

    return {
      gradients,
      hexToRgba,
      hand,
      sliderRef,
      activeGradient,
    };
  },
});
</script>
