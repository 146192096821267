import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { GroupDto } from '@/services/api/dto/user/GroupDto';
import { UserServiceTokenDto } from '@/services/api/dto/user/UserServiceTokenDto';
import { StorageDto } from '@/services/api/dto/user/StorageDto';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { ActivateUserDto } from '../dto/user/ActivateUserDto';
import { ReactivateUserDto } from '../dto/user/ReactivateUserDto';
import { UserDto } from '../dto/user/UserDto';
import { UserPropertiesDto } from '../dto/user/UserPropertiesDto';

export const endpoints = {
  getUser: (): string => '/api/v1/users/me/',
  registerUser: (): string => '/api/v1/users/register/',
  reactivateUser: (): string => '/api/v1/users/reactivate/',
  readSubjects: (): string => '/api/v1/users/subjects/',
  editingMail: (): string => '/api/v1/users/send-change-email/',
  aceptMail: ({ key1, key2 }: {'key1': string, 'key2': string}): string => `/api/v1/users/change-email/${key1}/${key2}/`,
  changePassword: (): string => '/api/v1/users/me/password_change/',
  activateUser: (address: string): string => `/user/activate/${address}/`,
  addSubjects: (): string => '/api/v1/users/subject/add/',
  readSubjectsUser: (id: string): string => `/api/v1/users/subject/${id}/`,
  resetPassword: (): string => '/api/v1/users/password-reset/',
  setPassword: (): string => '/api/v1/users/password-reset-done/',
  acceptEula: (): string => '/api/v1/users/me/',
  saveProfile: (): string => '/api/v1/users/me/',
  getGroup: () => '/api/v1/users/group/',
  getServiceToken: () => '/api/v1/users/me/token/',
  getStorage: (): string => '/api/v1/users/storage/',
  getStorageKey: (key: StorageKeyEnum): string => `/api/v1/users/storage/${key}/`,
  postStorageKey: (): string => '/api/v1/users/storage/',
  patchStorage: (key: StorageKeyEnum): string => `/api/v1/users/storage/${key}/`,
  deleteStorage: (): string => '/api/v1/users/storage/-/',
  deleteStorageKey: (key: StorageKeyEnum): string => `/api/v1/users/storage/${key}/`,

};

export class ApiUser extends ApiHandler {
  public async getUser(): Promise<ApiResponse<UserPropertiesDto>> {
    return await this.request({ auth: true }).get<UserPropertiesDto>(endpoints.getUser());
  }

  public async registUser(body: {
    'email': string;
    'password': string;
    'password_confirm': string;
    'first_name'?: string;
    'last_name'?: string
  }): Promise<ApiResponse<UserDto>> {
    return await this.request().post<UserDto>(endpoints.registerUser(), body);
  }

  public async resetPassword(data: { email: string }): Promise<ApiResponse<void>> {
    return await this.request().put<void>(endpoints.resetPassword(), data);
  }

  public async setPassword(data: { code: any, uid: any, 'new_password': any }): Promise<ApiResponse<void>> {
    return await this.request().put<void>(endpoints.setPassword(), data);
  }

  public async activateUser(address: string): Promise<ApiResponse<ActivateUserDto>> {
    return await this.request().get<ActivateUserDto>(endpoints.activateUser(address));
  }

  public async editingMail(data: {email: string}): Promise<ApiResponse<ReactivateUserDto>> {
    return await this.request().get<ReactivateUserDto>(endpoints.editingMail(), data);
  }

  public async aceptMail(address: {'key1': string, 'key2': string}): Promise<ApiResponse<ActivateUserDto>> {
    return await this.request().get<ActivateUserDto>(endpoints.aceptMail(address));
  }

  public async readSubjects(): Promise<ApiResponse<UserDto[]>> {
    return await this.request({ auth: true }).get<UserDto[]>(endpoints.readSubjects());
  }

  public async readSubjectsUser(address: {'user_id': string}): Promise<ApiResponse<UserPropertiesDto>> {
    return await
    this.request({ auth: true })
      .get<UserPropertiesDto>(endpoints.readSubjectsUser(address.user_id));
  }

  public async editingSubjects(address: {'user_id': string}, data: any): Promise<ApiResponse<UserPropertiesDto>> {
    return await
    this.request({ auth: true })
      .put<UserPropertiesDto>(endpoints.readSubjectsUser(address.user_id), data);
  }

  public async deleteSubject(address: {'user_id': string}): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true })
      .delete(endpoints.readSubjectsUser(address.user_id));
  }

  public async acceptEulaDate(date: string): Promise<ApiResponse<UserPropertiesDto>> {
    return await this.request({ auth: true }).patch<UserPropertiesDto>(endpoints.acceptEula(), { eula_accepted_from: date });
  }

  public async addSubjects(date: any): Promise<ApiResponse<UserDto>> {
    return await this.request({ auth: true }).post<UserDto>(endpoints.addSubjects(), date);
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<ApiResponse<void>> {
    return await this.request({ auth: true, passError: true }).put<void>(endpoints.changePassword(), {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  public async saveProfile(data: {
    firstName: string,
    lastName: string,
    phone: string,
  }): Promise<ApiResponse<UserPropertiesDto>> {
    return await this.request({ auth: true }).patch<UserPropertiesDto>(endpoints.saveProfile(), {
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
    });
  }

  public async getGroup(): Promise<ApiResponse<GroupDto[]>> {
    return await this.request({ auth: true }).get<GroupDto[]>(endpoints.getGroup());
  }

  public async getServiceToken(): Promise<ApiResponse<UserServiceTokenDto>> {
    return await this.request({ auth: true }).get<UserServiceTokenDto>(endpoints.getServiceToken());
  }

  public async getStorage(): Promise<ApiResponse<StorageDto[]>> {
    return await this.request({ auth: true }).get<StorageDto[]>(endpoints.getStorage());
  }

  public async getStorageKey(key: StorageKeyEnum): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).get<StorageDto>(endpoints.getStorageKey(key));
  }

  public async postStorage(data: {key: string, body: {[key: string]: any} | string}): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).post<StorageDto>(endpoints.postStorageKey(), data);
  }

  public async patchStorage(key: StorageKeyEnum, data: {[key: string]: any} | string): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).patch<StorageDto>(endpoints.patchStorage(key), { body: data });
  }

  public async deleteStorage(): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deleteStorage());
  }

  public async deleteStorageKey(key: StorageKeyEnum): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deleteStorageKey(key));
  }
}
