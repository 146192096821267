import { prepareValue } from '@/lib/convertors/prepareValue';

export const processingType = (value: string | number | null | undefined, processing: string): Date | string | number | null => {
  const _value = prepareValue(value);

  if (processing === 'day-month' && typeof _value === 'number') {
    return new Date(new Date().setTime(Math.min((_value + 1), 365) * 24 * 3600 * 1000));
  }
  return _value;
};
