import { BaseMapColors } from '@/assets/data/BaseMapColors';

export const canvasImageDataToZones = (data: Uint8ClampedArray) => {
  let i = 0;
  const colors = BaseMapColors.reduce((acc, v) => {
    const k: string = v.slice(0, 3).join('.');
    acc[k] = i++;
    return acc;
  }, {} as {[key: string]: number});
  const _data: Uint8ClampedArray = new Uint8ClampedArray(data.length / 4);

  for (i = 0; i < data.length; i += 4) {
    const k = `${data[i]}.${data[i + 1]}.${data[i + 2]}`;
    if (k in colors) {
      _data[i / 4] = colors[k];
    } else {
      _data[i / 4] = colors[0];
    }
  }

  return _data;
};
