import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a80a6c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "VectorPalettePanel-message"
}
const _hoisted_2 = {
  key: 1,
  class: "VectorPalettePanel-blocks"
}
const _hoisted_3 = { class: "VectorPalettePanel-block" }
const _hoisted_4 = { class: "VectorPalettePanel-block" }
const _hoisted_5 = { class: "VectorPalettePanel-block" }
const _hoisted_6 = { class: "VectorPalettePanel-classes" }
const _hoisted_7 = { class: "VectorPalettePanel-class header" }
const _hoisted_8 = { class: "VectorPalettePanel-class-colorBox" }
const _hoisted_9 = { class: "VectorPalettePanel-class-value" }
const _hoisted_10 = { class: "VectorPalettePanel-class-label" }
const _hoisted_11 = { class: "VectorPalettePanel-class" }
const _hoisted_12 = { class: "VectorPalettePanel-class-colorBox" }
const _hoisted_13 = {
  key: 0,
  class: "VectorPalettePanel-class-value"
}
const _hoisted_14 = {
  key: 1,
  class: "VectorPalettePanel-class-label"
}
const _hoisted_15 = { class: "VectorPalettePanel-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_VectorPaletteHistogram = _resolveComponent("VectorPaletteHistogram")!
  const _component_UiSlider = _resolveComponent("UiSlider")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_PaletteGradientColorPicker = _resolveComponent("PaletteGradientColorPicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.valuesCount <= 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('palette-border-values-count-too-low', { ns: 'palette'  })), 1))
      : _createCommentVNode("", true),
    (_ctx.layer.palette && _ctx.paletteData?.classCount && _ctx.valuesCount > 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('select-class-count', { ns: 'mapbox' })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: _ctx.paletteData.classCount,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paletteData.classCount) = $event)),
                  min: 1,
                  "value-on-clear": 3,
                  max: Math.min(20, Object.keys(_ctx.layer.palette.vectorData).length)
                }, null, 8, ["modelValue", "max"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_VectorPaletteHistogram, { layer: _ctx.layer }, null, 8, ["layer"]),
            (_ctx.layer.palette?.precision !== undefined && _ctx.layer.palette?.range !== undefined)
              ? (_openBlock(), _createBlock(_component_UiSlider, {
                  key: 0,
                  modelValue: _ctx.histogramSlider,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.histogramSlider) = $event)),
                  min: _ctx.layer.palette.range.min,
                  max: _ctx.layer.palette.range.max,
                  precision: _ctx.layer.palette.precision
                }, null, 8, ["modelValue", "min", "max", "precision"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('color')), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('value')), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('label')), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layer.palette?.borderData.values, (c, idx) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_color_picker, {
                      pureColor: c.color,
                      "onUpdate:pureColor": ($event: any) => ((c.color) = $event),
                      "blur-close": "",
                      "round-history": true,
                      format: 'hex8',
                      "picker-type": 'chrome',
                      "use-type": 'pure'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(c.color?.toLocaleUpperCase()), 1)
                      ]),
                      _: 2
                    }, 1032, ["pureColor", "onUpdate:pureColor"])
                  ]),
                  (c.range)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(c.range.from) + " - " + _toDisplayString(c.range.to), 1))
                    : _createCommentVNode("", true),
                  (c.label)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_el_input, {
                          modelValue: c.label,
                          "onUpdate:modelValue": ($event: any) => ((c.label) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 256))
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_PaletteGradientColorPicker, {
              palette: _ctx.layer.palette
            }, null, 8, ["palette"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}