<template lang="pug" src="./UnavailableLayout.pug"/>
<style lang="scss" src="./UnavailableLayout.scss"/>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';

export default defineComponent({
  name: 'UnavailableLayout',
  components: {},
  setup() {
    const { user } = useUser();
    onMounted(() => {
      useApp().setLoadingScreen('app-loading');
    });
    return {
      user,
    };
  },
});
</script>
