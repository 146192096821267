import { useMapContainers } from '@/composables/useMapContainers';
import { Map, MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import { ref } from 'vue';

const activeTool = ref('pointer');

const scaleIncrement = 10;

const focused = ref(0);

const imageDataZoned = ref<Uint8ClampedArray>();

export const useBaseMapEditor = () => {
  const eventsSubscription = (mainMap: Map, additionalMap: Map) => {
    if (additionalMap && mainMap) {
      additionalMap.setPadding(useMapContainers().mapPaddings);
      additionalMap.setCenter(mainMap.getCenter());
      additionalMap.setZoom(mainMap.getZoom());

      additionalMap.resize();
      mainMap.resize();
      additionalMap.on('move', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mainMap.setCenter(additionalMap.getCenter(), { crossAction: true }); });
      mainMap.on('move', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) additionalMap.setCenter(mainMap.getCenter(), { crossAction: true }); });
      additionalMap.on('zoom', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mainMap.setZoom(additionalMap.getZoom(), { crossAction: true }); });
      mainMap.on('zoom', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) additionalMap.setZoom(mainMap.getZoom(), { crossAction: true }); });
      additionalMap.on('rotate', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mainMap.setBearing(additionalMap.getBearing(), { crossAction: true }); });
      mainMap.on('rotate', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) additionalMap.setBearing(mainMap.getBearing(), { crossAction: true }); });
      additionalMap.on('pitch', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mainMap.setPitch(additionalMap.getPitch(), { crossAction: true }); });
      mainMap.on('pitch', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) additionalMap.setPitch(mainMap.getPitch(), { crossAction: true }); });
    }
  };

  return {
    activeTool,
    scaleIncrement,
    imageDataZoned,
    eventsSubscription,
    focused,
  };
};
