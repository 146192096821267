import { IntegrationDto } from '@/services/api/dto/integration/IntegrationDto';
import { FormElementType } from '@/constants/types/FormElementType';

type ActionFormType = {
  elements: FormElementType[],
  inline: boolean,
  label: {
    position: string,
    width: string,
    suffix: string,
  }[],
  size: string,
}

export class IntegrationModel {
  private readonly _tabName: string;

  private readonly _showTab: boolean;

  constructor(dto: IntegrationDto) {
    this._id = dto.id;
    this._name = dto.name;
    this._uuid = dto.uuid;
    this._createAt = new Date(dto.create_date);
    this._updateAt = new Date(dto.update_date);
    this._service = dto.service;
    this._serviceName = dto.service_name;
    this._icon = dto.icon;
    this._logo = dto.logo;
    this._site = dto.site;
    this._siteName = dto.site_name;
    this._documentation = dto.documentation;
    this._description = dto.description;
    this._agreement = dto.agreement;
    this._disconnect = dto.disconnect;
    if (dto.activation_action) {
      this._action = {
        name: dto.activation_action.name,
        description: dto.activation_action.description,
      };
      this._actionButton = dto.activation_action.button;
      this._actionForm = dto.activation_action.form;
    }
    this._status = dto.activated.status_data;
    this._lastSyncAt = dto.activated.sync_datetime ? new Date(dto.activated.sync_datetime) : null;
    this._isEnable = dto.activated.is_enable;
    this._isRoot = dto.activated.is_root;
    this._tabName = dto.tab_name;
    this._showTab = dto.activated.show_tab;
  }

  get tabName(): string {
    return this._tabName;
  }

  get showTab(): boolean {
    return this._showTab;
  }

  private _id: number;

  get id(): number {
    return this._id;
  }

  private _name: string;

  get name(): string {
    return this._name;
  }

  private _uuid: string;

  get uuid(): string {
    return this._uuid;
  }

  private _createAt: Date;

  get createAt(): Date {
    return this._createAt;
  }

  private _updateAt: Date;

  get updateAt(): Date {
    return this._updateAt;
  }

  private _service: number;

  get service(): number {
    return this._service;
  }

  private _serviceName: string;

  get serviceName(): string {
    return this._serviceName;
  }

  private _icon: string;

  get icon(): string {
    return this._icon;
  }

  private _logo: string;

  get logo(): string {
    return this._logo;
  }

  private _site: string;

  get site(): string {
    return this._site;
  }

  private _siteName: string;

  get siteName(): string {
    return this._siteName;
  }

  private _documentation: string | null;

  get documentation(): string | null {
    return this._documentation;
  }

  private _description: string;

  get description(): string {
    return this._description;
  }

  private _agreement: string;

  get agreement(): string {
    return this._agreement;
  }

  private _disconnect: string;

  get disconnect(): string {
    return this._disconnect;
  }

  private _action: { name: string, description: string};

  get action(): { name: string; description: string } {
    return this._action;
  }

  private _actionButton: string;

  get actionButton(): string {
    return this._actionButton;
  }

  private _actionForm: ActionFormType;

  get actionForm(): ActionFormType {
    return this._actionForm;
  }

  private _status: { name: string, label: string, color: string, message: string };

  get status(): { name: string; label: string; color: string; message: string } {
    return this._status;
  }

  private _lastSyncAt: Date | null;

  get lastSyncAt(): Date | null {
    return this._lastSyncAt;
  }

  private _isEnable: boolean;

  get isEnable(): boolean {
    return this._isEnable;
  }

  private _isRoot: boolean;

  get isRoot(): boolean {
    return this._isRoot;
  }
}
