import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "FieldView" }
const _hoisted_2 = ["viewbox", "width", "height"]
const _hoisted_3 = ["points"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["FieldView_polygon", _ctx.classList()])
    }, [
      (_openBlock(), _createElementBlock("svg", {
        viewbox: _ctx.viewbox,
        width: _ctx.width,
        height: _ctx.height,
        preserveAspectRatio: "xMidYMid meet"
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('navigation.fields')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
          return (_openBlock(), _createElementBlock("g", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field, (points, i) => {
              return (_openBlock(), _createElementBlock("polygon", {
                points: points,
                "stroke-width": "2"
              }, null, 8, _hoisted_3))
            }), 256))
          ]))
        }), 256))
      ], 8, _hoisted_2))
    ], 2)
  ]))
}