import { UserDto } from '@/services/api/dto/user/UserDto';
import { NotificationDto } from '@/services/api/dto/struct/NotificationDto';

export class NotificationModel {
  get id(): number {
    return this._id;
  }

  get createDate(): Date {
    return this._createDate;
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get levelName(): string {
    return this._levelName;
  }

  private readonly _id: number;

  private readonly _createDate: Date;

  private readonly _level: number;

  private readonly _levelName: string;

  private readonly _unit: number;

  private readonly _user: UserDto;

  private readonly _title: string;

  private readonly _message: string;

  constructor(data: NotificationDto) {
    this._id = data.id;
    this._createDate = new Date(data.create_date);
    this._level = Number(data.level);
    this._levelName = data.level_name;
    this._unit = Number(data.unit);
    this._user = data.initiator;
    this._title = data.title;
    this._message = data.text;
  }
}
