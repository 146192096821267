<template lang="pug" src="./CreateBaseMapContent.pug"/>
<style lang="scss" src="./CreateBaseMapContent.scss"/>

<script lang="ts">

import SkeletonLoader from '@/components/features/SkeletonLoader/SkeletonLoader.vue';
import Content from '@/components/shared/Content/Content.vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUnload } from '@/composables/useUnload';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import BaseMapEditForm from '@/pages/task-map/create/base-map/BaseMapEditForm/BaseMapEditForm.vue';
import CandidateSelector
  from '@/pages/task-map/create/base-map/CandidateSelector/CandidateSelector.vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { ElNotification } from 'element-plus';
import {
  defineComponent, nextTick, onMounted, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'BaseMapContent',
  components: {
    NoActiveField,
    SkeletonLoader,
    UiDialog,
    Content,
    CandidateSelector,
    BaseMapEditForm,
  },
  setup() {
    const {
      activeField,
      mapModel,
      additionalMap,
      fitField,
      isFieldFocus,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const {
      candidates,
      activeCandidate,
      previewLayer,
      postBaseMap,
      step,
    } = useCreateBaseMap();

    const createBaseMapModal = ref(false);

    const baseMapEditModeModal = ref(false);

    const skeletonLoading = ref(false);

    watch(activeField, async (v) => {
      if (v) {
        skeletonLoading.value = true;
        await candidates.value.fetchCandidates();
        step.value = 'selector';
        skeletonLoading.value = false;
      } else {
        step.value = 'field';
      }
    });

    const mount = ref(false);

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      mount.value = true;
      if (activeField.value) {
        fitField(activeField.value);
        step.value = 'selector';
        skeletonLoading.value = true;
        await candidates.value.fetchCandidates();
        skeletonLoading.value = false;
      } else {
        step.value = 'field';
      }
    });

    const create = () => {
      createBaseMapModal.value = false;
      if (activeCandidate.value) {
        postBaseMap();
        activeField.value?.fetchTaskMaps();

        ElNotification({
          title: 'Запрос отправлен',
          message: 'Шаблон карты создан и добавлен в список.',
          type: 'success',
          position: 'bottom-right',
          duration: 3000,
        });
      }
    };

    useUnload(() => {
      if (previewLayer.value?.uuid) {
        mapModel.value?.removeLayer(previewLayer.value?.uuid);
      }
      activeCandidate.value?.reset();
      step.value = 'selector';
      if (additionalMap.value !== undefined) {
        additionalMap.value?.removeMap();
        additionalMap.value = undefined;
        nextTick(() => {
          mapModel.value.map.resize();
        });
      }
    });

    const switchEdit = () => {
      step.value = 'edit';
      baseMapEditModeModal.value = false;
    };

    watch(step, (v, b) => {
      if (!['edit', 'zones'].includes(v)) {
        activeCandidate.value?.reset();
        isFieldFocus.value = false;
      } else {
        isFieldFocus.value = true;
      }
    });

    useUnload(() => {
      isFieldFocus.value = false;
    });

    watch(activeCandidate, (v) => {
      if (step.value === 'selector' && !!v) {
        step.value = 'zones';
      }
    });

    return {
      activeField,
      activeCandidate,
      create,
      mount,
      step,
      switchEdit,
      createBaseMapModal,
      baseMapEditModeModal,
      skeletonLoading,
    };
  },
});
</script>
