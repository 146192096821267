import { LngLatLike, Map } from 'mapbox-gl';

export const flyToLngLat = (map: Map, coords: LngLatLike, zoom: number | undefined = undefined, timeout = 300) => {
  setTimeout(() => {
    map.resize().flyTo({
      center: coords,
      essential: true,
      zoom: zoom || map.getZoom(),
    });
  }, timeout);
};
