<template lang="pug" src="./UiHistogram.pug"/>
<style lang="scss" src="./UiHistogram.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { Goblet } from '@element-plus/icons-vue';
import { reduceSet } from '@/lib/tools/reduceSet';

export default defineComponent({
  name: 'UiHistogram',
  components: { Goblet },
  props: {
    values: {
      type: Array as PropType<number[]>,
      required: true,
    },
    height: {
      type: Number,
      default: 200,
    },
    classes: {
      type: Array as PropType<{ color: string, range: { from: number, to: number }}[]>,
      required: true,
    },
    accuracy: {
      type: Number,
      default: 100,
    },
  },

  setup(props) {
    const { user } = useUser();
    const sortedValues = computed(() => props.values.filter((n: number) => !Number.isNaN(n)).sort((a, b) => a - b));
    const min = computed(() => (sortedValues.value.length > 0 ? sortedValues.value[0] : 0));
    const max = computed(() => (sortedValues.value.length > 0 ? sortedValues.value[sortedValues.value.length - 1] : 1));

    const computedAccuracy = computed(() => {
      const uniqArray = reduceSet(sortedValues.value);
      if (uniqArray.length < props.accuracy) {
        return uniqArray.length;
      }
      return props.accuracy;
    });

    const stepValue = computed(() => (max.value - min.value) / computedAccuracy.value);

    const position = ref({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    });
    // region tooltip
    const tooltipVisible = ref(false);
    const triggerRef = ref({
      getBoundingClientRect() {
        return position.value;
      },
    });

    const tooltipCount = ref(0);
    const tooltipValues = ref('');

    const barHover = (count: number, idx: number) => {
      tooltipCount.value = count;
      tooltipValues.value = `${(min.value + idx * stepValue.value).toFixed(2)} - ${(min.value + (idx + 1) * stepValue.value).toFixed(2)}`;
      tooltipVisible.value = true;
    };

    const mousemoveHandler = (e: MouseEvent) => {
      position.value = DOMRect.fromRect({
        width: 0,
        height: 0,
        x: e.clientX,
        y: e.clientY,
      });
    };
    onMounted(() => {
      document.addEventListener('mousemove', mousemoveHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('mousemove', mousemoveHandler);
    });
    // endregion

    const matrixChart = computed(() => {
      const matrix: number[] = Array(Math.ceil(computedAccuracy.value - 1)).fill(0);
      sortedValues.value.forEach((v, i) => {
        const index = Math.floor((v - min.value) / stepValue.value);
        matrix[index] += 1;
      });
      return matrix;
    });

    const labels = computed(() => [
      min.value,
      min.value + (max.value - min.value) / 4,
      min.value + (max.value - min.value) / 2,
      min.value + (3 * (max.value - min.value)) / 4,
      max.value,
    ]);

    const maxCount = computed(() => matrixChart.value.reduce((acc, v) => {
      if (!Number.isNaN(v) && v > acc) {
        acc = v;
      }
      return acc;
    }, -Infinity));

    const reactivePaint = (v: number) => {
      const _value = min.value + v * (max.value - min.value);
      let color = props.classes[0]?.color || '#F00';
      props.classes.forEach((c) => {
        if (_value >= c.range.from && _value < c.range.to) {
          color = c.color;
        }
      });
      return color;
    };

    const barHeight = (count: number) => {
      const padding = 15;
      const maxHeight = props.height - padding;
      return (count * maxHeight) / maxCount.value;
    };

    return {
      computedAccuracy,
      user,
      matrixChart,
      reactivePaint,
      maxCount,
      barHeight,
      barHover,
      labels,
      triggerRef,
      tooltipVisible,
      tooltipCount,
      tooltipValues,
    };
  },
});
</script>
