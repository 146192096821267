import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ColorGradientPicker" }
const _hoisted_2 = { class: "MapFactMapLayerSettings-gradient-label" }

export function render(_ctx, _cache) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_color_picker, {
      "model-value": _ctx.modelValue,
      onChange: _ctx.setColor,
      predefine: _ctx.predefineColors,
      "popper-class": `${{ '--clear-able': _ctx.clearAble}}`
    }, null, 8, ["model-value", "onChange", "predefine", "popper-class"]),
    _createVNode(_component_el_select, {
      "model-value": _ctx.activeGradient,
      onChange: _ctx.setGradient,
      "value-key": "name",
      "fit-input-width": ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gradients, (c) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: `color-${c.id}`,
            label: c.name,
            value: c
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "MapFactMapLayerSettings-gradient",
                style: _normalizeStyle({ background: `linear-gradient(90deg, ${c.positions.map((s) => 'rgba(' + _ctx.hexToRgba(s.color) + ') ' + s.position +'%').join(', ')})`})
              }, null, 4),
              _createElementVNode("div", _hoisted_2, _toDisplayString(c.name), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["model-value", "onChange"])
  ]))
}