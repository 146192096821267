import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-row flex-row-justify-between" }
const _hoisted_2 = { class: "flex-row" }
const _hoisted_3 = { class: "ph-15 ta-right" }
const _hoisted_4 = { class: "RatingNdviContent" }
const _hoisted_5 = { class: "flex-row flex-row-align-center gap-3" }
const _hoisted_6 = { class: "flex-row flex-row-align-center gap-3" }
const _hoisted_7 = { class: "flex-row flex-row-align-center gap-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex-row flex-row-justify-between flex-row-align-center" }
const _hoisted_10 = { class: "flex-row flex-row-align-center" }
const _hoisted_11 = {
  class: "pl-3 pr-3",
  style: {"color":"#b8b8b8","text-align":"left"}
}
const _hoisted_12 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_SortCaret = _resolveComponent("SortCaret")
  const _component_NdviCheckbox = _resolveComponent("NdviCheckbox")
  const _component_Content = _resolveComponent("Content")

  return (_openBlock(), _createBlock(_component_Content, {
    title: _ctx.$t('ndvi-rating', { ns: 'navigation' })
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('select-map-painting-mode', { ns: 'rating-ndvi' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.activePaintType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.activePaintType) = $event)),
            size: "small"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, {
                label: "Средний NDVI",
                value: "avg"
              }),
              _createVNode(_component_el_radio_button, {
                label: "Контраст",
                value: "contrast"
              }),
              _createVNode(_component_el_radio_button, {
                label: "Рейтинг",
                value: "rating"
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_input, {
          class: "pb-6",
          modelValue: _ctx.searchField,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.searchField) = $event)),
          placeholder: "Поиск по полю",
          style: {"width":"200px"}
        }, null, 8, ["modelValue"]),
        _createVNode(_component_el_select, {
          modelValue: _ctx.activeCrop,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.activeCrop) = $event)),
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              value: -1,
              label: "---"
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crops.sort((a, b) => a.order - b.order), (crop) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                value: crop.id,
                label: crop.name
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(crop.name), 1)
                ]),
                _: 2
              }, 1032, ["value", "label"]))
            }), 256))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.showNir,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.showNir) = $event)),
          "active-text": "Показать снимок по выбраной дате и полю"
        }, null, 8, ["modelValue"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_tooltip, {
          content: _ctx.$t('tooltip-create-ndvi-rating-select-date', { ns: 'rating-ndvi' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: _ctx.createSelectFilteredDateRaster,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('create-ndvi-rating-select-date', { ns: 'rating-ndvi' })), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["content"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                onClick: _cache[4] || (_cache[4] = $event => (_ctx.toggleSort('name')))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('field-name', { ns: 'rating-ndvi' })), 1),
                  _createVNode(_component_SortCaret, {
                    sort: _ctx.tableHeader.name
                  }, null, 8, ["sort"])
                ])
              ]),
              _createElementVNode("th", {
                onClick: _cache[5] || (_cache[5] = $event => (_ctx.toggleSort('ndviAvg')))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('avg-index', { ns: 'rating-ndvi' })), 1),
                  _createVNode(_component_SortCaret, {
                    sort: _ctx.tableHeader.ndviAvg
                  }, null, 8, ["sort"])
                ])
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nir-date', { ns: 'rating-ndvi' })), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedCrop, (row) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (row.name.includes(_ctx.searchField))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                      _createElementVNode("td", null, [
                        _createElementVNode("div", {
                          class: "RatingNdviContent-color",
                          style: _normalizeStyle({ backgroundColor: _ctx.computedColorField(row.model), boxShadow: '#e3e3e3 1px 0px 0px' })
                        }, null, 4),
                        _createElementVNode("span", null, _toDisplayString(row.name), 1)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(row.selectedNDVI?.avg), 1),
                      _createElementVNode("td", null, [
                        (row.indexes.length > 0)
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              modelValue: row.selectedNDVI,
                              "onUpdate:modelValue": $event => ((row.selectedNDVI) = $event),
                              placeholder: _ctx.$t('select-nir-date-placeholder'),
                              onChange: $event => (_ctx.changeDate(row.model ,$event )),
                              "value-key": "dataDate"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.indexes, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.id,
                                    label: _ctx.formatRuDate(item.dataDate),
                                    value: item
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.formatRuDate(item.dataDate)), 1),
                                        _createElementVNode("div", _hoisted_10, [
                                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatNumber(item.dto?.avg)), 1),
                                          _createVNode(_component_NdviCheckbox, {
                                            ndvi: item,
                                            field: row.model
                                          }, null, 8, ["ndvi", "field"])
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "onChange"]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('no-data-short')), 1))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}