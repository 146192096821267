<template lang="pug" src="./VersionStatus.pug"/>
<style lang="scss" src="./VersionStatus.scss"/>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'VersionStatus',
  components: {},
  setup() {
    const majorVersion = process.env.VUE_APP_MAJOR_VERSION || '';
    const minorVersion = process.env.VUE_APP_MINOR_VERSION || '';
    const buildVersion = process.env.VUE_APP_BUILD_VERSION || '';

    const version = computed(() => `ver. ${majorVersion}.${minorVersion}.${buildVersion}`);
    return {
      version,
    };
  },
});
</script>
