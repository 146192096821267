import { ShapeStandardModel } from '@/models/assets/ShapeStandardModel';
import { reactive } from 'vue';
import ApiService from '@/services/api/ApiService';

class ShapeStandardList {
  get data(): ShapeStandardModel[] {
    return this._data;
  }

  private _data: ShapeStandardModel[] = reactive([]);

  async fetch() {
    const { data } = await ApiService.assets.shapeStandardsList();
    const ids = [];
    data.forEach((dto) => {
      ids.push(dto._id);
      const existsModel = this._data.find((v) => v.id === dto._id);
      if (existsModel) {
        existsModel.update(dto);
      } else {
        this._data.push(new ShapeStandardModel(dto));
      }
    });
    const toRemove: ShapeStandardModel[] = [];
    this._data.forEach((v) => {
      if (!ids.includes(v.id)) {
        toRemove.push(v);
      }
    });
    toRemove.forEach((m) => {
      this._data.splice(this._data.indexOf(m), 1);
    });
  }
}

export default new ShapeStandardList();
