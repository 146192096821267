import { ElNotification } from 'element-plus';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';

import AdminService from '@/modules/admin/AdminService';
import { useRasterVector } from '@/composables/useRasterVector';
import { RasterTypeEnum } from '@/constants/enums/RasterTypeEnum';
import MapRastersList from '@/modules/map/MapRastersList';

const events = {
  taskAdd: 'SATELLITE_TASK_ADD',
  taskChange: 'SATELLITE_TASK_CHANGE',
  taskDelete: 'SATELLITE_TASK_DELETE',
  subtaskAdd: 'SATELLITE_SUBTASK_CREATE',
  subtaskChange: 'SATELLITE_SUBTASK_CHANGE',
  subtaskDone: 'SATELLITE_SUBTASK_DONE',
  subtaskError: 'SATELLITE_SUBTASK_ERROR',
  MSFCreate: 'SATELLITE_MSF_CREATE',
};

export const SocketSatelliteEvents = (): void => {
  EventBus.$on(EventsEnum.SocketMessage, (message: SocketMessageType) => {
    if (message.event === events.MSFCreate) {
      const activeMsf = useRasterVector().activeRasters.value.find((r) => r.raster_type === RasterTypeEnum.autoMSF);
      if (activeMsf) {
        EventBus.$emit(EventsEnum.MapToggleRasterVisibility, activeMsf);
      }
      AdminService.fetchRasters().then(() => {
        const newMsf = MapRastersList.rasters.value.find((r) => r.raster_type === RasterTypeEnum.autoMSF);
        activeMsf && EventBus.$emit(EventsEnum.MapToggleRasterVisibility, newMsf);
      });
      ElNotification(
        {
          title: 'Формирование MSF завершено.',
          message: 'Теперь вы можете посмотреть обновленный MSF на карте, включив режим отображения MSF.',
          type: 'success',
          position: 'bottom-right',
        },
      );
    }
  });
};
