<template src="./OrderedList.pug" lang="pug"/>
<style src="./OrderedList.scss" lang="scss"/>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, ref, useSlots, watch,
} from 'vue';
import { mdiDragVertical } from '@mdi/js';

export default defineComponent({
  name: 'OrderedList',
  props: {
    dragAble: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    titleKey: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'moveItem'],
  setup(props, { emit }) {
    const slots = useSlots();
    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: any[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type.toString() !== 'Symbol(Comment)').length > 0;
    };

    const listRef = ref<HTMLElement>();

    const hasActions = computed(() => hasSlot('actions'));

    const innerItems = ref<any[]>([]);

    onMounted(() => {
      innerItems.value = [...props.items];
    });

    const reactiveItems = computed(() => props.items);

    watch(reactiveItems, () => {
      innerItems.value = [...props.items];
    });

    const value = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v),
    });

    const moveItem = (fromIdx: number, toIdx: number) => {
      if (fromIdx === toIdx) return;
      if (toIdx > innerItems.value.length) toIdx = innerItems.value.length - 1;
      innerItems.value.splice(toIdx, 0, innerItems.value.splice(fromIdx, 1)[0]);
    };

    const draggingItemOrder = ref(-1);

    const dragStart = (index: number) => {
      draggingItemOrder.value = index;
    };

    const dragOver = (evt: DragEvent) => {
      const toIdx = Number((evt.target as HTMLElement).dataset?.idx);
      if (!Number.isNaN(toIdx) && draggingItemOrder.value !== toIdx) {
        moveItem(draggingItemOrder.value, toIdx);
        draggingItemOrder.value = toIdx;
      }
      evt.preventDefault();
    };

    const dragEnd = () => {
      draggingItemOrder.value = -1;
      let i = 1;
      innerItems.value.forEach((item) => { item.order = i++; });
      emit('moveItem', innerItems);
    };

    return {
      listRef,
      innerItems,
      mdiDragVertical,
      hasActions,
      value,
      draggingItemOrder,
      dragStart,
      dragOver,
      dragEnd,
    };
  },
});
</script>
