import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import {
  BorderDataType,
  BorderValuesType,
  UniqDataType,
  UniqValuesType,
} from '@/constants/types/palette/UnifiedVectorPaletteType';
import hexToRgba from '@/lib/convertors/hexToRgba';
import { fetcher } from '@/lib/tools/fetcher';
import { PaletteModel } from '@/models/assets/PaletteModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';
import { ElNotification } from 'element-plus';
import { FeatureCollection } from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';
import ShapeStandardList from '@/modules/assets/shapeStandard/ShapeStandardList';
import { PaletteDto } from '@/services/api/dto/assets/palette/PaletteDto';

export class MapLayerUnifiedVectorModel extends MapLayerModel implements IMapLayerModel {
  readonly data: UnifiedVectorModel;

  readonly treeKey: string;

  readonly treeName: string;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType, options: { treeKey: string, treeName: string, paletteId: string }) {
    super(mapModel, type, 'unified-vector', `${input.uuid}-${options.treeKey}`);
    this.data = input as UnifiedVectorModel;
    this.treeKey = options.treeKey;
    this.treeName = options.treeName;
    this.fetchPalette(options.paletteId).then(() => {
      this.createSource();
      this.createLayer();
      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
    });
    this.showLegend.value = true;
  }

  private _palette: PaletteModel;

  get palette(): PaletteModel {
    return this._palette;
  }

  set palette(value: PaletteModel) {
    this._palette = value;
  }

  get featuresCollection(): FeatureCollection {
    const collection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (this.data.geojson?.features) {
      this.data.geojson.features.forEach((feature) => {
        let paletteColor = '#A5F3F3';
        if (this.palette?.property && feature.properties) {
          const value = feature.properties[this.palette.property];
          if (this.palette.type === 'uniqValues') {
            const data = this.palette.uniqData as UniqDataType;

            const _palette = data.values.find((v) => v.value?.toString() === value?.toString());
            if (_palette) {
              paletteColor = `rgba(${hexToRgba(_palette.color).join(', ')})`;
            } else {
              paletteColor = 'rgba(0, 0, 0, 0)';
            }
          }
          if (this.palette.type === 'borderValues') {
            const data = this.palette.borderData as BorderDataType;
            const _palette = data.values.find((v, idx) => (value >= v.range.from && value < v.range.to) || (idx === data.values.length - 1 && v.range.to === value));
            if (_palette) {
              paletteColor = `rgba(${hexToRgba(_palette.color).join(', ')})`;
            } else {
              paletteColor = 'rgba(0, 0, 0, 0)';
            }
          }
        }
        collection.features.push({
          type: 'Feature',
          geometry: feature.geometry,
          properties: {
            ...feature.properties,
            __paletteColor: paletteColor,
          },
        });
      });
    }
    return collection;
  }

  async fetchPalette(paletteId: string): Promise<void> {
    return fetcher(LoadingNamesEnum.ASSETS_VECTOR_PALETTE, this.data.id, true, async () => {
      if (paletteId.startsWith('default:')) {
        const property = paletteId.replace('default:', '');
        const standardModel = ShapeStandardList.data.find((v) => v.id === property);
        if (standardModel) {
          if (standardModel.type === 'uniqValues') {
            this._palette = new PaletteModel(this.data.keys, {
              _id: paletteId,
              property,
              type: standardModel.type,
              data: {
                values: standardModel.data as UniqValuesType[],
              } as UniqDataType,
            } as PaletteDto);
          }

          if (standardModel.type === 'borderValues') {
            this._palette = new PaletteModel(this.data.keys, {
              _id: paletteId,
              property,
              type: standardModel.type,
              data: {
                classCount: standardModel.data.length,
                classification: 'custom',
                values: standardModel.data as BorderValuesType[],
              } as BorderDataType,
            } as PaletteDto);
          }
        } else {
          LoggerService.error(`Default palette ${property} not found`);
          ElNotification({
            title: 'Палитра не найден.',
            message: `Указаная цветовая палитра по умолчанию для ключа ${property} не найдена.`,
            type: 'error',
            duration: 2000,
            position: 'bottom-right',
          });
        }
      } else if (paletteId) {
        const { data } = await ApiService.assets.getPalette(paletteId.replace(':', '-'));
        this._palette = new PaletteModel(this.data.keys, data);
      } else {
        // todo должен ли быть первый аргумент undefined? также есть проблема с рараметром land_gr в групп земель
        this._palette = new PaletteModel(this.data.keys, undefined);
      }
    });
  }

  createSource = (): void => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.featuresCollection,
    });
  }

  createLayer = (): void => {
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      layout: {},
      metadata: { type: this.treeName },
      paint: {
        'fill-color': ['get', '__paletteColor'],
        'fill-opacity': 1,
        'fill-outline-color': '#329FCF',
      },
    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.VECTOR);
  }

  redraw =(): void => {
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource).setData(this.featuresCollection);
  }

  paintDefaultVector = () => {
    // if (usePaint().selectedPaint.value === 'vector-custom' && usePaint().customPalette.value.customType === 'class') {
    //   const prop = `${usePaint().customPalette.value.name}_dg`;
    //   const coloring: any[] = [];
    //
    //   usePaint().customPalette.value?.palette.forEach((item: any, index: number) => {
    //     coloring.push(['==', ['get', prop], item.value], item.color);
    //   });
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       'rgba(255,255,255,0.40)',
    //     ]
    //
    //     ,
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    //   return;
    // }
    // if (!usePaint().selectedColor.value && usePaint().customPalette.value.palette.length === 0) {
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     '#A5F3F3',
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#329FCF',
    //   );
    // } else if (usePaint().selectedPaint.value === 'vector-custom' && usePaint().customPalette.value.palette.length > 0) {
    //   const coloring: any[] = [];
    //   if (usePaint().customPalette.value?.type === 'range') {
    //     const reversArr = [...usePaint().customPalette.value?.palette];
    //
    //     reversArr.forEach((item: any, index: number) => {
    //       if (index === reversArr.length - 1) {
    //         coloring.push(['>', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //       } else {
    //         coloring.push(['<=', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //       }
    //     });
    //   }
    //   if (usePaint().customPalette.value?.type === 'unique') {
    //     [...usePaint().customPalette.value?.palette].reverse().forEach((item: any, index: number) => {
    //       coloring.push(['==', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //     });
    //   }
    //
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       '#fff',
    //     ],
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    // } else if (usePaint().selectedColor.value) {
    //   const selectedColor: VectorColorType = usePaint().selectedColor.value as VectorColorType;
    //
    //   const coloring: any[] = [];
    //
    //   selectedColor.palette.forEach((item, index: number) => {
    //     // @ts-ignore
    //     if (selectedColor.type === VectorPaletteEnum.UNIQUE) {
    //       coloring.push(['==', ['get', selectedColor.name], item.value], item.color);
    //     } else if (index === 0) {
    //       coloring.push(['<=', ['get', selectedColor.name], item.value], item.color);
    //     } else if (index === selectedColor.palette.length - 1) {
    //       coloring.push(['>', ['get', selectedColor.name], item.value], item.color);
    //     } else {
    //       coloring.push(['<=', ['get', selectedColor.name], item.value], item.color);
    //     }
    //   });
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       'rgba(255,255,255,0.40)',
    //     ],
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    // }
  }
}
