<template lang='pug' src='./ImagesContainer.pug'/>
<style lang='scss' src='./ImagesContainer.scss'/>

<script lang="ts">
import { RasterValue } from '@/assets/data/RasterValue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useAuth } from '@/composables/useAuth';
import { ImageFileType } from '@/constants/types/ImageFileType';
import { RasterModel } from '@/models/RasterModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import RasterFileCard
  from '@/modules/map/container/SidePanel/ImagesPanel/ImagesContainer/RasterFileCard.vue';
import VectorFileCard
  from '@/modules/map/container/SidePanel/ImagesPanel/ImagesContainer/VectorFileCard.vue';
import StructFilesList from '@/modules/struct/StructFilesList';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import {
  FormInstance,
  FormRules,
  genFileId,
  UploadInstance,
  UploadProps,
  UploadRawFile,
  UploadUserFile,
} from 'element-plus';
import {
  computed, defineComponent, reactive, ref,
} from 'vue';

export default defineComponent({
  name: 'ImagesContainer',
  components: {
    UiDialog,
    UiIcon,
    VectorFileCard,
    RasterFileCard,
  },
  setup() {
    const activeTab = ref<'upload_file' | 'user_files' | 'expert_files' | 'global_files'>('user_files');

    const { accessToken } = useAuth();

    const files = computed<Array<UnifiedVectorModel | RasterModel>>(() => [
      ...(StructFilesList.vectors.value as UnifiedVectorModel[]),
      ...(StructFilesList.rasters.value as RasterModel[]),
    ].sort((a, b) => (a.date > b.date ? 1 : -1)));

    const isRaster = (file: UnifiedVectorModel | RasterModel) => file instanceof RasterModel;

    const isVector = (file: UnifiedVectorModel | RasterModel) => file instanceof UnifiedVectorModel;

    const upload = ref<UploadInstance>();
    const fileList = ref<UploadUserFile>();
    const ruleFormRef = ref<FormInstance>();
    const type = ref('vector');
    const colorIndex = ref(0);

    const addLayerForm = ref({
      active: false,
      file: null,
      name: '',
      group: 0,
    });

    const groupsTree = computed(() => {
      const mapGroups = (node: ImageFileType) => ({
        label: node.label,
        value: node.id,
        children: node.children?.filter((v) => v.type === 'group').map((v) => mapGroups(v)),
      });
      return [
        { label: 'Корневая группа', value: 0 },
        ...StructFilesList.groups.value.filter((v) => v.type === 'group').map((v) => mapGroups(v)),
      ];
    });

    // region computed
    const typeFile = computed(() => (type.value === 'vector' ? '.kml,.zip,.KML,.ZIP' : '.tif,.zip,.TIF,.ZIP'));

    // endregion

    // region editing
    const form = reactive({
      name: '',
      farmunit: StructList.activeStruct.value?.id || 0,
    });

    const handleExceed: UploadProps['onExceed'] = (_files) => {
      upload.value?.clearFiles();
      const file = _files[0] as UploadRawFile;
      file.uid = genFileId();
      upload.value?.handleStart(file);
    };

    const rules = reactive<FormRules>({
      name: [{
        required: true,
        message: 'Ведите имя файла',
        trigger: 'blur',
      }],
      file: [{
        required: false,
      }],
      radio: [{
        required: false,
      }],
      farmunit: [{
        required: false,
      }],
      color: [{
        required: false,
      }],
    });

    // endregion

    const save = () => {
      const formData = new FormData();

      formData.append('name', form.name);
      formData.append('farmunit', form.farmunit.toString());

      // @ts-ignore
      fileList.value ? formData.append('file', fileList.value[0].raw) : null;
      // @ts-ignore
      fileList.value ? formData.append('filename', fileList.value[0].name) : null;

      colorIndex.value && formData.append('colorizer_name', RasterValue[colorIndex.value].name);

      colorIndex.value ? formData.append('colorizer',
        JSON.stringify(RasterValue[colorIndex.value].value)) : null;

      if (type.value === 'vector') {
        ApiService.gis.postVector(formData).then((resp) => {
          StructFilesList.fetchVectors(true);
          activeTab.value = 'user_files';
        }).catch(() => {
          activeTab.value = 'user_files';
        });
      } else {
        ApiService.gis.postRaster(formData).then(() => {
          StructFilesList.fetchRasters(true);
          activeTab.value = 'user_files';
        }).catch(() => {
          activeTab.value = 'user_files';
        });
      }
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      // eslint-disable-next-line consistent-return
      formEl.validate((valid) => {
        if (valid) {
          save();
        }
      });
    };

    const deleteFile = async (file: UnifiedVectorModel | RasterModel) => {
      if (isVector(file)) {
        await ApiService.gis.deleteVector({ id: file.vector.id });
        await StructFilesList.fetchVectors(true);
      }
      if (isRaster(file)) {
        await ApiService.gis.deleteRaster({ id: file.id });
        await StructFilesList.fetchRasters(true);
      }
    };

    const downloadFile = (file: UnifiedVectorModel | RasterModel) => {
      if (isVector(file)) {
        window.open(`${process.env.VUE_APP_API_URL}/gis/export_vector/?id=${file.vector.id}&format=${'esri'}&access_token=${accessToken.value}`);
      }
      if (isRaster(file)) {
        window.open(`${process.env.VUE_APP_API_URL}/gis/original_raster/${file.source}/?access_token=${accessToken.value}`);
      }
    };

    const closeAddLayer = () => {
      addLayerForm.value.active = false;
      addLayerForm.value.file = null;
      addLayerForm.value.name = '';
      addLayerForm.value.group = 0;
    };

    const addLayer = (file: UnifiedVectorModel | RasterModel) => {
      addLayerForm.value.active = true;
      addLayerForm.value.file = file;
      addLayerForm.value.name = file instanceof RasterModel ? file?.alias || '' : file?.name || '';
    };

    const sendLayer = () => {
      ApiService.gis.postLayer({
        label: addLayerForm.value.name,
        target: {
          obj: addLayerForm.value.file?.id,
          type: `user_${addLayerForm.value.file instanceof RasterModel ? 'raster' : 'vector'}`,
        },
        position: {
          item: {
            type: 'group',
            obj: addLayerForm.value.group,
          },
          drop_type: 'inner',
        },
      });
      closeAddLayer();
    };

    return {
      activeTab,
      files,
      isRaster,
      isVector,
      onSubmit,
      typeFile,
      handleExceed,
      ruleFormRef,
      form,
      rules,
      type,
      colorIndex,
      RasterValue,
      fileList,
      deleteFile,
      downloadFile,
      addLayer,
      addLayerForm,
      groupsTree,
      sendLayer,
      closeAddLayer,
    };
  },
});

</script>

<style lang="scss" scoped>

</style>
