import { EventsEnum } from '@/constants/enums/EventsEnum';
import { ImageFileType } from '@/constants/types/ImageFileType';
import Coordinates from '@/constants/types/mapbox/Coordinates';
import MapStyleSpecification from '@/constants/types/mapbox/MapStyleSpecification';
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { AddPoiDto } from '@/services/api/dto/gis/AddPoiDto';
import { FieldsDto } from '@/services/api/dto/gis/FieldsDto';
import { LocatoinInfoDto } from '@/services/api/dto/gis/LocatoinInfoDto';
import { RasterCropDto } from '@/services/api/dto/gis/RasterCropDto';
import { RastersDto } from '@/services/api/dto/gis/RastesDto';
import { ReadRasterDto } from '@/services/api/dto/gis/ReadRasterdto';
import { SourceStyleDto } from '@/services/api/dto/gis/SourceStyleDto';
import { StyleDto } from '@/services/api/dto/gis/StyleDto';
import { TaskFieldDto } from '@/services/api/dto/gis/TaskFieldDto';
import { TaskMapListDto } from '@/services/api/dto/gis/TaskMapListDto';
import { TasksDto } from '@/services/api/dto/gis/TasksDto';
import { TilejsonDto } from '@/services/api/dto/gis/TilejsonDto';
import { UnifiedVectorDto } from '@/services/api/dto/gis/UnifiedVectorDto';
import { VectorDto } from '@/services/api/dto/gis/VectorDto';
import { VectorsName } from '@/services/api/dto/gis/VectorsName';
import EventBus from '@/services/eventBus/EventBus';
import { FeatureCollection } from 'geojson';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getFields: (): string => '/api/v1/gis/fields/',
  addVector: (): string => '/api/v1/gis/add_vector/',
  addPoi: (): string => '/api/v1/gis/add_poi/',
  addTask: (): string => '/api/v1/gis/add_task/',
  addTasks: (): string => '/api/v1/gis/add_tasks/',
  addFact: (): string => '/api/v1/gis/add_fact/',
  addFields: (): string => '/api/v1/gis/add_fields/',
  addRaster: (): string => '/api/v1/gis/add_raster/',
  importRaster: (): string => '/api/v1/gis/register/',
  locatoinInfo: ({ source, x, y }: {
    'source': string ;
    'x': string ;
    'y': string}): string => `/api/v1/gis/locationinfo/?source=${source}&x=${x}&y=${y}`,
  readVectors: (farmunitId: number): string => `/api/v1/gis/vectors/${farmunitId}/`,
  getRasters: (farmunitId: number): string => `/api/v1/gis/rasters/${farmunitId}/`,
  readTasks: (fieldId: number): string => `/api/v1/gis/tasks/${fieldId}/`,
  readFacts: (fieldId: number): string => `/api/v1/gis/facts/${fieldId}/`,
  getUnitFields: (unitId: number): string => `/api/v1/gis/unit/${unitId}/fields/`,
  readStyle: (id: number): string => '/api/v1/gis/public_style/3/',
  readStyleLocal: (id: number): string => `/api/v1/gis/style/${id}/internal/`,
  readStyleSours: (id: number): string => `/api/v1/gis/source_style/${id}/`,
  getRaster: (id: number): string => `/api/v1/gis/raster/${id}/`,
  getTileJson: (id: number): string => `/api/v1/gis/source_style/${id}/`,
  editingField: (id: number): string => `/api/v1/struct/field/${id}/`,
  deleteField: (id: number): string => `/api/v1/struct/field/${id}/`,
  raster: (id: number): string => `/api/v1/gis/raster/${id}/`,
  putVector: (id: number): string => `/api/v1/gis/vector/${id}/`,
  deleteRaster: (id: number): string => `/api/v1/gis/raster/${id}/`,
  deleteVector: (id: number): string => `/api/v1/gis/vector/${id}/`,
  getAllTasks: (id: number): string => `/api/v1/gis/all_tasks/${id}/`,
  postTaskMap: (): string => '/api/v1/gis/taskmap/',
  postFieldAdd: (): string => '/api/v1/struct/field/add/',
  postRaster: (): string => '/api/v1/gis/add_raster/',
  postVector: (): string => '/api/v1/gis/add_vector/',
  getTasksField: (id: number): string => `/api/v1/gis/tasks/${id}/`,
  postTaskMapDesiccation: (): string => '/api/v1/gis/taskmap-desiccation/',
  postCalculateHistogramArea: (id: number): string => `/api/v1/task-map/${id}/work/histogram-area/`,
  getRasterCrop: (rasterId: number, fieldId: number): string => `/api/v1/gis/raster/${rasterId}/crop/${fieldId}/`,
  getVector: (structId: number): string => `/api/v1/gis/vectors/${structId}/names/`,
  getVectorGeometry: (id: number): string => `/api/v1/gis/vector/${id}`,
  getUnifiedVectors: (structId: number): string => `/api/v1/gis/${structId}/unified-farm-vectors/`,
  getVectorGeojson: (structId: number, vectorId: number): string => `/api/v1/gis/${structId}/unified-farm-vectors/${vectorId}/geojson`,
  getFilesGroups: (structId: number): string => `/api/v1/gis/${structId}/groups/`,
  createFolder: (structId: number): string => `/api/v1/gis/${structId}/groups/`,
  orderGroups: (structId: number): string => `/api/v1/gis/${structId}/groups/order/`,
  renameGroup: (structId: number, compositeId: string): string => `/api/v1/gis/${structId}/groups/${compositeId}/`,
  deleteGroup: (structId: number, compositeId: string): string => `/api/v1/gis/${structId}/groups/${compositeId}/`,
  patchGroup: (structId: number, compositeId: string): string => `/api/v1/gis/${structId}/groups/${compositeId}/`,
  postGroup: (structId: number): string => `/api/v1/gis/${structId}/groups/`,
};

export class ApiGis extends ApiHandler {
  public getFilesGroups(): Promise<ApiResponse<ImageFileType[]>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).get<ImageFileType[]>(endpoints.getFilesGroups(unit));
  }

  public getVectorGeojson(vectorId: number): Promise<ApiResponse<{ geojson: FeatureCollection }>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).get<{ geojson: FeatureCollection }>(endpoints.getVectorGeojson(unit, vectorId));
  }

  public createFolder(data: {
    label: string;
    target: {
      obj: 0,
      type: 'group',
    }
    position: {
      item: {
        type: 'group',
        obj: number,
        },
      'drop_type': 'inner';
    },
  }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).post<void>(endpoints.createFolder(unit), data);
  }

  public orderGroups(data: {
    target: {
      obj: number,
      type: string,
    }
    position: {
      item: {
        obj: number,
        type: string,
      },
      'drop_type': string
    }
  }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).patch<void>(endpoints.orderGroups(unit), data);
  }

  public renameGroup(groupId: string, data: {
    name: string,
  }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).patch<void>(endpoints.renameGroup(unit, groupId), data);
  }

  public deleteGroup(compositeId: string): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).delete<void>(endpoints.deleteGroup(unit, compositeId));
  }

  public patchGroup(compositeId: string, data: { palette?: string, label?: string }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).patch<void>(endpoints.patchGroup(unit, compositeId), data);
  }

  public postLayer(data: { label: string, target: { obj: number, type: string, }, position: { item: { type: 'group', obj: number, }, 'drop_type': 'inner', }, }): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return this.request({ auth: true }).post<void>(endpoints.postGroup(unit), data);
  }

  public async addFields(data: FormData): Promise<ApiResponse<AddPoiDto>> {
    return await this.request({ auth: true }).post<AddPoiDto>(endpoints.addFields(), data, {
      onUploadProgress: ({ loaded, total }) => {
        EventBus.$emit(EventsEnum.ProgressBarValue, { loaded, total });
      },
    });
  }

  public async locatoinInfo(address: {
    'source': string ;
    'x': string ;
    'y': string
  }): Promise<ApiResponse<LocatoinInfoDto[]>> {
    return await
    this.request({ auth: true }).get<LocatoinInfoDto[]>(endpoints.locatoinInfo(address));
  }

  public async readVectors(address: {
    'farmunitId': number ;
  }): Promise<ApiResponse<VectorDto[]>> {
    return await
    this.request({ auth: true }).get<VectorDto[]>(endpoints.readVectors(address.farmunitId));
  }

  public async getRasters(): Promise<ApiResponse<RastersDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<RastersDto[]>(endpoints.getRasters(unit));
  }

  public async readTasks(address: {
    'fieldId': number ;
  }): Promise<ApiResponse<TasksDto[]>> {
    return await this.request({ auth: true }).get<TasksDto[]>(endpoints.readTasks(address.fieldId));
  }

  public async readFacts(address: {
    'fieldId': number ;
  }): Promise<ApiResponse<TasksDto[]>> {
    return await this.request({ auth: true }).get<TasksDto[]>(endpoints.readFacts(address.fieldId));
  }

  public async getFields(): Promise<ApiResponse<FieldsDto>> {
    return await this.request({ auth: true }).get<FieldsDto>(endpoints.getFields());
  }

  public async getUnitFields(address: {'unitId': number}): Promise<ApiResponse<FieldsDto>> {
    return await this.request({ auth: true }).get<FieldsDto>(endpoints.getUnitFields(address.unitId));
  }

  public async readStyle(data: { id: number }): Promise<ApiResponse<MapStyleSpecification>> {
    return await this.request({ auth: true, freeze: false }).get<MapStyleSpecification>(endpoints.readStyle(data.id));
  }

  public async readStyleLocal(address: {id: number}): Promise<ApiResponse<StyleDto>> {
    return await this.request({ auth: true }).get<StyleDto>(endpoints.readStyleLocal(address.id));
  }

  public async readStyleSours(address: {id: number}): Promise<ApiResponse<SourceStyleDto>> {
    return await
    this.request({ auth: true }).get<SourceStyleDto>(endpoints.readStyleSours(address.id));
  }

  public async getRaster(address: {id: number}): Promise<ApiResponse<ReadRasterDto>> {
    return await
    this.request({ auth: true }).get<ReadRasterDto>(endpoints.getRaster(address.id));
  }

  public async getVectorName(data: {structId: number}): Promise<ApiResponse<VectorsName[]>> {
    return await
    this.request({ auth: true }).get<VectorsName[]>(endpoints.getVector(data.structId));
  }

  public async getTileJson(address: {id: number}): Promise<ApiResponse<TilejsonDto>> {
    return await
    this.request({ auth: true }).get<TilejsonDto>(endpoints.getTileJson(address.id));
  }

  public async editingField(id: number, formData?: any): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).put<any>(endpoints.editingField(id), formData);
  }

  public async deleteField(field: {id: number}): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).delete(endpoints.deleteField(field.id));
  }

  public async putRaster(address: {id: number}, data: any): Promise<ApiResponse<RastersDto>> {
    return await
    this.request({ auth: true }).put<RastersDto>(endpoints.raster(address.id), data);
  }

  public async putVector(address: {id: number}, data: any): Promise<ApiResponse<VectorsName>> {
    return await
    this.request({ auth: true }).put<VectorsName>(endpoints.putVector(address.id), data);
  }

  public async deleteVector(data: {id: number}): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deleteVector(data.id));
  }

  public async deleteRaster(data: {id: number}): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deleteRaster(data.id));
  }

  public async getAllTasks(farmunitId: {id: number}): Promise<ApiResponse<TaskMapListDto[]>> {
    return await
    this.request({ auth: true }).get<TaskMapListDto[]>(endpoints.getAllTasks(farmunitId.id));
  }

  public async postTaskMap(data: any): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postTaskMap(), data);
  }

  public async postFieldAdd(data: any): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postFieldAdd(), data);
  }

  public async postRaster(data: FormData): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postRaster(), data, {
      onUploadProgress: ({ loaded, total }) => {
        EventBus.$emit(EventsEnum.ProgressBarValue, { loaded, total });
      },
    });
  }

  public async postVector(data: FormData): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postVector(), data, {
      onUploadProgress: ({ loaded, total }) => {
        EventBus.$emit(EventsEnum.ProgressBarValue, { loaded, total });
      },
    });
  }

  public async getTasksField(id: number): Promise<ApiResponse<TaskFieldDto[]>> {
    return await
    this.request({ auth: true }).get<TaskFieldDto[]>(endpoints.getTasksField(id));
  }

  public async postTaskMapDesiccation(data: {
    // eslint-disable-next-line camelcase
    task_name: string,
    type: number,
    field: {id: number},
    index: number,
    // eslint-disable-next-line camelcase
    material_value: number,
    borders: number[],
    // eslint-disable-next-line camelcase
    zones_proc: number[],
    user: {email: string}}): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postTaskMapDesiccation(), data);
  }

  public async postCalculateHistogramArea(data: {
    field: number,
    'bbox': Coordinates | [number, number, number, number],
    'zones': number[],
    'height': number,
    'width': number,
    payload: string[]
  }, id: number): Promise<ApiResponse<any>> {
    return await
    this.request({ auth: true }).post<any>(endpoints.postCalculateHistogramArea(id), data);
  }

  public async getRasterCrop(rasterId: number, fieldId: number) {
    return await this.request({ auth: true }).get<RasterCropDto>(endpoints.getRasterCrop(rasterId, fieldId));
  }

  public async getVectorGeometry(id: number) {
    return await this.request({ auth: true }).get<any>(endpoints.getVectorGeometry(id));
  }

  public async getUnifiedVectors(): Promise<ApiResponse<UnifiedVectorDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<UnifiedVectorDto[]>(endpoints.getUnifiedVectors(unit));
  }
}
