import { computed, ref } from 'vue';
import { VectorModel } from '@/models/VectorModel';
import { RasterModel } from '@/models/RasterModel';

const _editMap = ref<VectorModel|RasterModel>();
export const useAdmin = () => {
  const editMap = computed({
    get: () => _editMap.value,
    set: (s) => {
      _editMap.value = s;
    },
  });
  return { editMap };
};
