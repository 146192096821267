<template src="./NavigationIntegrations.pug" lang="pug"/>
<style src="./NavigationIntegrations.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import { NavigationMenuItems } from '@/assets/data/NavigationMenuItems';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import { useMapLayout } from '@/composables/useMapLayout';
import { useNavigation } from '@/composables/useNavigation';

export default defineComponent({
  name: 'NavigationIntegrations',
  setup() {
    const {
      showBlock,
      clearArea,
    } = useMapLayout();
    const { activeSector } = useNavigation();
    const onClick = () => {
      clearArea(MapLayoutAreaEnum.LEFT);
      clearArea(MapLayoutAreaEnum.RIGHT);
      activeSector.value = undefined;
      showBlock('IntegrationModal');
    };
    return {
      onClick,
    };
  },
});
</script>
