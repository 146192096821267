import { ConfigDefaultPosition, ConfigDefaultZoom } from '@/assets/data/Config';
import { FieldsCollection } from '@/collection/FieldsCollection';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { AdditionalMapIdType } from '@/constants/types/AdditionalMapIdType';
import { SelectFieldStateType } from '@/constants/types/map/SelectFieldStateType';
import { emptyFeatureCollection } from '@/lib/map/emptyFeatureCollection';
import { FieldModel } from '@/models/field/FieldModel';
import { MapModel } from '@/models/map/MapModel';
import { MapContainerModel } from '@/models/mapContainer/MapContainerModel';
import { PoiModel } from '@/models/poi/PoiModel';
import FieldsList from '@/modules/fields/FieldsList';
import PoisList from '@/modules/poi/PoisList';
import StructList from '@/modules/struct/StructList';
import LoadingStatus from '@/services/loading/LoadingStatus';
import LoggerService from '@/services/logger/LoggerService';
import { ElNotification } from 'element-plus';
import mapboxgl, {
  FitBoundsOptions,
  LngLatBoundsLike,
  MapMouseEvent,
  MapTouchEvent,
} from 'mapbox-gl';
import { computed, ref } from 'vue';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';

const _containers = ref<MapContainerModel[]>([]);

const fitIntervals: Partial<Record<MapContainerEnum, ReturnType<typeof setInterval> | undefined>> = {};

export type SidePanelType = 'none' | 'fields' | 'poi' | 'images' | 'work-task-map' | 'base-task-map' | 'fact-task-map' | 'info';

const sidePanel = ref<{ active: SidePanelType, width: number }>({
  active: 'fields',
  width: 300,
});

const mapPaddings = {
  left: 10, top: 10, right: 10, bottom: 10,
};

// флаг фокусировки на активном поле. Если поставить true, то смена поля потребует подтверждения.
const isFieldFocus = ref(false);

export const useMapContainers = (id: MapContainerEnum = MapContainerEnum.MAIN_MAP) => {
  // @ts-ignore
  const container = computed<MapContainerModel | undefined>(() => _containers.value.find((v) => v.id === id) as MapContainerModel | undefined);

  // @ts-ignore
  const fields = computed<FieldModel[]>(() => (container.value?.fieldsFilter ? FieldsList.fields.filter(container.value.fieldsFilter).sort((a, b) => (a.name > b.name ? 1 : -1)) : [] as FieldModel[]));

  const addContainer = async (model: MapContainerModel) => {
    LoadingStatus.loading(LoadingNamesEnum.MAP_CONTAINER, id);

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (model.mapModel?.mapLoaded) {
          clearInterval(interval);
          resolve(true);
        }
      }, 50);
    });
    Object.values(MapAnchorEnum).forEach((layer) => {
      model.mapModel?.map?.addSource(layer, {
        type: 'geojson',
        data: emptyFeatureCollection,
      });
      model.mapModel?.map?.addLayer({
        id: layer,
        source: layer,
        type: 'symbol',
      });
    });
    model.mapModel?.map?.setPadding(mapPaddings);

    setTimeout(() => {
      model.mapModel?.render(new FieldsCollection(fields.value as FieldModel[]));
      model.mapModel?.renderCursorLayer();
      model.mapModel?.renderYouLocation();
      LoadingStatus.success(LoadingNamesEnum.MAP_CONTAINER, id);
    });
    // @ts-ignore
    _containers.value.push(model);
  };

  const mapModel = computed(() => container.value?.mapModel as MapModel | undefined);

  const compareMode = computed({
    get() {
      return container.value?.compareMode || 'single';
    },
    set(value: 'single' | 'shuffle' | 'double') {
      if (container.value) {
        container.value.compareMode = value;
      }
    },
  });

  const compareMap = computed<MapModel | undefined>({
    get() {
      return container.value?.compareMap as MapModel | undefined;
    },
    set(value: MapModel | undefined) {
      if (container.value) {
        container.value.compareMap = value;
      }
    },
  });

  const disableInteraction = () => {
    const maps = [];
    maps.push(container.value?.mapModel?.map);
    maps.push(container.value?.compareMap?.map);
    maps.push(container.value?.additionalMap?.map);
    maps.forEach((map) => {
      map?.scrollZoom.disable();
      map?.boxZoom.disable();
      map?.dragRotate.disable();
      map?.dragPan.disable();
      map?.keyboard.disable();
      map?.touchZoomRotate.disable();
    });
  };

  const enableInteraction = () => {
    const maps = [];
    maps.push(container.value?.mapModel?.map);
    maps.push(container.value?.compareMap?.map);
    maps.push(container.value?.additionalMap?.map);
    maps.forEach((map) => {
      map?.scrollZoom.enable();
      map?.boxZoom.enable();
      map?.dragRotate.enable();
      map?.dragPan.enable();
      map?.keyboard.enable();
      map?.touchZoomRotate.enable();
    });
  };

  const addCompareMap = async () => {
    LoadingStatus.loading(LoadingNamesEnum.MAP_CONTAINER, `${id}-compare`);
    const map = new MapModel(id, 'compare');

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (map.mapLoaded) {
          clearInterval(interval);
          resolve(true);
        }
      }, 50);
    });

    container.value.compareMap = map;

    const mbc = container.value?.compareMap?.map;
    const mbm = mapModel.value?.map;

    if (mbc && mbm) {
      Object.values(MapAnchorEnum).forEach((layer) => {
        mbc.addSource(layer, { type: 'geojson', data: emptyFeatureCollection });
        mbc.addLayer({ id: layer, source: layer, type: 'symbol' });
      });

      mbc.setPadding(mapPaddings);
      mbc.setCenter(mbm.getCenter());
      mbc.setZoom(mbm.getZoom());

      mbc.resize();
      mbm.resize();
      mbc.on('move', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbm.setCenter(mbc.getCenter(), { crossAction: true }); });
      mbm.on('move', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbc.setCenter(mbm.getCenter(), { crossAction: true }); });
      mbc.on('zoom', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbm.setZoom(mbc.getZoom(), { crossAction: true }); });
      mbm.on('zoom', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbc.setZoom(mbm.getZoom(), { crossAction: true }); });
      mbc.on('rotate', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbm.setBearing(mbc.getBearing(), { crossAction: true }); });
      mbm.on('rotate', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbc.setBearing(mbm.getBearing(), { crossAction: true }); });
      mbc.on('pitch', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbm.setPitch(mbc.getPitch(), { crossAction: true }); });
      mbm.on('pitch', (evt: MapMouseEvent | MapTouchEvent) => { if (!('crossAction' in evt)) mbc.setPitch(mbm.getPitch(), { crossAction: true }); });

      map.render(new FieldsCollection(fields.value as FieldModel[]));
      map.renderCursorLayer();
      LoadingStatus.success(LoadingNamesEnum.MAP_CONTAINER, `${id}-compare`);
      if (container.value.activeField) {
        const compareLayer = container.value.compareMap.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
        mbc?.setFeatureState({ source: compareLayer.sourceId, id: container.value.activeField.id }, { active: true });
      }
    }
  };

  const addAdditionalMap = async (postfix: AdditionalMapIdType) => {
    LoadingStatus.loading(LoadingNamesEnum.MAP_CONTAINER, `${id}-${postfix}`);
    const map = new MapModel(id, postfix);

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (map.mapLoaded) {
          clearInterval(interval);
          resolve(true);
        }
      }, 50);
    });

    container.value.additionalMap = map;
    LoadingStatus.success(LoadingNamesEnum.MAP_CONTAINER, `${id}-${postfix}`);
  };

  const additionalMap = computed<MapModel | undefined>({
    get() {
      return container.value?.additionalMap as MapModel | undefined;
    },
    set(value: MapModel | undefined) {
      if (container.value) {
        container.value.additionalMap = value;
      }
    },
  });

  const selectState = computed<SelectFieldStateType>(() => (container.value?.selectState || 'single') as SelectFieldStateType);

  const setSelectState = (state: 'single'|'multiple') => {
    if (container.value) {
      container.value.selectState = state;
    }
  };

  const getField = (value: FieldModel | string | number | undefined): FieldModel | undefined => {
    if (typeof value === 'string' || typeof value === 'number') {
      return fields.value.find((v) => v.id === Number(value));
    }
    return value;
  };

  /** Очистить массив выбранных полей */
  const cleanSelectedFields = () => {
    if (container.value) {
      container.value.selectedFields.splice(0, container.value.selectedFields.length);
    }
  };

  const activeField = computed<FieldModel | undefined>(() => container.value?.activeField as FieldModel | undefined);

  const hoverField = computed<FieldModel | undefined>(() => container.value?.hoverField as FieldModel | undefined);

  const setHoverField = (value: FieldModel | string | number | undefined) => {
    if (container.value) {
      container.value.hoverField = getField(value);
    }
  };

  const isFieldActive = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (container.value && container.value.activeField && field) {
      return container.value.activeField.id === field.id;
    }
    return false;
  };

  const setActiveField = (value: FieldModel | string | number | undefined = undefined) => {
    const field = getField(value);
    if (container.value) {
      container.value.activeField = field;
    }
  };

  const selectedFields = computed<FieldModel[]>(() => (container.value ? container.value.selectedFields as FieldModel[] : []));

  /** Удалить из массива выбранных полей указанное поле */
  const isDisabledField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      return !!container.value.disabledFields.find((v) => v.id === field.id);
    }
    return false;
  };

  const hasSelectedField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      return container.value.selectedFields.some((a) => a.id === field.id);
    }
    return false;
  };

  /** Добавить в массив выбранных полей указанное поле */
  const addSelectedField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      container.value.selectedFields.push(field);
    }
  };

  /** Добавить в массив выбранных полей указанное поле */
  const selectAllFields = () => {
    fields.value.forEach((v) => {
      if (!container.value?.selectedFields.find((s) => s.id === v.id) && !isDisabledField(v.id)) {
        container.value?.selectedFields.push(v);
      }
    });
  };

  /** Удалить из массива выбранных полей указанное поле */
  const removeSelectedField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      container.value.selectedFields.splice(container.value.selectedFields.indexOf(field), 1);
    }
  };

  const toggleSelectedField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      const found = container.value.selectedFields.find((f) => f.id === field.id);
      if (found) {
        removeSelectedField(field);
      } else {
        addSelectedField(field);
      }
    }
  };

  const disabledFields = computed<FieldModel[]>(() => container.value?.disabledFields as FieldModel[]);

  /** Добавить в массив выбранных полей указанное поле */
  const addDisabledField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      container.value.disabledFields.push(field);
    }
  };

  /** Удалить из массива выбранных полей указанное поле */
  const removeDisabledField = (value: FieldModel | string | number | undefined) => {
    const field = getField(value);
    if (field && container.value) {
      container.value.disabledFields.splice(container.value.disabledFields.indexOf(field), 1);
    }
  };

  /** Очистить массив выбранных полей */
  const cleanDisabledFields = () => {
    if (container.value) {
      container.value.disabledFields.splice(0, container.value.disabledFields.length);
    }
  };

  const toDefaultPosition = () => {
    if (container.value?.id && fitIntervals[container.value.id]) {
      clearInterval(fitIntervals[container.value.id]);
    }
    container.value?.mapModel?.map?.flyTo({
      center: ConfigDefaultPosition,
      essential: true,
      zoom: ConfigDefaultZoom,
      duration: 0,
    });
  };

  /**
   * Переместить карту для обзора всех полей текущего хозяйства.
   */
  const fitStruct = (options: FitBoundsOptions = {
    duration: 0,
    maxZoom: 14.5,
  }) => {
    // @ts-ignore
    container.value?.mapModel?.map?.setMaxBounds();
    if (container.value?.id && fitIntervals[container.value.id]) {
      clearInterval(fitIntervals[container.value.id]);
    }
    if (StructList.activeStruct.value) {
      const bounds = fields.value.reduce((acc, f) => {
        acc.west = Math.min(acc.west, f.bounds.getWest());
        acc.east = Math.max(acc.east, f.bounds.getEast());
        acc.north = Math.max(acc.north, f.bounds.getNorth());
        acc.south = Math.min(acc.south, f.bounds.getSouth());
        return acc;
      }, {
        west: Infinity,
        east: -Infinity,
        north: -Infinity,
        south: Infinity,
      });
      if (bounds.west < Infinity) {
        container.value?.mapModel?.map?.fitBounds([
          [bounds.east, bounds.north], // northeastern corner of the bounds
          [bounds.west, bounds.south], // southwestern corner of the bounds
        ], options);
      } else {
        toDefaultPosition();
      }
    } else {
      LoggerService.error('Try to fit struct, when the active struct is undefined.');
    }
  };

  /**
   * Переместить карту у указанному полю. Если поле не будет указанно, переместиться к текущему активному полю.
   * @param {FieldModel|undefined} fieldModel
   * @param {boolean} limitCameraView ограничить передвижения камеры по карте в границах указанного поля
   */
  const fitField = (fieldModel: FieldModel | undefined = undefined, limitCameraView = false, map: 'main' | 'compare' | undefined = undefined) => {
    const _map: mapboxgl.Map | undefined = map === 'main' ? container.value?.mapModel?.map : container.value?.compareMap?.map;
    if (!_map) {
      return;
    }
    const bounds = fieldModel?.bounds;
    if (bounds) {
      // @ts-ignore
      _map.setMaxBounds();
      disableInteraction();
      _map.on('moveend', () => {
        if (limitCameraView) {
          // @ts-ignore
          _map.setMaxBounds(_map.getBounds());
        }
        enableInteraction();
      });
      setTimeout(() => {
        enableInteraction();
      }, 2000);
      _map.fitBounds(bounds, { padding: 15, duration: 1500 });
    }
  };

  const fitBounds = (bounds: LngLatBoundsLike, immediately = false) => {
    // @ts-ignore
    container.value?.mapModel?.map?.setMaxBounds();
    if (container.value?.id && fitIntervals[container.value.id]) {
      clearInterval(fitIntervals[container.value.id]);
    }
    setTimeout(() => {
      const wrong = [Infinity, -Infinity, NaN];

      // @ts-ignore
      if (wrong.includes(bounds[0][0]) || wrong.includes(bounds[0][1]) || wrong.includes(bounds[1][0]) || wrong.includes(bounds[1][1])) {
        toDefaultPosition();
        return;
      }
      if (immediately) {
        container.value?.mapModel?.map?.fitBounds(bounds, {
          padding: 25,
          duration: 0,
          maxZoom: 14.5,
        });
      } else {
        container.value?.mapModel?.map?.fitBounds(bounds, {
          padding: 25,
          maxDuration: 3000,
          maxZoom: 14.5,
        });
      }
    }, 300);
  };

  const toUserPosition = () => {
    if (container.value?.id && fitIntervals[container.value.id]) {
      clearInterval(fitIntervals[container.value.id]);
    }
    if (!navigator.geolocation) {
      ElNotification(
        {
          message: 'Ваш браузер не поддерживает геолокацию.',
          type: 'error',
          position: 'bottom-right',
        },
      );
    } else {
      navigator.geolocation.getCurrentPosition((e) => {
        setTimeout(() => {
          container.value?.mapModel?.map?.resize().flyTo({
            center: [
              e.coords.longitude,
              e.coords.latitude,
            ],
            essential: true,
            zoom: container.value?.mapModel?.map.getZoom(),
          });
        }, 300);
      });
    }
  };

  const activePoi = computed<PoiModel | undefined>(() => container.value?.activePoi as PoiModel | undefined);

  const hoverPoi = computed<PoiModel | undefined>(() => container.value?.hoverPoi as PoiModel | undefined);

  const getPoi = (value: PoiModel | string| number | undefined): PoiModel | undefined => {
    if (typeof value === 'string' || typeof value === 'number') {
      return PoisList?.findPoiById(Number(value));
    }
    return value;
  };

  const setActivePoi = (value: PoiModel | string| number | undefined) => {
    const poi = getPoi(value);
    if (container.value) {
      container.value.activePoi = poi;
    }
  };

  const setHoverPoi = (value: PoiModel | string | number | undefined) => {
    if (container.value) {
      container.value.hoverPoi = getPoi(value);
    }
  };

  const filterPoi = computed(() => container.value?.filterPoi || {
    selectPoiGroup: [],
    searchValue: '',
    keySearch: undefined,
    filterKeys: [],
  });

  const informationMode = computed({
    get: () => container.value?.informationMode || 'none',
    set: (v: 'none' | 'field' | 'poi') => {
      if (container.value) {
        container.value.informationMode = v;
      }
    },
  });

  const baseTaskMap = computed({
    get: () => container.value?.baseTaskMap,
    set: (taskMap) => { container.value && (container.value.baseTaskMap = taskMap); },
  });

  const maxSelectFields = computed(() => container.value?.maxSelectFields);

  const helper = computed({
    get: () => container.value?.helperContent,
    set: (content: string) => { container.value && (container.value.helperContent = content); },
  });

  const isHelperActive = computed(() => container.value?.helperContent.length > 0);

  return {
    sidePanel,
    addContainer,
    mapModel,
    compareMode,
    compareMap,
    fields,
    activeField,
    isFieldActive,
    setActiveField,
    selectedFields,
    hasSelectedField,
    addSelectedField,
    selectAllFields,
    removeSelectedField,
    toggleSelectedField,
    cleanSelectedFields,
    disabledFields,
    addDisabledField,
    removeDisabledField,
    isDisabledField,
    cleanDisabledFields,
    toUserPosition,
    fitBounds,
    fitField,
    fitStruct,
    selectState,
    hoverField,
    setHoverField,
    addCompareMap,
    // structVectors,
    activePoi,
    setActivePoi,
    setSelectState,
    filterPoi,
    informationMode,
    baseTaskMap,
    maxSelectFields,
    addAdditionalMap,
    additionalMap,
    helper,
    isHelperActive,
    mapPaddings,
    isFieldFocus,
    hoverPoi,
    setHoverPoi,
  };
};
