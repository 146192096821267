import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { indexLayerDef, indexSourceDef } from '@/models/map/Layers/defs/IndexDef';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { ImageSource } from 'mapbox-gl';

/** Для каких индексов отобразить легенду */
const legendKinds = [
  FileIndexKindEnums.NDVI,
  FileIndexKindEnums.PVI,
  FileIndexKindEnums.EVI,
  FileIndexKindEnums.mSAVI2,
  FileIndexKindEnums.NDRE,
  FileIndexKindEnums.RECL,
  FileIndexKindEnums.VARI,
  FileIndexKindEnums.NDMI,
  FileIndexKindEnums.NDSI,
  FileIndexKindEnums.NDWI,
];

export class MapLayerIndexModel extends MapLayerModel implements IMapLayerModel {
  get contrast(): boolean {
    return this._contrast;
  }

  private _contrast: boolean;

  // ? зачем здесь readonly был добавлен
  private _data: FieldIndexMonitoringModel | FieldAverageIndexModel;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'index', input.uuid);
    this._data = input as (FieldIndexMonitoringModel | FieldAverageIndexModel);
    this._contrast = this._data.kind === FileIndexKindEnums.MASK ? false : this._data.showContrast || false;
    if (mapModel.map) {
      const anchor = (input as FieldIndexMonitoringModel).kind === FileIndexKindEnums.MASK ? MapAnchorEnum.INDEX_MASK_FILE : MapAnchorEnum.INDEX_FILE;
      mapModel.map
        .addSource(this.sourceId, indexSourceDef(this._contrast ? this._data.imgContrastFile : this._data.imgColoredFile, this._data.geomCoordinates))
        .addLayer(indexLayerDef(this.sourceId, this.layerId))
        .moveLayer(this.layerId, anchor);
      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
    }
    if (input instanceof FieldIndexMonitoringModel && legendKinds.includes((input as FieldIndexMonitoringModel).kind)) {
      this.showLegend.value = true;
    }
  }

  get data(): FieldIndexMonitoringModel | FieldAverageIndexModel {
    return this._data;
  }

  updateImage = (file: FieldIndexMonitoringModel | FieldAverageIndexModel): void => {
    this.showLegend.value = false;
    const source = this._mapModel?.map?.getSource(this.sourceId) as ImageSource;
    source.updateImage({
      url: file.showContrast ? file.imgContrastFile || '' : file.imgColoredFile || '',
      coordinates: file.geomCoordinates,
    });
    this._data = file;
    this._contrast = file.showContrast;
    if (file instanceof FieldIndexMonitoringModel && legendKinds.includes((file as FieldIndexMonitoringModel).kind)) {
      this.showLegend.value = true;
    }
  }

  setContrast = (value: boolean) => {
    this.showLegend.value = false;
    if (value !== this._contrast) {
      const source = this._mapModel?.map?.getSource(this.sourceId) as ImageSource;
      if (value) {
        source.updateImage({
          url: this._data.imgContrastFile || '',
          coordinates: this._data.geomCoordinates,
        });
      } else {
        source.updateImage({
          url: this._data.imgColoredFile || '',
          coordinates: this._data.geomCoordinates,
        });
      }
      this._contrast = value;
    }

    this.showLegend.value = true;
  }
}
