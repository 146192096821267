import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import { PoiParamsType } from '@/constants/types/PoiParamsType';

export const POIParams: Record<string, {
  category: POICategoryEnum,
  type: PoiParamsType,
  [key: string]: unknown,
  tableWidth: number,
  precision?: number,
  maxLength?: number,
  unit?: string,
  min?: number,
  max?: number
}> = {
  n_prob: {
    category: POICategoryEnum.NAME,
    type: 'string',
    maxLength: 127,
    tableWidth: 150,
  },
  field: {
    category: POICategoryEnum.NAME,
    type: 'string',
    maxLength: 127,
    tableWidth: 150,
  },
  x: {
    category: POICategoryEnum.COORDS,
    type: 'number',
    maxLength: 20,
    tableWidth: 80,
    precision: 15,
    min: -180,
    max: 180,
  },
  y: {
    category: POICategoryEnum.COORDS,
    type: 'number',
    maxLength: 20,
    tableWidth: 80,
    precision: 15,
    min: -180,
    max: 180,
  },
  soil_type: {
    category: POICategoryEnum.SOIL,
    type: 'string',
    maxLength: 127,
    tableWidth: 150,
  },
  soil_index: {
    category: POICategoryEnum.SOIL,
    type: 'soilIndex',
    maxLength: 127,
    tableWidth: 110,
  },
  soiltextur: {
    category: POICategoryEnum.SOIL,
    type: 'select',
    select: [
      {
        value: '',
        title: 'Не выбрано',
      },
      {
        value: 'песок',
      },
      {
        value: 'супесь',
      },
      {
        value: 'легкий суглинок',
      },
      {
        value: 'средний суглинок',
      },
      {
        value: 'тяжелый суглинок',
      },
      {
        value: 'глина',
      },
    ],
    tableWidth: 250,
  },
  parent_mat: {
    category: POICategoryEnum.SOIL,
    type: 'select',
    select: [
      {
        value: '',
        title: 'Не выбрано',
      },
      {
        value: 'покровные глины',
      },
      {
        value: 'покровные суглинки',
      },
      {
        value: 'лёссовидные суглинки',
      },
      {
        value: 'лёссовидные глины',
      },
      {
        value: 'третичные отложения',
      },
      {
        value: 'лёссы',
      },
      {
        value: 'аллювиальные отложения',
      },
      {
        value: 'элювиальные отложения',
      },
      {
        value: 'делювиальные отложения',
      },
      {
        value: 'ледниковые отложения',
      },
      {
        value: 'морские отложения',
      },
      {
        value: 'озерные отложения',
      },
      {
        value: 'торфяные отложения',
      },
    ],
    tableWidth: 250,
  },
  compact_dg: {
    category: POICategoryEnum.SOIL,
    type: 'select',
    select: [
      {
        value: '',
        title: 'Не выбрано',
      },
      {
        value: 'нет',
      },
      {
        value: 'слабая',
      },
      {
        value: 'остаточно-глыбистая',
      },
      {
        value: 'средняя',
      },
      {
        value: 'сильная',
      },
    ],
    tableWidth: 170,
  },
  relief: {
    category: POICategoryEnum.SOIL,
    type: 'string',
    maxLength: 127,
    tableWidth: 200,
  },

  humus_dept: {
    category: POICategoryEnum.SOIL,
    type: 'int',
    tableWidth: 150,
    precision: 0,
    unit: 'см',
    min: 0,
    max: 999,
  },
  hcl: {
    category: POICategoryEnum.SOIL,
    type: 'int',
    tableWidth: 50,
    precision: 0,
    unit: 'см',
    min: 0,
    max: 999,
  },
  compact_h: {
    category: POICategoryEnum.SOIL,
    type: 'int',
    tableWidth: 150,
    precision: 0,
    unit: 'см',
    min: 0,
    max: 999,
  },
  compact_l: {
    category: POICategoryEnum.SOIL,
    type: 'int',
    tableWidth: 150,
    precision: 0,
    unit: 'см',
    min: 0,
    max: 999,
  },
  water: {
    category: POICategoryEnum.SOIL,
    type: 'int',
    tableWidth: 65,
    precision: 0,
    unit: 'см',
    min: 0,
    max: 999,
  },
  waterlog: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 90,
  },
  ins_hydrat: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 100,
  },
  add_hydrat: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 105,
  },
  erosion: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 95,
  },
  deflation: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 90,
  },
  stones: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 80,
  },
  sandiness: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 80,
  },
  solonetz: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 90,
  },
  salinizat: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 95,
  },
  par_mat: {
    category: POICategoryEnum.LIMIT_FACTOR,
    type: 'stars',
    tableWidth: 95,
  },
  description: {
    category: POICategoryEnum.DESCRIPTION,
    type: 'string',
    tableWidth: 200,
    maxLength: 500,
  },
  hms_p: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 75,
    precision: 1,
    unit: '%',
    min: 0,
    max: 100,
  },
  hms_t: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 75,
    precision: 1,
    unit: 'т/га',
    min: 0,
    max: 9999,
  },
  ph_kcl: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 99,
  },
  ph_h2o: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 99,
  },
  hr: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    unit: 'ммоль/100 г',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 99,
  },
  p2o5_m: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  p2o5_ch: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  p2o5_k: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  k2o_m: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  k2o_ch: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  k2o_k: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  s: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 999,
  },
  spo: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    unit: 'ммоль/100 г',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 9999,
  },
  eko: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    unit: 'ммоль/100 г',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 9999,
  },
  sno: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    unit: '%',
    tableWidth: 100,
    precision: 1,
    min: 0,
    max: 100,
  },
  n_tk: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  n_k: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  no3: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  nh4: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  zpv: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мм',
    min: 0,
    max: 9999,
  },
  mgo: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 9999,
  },
  cu: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 3,
    unit: 'мг/кг',
    min: 0,
    max: 99,
  },
  zn: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 3,
    unit: 'мг/кг',
    min: 0,
    max: 99,
  },
  mn: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 1,
    unit: 'мг/кг',
    min: 0,
    max: 999,
  },
  co: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 3,
    unit: 'мг/кг',
    min: 0,
    max: 99,
  },
  mo: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 3,
    unit: 'мг/кг',
    min: 0,
    max: 99,
  },
  b: {
    category: POICategoryEnum.AGRO_CHEMISTRY,
    type: 'number',
    tableWidth: 100,
    precision: 3,
    unit: 'мг/кг',
    min: 0,
    max: 99,
  },
};
