import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { StorageChangelogType } from '@/constants/types/storage/StorageChangelogType';
import { StorageMapText } from '@/constants/types/storage/StorageMapText';
import { StoragePaletteType } from '@/constants/types/storage/StoragePaleteType';
import { StorageTypes } from '@/constants/types/storage/StorageTypes';
import EventBus from '@/services/eventBus/EventBus';
import { computed, ref } from 'vue';

const data = ref<{[key: string]: unknown}>({});

const _storage = ref<StorageTypes>({
  [StorageKeyEnum.PALETTE]: [],
  [StorageKeyEnum.CHANGELOG]: {},
  [StorageKeyEnum.NOTIFICATIONS]: {},
  [StorageKeyEnum.MAPTEXT]: {},
  [StorageKeyEnum.RASTER_ORDER]: [],
});

export const useStorage = () => {
  const storage = computed({
    get: () => _storage.value,
    set: (s) => {
      _storage.value = s;
    },
  });

  const hasItem = (key: keyof StorageTypes) => !!_storage.value[key];

  const replaceItem = (key: StorageKeyEnum, value: any) => {
    _storage.value[key] = value;
    EventBus.$emit('STORAGE_KEY_SET', key, value);
  };

  const deleteItem = (key: keyof StorageTypes) => hasItem(key) && delete _storage.value[key];

  const getPalette = computed(() => (_storage.value[StorageKeyEnum.PALETTE] || []) as StoragePaletteType[]);

  const getChangelog = computed(() => (_storage.value[StorageKeyEnum.CHANGELOG] || {}) as StorageChangelogType);

  const getMapText = computed(() => (_storage.value[StorageKeyEnum.MAPTEXT] || {}) as StorageMapText);

  // массив который хранит очередность uuid растров
  const getRasterOrder = computed(() => (_storage.value[StorageKeyEnum.RASTER_ORDER]) as string[]);

  return {
    data,
    storage,
    hasItem,
    replaceItem,
    deleteItem,
    getPalette,
    getChangelog,
    getMapText,
    getRasterOrder,
  };
};
