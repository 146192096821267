export enum POICategoryEnum {
    NAME = 'name',
    SOIL = 'soil',
    CUT = 'cut',
    LIMIT_FACTOR = 'limit-factor',
    COMMENTS = 'info',
    DESCRIPTION = 'description',
    AGRO_CHEMISTRY = 'agro-chemistry',
    COORDS = 'coords',
    NOT_STANDARDIZED = 'not_standardized',
}
