export const RulePoiGroupName = [
  {
    required: true,
    message: 'Введите наименование группы',
    trigger: 'blur',
  },
  {
    min: 2,
    max: 80,
    message: 'Длина наименования группы должна быть от 2-ух до 80-ти символов',
    trigger: 'blur',
  },
];
