export const cleanObject = (obj: Record<string, any>): Record<string, any> => {
  const cleanedObject: Record<string, any> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && ![null, undefined, -1, ''].some((a) => a === obj[key])) {
      cleanedObject[key] = obj[key];
    }
  }

  return cleanedObject;
};
