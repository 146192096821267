import type { MapModel } from '@/models/map/MapModel';
import type { MapLayerStructViewModel } from '@/models/map/Layers/MapLayerStructViewModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';

export class MapStructViewEvents {
  constructor(layer: MapLayerStructViewModel, mapModel: MapModel) {
    FieldsEvents.onFeatureUpdated(() => {
      layer.redraw();
    });

    mapModel.events.onStructViewRedraw(() => {
      layer.redraw();
    });
  }
}
