import { SatelliteSceneType } from '@/constants/types/SatelliteSceneType';

export class SatelliteSceneModel {
  get cloud(): number {
    return this._cloud;
  }

  get title(): string {
    return this._title;
  }

  get name(): string {
    return this._name;
  }

  get datetime(): Date {
    return this._datetime;
  }

  get id(): number {
    return this._id;
  }

  private readonly _id: number;

  private readonly _datetime: Date;

  private readonly _name: string;

  private readonly _title: string;

  private readonly _cloud: number;

  constructor(scene: SatelliteSceneType) {
    this._datetime = new Date(scene.datetime);
    this._id = scene.id;
    this._cloud = scene.cloud;
    this._name = scene.name;
    this._title = scene.title;
  }
}
