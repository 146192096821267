import { computed, ref } from 'vue';
import { VectorModel } from '@/models/VectorModel';
import LoggerService from '@/services/logger/LoggerService';
import ApiService from '@/services/api/ApiService';

class MapVectorsList {
  private _vectors = ref<VectorModel[]>([])

  private _activeVector = ref<VectorModel | undefined>();

  private _activeEditVector = ref<VectorModel>()

  vectors = computed({
    get: () => this._vectors.value,
    set: (v) => {
      this._vectors.value = v;
    },
  })

  activeVector = computed({
    get: () => this._activeVector.value,
    set: (v) => {
      this._activeVector.value = v;
    },
  })

  activeEditVector = computed({
    get: () => this._activeEditVector.value,
    set: (v) => {
      this._activeEditVector.value = v;
    },
  })

  setEditVector(id: number): VectorModel | undefined {
    const object = this.vectors.value?.find((item) => item.id === id);
    if (object) {
      // @ts-ignore
      this.activeEditVector.value = object as VectorModel;
    }
    // @ts-ignore
    return (object as VectorModel) || undefined;
  }

  async fetchGeometryVector(id: number) {
    try {
      const { data } = await ApiService.gis.getVectorGeometry(id);
      const vector = this._vectors.value.find((v) => v.id === id);
      if (vector) {
        vector.setGeometry(data.geojson);
      }
    } catch (e) {
      LoggerService.error(e);
    }
  }
}
export default new MapVectorsList();
