import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "FieldDrawBlock" }
const _hoisted_2 = { class: "FieldDrawBlock_ContuerMessage" }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_ui_drawer = _resolveComponent("ui-drawer")

  return (_openBlock(), _createBlock(_component_ui_drawer, {
    show: _ctx.openDrawer,
    title: "Добавление нового поля",
    position: "right",
    onClose: _ctx.closeWindow
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          model: _ctx.form,
          "label-width": "120px",
          "label-position": "top",
          "status-icon": "",
          rules: _ctx.rules,
          ref: "fieldDrawRef"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Краткое наименование поля",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: "Введите краткое наименование поля",
                  modelValue: _ctx.form.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Полное наименование поля",
              prop: "name_rus"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: "Введите полное наименование поля",
                  modelValue: _ctx.form.name_rus,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.name_rus) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Описание",
              prop: "desc"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: "Введите краткое описание",
                  type: "textarea",
                  rows: 3,
                  modelValue: _ctx.form.descr,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.form.descr) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Учетная площадь поля (га)",
              prop: "sq_acc"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  placeholder: "",
                  modelValue: _ctx.form.sq_acc,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.form.sq_acc) = $event)),
                  precision: 2,
                  min: 0,
                  max: _ctx.FIELD_MAX_AREA_SIZE,
                  onKeypress: _cache[4] || (_cache[4] = $event => (_ctx.isNumber($event)))
                }, null, 8, ["modelValue", "max"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Контур поля",
              prop: "geom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.lengthPolygon === 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("Укажите контуры поля на карте")
                      ], 64))
                    : (_ctx.lengthPolygon > 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode("Контур поля определен по " + _toDisplayString(_ctx.lengthPolygon) + " точкам.", 1)
                        ], 64))
                      : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  disabled: _ctx.disabled,
                  onClick: _cache[5] || (_cache[5] = $event => (_ctx.onSubmit(_ctx.fieldDrawRef)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button-add', 'Добавить')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_el_button, { onClick: _ctx.closeWindow }, {
                  default: _withCtx(() => [
                    _createTextVNode("Отмена")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _: 1
  }, 8, ["show", "onClose"]))
}