<template lang="pug" src="./CustomCheckbox.pug"/>
<style lang="scss" src="./CustomCheckbox.scss"/>

<script lang="ts">
import { Loading, Select } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';

type PropsType = {
  value: boolean,
  disabled: boolean
}

export default defineComponent({
  name: 'CustomCheckbox',
  components: {
    Select,
    Loading,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props: PropsType, { emit }) {
    const onClick = () => {
      if (!props.disabled) {
        emit('change');
      }
    };
    return {
      props,
      onClick,
    };
  },
});
</script>
