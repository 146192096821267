import ApiService from '@/services/api/ApiService';
import { ColorKitDto } from '@/services/api/dto/dictionary/ColorKitDto';
import { ColorsKitTypeEnum } from '@/constants/enums/ColorsKitTypeEnum';
import { SatelliteIndexColorsDto } from '@/services/api/dto/satellites/SatelliteIndexColorsDto';
import DictionaryColorsKitModel from '@/models/dictionary/DictionaryColorsKitModel';
import { MonitoringIndexColorDto } from '@/services/api/dto/monitoring/MonitoringIndexColorDto';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { Ref, ref, UnwrapRef } from 'vue';
import { SimpleType } from '@/constants/types/BasedTypes';
import { fetcher } from '@/lib/tools/fetcher';

const makeColorKitDto = (dto: SatelliteIndexColorsDto | MonitoringIndexColorDto, name: string, key: string, properties: Record<string, SimpleType>) => {
  const colorKit: ColorKitDto = {
    name,
    key,
    opacity: 1,
    type: ColorsKitTypeEnum.RANGE,
    precision: 2,
    version: dto.id,
    colors: dto.colors,
    properties,
  };
  return colorKit;
};

class DictionaryColorsKitList {
  get colorKits(): Ref<UnwrapRef<DictionaryColorsKitModel[]>> {
    return this._colorKits;
  }

  private _colorKits= ref<DictionaryColorsKitModel[]>([]);

  async fetchColors(force = false) {
    await fetcher(LoadingNamesEnum.COLOR_KITS, 'default', force, async () => {
      this._colorKits.value.splice(0, this._colorKits.value.length);
      await this.fetchSatelliteColors();
      await this.fetchMonitoringColors();
    });
  }

  private async fetchMonitoringColors() {
    const { data } = await ApiService.monitoring.getColors();
    data.forEach((dto: MonitoringIndexColorDto) => {
      dto.kinds.forEach((kind) => {
        this._colorKits.value.push(new DictionaryColorsKitModel(makeColorKitDto(dto, kind.label, kind.name, {
          is_contrast: dto.is_contrast,
          is_monitoring_index: true,
        })));
      });
    });
  }

  private async fetchSatelliteColors() {
    const { data } = await ApiService.satellites.getColors();

    data.forEach((dto: SatelliteIndexColorsDto) => {
      if (dto.kinds.find((k) => k.name === 'msf')) {
        this._colorKits.value.push(new DictionaryColorsKitModel(makeColorKitDto(dto, 'MSF', 'msf', {
          is_history_index: true,
        })));
      }
      if (dto.kinds.find((k) => k.name === 'rsf')) {
        this._colorKits.value.push(new DictionaryColorsKitModel(makeColorKitDto(dto, 'RSF', 'rsf', {
          is_history_index: true,
        })));
      }
      if (dto.kinds.find((k) => k.name === 'rvi')) {
        this._colorKits.value.push(new DictionaryColorsKitModel(makeColorKitDto(dto, 'RVI', 'rvi', {
          is_history_index: true,
        })));
      }
    });
  }
}

export default new DictionaryColorsKitList();
