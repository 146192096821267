import ApiService from '@/services/api/ApiService';
import { useUser } from '@/composables/useUser';
import { UserModel } from '@/models/UserModel';
import { ElNotification } from 'element-plus';

class UserService {
  async loadUser() {
    const { data } = await ApiService.user.getUser();
    useUser().setUser(new UserModel(data));
    if (data.permissions.length === 0) {
      ElNotification({
        title: 'Ошибка доступа',
        message: 'Уважаемый пользователь!\n'
          + 'У вас отсутствуют настройки доступа и тариф.\n'
          + 'Пожалуйста, обратитесь к своему менеджеру или в службу поддержки ООО "Агроноут".\n'
          + 'Спасибо!\n',
        type: 'error',
        position: 'bottom-right',
        duration: 15000,
      });
    }
  }
}

export default new UserService();
