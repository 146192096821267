import EventBus from '@/services/eventBus/EventBus';
import {
  BackendFetch,
  DevTools,
  FormatSimple,
  Tolgee,
} from '@tolgee/vue';

const apiKey = window.localStorage.getItem('tolgeeKey') || process.env.VUE_APP_TOLGEE_API_KEY;

export const tolgee = Tolgee()
  .use(DevTools())
  .use(BackendFetch())
  .use(FormatSimple())
  .init({
    defaultNs: 'common',
    language: 'ru-RU',
    apiUrl: process.env.VUE_APP_TOLGEE_API_URL,
    apiKey,
    ns: [
      'common',
      'navigation',
      'placeholder',
      'poi',
      'mapbox',
      'fields',
      'tech',
      'monitoring',
      'taskMap',
      'experiments',
      'struct-view',
      'crop-rotation',
      'login',
      'palette',
      'satellite',
      'ui-table',
      'notification',
      'statusbar',
      'context-menu',
      'about-program',
      'rating-ndvi',
      'asf-order',
      'side-panel',
      'route',
    ],
  });

EventBus.$on('TOLGE__KEY_UPDATE', (value: string) => {
  tolgee.updateOptions({
    apiKey: value,
  });
});
