import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { LngLat } from 'mapbox-gl';
import { WeatherDataDto } from '@/services/api/dto/weather/WeatherDataDto';

export const endpoints = {
  weatherData: (lat: number, lng: number) => `https://services.truefields.ru/${process.env.VUE_APP_SERVER_LABEL}/openweathermap/data/2.5/weather?lat=${lat}&lon=${lng}&units=metric&lang=ru`,
};

export class ApiWeather extends ApiHandler {
  public async fetchData(coords: LngLat): Promise<ApiResponse<WeatherDataDto>> {
    return await
    this.request({ auth: true, serviceToken: true }).get<WeatherDataDto>(endpoints.weatherData(coords.lat, coords.lng));
  }
}
