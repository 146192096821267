import LoggerService from '@/services/logger/LoggerService';

const decToHex = (v: number) => v.toString(16).padStart(2, '0');

export const rgbaToHex = (rgba: number[]): string => {
  if (rgba.length < 3 || rgba.length > 4) {
    LoggerService.error('Try to transform rgba to hex: ', rgba);
  }
  return `#${decToHex(rgba[0])}${decToHex(rgba[1])}${decToHex(rgba[2])}${!rgba[3] || rgba[3] === 255 ? '' : decToHex(rgba[3])}`;
};
