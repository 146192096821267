import { Collection } from '@/collection/Collection';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';

export class TaskCollection extends Collection implements Collection {
  get collection(): Array< FieldTaskMapBaseModel
      | FieldTaskMapWorkModel
      | FieldTaskMapHarvestModel
      | FieldTaskMapFactModel> {
    return this._collection;
  }

  set collection(collection: Array< FieldTaskMapBaseModel
      | FieldTaskMapWorkModel
      | FieldTaskMapHarvestModel
      | FieldTaskMapFactModel>) {
    this._collection.splice(0, this._collection.length);
    collection.forEach((model) => this._collection.push(model));
  }

  private readonly _collection: Array< FieldTaskMapBaseModel
      | FieldTaskMapWorkModel
      | FieldTaskMapHarvestModel
      | FieldTaskMapFactModel> = [];

  constructor(tasks: Array< FieldTaskMapBaseModel
      | FieldTaskMapWorkModel
      | FieldTaskMapHarvestModel
      | FieldTaskMapFactModel>) {
    super();
    this._collection = tasks;
  }
}
