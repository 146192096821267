import { UserPropertiesDto } from '@/services/api/dto/user/UserPropertiesDto';
import { PermissionActionType } from '@/constants/types/permission/PermissionActionType';
import LoggerService from '@/services/logger/LoggerService';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import PermissionsList from '@/modules/permissions/PermissionsList';

export class UserModel {
  get id(): number {
    return this._id;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  set isEulaAccepted(value: boolean) {
    this._isEulaAccepted = value;
  }

  get isEulaAccepted(): boolean {
    return this._isEulaAccepted;
  }

  get eulaDate(): number {
    return this._eulaDate;
  }

  get isStaff(): boolean {
    return this._isStaff;
  }

  get email(): string {
    return this._email;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  private _id: number;

  private _firstName: string;

  private _lastName: string;

  private _email: string;

  private _isStaff: boolean;

  private _isActive: boolean;

  private _eulaAcceptedFrom = 0;

  private _isEulaAccepted = false;

  private _eulaDate = 0;

  private _phone: string;

  constructor(data: UserPropertiesDto) {
    this._id = data.id;
    this._firstName = data.first_name || '';
    this._lastName = data.last_name || '';
    this._email = data.email;
    this._isStaff = data.is_staff;
    this._isActive = data.is_active;
    this._phone = data.phone;
    if (data.eula_accepted_from) {
      this._eulaAcceptedFrom = new Date(data.eula_accepted_from).getTime();
    }
    if (data.eula_date) {
      this._eulaDate = new Date(data.eula_date).getTime();
      if (this._eulaAcceptedFrom) {
        const currentEulaDate: number = this._eulaDate;
        this._isEulaAccepted = this._eulaAcceptedFrom >= currentEulaDate;
      }
    }
    PermissionsList.setUserPermissions(data);
  }
}
