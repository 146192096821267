<template lang="pug" src="./Path.pug"/>
<style lang="scss" src="./Path.scss"/>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUser } from '@/composables/useUser';
import SvgIcon from '@jamescoyle/vue-icon';

export default defineComponent({
  name: 'Path',
  components: { SvgIcon },
  props: {
    name: {
      type: String,
    },
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  setup() {
    const { user } = useUser();
    return {
      user,
    };
  },
});
</script>
