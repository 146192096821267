import PromiseWorker from 'promise-worker';
import Worker from 'worker-loader!./worker';
import { ZonesWorkerDataInterface } from '@/constants/types/ZonesWorkerDataInterface';

const worker = new Worker();
const promiseWorker = new PromiseWorker(worker);

const calculateZonesStats = (message: ZonesWorkerDataInterface) => promiseWorker.postMessage({
  type: 'calculateZonesStats',
  data: message,
});

export default {
  calculateZonesStats,
};
