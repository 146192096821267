<template src="./EmptyList.pug" lang="pug"/>
<style src="./EmptyList.scss" lang="scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    message: {
      type: String,
    },
  },
  name: 'EmptyList',
});
</script>
