import { FormItemRule } from 'element-plus/es/components/form/src/types';

export const RuleMaterialName: Record<string, FormItemRule[]> = {
  name: [{
    required: true,
    message: 'Не заполнено поле',
    trigger: ['blur', 'change'],
  },
  {
    min: 1,
    max: 250,
    message: 'Длина поля не должна превышать  250 символов',
    trigger: ['blur', 'change'],
  }],
};
