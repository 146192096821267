class FieldsSelectorState {
  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get isSelectAble(): boolean {
    return this._isSelectAble;
  }

  set isSelectAble(value: boolean) {
    this._isSelectAble = value;
  }

  get isActiveAble(): boolean {
    return this._isActiveAble;
  }

  set isActiveAble(value: boolean) {
    this._isActiveAble = value;
  }

  /** Активный ли сейчас какой либо селектор */
  private _isActive = false

  /** Можно ли выбрать поле для текущего селектора */
  private _isActiveAble = true;

  /** Можно ли выбрать поля для текущего селектора */
  private _isSelectAble = true;
}

export default new FieldsSelectorState();
