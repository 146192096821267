import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "TaskMapContent" }
const _hoisted_2 = { class: "TaskMapContent-body" }
const _hoisted_3 = { class: "TaskMapContent-body-selector" }
const _hoisted_4 = { class: "TaskMapContent-body-selector-icon" }
const _hoisted_5 = { class: "TaskMapContent-body-selector-info" }
const _hoisted_6 = { class: "TaskMapContent-body-selector-name" }
const _hoisted_7 = { class: "TaskMapContent-body-selector-decsription" }
const _hoisted_8 = { class: "TaskMapContent-body-selector-icon" }
const _hoisted_9 = { class: "TaskMapContent-body-selector-info" }
const _hoisted_10 = { class: "TaskMapContent-body-selector-name" }
const _hoisted_11 = { class: "TaskMapContent-body-selector-decsription" }
const _hoisted_12 = { class: "TaskMapContent-body-selector-icon" }
const _hoisted_13 = { class: "TaskMapContent-body-selector-info" }
const _hoisted_14 = { class: "TaskMapContent-body-selector-name" }
const _hoisted_15 = { class: "TaskMapContent-body-selector-decsription" }
const _hoisted_16 = { class: "TaskMapContent-body-selector-icon" }
const _hoisted_17 = { class: "TaskMapContent-body-selector-info" }
const _hoisted_18 = { class: "TaskMapContent-body-selector-name" }
const _hoisted_19 = { class: "TaskMapContent-body-selector-decsription" }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_Content = _resolveComponent("Content")

  return (_openBlock(), _createBlock(_component_Content, {
    title: _ctx.$t(_ctx.route.name, { ns: 'route' })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.basetaskmapPermission)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "TaskMapContent-body-selector-item",
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.select('BaseMap-create')))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ui_icon, {
                      name: "/images/icons/base-map.svg",
                      size: 32
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('base-task-map')), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('create-base-task-map-description', { ns: 'taskMap' })), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.worktaskmapPermission)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "TaskMapContent-body-selector-item",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.select('work-map-create')))
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ui_icon, {
                      name: "/images/icons/work-map.svg",
                      size: 32
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('work-task-map')), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('create-work-task-description', { ns: 'taskMap' })), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.basetaskmapexperimentPermission)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "TaskMapContent-body-selector-item",
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.select('experiments-create')))
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_ui_icon, {
                      name: "/images/icons/experiments.svg",
                      size: 32
                    })
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('experiments-task-map')), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('create-experiments-task-map-description', { ns: 'taskMap' })), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.desiccationPermission)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: "TaskMapContent-body-selector-item",
                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.select('desiccation-map-create')))
                }, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ui_icon, {
                      name: "/images/icons/work-map.svg",
                      size: 32
                    })
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('desiccation-task-map')), 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('create-desiccation-task-map-description', { ns: 'taskMap' })), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}