import { PermissionActionType } from '@/constants/types/permission/PermissionActionType';

export class PermissionModel {
  public target: string;

  public actions: PermissionActionType[] = [];

  public fieldIds: number[] = [];

  constructor(target: string) {
    this.target = target;
  }

  setPermission(action: PermissionActionType, fieldIds: number[] | undefined = undefined): void {
    if (!this.actions.includes(action)) {
      this.actions.push(action);
    }
    if (fieldIds) {
      this.fieldIds = fieldIds;
    }
  }

  removePermission(action: PermissionActionType): void {
    this.actions.splice(this.actions.indexOf(action), 1);
  }

  hasPermission(action: PermissionActionType, fieldId: number | undefined = undefined): boolean {
    if (fieldId && this.fieldIds.length > 0) {
      return this.fieldIds.includes(fieldId);
    }
    return this.actions.includes(action);
  }
}
