import { computed, ref } from 'vue';

export const useImageEditorHistory = () => {
  const history = ref<ImageData[]>([]);

  const resetImageData = ref<ImageData>();

  const historyIndex = ref<number>(0);

  const undoAvailable = computed(() => history.value.length > historyIndex.value + 1);
  const redoAvailable = computed(() => historyIndex.value > 0);
  const resetAvailable = computed(() => !!resetImageData.value && history.value.length > 1);

  const addToHistory = (imageData: ImageData) => {
    if (historyIndex.value) {
      history.value.splice(0, historyIndex.value);
    }
    history.value.unshift(imageData);
    historyIndex.value = 0;
  };

  const initHistory = (imageData: ImageData) => {
    history.value = [imageData];
    historyIndex.value = 0;
    resetImageData.value = imageData;
  };

  const undo = (ctx: CanvasRenderingContext2D | undefined, canvas: HTMLCanvasElement | undefined) => {
    if (canvas && ctx && undoAvailable.value) {
      historyIndex.value += 1;
      const id = history.value[historyIndex.value] as ImageData;
      if (id) {
        ctx.putImageData(id, 0, 0);
      }
    }
  };

  const redo = (ctx: CanvasRenderingContext2D | undefined) => {
    if (ctx && redoAvailable.value) {
      historyIndex.value -= 1;
      const id = history.value[historyIndex.value] as ImageData;
      if (id) {
        ctx.putImageData(id, 0, 0);
      }
    }
  };

  const reset = (ctx: CanvasRenderingContext2D | undefined) => {
    if (ctx && resetAvailable.value) {
      historyIndex.value = history.value.length - 1;
      const id = resetImageData.value as ImageData;
      if (id) {
        ctx.putImageData(id, 0, 0);
        history.value = [id];
        historyIndex.value = 0;
      }
    }
  };

  return {
    history,
    addToHistory,
    initHistory,
    undo,
    redo,
    reset,
    undoAvailable,
    redoAvailable,
    resetAvailable,
  };
};
