export const createHelperHtml = '<div class="HelperBlock block">'
  + '<div class="HelperBlock_Header">'
  + '<div class="HelperBlock_Header-title">'
  + '<span>Создание контура поля</span>'
  + '</div>'
  + '<div class="HelperBlock_Header-toggleCompact">'
  + '</div>'
  + '</div>'
  + '<div class="HelperBlock-header">Клик на карту добавит точку контура</div>'
  + '<div class="HelperBlock-description mt-1">Определите контур требуемого поля, добавляя точки контура поля на карте. Ни одна сторона контура не должна пересекаться с любой другой стороной контура.</div>'
  + '<div class="HelperBlock-help mt-6">'
  + '<strong>Завершение контура.&nbsp;</strong>'
  + '<span>Для завершения создания контура из трех или более точек, кликните на последнюю точку контура или нажмите кнопку Enter.</span>'
  + '</div>'
  + '<div class="HelperBlock-help mt-6">'
  + '<strong>Удалить все точки.&nbsp;</strong>'
  + '<span>Чтобы удалить все точки и начать создание контура заново, нажмите кнопку Esc.</span>'
  + '</div></div>';

export const editHelperHtml = '<div class="HelperBlock block"> '
  + '<div class="HelperBlock_Header">'
  + '<div class="HelperBlock_Header-title">'
  + '<span>Редактирование контура поля</span>'
  + '</div>'

  + '</div>'
  + '<div class="HelperBlock-description mt-1">'
  + 'Определите контур требуемого поля, добавляя точки контура поля на карте. Ни одна сторона контура не должна пересекаться с любой другой стороной контура.'
  + '</div>'
  + '<div class="HelperBlock-help mt-6">'
  + '<strong>Перемещение точек контура.&nbsp;</strong>'
  + '<span>Точки контура можно переместить мышкой.</span>'
  + '</div>'
  + '<div class="HelperBlock-help mt-6">'
  + '<strong>Добавить точки контура.&nbsp;</strong>'
  + '<span>При клике на точку будут созданы точки в центре примыкающих сторон.</span>'
  + '</div>'
  + '<div class="HelperBlock-help mt-6">'
  + '<strong>Удалить точку контура.&nbsp;</strong>'
  + '<span>Для удаления лишней точки контура кликните на точку с зажатой Ctrl.</span>'
  + '</div>'
  + '</div>';
