import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import LoadingStatus from '@/services/loading/LoadingStatus';
import LoggerService from '@/services/logger/LoggerService';
import { useStruct } from '@/composables/useStruct';

export const fetcher = (loader: LoadingNamesEnum, loaderKey: string | number = 'default', force = false, callback: () => Promise<void>, ignoreStruct = false): Promise<void> => new Promise<void>((resolve, reject) => {
  const structId = useStruct().structId.value;

  const key = Object.keys(LoadingNamesEnum)[Object.keys(LoadingNamesEnum).length / 2 + loader];
  LoggerService.from(LoggerGroupsEnum.FETCHER_STATUS).group(
    'Try to run fetcher',
    `NAME: ${key}`,
    `KEY: ${loaderKey}`,
    `Is force fetch: ${force}`,
    `Is struct required: ${!ignoreStruct}`,
    `Struct ID: ${structId}`,
  );
  if (!ignoreStruct && structId === 0) {
    LoadingStatus.error(loader, 'Try to fetch without active struct!', loaderKey);
    reject(new Error('Try to fetch without active struct!'));
  } else if (LoadingStatus.isNone.value(loader, loaderKey) || force) {
    LoadingStatus.loading(loader, loaderKey);
    try {
      LoggerService.from(LoggerGroupsEnum.FETCHER_STATUS).group('Start fetching', `NAME: ${key}`);
      callback().then(() => {
        LoadingStatus.success(loader, loaderKey);
        LoggerService.from(LoggerGroupsEnum.FETCHER_STATUS).group('Successful fetch done.', `NAME: ${key}`);
        resolve();
      });
    } catch (e) {
      LoadingStatus.error(loader, e, loaderKey);
      LoggerService.from(LoggerGroupsEnum.FETCHER_STATUS).error('Get error while fetching.');
      reject(e);
    }
  } else {
    LoggerService.from(LoggerGroupsEnum.FETCHER_STATUS).log(`Fetch [${key}] is not required. Such fetch request is already fetching or fetched.`);
    resolve();
  }
});
