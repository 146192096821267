import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { BillingContractDto } from '@/services/api/dto/billing/BillingContractDto';
import { ChangelogLinkDto } from '@/services/api/dto/billing/ChangelogLinkDto';

export const endpoints = {
  structContract: (structId: number) => `/api/v1/billing/${structId}/contract/`,
  changeLogLinks: () => '/api/v1/billing/change-log/public/',
};

export class ApiBilling extends ApiHandler {
  public async getStructContract(structId: number): Promise<ApiResponse<BillingContractDto[]>> {
    return await this.request({ auth: true }).get<BillingContractDto[]>(endpoints.structContract(structId));
  }

  public async getChangelogLinks(): Promise<ApiResponse<ChangelogLinkDto[]>> {
    return await this.request().get<ChangelogLinkDto[]>(endpoints.changeLogLinks());
  }
}
