import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import { BlockConfigType } from '@/constants/types/BlockConfigType';
import { BlockNameType } from '@/constants/types/BlockNameType';

export const BlocksConfig: Record<BlockNameType, BlockConfigType> = {
  NavigationBlock: {
    height: 50,
    placement: MapLayoutAreaEnum.TOP,
  },
  StatusBarBlock: {
    placement: MapLayoutAreaEnum.BOTTOM,
    height: 30,
  },
  FieldsListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 250,
  },
  CropRotationContent: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 600,
  },
  AdminListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 250,
  },
  UserListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 300,
  },
  MapListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 300,
  },
  StructListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 300,
  },
  POIListBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 350,
  },
  FieldEditBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 400,
  },
  FieldDrawBlock: {
    width: 360,
    placement: MapLayoutAreaEnum.RIGHT,
  },
  MapEditBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 400,
  },
  UserEditBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 450,
  },
  StructEditBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 400,
  },
  ToolAreaBlock: {
    placement: MapLayoutAreaEnum.MAP_TOP_RIGHT,
  },
  ToolAutoIndexBlock: {
    placement: MapLayoutAreaEnum.MAP_LEFT_CENTER,
  },
  ToolRulerBlock: {
    placement: MapLayoutAreaEnum.MAP_TOP_RIGHT,
  },
  ToolMyLocationBlock: {
    placement: MapLayoutAreaEnum.MAP_TOP_RIGHT,
  },
  ToolStructLocationBlock: {
    placement: MapLayoutAreaEnum.MAP_RIGHT_CENTER,
  },
  ToolZoomBlock: {
    placement: MapLayoutAreaEnum.MAP_RIGHT_CENTER,
  },
  ToolLayersBlock: {
    placement: MapLayoutAreaEnum.MAP_TOP_LEFT,
  },
  ToolSearch: {
    placement: MapLayoutAreaEnum.MAP_TOP_LEFT,
  },
  Tool3DMapBlock: {
    placement: MapLayoutAreaEnum.MAP_RIGHT_CENTER,
  },

  POIGroupEditBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
  },
  POIAddBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 450,
  },
  POITableModal: {
    placement: MapLayoutAreaEnum.MODAL,
  },
  AddUserBlock: {
    width: 450,
    placement: MapLayoutAreaEnum.RIGHT,
  },
  AddStructBlock: {
    width: 360,
    placement: MapLayoutAreaEnum.RIGHT,
  },
  VectorDataBlock: {
    placement: MapLayoutAreaEnum.MODAL,
    width: 400,
  },
  SatelliteLeftBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  SatelliteProgressBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 350,
  },
  SatelliteEstimationBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 450,
  },
  SatelliteSearchBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 350,
  },
  LegendColorBlock: {
    placement: MapLayoutAreaEnum.MAP_LEFT_BOTTOM,
  },
  DrawerHelperBlock: {
    placement: MapLayoutAreaEnum.MAP_BOTTOM_CENTER,
  },
  ToolPaintVectorBlock: {
    placement: MapLayoutAreaEnum.MAP_TOP_LEFT,
  },
  CustomColorsBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 380,
  },
  AsfOrderBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  AsfOrderConfirmBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 380,
  },
  UserProfileBlock: {
    placement: MapLayoutAreaEnum.MODAL,
  },
  ToolUnderlaysBlock: {
    placement: MapLayoutAreaEnum.MAP_BOTTOM_RIGHT,
  },
  MapHoverVectorBlock: {
    placement: MapLayoutAreaEnum.MAP_WORKSPACE,
  },
  MonitoringFieldsBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  AboutProgramBlock: {
    placement: MapLayoutAreaEnum.MODAL,
    width: 1120,
  },
  ReliefViewBlock: {
    placement: MapLayoutAreaEnum.LEFT,
    width: 250,
  },
  ControlRasterLayersBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 360,
  },
  ScaleControlBlock: {
    placement: MapLayoutAreaEnum.MAP_LEFT_BOTTOM,
  },

  ViewTaskHistoryBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  ViewTasksFieldsBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 390,
  },
  MonitoringChartBlock: {
    placement: MapLayoutAreaEnum.MAP_CALENDAR,
    height: 310,
  },
  WeatherWidgetBlock: {
    placement: MapLayoutAreaEnum.WIDGETS,
  },
  FieldCropRateFieldsBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  FieldCropRateManageBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 360,
  },
  CropRotationModal: {
    placement: MapLayoutAreaEnum.MODAL,
  },
  Integration: {
    placement: MapLayoutAreaEnum.MODAL,
  },
  StructViewBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 600,
  },
  IntegrationModal: {
    placement: MapLayoutAreaEnum.MODAL,
  },
  ReceivedTaskFieldsBlock: {
    placement: MapLayoutAreaEnum.LEFT,
  },
  ReceivedTaskHistoryBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 390,
  },
  ConsoleLoggerBlock: {
    placement: MapLayoutAreaEnum.MODAL,
    width: 590,
  },

  SelectTaskMapBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 400,
  },
  TaskMapNormaFormBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 420,
  },
  TaskMapZoneFormBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 420,
  },
  ApiKeysBlock: {
    placement: MapLayoutAreaEnum.MODAL,
    width: 420,
  },
  RatingNdviBlock: {
    placement: MapLayoutAreaEnum.RIGHT,
    width: 500,
  },
};
