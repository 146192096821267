<template lang='pug' src='./MapContainer.pug'/>
<style lang='scss' src='./MapContainer.scss'/>

<script lang='ts'>
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerPropertyEnum } from '@/constants/enums/MapLayerPropertyEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { FieldModel } from '@/models/field/FieldModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapContainerModel } from '@/models/mapContainer/MapContainerModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import BaseMapEditor from '@/modules/map/container/BaseMapEditor/BaseMapEditor.vue';
import BaseMapPreview from '@/modules/map/container/BaseMapPreview/BaseMapPreview.vue';
import CalculateTool from '@/modules/map/container/CalculateTool.vue';
import MapLegend from '@/modules/map/container/MapLegend/MapLegend.vue';
import MapUnderlaysMenu from '@/modules/map/container/MapUnderlaysMenu.vue';
import ScaleControlBlock from '@/modules/map/container/ScaleControlBlock/ScaleControlBlock.vue';
import SidePanel from '@/modules/map/container/SidePanel/SidePanel.vue';
import ToolSearch from '@/modules/mapbox/ui/ToolSearch/ToolSearch.vue';
import PoiEvents from '@/modules/poi/PoiEvents';
import POIEditBlock from '@/modules/poi/ui/POIEditBlock/POIEditBlock.vue';
import TaskMapReportMapContainer
  from '@/modules/viewTask/ui/TaskMapReportMapContainer/TaskMapReportMapContainer.vue';
import EventBus from '@/services/eventBus/EventBus';
import LoadingStatus from '@/services/loading/LoadingStatus';
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch,
} from 'vue';
import ZoomControlBlock from '@/modules/map/container/ZoomControlBlock/ZoomControlBlock.vue';
import { PoiModel } from '@/models/poi/PoiModel';
import { MapLayerCursorModel } from '@/models/map/Layers/MapLayerCursorModel';

export default defineComponent({
  name: 'MapContainer',
  props: {
    id: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    fieldsFilter: {
      type: Function as PropType<(field: FieldModel) => boolean>,
      default: () => true,
    },
    showMonitoringMenu: {
      type: Boolean,
      default: true,
    },
    showHistoryMenu: {
      type: Boolean,
      default: true,
    },
    showTaskMapMenu: {
      type: Boolean,
      default: true,
    },
    showPOI: {
      type: Boolean,
      default: true,
    },
    showDownloadTask: {
      type: Boolean,
      default: true,
    },
    showCompare: {
      type: Boolean,
      default: false,
    },
    selectState: {
      type: String as PropType<'single' | 'multiple'>,
      default: 'single',
    },
  },
  components: {
    UiIcon,
    BaseMapPreview,
    BaseMapEditor,
    MapLegend,
    SidePanel,
    MapUnderlaysMenu,
    TaskMapReportMapContainer,
    CalculateTool,
    ScaleControlBlock,
    POIEditBlock,
    ToolSearch,
    ZoomControlBlock,
  },

  setup(props) {
    const { showMapLayerSettings } = useMapLayout();

    const {
      addContainer,
      compareMode,
      compareMap,
      mapModel,
      fitStruct,
      activeField,
      setActiveField,
      fields,
      selectedFields,
      selectState,
      toggleSelectedField,
      hoverField,
      setHoverField,
      addCompareMap,
      activePoi,
      informationMode,
      sidePanel,
      isDisabledField,
      additionalMap,
      isHelperActive,
      helper,
      toUserPosition,
      isFieldFocus,
      setHoverPoi,
      hoverPoi,
    } = useMapContainers(props.id);

    const { activeCandidate, step } = useCreateBaseMap();

    const containerRef = ref<HTMLElement | null>(null);

    const shufflerButtonRef = ref<HTMLElement | null>(null);

    const sidePanelWidth = ref(300);
    const searchValue = ref('');
    const rotate = ref(0);

    const showFocus = ref({
      progress: -1,
      interval: null,
      timeout: null,
      field: {
        id: null,
        name: null,
      },
    });

    const updateFields = () => {
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      if (layer) {
        layer.data.update(fields.value);
        layer.redrawFields();
      }
      if (compareMap.value) {
        (compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel).data.update(fields.value);
        (compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel).redrawFields();
      }
    };

    onMounted(async () => {
      const containerModel = new MapContainerModel(props.id, props);

      await addContainer(containerModel).then(() => {
        compareMap.value?.map?.resize();
        updateFields();
      });
      await LoadingStatus.awaitLoad(LoadingNamesEnum.FIELDS_LIST);
      fitStruct();

      mapModel.value?.map.on('rotate', (e) => {
        rotate.value = mapModel.value.map.getBearing();
      });
    });

    watch(fields, updateFields);

    const isLayerSettingsDisabled = computed(() => !mapModel.value?.config.some((c) => c.properties.includes(MapLayerPropertyEnum.SETTINGS)));

    watch(compareMode, () => {
      if (compareMode.value === 'single') {
        if (compareMap.value?.uuid) {
          compareMap.value?.removeMap();
          compareMap.value = undefined;
        }
      } else if (compareMap.value === undefined) {
        addCompareMap();
      }
    });

    // region resize event and shuffle
    const shuffleData = ref({
      left: 200,
      active: false,
      clientX: 0,
      startLeft: 0,
    });

    const onShufflerStart = (event: MouseEvent) => {
      shuffleData.value.clientX = event.clientX;
      shuffleData.value.startLeft = shuffleData.value.left;
      shuffleData.value.active = true;
    };

    window.document.addEventListener('mouseup', () => {
      shuffleData.value.active = false;
    });

    const onShufflerMove = (event: MouseEvent) => {
      if (shuffleData.value.active) {
        shuffleData.value.left = shuffleData.value.startLeft + event.clientX - shuffleData.value.clientX;
      }
    };

    const compareBeforeStyle = computed(() => (compareMode.value === 'shuffle' ? `clip-path: polygon(0px 0%,  ${shuffleData.value.left}px 0,  ${shuffleData.value.left}px 100%, 0px 100%)` : ''));

    const compareAddonBeforeStyle = computed(() => (compareMode.value === 'shuffle' ? `clip-path: polygon(0 0, v 0, ${shuffleData.value.left}px 100%, 0 100%)` : ''));

    const compareAfterStyle = computed(() => {
      if (additionalMap.value !== undefined) {
        return { left: 'calc(50% - 2px)', width: 'calc(50% - 2px)' };
      }
      return compareMode.value === 'shuffle' ? `clip-path: polygon(${shuffleData.value.left}px 0%, 100% 0, 100% 100%, ${shuffleData.value.left}px 100%)` : '';
    });

    const containerWidth = ref(0);

    const resizeMaps = () => {
      setTimeout(() => {
        if (compareMode.value !== 'single') {
          compareMap.value?.map?.resize();
        }
        mapModel.value?.map?.resize();
      });
    };

    window.addEventListener('resize', resizeMaps);

    watch(containerWidth, (w) => {
      shuffleData.value.left = w / 2 - 2;
      resizeMaps();
    });

    watch(compareMode, () => {
      resizeMaps();
    });

    const resizeInterval = setInterval(() => {
      if (containerWidth.value !== containerRef.value?.offsetWidth) {
        containerWidth.value = containerRef.value?.offsetWidth || 0;
      }
    }, 500);

    onUnmounted(() => {
      clearInterval(resizeInterval);
    });
    // endregion

    FieldsEvents.onClick((id, container) => {
      if (container.startsWith(props.id) && !isDisabledField(id)) {
        if (isFieldFocus.value) {
          if (activeField.value?.id !== id) {
            const field = fields.value.find((v) => v.id === Number(id));
            showFocus.value.field = {
              id,
              name: field.name,
            };
            showFocus.value.progress = 0;

            if (showFocus.value.interval) clearInterval(showFocus.value.interval);
            if (showFocus.value.timeout) clearTimeout(showFocus.value.timeout);

            showFocus.value.interval = setInterval(() => {
              showFocus.value.progress += 10;
            }, 500);
            showFocus.value.timeout = setTimeout(() => {
              clearInterval(showFocus.value.interval);
              showFocus.value.progress = -1;
            }, 5000);
          }
        } else if (selectState.value === 'single') {
          if (activeField.value?.id !== id) {
            setActiveField(id);
          } else {
            informationMode.value = 'field';
          }
        } else if (selectState.value === 'multiple') {
          toggleSelectedField(id);
        }
      }
    });

    EventBus.$on(EventsEnum.HoverField, (container: MapContainerEnum, id: string | number | undefined) => {
      if (container === props.id) {
        if ((hoverField.value?.id || 0) !== id) {
          const fieldLayer = mapModel.value.getLayer(MapLayerTypeEnum.FIELDS);
          const field = fields.value.find((v) => v.id === Number(id));
          setHoverField(id);

           mapModel.value.map?.setLayoutProperty(`${fieldLayer.layerId || ''}-contour`, 'line-sort-key', ['case',
             ['==', ['get', 'uuid'], field?.dto.properties.uuid || null], 1,
             ['==', ['get', 'id'], activeField.value?.id || null], 2,
             0,
           ]);
        }
      }
    });

    EventBus.$on(EventsEnum.HoverPoi, (container: MapContainerEnum, id: string | number | undefined) => {
      if (container === props.id) {
        if ((hoverPoi.value?.id || 0) !== id) {
          setHoverPoi(id);
        }
      }
    });

    watch(activeField, (a: FieldModel | undefined, b: FieldModel | undefined) => {
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      const compareLayer = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      if (layer) {
        if (b) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: b?.id }, { active: false });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: b?.id }, { active: false });
        }
        if (a) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: a?.id }, { active: true });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: a?.id }, { active: true });
        }
      }
    });

    watch(hoverField, (a: FieldModel | undefined, b: FieldModel | undefined) => {
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      const compareLayer = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;

      if (layer) {
        if (b) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: b?.id }, { hover: false });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: b?.id }, { hover: false });
        }
        if (a) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: a?.id }, { hover: true });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: a?.id }, { hover: true });
        }
      }
    });

    watch(hoverPoi, (a: PoiModel | undefined, b: PoiModel | undefined) => {
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.POI);
      const compareLayer = compareMap.value?.getLayer(MapLayerTypeEnum.POI);

      if (layer) {
        if (b) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: b?.id }, { hover: false });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: b?.id }, { hover: false });
        }
        if (a) {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: a?.id }, { hover: true });
          compareLayer && compareMap.value?.map?.setFeatureState({ source: compareLayer.sourceId, id: a?.id }, { hover: true });
        }
      }
    });

    watch(selectedFields, (a) => {
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      if (layer) {
        fields.value.forEach((f: FieldModel) => {
          mapModel.value?.map?.setFeatureState({ source: layer.sourceId, id: f.id }, { selected: selectedFields.value.findIndex((v) => v.id === f.id) !== -1 });
        });
      }
    }, {
      deep: true,
    });

    PoiEvents.onClick((poiFeature, container) => {
      if (mapModel.value?.container === container) {
        if (informationMode.value !== 'poi' && activePoi.value?.id === poiFeature.id) {
          informationMode.value = 'poi';
        }
      }
    });

    // endregion
    const rotateZero = () => {
      mapModel.value.map.easeTo({
        pitch: 0,
        bearing: 0,
        duration: 500,
      });
    };
    const zoomIn = () => {
      mapModel.value.map.easeTo({
        zoom: (mapModel.value.map.getZoom() as number) + 1,
        duration: 500,
        essential: true,
      });
    };

    const zoomOut = () => {
      mapModel.value.map.easeTo({
        zoom: (mapModel.value.map.getZoom() as number) - 1,
        duration: 500,
        essential: true,
      });
    };

    const compassStyleRotate = computed(() => ({
      transform: `rotate(${(rotate.value)}deg)`,
    }));

    const focusCancel = () => {
      clearInterval(showFocus.value.interval);
      clearTimeout(showFocus.value.timeout);
      showFocus.value.progress = -1;
    };

    const focusConfirm = () => {
      focusCancel();
      setActiveField(showFocus.value.field.id);
    };

    const toggleCursor = (mapCursor: MapLayerCursorModel | undefined, active: boolean) => {
      if (compareMode.value === 'double' && mapCursor !== undefined) {
        mapCursor.setActive(active);
      }
    };

    const mouseEnterCompare = () => {
      toggleCursor(mapModel.value?.cursor, true);
      toggleCursor(compareMap.value?.cursor, false);
    };
    const mouseEnterMain = () => {
      toggleCursor(compareMap.value?.cursor, true);
      toggleCursor(mapModel.value?.cursor, false);
    };
    const mouseLeave = () => {
      toggleCursor(mapModel.value?.cursor, false);
      toggleCursor(compareMap.value?.cursor, false);
    };

    const mouseLeaveCompare = () => {
      toggleCursor(compareMap.value?.cursor, true);
      toggleCursor(mapModel.value?.cursor, false);
    };

    // watch(compareMode, (a) => {
    //   if (a === 'double') {
    //     compareMap.value.map.on('mouseenter', mouseEnterCompare);
    //     toggleCursor(mapModel.value?.cursor, false);
    //   }
    // });

    return {
      activeField,
      showMapLayerSettings,
      isLayerSettingsDisabled,
      compareMode,
      compareMap,
      onShufflerStart,
      onShufflerMove,
      activeCandidate,
      shuffleData,
      containerRef,
      shufflerButtonRef,
      compareBeforeStyle,
      compareAfterStyle,
      mapModel,
      fields,
      compareAddonBeforeStyle,
      sidePanelWidth,
      sidePanel,
      additionalMap,
      fitStruct,
      rotateZero,
      isHelperActive,
      helper,
      searchValue,
      zoomIn,
      zoomOut,
      compassStyleRotate,
      toUserPosition,
      step,
      showFocus,
      focusCancel,
      focusConfirm,
      mouseEnterCompare,
      mouseEnterMain,
      mouseLeave,
      mouseLeaveCompare,
    };
  },
});
</script>
