import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { SystemStatusDto } from '@/services/api/dto/etc/SystemStatusDto';
import { SimpleType } from '@/constants/types/BasedTypes';
import { TableDto } from '@/services/api/dto/etc/TableDto';

export const endpoints = {
  systemStatus: () => '/api/v1/etc/system/status/',
  postTable: () => '/api/v1/etc/tools/table/',
};

export class ApiEtc extends ApiHandler {
  public async systemStatus(): Promise<ApiResponse<SystemStatusDto>> {
    return await this.request({ ignoreLog: true }).get<SystemStatusDto>(endpoints.systemStatus());
  }

  public async postTable(data: {
    // eslint-disable-next-line camelcase
    file_format: 'csv'| 'xlsx' |'html',
    headers: string[],
    rows: SimpleType[][],
    style: Record<string, string>,

  }): Promise<ApiResponse<string>> {
    return await this.request({ auth: true, ignoreLog: true }).post<string>(endpoints.postTable(), data);
  }
}
