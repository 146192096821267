import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import LoggerService from '@/services/logger/LoggerService';
import { Router } from 'vue-router';

export const loggerMiddleware = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    if (!from.name) {
      LoggerService.from(LoggerGroupsEnum.ROUTER).log(`{ name: ${String(to.name)} } "${to.fullPath}"`);
    } else {
      LoggerService.from(LoggerGroupsEnum.ROUTER).log(`{ name: ${String(from.name)} } "${from.fullPath}" => { name: ${String(to.name)} } "${to.fullPath}"`);
    }
    next();
  });
};
