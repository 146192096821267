import { GradientDto } from '@/services/assets/dto/GradientDto';

export class GradientModel {
  public readonly id: string;

  private readonly rev: string;

  public readonly unit: number;

  public name: string;

  public positions: { position: number, color: string }[];

  constructor(data: GradientDto) {
    this.id = data._id;
    this.rev = data._rev;
    this.name = data.name;
    this.unit = data.unit;

    this.positions = data.positions;
  }
}
