import { Bus } from '@/lib/classes/Bus';

enum Events {
  CHANGE_STRUCT_UNIT,
  CHANGE_STRUCT_YIELD_UNIT
}

class StructEvents extends Bus {
  onChangeStructUnit(callback: (structId: number | undefined, beforeStructId: number | undefined) => void, once = false) {
    this.registerListener(Events.CHANGE_STRUCT_UNIT, callback, once);
  }

  offChangeStructUnit(callback: (structId: number | undefined, beforeStructId: number | undefined) => void) {
    this.unregisterListener(Events.CHANGE_STRUCT_UNIT, callback);
  }

  emitChangeStructUnit(structId: number | undefined, beforeStructId: number | undefined = undefined) {
    this.emitListeners(Events.CHANGE_STRUCT_UNIT, structId, beforeStructId);
  }

  onChangeStructYieldUnit(callback: () => void, once = false) {
    this.registerListener(Events.CHANGE_STRUCT_YIELD_UNIT, callback, once);
  }

  offChangeStructYieldUnit(callback: () => void) {
    this.unregisterListener(Events.CHANGE_STRUCT_YIELD_UNIT, callback);
  }

  emitChangeStructYieldUnit() {
    this.emitListeners(Events.CHANGE_STRUCT_YIELD_UNIT);
  }
}

export default new StructEvents();
