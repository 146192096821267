<template lang="pug" src="./MapHoverRasterValue.pug"/>
<style lang="scss" src="./MapHoverRasterValue.scss"/>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useUser } from '@/composables/useUser';
import { useMapHover } from '@/composables/useMapHover';

export default defineComponent({
  name: 'MapHoverRasterValue',
  components: {},
  setup() {
    const { user } = useUser();
    const { rasterValue, color } = useMapHover();
    const valueComputed = computed(() => rasterValue.value.filter((r) => r?.band_values[0].value !== 0 && r?.band_values.length === 1));
    return {
      user,
      rasterValue,
      valueComputed,
      color,
    };
  },
});
</script>
