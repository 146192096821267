import { CropRateDto } from '@/services/api/dto/cropRate/CropRateDto';
import { CropRateColors } from '@/assets/data/CropRateColors';

export class CropRateModel {
  get color(): string {
    return this._color;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get cropItem(): number {
    return this._cropItem;
  }

  get rate(): number {
    return this._rate;
  }

  private _id: number;

  private _name: string;

  private _cropItem: number;

  private _rate: number

  private _createDate: Date;

  private _updateDate: Date;

  private _color: string;

  constructor(dto: CropRateDto) {
    this._id = dto.id;
    this._name = dto.name;
    this._cropItem = dto.crop_item;

    this._createDate = new Date(dto.create_date);
    this._updateDate = new Date(dto.update_date);

    this._rate = Number(dto.rating);

    this._color = CropRateColors[this._rate] || '#999999';
  }
}
