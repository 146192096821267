/**
 * Используем только как parent класс для индексов
 */
import Coordinates from '@/constants/types/mapbox/Coordinates';
import { getCorrectCoordinates } from '@/lib/convertors/getCorrectCoordinates';
import { Model } from '@/models/Model';
import {
  SatelliteFieldIndexFileDto,
} from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';

export class FieldIndexModel extends Model {
  get statistics(): Record<string, any> | undefined {
    return this._statistics;
  }

  get colormap(): number | undefined {
    return this._colormap;
  }

  get tiffColoredFile(): string | undefined {
    return this._tiffColoredFile;
  }

  get tiffFile(): string | undefined {
    return this._tiffFile;
  }

  get imgFile(): string | undefined {
    return this._imgFile;
  }

  get imgContrastFile(): string | undefined {
    return this._imgContrastFile;
  }

  get imgColoredFile(): string | undefined {
    return this._imgColoredFile;
  }

  get geomCoordinates(): Coordinates | undefined {
    return this._geomCoordinates;
  }

  get updatedAt(): Date | undefined {
    return this._updatedAt;
  }

  get createdAt(): Date | undefined {
    return this._createdAt;
  }

  get kind(): number {
    return this._kind;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  public showContrast = false;

  protected _id = 0;

  protected _name = '';

  protected _kind = 0;

  protected _createdAt: Date | undefined;

  protected _updatedAt: Date | undefined;

  protected _geomCoordinates: Coordinates | undefined;

  private _statistics: Record<string, any> | undefined

  /** webp индекса в раскраске */
  protected _imgColoredFile: string | undefined;

  /** webp индекса в контрастной раскраске */
  protected _imgContrastFile: string | undefined;

  /**  webp черно-белого изображения (но не для MSF) */
  protected _imgFile: string | undefined;

  /** tif одноканальный (чб) индекс (оригинал) */
  protected _tiffFile: string | undefined;

  /** tif трехканальный RGB индекс (не используется вроде) */
  protected _tiffColoredFile: string | undefined;

  private _colormap: number | undefined

  constructor(dto: SatelliteFieldIndexFileDto) {
    super();
    this._id = Number(dto.id);
    this._name = dto.index_name;
    this._kind = dto.kind;
    this._colormap = dto.colormap;
    if (dto.create_date) {
      this._createdAt = new Date(dto.create_date);
    }

    if (dto.update_date) {
      this._updatedAt = new Date(dto.update_date);
    }

    this._geomCoordinates = getCorrectCoordinates(dto.geom_envelope.coordinates[0]);

    this._imgColoredFile = dto.img_colored_file || undefined;

    this._imgFile = dto.img_file || undefined;

    this._imgContrastFile = dto.img_contrast_file || undefined;

    this._tiffFile = dto.tif_file || undefined;

    this._tiffColoredFile = dto.tif_colored_file || undefined;

    this._statistics = dto.statistics;
  }
}
