import { useBilling } from '@/composables/useBilling';
import { BillingContractModel } from '@/models/BillingContractModel';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';

class BillingService {
  async fetchStructBilling() {
    if (StructList.activeStruct.value) {
      try {
        const { data } = await ApiService.billing.getStructContract(StructList.activeStruct.value?.id);
        if (data.length) {
          useBilling().contract.value = new BillingContractModel(data[0]);
        } else {
          useBilling().contract.value = undefined;
        }
      } catch (e) {
        useBilling().contract.value = undefined;
      }
    }
  }
}

export default new BillingService();
