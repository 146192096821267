import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import LoggerService from '@/services/logger/LoggerService';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { StorageValuesType } from '@/constants/types/storage/StorageTypes';
import { StorageDto } from '@/services/api/dto/user/StorageDto';
import { StorageMaxSize } from '@/assets/data/StorageMaxSize';

import { useStorageStruct } from '@/composables/useStorageStruct';
import StructList from '@/modules/struct/StructList';
import { fetcher } from '@/lib/tools/fetcher';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

class StorageStructService {
  async fetchStorage() {
    await fetcher(LoadingNamesEnum.STORAGE_STRUCT, 'default', true, async () => {
      if (StructList.activeStruct.value?.id) {
        useStorageStruct().cleanStorage();
        const { data } = await ApiService.struct.getStorage(StructList.activeStruct.value?.id || 0);
        data.forEach((dto: StorageDto) => {
          if (Object.values(StorageKeyEnum).includes(dto.key) || dto.key.split('_')[0] === 'palette') {
            const body: StorageValuesType = JSON.parse(dto.body.toString()) as StorageValuesType;
            const key: StorageKeyEnum = dto.key;
            useStorageStruct().replaceItem(key, body);
          }
        });
        EventBus.$emit(EventsEnum.StorageLoaded);
      }
    });
  }

  async setItem(key: StorageKeyEnum, body: StorageValuesType) {
    const zip: string = JSON.stringify(body, null, '');
    try {
      await ApiService.struct.postStorage({ key, body: zip }, StructList.activeStruct.value?.id || 0);
      useStorageStruct().replaceItem(key, body);
      EventBus.$emit(EventsEnum.UpdateStorage);
    } catch (e) {
      LoggerService.error(e);
    }
  }

  async removeItem(key: StorageKeyEnum) {
    await ApiService.struct.deleteStorageKey(key, StructList.activeStruct.value?.id || 0);
    if (useStorageStruct().hasItem(key as StorageKeyEnum)) {
      useStorageStruct().deleteItem(key as StorageKeyEnum);
    }
    EventBus.$emit(EventsEnum.UpdateStorage);
  }

  async updateItem(key: StorageKeyEnum, body: any) {
    const zip: string = JSON.stringify(body, null, '');
    ApiService.struct.patchStorage(key, zip, StructList.activeStruct.value?.id || 0).then(() => {
      useStorageStruct().replaceItem(key as StorageKeyEnum, body);
      EventBus.$emit(EventsEnum.UpdateStorage);
    });
  }

  itCanSave(object: StorageValuesType) {
    return JSON.stringify(object, null, '').length < StorageMaxSize;
  }
}
export default new StorageStructService();
