import axios from 'axios';
import LoggerService from '@/services/logger/LoggerService';

class MyTrackerService {
  private client;

  constructor() {
    this.client = axios.create({
      baseURL: `https://services.truefields.ru/${process.env.VUE_APP_SERVER_LABEL}/amplitude/2/`,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    });
  }

  send(title: string, params: Record<string, string> = {}) {
    let isCorrect = true;
    Object.keys(params).forEach((key) => {
      if (key.length > 255) {
        LoggerService.error(`Try to send event to MyTracker with KEY length greater than 255 symbols: ${key} -> ${params[key]}`);
        isCorrect = false;
      }
      if (params[key].length > 255) {
        LoggerService.error(`Try to send event to MyTracker with VALUE length greater than 255 symbols: ${key} -> ${params[key]}`);
        isCorrect = false;
      }
    });
    if (isCorrect) {
      const id = window.sessionStorage.getItem('userId');
      const firstName = window.sessionStorage.getItem('userFirstName');
      const lastName = window.sessionStorage.getItem('userLastName');
      const email = window.sessionStorage.getItem('userEmail');

      const domain = (new URL(window.location.href));

      try {
        this.client.post(
          'httpapi',
          {
            events: [{
              device_id: `[${id}]: ${firstName} ${lastName} <${email}>`,
              event_type: title,
              event_properties: params,
              app_version: `${domain.host.replace('www.', '')}:${process.env.VUE_APP_MAJOR_VERSION}.${process.env.VUE_APP_MINOR_VERSION}.${process.env.VUE_APP_BUILD_VERSION}`,
            }],
          },
        );
      } catch (e) {
        LoggerService.error(e);
      }
    }
  }
}

export default new MyTrackerService();
