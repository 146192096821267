import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { CropModel } from '@/models/crop/CropModel';
import { FieldModel } from '@/models/field/FieldModel';
import { MapStructViewEvents } from '@/models/map/Events/MapStructViewEvents';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import CropRotationList from '@/modules/cropRotation/CropRotationList';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import {
  Feature, FeatureCollection, GeoJsonProperties, Geometry,
} from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';

type ModesType = 'rating' | 'ndvi' | 'crop-rotation' | undefined

export class MapLayerStructViewModel extends MapLayerModel {
  get mode(): ModesType {
    return this._mode;
  }

  set mode(value: ModesType) {
    this._mode = value;
    this.redraw();
  }

  private _mode: ModesType = 'crop-rotation';

  private _fillCropModel: CropModel | undefined;

  constructor(mapModel: MapModel) {
    super(mapModel, MapLayerTypeEnum.STRUCT_VIEW, 'crop-rotation', 'crop-rotation');
    this.createSource();
    this.createLayer();
    FieldsEvents.onFeatureUpdated(() => {
      this.redraw();
    });
    // eslint-disable-next-line no-new
    new MapStructViewEvents(this, mapModel);
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  redraw() {
    const source = (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource);
    if (source) {
      source.setData(this.calculateFeatureCollection());
    }
  }

  fillCropModel = () => this._fillCropModel

  toggleFillCropModel = (crop: CropModel) => {
    if (this._fillCropModel?.id === crop.id) {
      this._fillCropModel = undefined;
    } else {
      this._fillCropModel = crop;
    }
    this.redraw();
  }

  calculateFeatureCollection = () => {
    const acc: FeatureCollection = {
      type: 'FeatureCollection',
      features: (this._mapModel?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel).data.collection.map((field: FieldModel) => {
        const rotation = CropRotationList.getFieldData(field.id);
        // eslint-disable-next-line no-nested-ternary
        const color: string = this._fillCropModel?.color ? this._fillCropModel.color : (this._fillCropModel?.id === rotation?.cropItem.id && rotation ? rotation.cropItem.color : '#FFFFFF');
        return {
          type: 'Feature',
          properties: {
            ...field.feature.properties,
            color,
            mode: this._mode || 'none',
          } as GeoJsonProperties,
        };
      }) as Feature<Geometry, GeoJsonProperties>[],
    };

    return acc;
  }

  createSource=() => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.calculateFeatureCollection(),
    });
  }

  createLayer = () => {
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['==', ['get', 'mode'], 'rating'], ['case',
            ['==', ['get', 'rating'], 'A'], '#15a203',
            ['==', ['get', 'rating'], 'B'], '#ffd900',
            ['==', ['get', 'rating'], 'C'], '#ff0000',
            '#FFFFFF',
          ],
          ['==', ['get', 'mode'], 'crop-rotation'], ['get', 'color'],
          ['==', ['get', 'mode'], 'ndvi'], ['case',
            ['>=', ['get', 'avg_ndvi'], 0.7], '#044804',
            ['>=', ['get', 'avg_ndvi'], 0.6], 'rgba(4, 96, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.5], 'rgba(28, 114, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.45], 'rgba(60, 134, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.4], 'rgba(84, 150, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.35], 'rgba(100, 162, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.3], 'rgba(116, 170, 4, 1)',
            ['>=', ['get', 'avg_ndvi'], 0.2], 'rgba(148, 182, 20, 1)',
            ['<', ['get', 'avg_ndvi'], 0.2], 'rgba(205, 215, 17, 1)',
            ['==', ['get', 'avg_ndvi'], 0], 'rgb(255,16,16)',
            '#F73D3D',
          ],
          '#FFFFFF',
        ],
        'fill-opacity': [
          'case',
          ['==', ['get', 'mode'], 'crop-rotation'], ['case', ['has', 'color'], 1, 0],
          ['==', ['get', 'mode'], 'rating'], ['case', ['has', 'rating'], 1, 0],
          ['==', ['get', 'mode'], 'ndvi'], ['case', ['has', 'avg_ndvi'], 1, 0],
          0,
        ],
      },

    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.STRUCT_VIEW);
  }
}
