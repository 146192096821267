import Coordinates from '@/constants/types/mapbox/Coordinates';
import { ImageSourceSpecification, LayerSpecification } from 'mapbox-gl';

export const indexSourceDef = (url: string, coordinates: Coordinates): ImageSourceSpecification => ({
  type: 'image',
  url,
  coordinates,
});

export const indexLayerDef = (sourceId: string, layerId: string): LayerSpecification => ({
  id: layerId,
  source: sourceId,
  type: 'raster',
});
