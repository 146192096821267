import { isObject } from '@/utils/isObject';

export function freezeObject(obj: any, options = { deep: true }): any {
  if (!isObject(obj)) return obj;

  if (options.deep) {
    const propNames = Object.getOwnPropertyNames(obj);

    propNames.forEach((name) => {
      const prop = obj[name];

      if (isObject(prop)) {
        freezeObject(prop);
      }
    });
  }

  return Object.freeze(obj);
}
