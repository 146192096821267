import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { TaskMapBaseDto } from '@/services/api/dto/taskMap/TaskMapBaseDto';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

export class FieldTaskMapBaseModel extends FieldTaskMapModel {
  get candidate(): number {
    return this._candidate;
  }

  get isExperiment(): boolean {
    return this._isExperiment;
  }

  private _candidate: number;

  private _isExperiment: boolean;

  constructor(dto: TaskMapBaseDto) {
    super(dto);
    this._candidate = dto.candidate;
    this._isExperiment = dto.is_experiment;
  }

  async delete() {
    await ApiService.taskMap.deleteTaskMap('base', this.id);
  }

  async fetchData(): Promise<void> {
    if (!this.geojson) {
      await fetcher(LoadingNamesEnum.TASK_GEOJSON, this.id, false, async () => {
        const { data } = await ApiService.taskMap.getData('base', this.id);
        this.setGeoJson(data.geojson);
      });
    }
  }

  setSelected(value: boolean): void {
    if (value) {
      EventBus.$emit(EventsEnum.SetSelectedTaskMap, this);
    }
    super.setSelected(value);
  }
}
