<template src="./AuthLayout.pug" lang="pug" />
<style src="./AuthLayout.scss" lang="scss" />

<script lang="ts">
import { TolgeeProvider } from '@tolgee/vue';
import { defineComponent, onMounted } from 'vue';
import { useApp } from '@/composables/useApp';

export default defineComponent({
  components: { TolgeeProvider },
  setup() {
    onMounted(() => {
      useApp().setLoadingScreen('app-loading');
    });
  },
});

</script>
