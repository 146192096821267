import { computed, ref } from 'vue';
import { CreateTaskMapModeEnum } from '@/constants/enums/CreateTaskMapModeEnum';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';

const _activeModeCreateTask = ref<CreateTaskMapModeEnum>(0);
const _stepCreateTask = ref(0);

const _activeNirFile = ref<FieldNirModel>();
const _activeIndex = ref<FileIndexKindEnums>(FileIndexKindEnums.NDVI);
const _selectBaseTaskMaps = ref<Array<FieldTaskMapBaseModel>>([]);

export const useTaskMap = () => {
  const activeNirFile = computed({
    get: () => _activeNirFile.value,
    set: (s) => {
      _activeNirFile.value = s;
    },
  });

  const activeModeCreateTask = computed({
    get: () => _activeModeCreateTask.value,
    set: (s) => {
      _activeModeCreateTask.value = s;
    },
  });
  const stepCreateTask = computed({
    get: () => _stepCreateTask.value,
    set: (s) => {
      _stepCreateTask.value = s;
    },
  });

  const activeIndex = computed({
    get: () => _activeIndex.value,
    set: (s) => {
      _activeIndex.value = s;
    },
  });

  const selectBaseTaskMaps = computed({
    get: () => _selectBaseTaskMaps.value,
    set: (s) => {
      _selectBaseTaskMaps.value = s;
    },
  });

  return {
    activeModeCreateTask,
    stepCreateTask,
    activeNirFile,
    activeIndex,
    selectBaseTaskMaps,
  };
};
