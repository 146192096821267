import { EventBusEventType, EventBusMapType, EventNameType } from '@/constants/types/EventBusTypes';

export class Bus {
  protected eventListeners: EventBusMapType;

  constructor() {
    /**
     * @type {Map<EventNameType, Array<{ callback: Function, once: boolean }>>}
     */
    this.eventListeners = new Map();
  }

  /**
   * Добавить слушателя на определенное событие
   * @param {EventNameType} eventName
   * @param {Function} callback
   * @param {boolean} [once]
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerListener(eventName: EventNameType, callback: Function, once = false) {
    if (!this.eventListeners.has(eventName)) {
      this.eventListeners.set(eventName, []);
    }

    const eventListeners: EventBusEventType[] = this.eventListeners.get(eventName) || [];
    eventListeners.push({ callback, once });
  }

  /**
   * Удалить слушателей/слушателя с определенного события
   * @param eventNameOrNames
   * @param callback
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  unregisterListener(eventNameOrNames: EventNameType | EventNameType[], callback: Function | undefined = undefined) {
    const eventNames = Array.isArray(eventNameOrNames) ? eventNameOrNames : [eventNameOrNames];

    eventNames.forEach((eventName) => {
      const eventListeners = this.eventListeners.get(eventName);

      if (eventListeners === undefined) {
        return;
      }

      if (callback === undefined) {
        this.eventListeners.delete(eventName);
      } else {
        for (let i = eventListeners.length - 1; i >= 0; i--) {
          if (eventListeners[i].callback === callback) {
            eventListeners.splice(i, 1);
          }
        }
      }
    });
  }

  /**
   * Выполнить callback функции подписавшихся слушателей
   * @param eventName
   * @param args
   */
  emitListeners(eventName: EventNameType, ...args: any[]) {
    const eventListeners = this.eventListeners.get(eventName);
    if (eventListeners) {
      const eventListenerToDelete: EventBusEventType[] = [];
      eventListeners.forEach((event) => {
        event.callback(...args);
        if (event.once) {
          eventListenerToDelete.push(event);
        }
      });

      eventListenerToDelete.forEach((event) => {
        eventListeners.splice(eventListeners.indexOf(event), 1);
      });
    }
  }
}
