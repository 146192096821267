<template src="./WindowBox.pug" lang="pug"/>
<style src="./WindowBox.scss" lang="scss"/>

<script lang="ts">
import { Close } from '@element-plus/icons-vue';
import UiButton from '@/components/ui/Button/UiButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WindowBox',
  props: {
    shaded: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  components: {
    UiButton,
  },

  setup() {
    return {
      Close,
    };
  },
});
</script>
