<template lang="pug" src="./MDI.pug"/>
<style lang="scss" src="./MDI.scss"/>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import SvgIcon from '@jamescoyle/vue-icon';
import * as mdi from '@mdi/js';

export default defineComponent({
  name: 'MDI',
  components: { SvgIcon },
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: '#ff0000',
    },
  },
  setup() {
    return {
      mdi,
    };
  },
});
</script>
