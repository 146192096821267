import { pythonRound } from '@/utils/pythonRound';

type ModeType = 'g-short' | 'g-w' | 'w' | 'm';

/**
 * Calculates the square of a given number.
 *
 * @param {number} sq - The number to calculate the square of.
 * @param {string} mode - The mode to apply to the square calculation. (Optional, default: 'w')
 *                        Accepted values are 'g-short', 'g-w', 'w', and 'm'.
 * @returns {number} - The calculated square.
 */
export const fieldSquare = (sq: number, mode: ModeType = 'w'): number => {
  const modeOperations = {
    'g-short': () => pythonRound(sq),
    'g-w': () => pythonRound(sq, 2),
    w: () => pythonRound(sq * 100),
    m: () => pythonRound(sq * 100 * 100),
  };

  return modeOperations[mode] ? modeOperations[mode]() : Math.round(sq);
};
