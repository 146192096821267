export enum SatelliteTaskStageEnums {
  UNKNOWN = 0,
  SEARCH_RGB = 100, // Поиск снимков
  DOWNLOAD_QUICKLOOK = 200, // Скачивание миниатюр
  DOWNLOAD_RGB = 300, // Скачивание снимков
  CROP_RGB = 400, // Формирование RGB представления
  ESTIMATIONS = 500, // Автоматическая оцена снимков
  INDEXES = 600, // формирование индексов
  NDVI = 610, // формирование NDVI
  MSF = 620, // Формирование MSF
  MERGE = 700, // Формирование общего индекса
  MERGE_NDVI = 710, // Формирование общего NDVI
  MERGE_MSF = 720, // Формирование общего MSF
  USER_INPUT = 1000, // Пользовательский ввод
}
