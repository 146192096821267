import ApiService from '@/services/api/ApiService';
import StructList from '@/modules/struct/StructList';
import { PoiModel } from '@/models/poi/PoiModel';
import {
  computed, reactive, Ref, ref, UnwrapNestedRefs,
} from 'vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { PoiGroupModel } from '@/models/poi/PoiGroupModel';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import StructEvents from '@/modules/struct/StructEvents';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { PoiCollection } from '@/collection/PoiCollection';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';

class PoisList {
  get collection(): UnwrapNestedRefs<PoiCollection> {
    return this._collection;
  }

  set collection(value: UnwrapNestedRefs<PoiCollection>) {
    this._collection = value;
  }

  get activeGroup(): Ref<PoiGroupModel | undefined> {
    return this._activeGroup;
  }

  private _pois = ref<PoiModel[]>([]);

  private _groups = ref<PoiGroupModel[]>([]);

  groups = computed(() => this._groups.value.filter((g) => g.farmunit === StructList.activeStruct.value?.id));

  pois = computed(() => this._pois.value.filter((poi) => poi.farmunit === StructList.activeStruct.value?.id));

  private _activePoi = ref<PoiModel>()

  private _activeGroup = ref<PoiGroupModel>()

  private _collection = reactive<PoiCollection>(new PoiCollection([]));

  constructor() {
    LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP).then(() => {
      this.fetchGroups();
      this.fetchPois();
    });
    StructEvents.onChangeStructUnit(() => {
      this.fetchGroups();
      this.fetchPois();
    });
  }

  addPoi(poi: PoiModel) {
    this._pois.value.push(poi);
  }

  addGroups(group: PoiGroupModel) {
    this._groups.value.push(group);
  }

  updateGroups(group: PoiGroupModel) {
    this._groups.value = this._groups.value.filter((p) => p.id !== group.id);

    this._groups.value.push(group);
  }

  async fetchGroups(force = false) {
    if (LoadingStatus.isNone.value(LoadingNamesEnum.POIS_GROUPS_LIST) || force) {
      LoadingStatus.loading(LoadingNamesEnum.POIS_GROUPS_LIST);
      try {
        this._groups.value.splice(0, this._groups.value.length);
        const { data } = await ApiService.scouting.getUnitPoiGroups();

        data.forEach((dto) => {
          const existGroup = this._groups.value.find((v) => v.id === dto.id);
          if (existGroup) {
            this._groups.value.splice(this._groups.value.indexOf(existGroup), 1);
          }
          this._groups.value.push(new PoiGroupModel(dto));
        });
        LoadingStatus.success(LoadingNamesEnum.POIS_GROUPS_LIST);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.POIS_GROUPS_LIST, e);
      }
    }
  }

  async deletePoiGroup(poiGroup: PoiGroupModel) {
    ApiService.scouting.deletePoigroup(poiGroup.id).then((response) => {
      if (response.status === 204) {
        this._groups.value = this._groups.value.filter((v) => v.id !== poiGroup.id) || [];
      }
    });
  }

  async deleteActivePoi() {
    if (this._activePoi.value) {
      this._pois.value = this._pois.value.filter((v) => v.id !== this._activePoi.value?.id);

      await ApiService.scouting.deletePoi(this._activePoi.value?.id);

      EventBus.$emit(EventsEnum.MapRedrawPois);
    }
  }

  async deletePoi(id: number) {
    this._pois.value = this._pois.value.filter((v) => v.id !== id);
    await ApiService.scouting.deletePoi(id);

    EventBus.$emit(EventsEnum.MapRedrawPois);
  }

  setActivePoi(poi: number | PoiModel | undefined = undefined) {
    if (this._activePoi.value) {
      this._activePoi.value.isActive = false;
      EventBus.$emit(EventsEnum.PoiToggleActivePoi, this._activePoi.value?.id, false);
    }

    this._activePoi.value = this.getPoiModel(poi);

    if (this._activePoi.value) {
      this._activePoi.value.isActive = true;
      EventBus.$emit(EventsEnum.PoiToggleActivePoi, this._activePoi.value?.id, true);
    }
  }

  getPoiModel(poi: number | PoiModel | undefined = undefined): PoiModel | undefined {
    if (typeof poi === 'string' || typeof poi === 'number') {
      return this.findPoiById(poi) as PoiModel;
    }
    if (typeof poi === 'object') {
      return poi as PoiModel;
    }
    return undefined;
  }

  getGroupModel(group: number | string | PoiGroupModel | undefined = undefined): PoiGroupModel | undefined {
    if (typeof group === 'string' || typeof group === 'number') {
      return this._groups.value.find((g) => (g.id === group)) as PoiGroupModel | undefined;
    }
    if (typeof group === 'object') {
      return group as PoiGroupModel;
    }
    return undefined;
  }

  findPoiById(poiId: number): PoiModel | undefined {
    return this._pois.value.find((poi) => poi.dto.id === poiId) as PoiModel | undefined;
  }

  async fetchPois(force = false) {
    if (LoadingStatus.isNone.value(LoadingNamesEnum.POIS_LIST) || force) {
      LoadingStatus.loading(LoadingNamesEnum.POIS_LIST);
      try {
        const { data } = await ApiService.scouting.getUnitPois();

        const ids: number[] = [];
        data.forEach((dto) => {
          ids.push(dto.id);
          const poi = this._pois.value.find((p) => p.id === dto.id);
          if (poi) {
            poi.update(dto);
          } else {
            this._pois.value.push(new PoiModel(dto));
          }
        });

        this._pois.value.forEach((p) => {
          if (!ids.includes(p.id)) {
            this._pois.value.splice(this._pois.value.indexOf(p), 1);
          }
        });

        this._collection.update(this._pois.value as PoiModel[]);
        EventBus.$emit(EventsEnum.MapPoisUpdated);

        LoadingStatus.success(LoadingNamesEnum.POIS_LIST);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.POIS_LIST, e);
      }
    }
  }
}

export default new PoisList();
