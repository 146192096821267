/* eslint-disable no-restricted-syntax */
export const generalization = (
  zoneArray: Uint8ClampedArray,
  width: number,
  height: number,
  generalizationThreshold: number,
): Uint8ClampedArray => {
  // Работаем напрямую с одномерным массивом для оптимизации доступа к элементам
  const zoneGrid = zoneArray;

  const isInBounds = (x: number, y: number) => x >= 0 && y >= 0 && x < width && y < height;

  const zoneSizes: Map<number, number> = new Map();
  let zoneComponents: { zone: number, pixels: number[] }[] = [];

  // Используем одномерный массив для посещённых пикселей для уменьшения накладных расходов
  const visited = new Uint8Array(zoneArray.length);

  // Функция flood fill, работает с одномерным массивом
  const floodFill = (startX: number, startY: number, zone: number) => {
    const stack: number[] = [startY * width + startX];
    const componentPixels: number[] = [];

    while (stack.length > 0) {
      const idx = stack.pop()!;
      const x = idx % width;
      const y = Math.floor(idx / width);

      if (!isInBounds(x, y) || visited[idx] || zoneGrid[idx] !== zone || zone === 0) continue;

      visited[idx] = 1;
      componentPixels.push(idx);

      // Соседи
      const neighbors = [
        idx - width, // вверх
        idx + width, // вниз
        idx - 1, // влево
        idx + 1, // вправо
      ];

      for (const neighborIdx of neighbors) {
        if (isInBounds(neighborIdx % width, Math.floor(neighborIdx / width)) && !visited[neighborIdx] && zoneGrid[neighborIdx] === zone) {
          stack.push(neighborIdx);
        }
      }
    }

    zoneComponents.push({ zone, pixels: componentPixels });
    zoneSizes.set(zone, (zoneSizes.get(zone) || 0) + componentPixels.length);
  };

  const resetVisited = () => {
    visited.fill(0);
  };

  const collectComponents = () => {
    zoneComponents = [];
    zoneSizes.clear();
    resetVisited();

    for (let idx = 0; idx < zoneArray.length; idx++) {
      const zone = zoneGrid[idx];
      if (!visited[idx] && zone !== 0) {
        floodFill(idx % width, Math.floor(idx / width), zone);
      }
    }
  };

  const findMaxContactZone = (pixels: number[]): number | null => {
    const neighborZoneCounts: Map<number, number> = new Map();

    for (const idx of pixels) {
      const x = idx % width;
      const y = Math.floor(idx / width);
      const neighbors = [
        idx - width, // вверх
        idx + width, // вниз
        idx - 1, // влево
        idx + 1, // вправо
      ];

      for (const neighborIdx of neighbors) {
        if (isInBounds(neighborIdx % width, Math.floor(neighborIdx / width)) && zoneGrid[neighborIdx] !== zoneGrid[idx] && zoneGrid[neighborIdx] !== 0) {
          const neighborZone = zoneGrid[neighborIdx];
          neighborZoneCounts.set(neighborZone, (neighborZoneCounts.get(neighborZone) || 0) + 1);
        }
      }
    }

    let maxZone: number | null = null;
    let maxContacts = -1;

    for (const [neighborZone, count] of neighborZoneCounts) {
      if (count > maxContacts) {
        maxContacts = count;
        maxZone = neighborZone;
      }
    }

    return maxZone;
  };

  const generalizeSmallComponents = (): boolean => {
    let changed = false;

    for (const component of zoneComponents) {
      const { zone, pixels } = component;
      const zoneSize = pixels.length;

      if (zoneSize <= generalizationThreshold && zone !== 0) {
        const newZone = findMaxContactZone(pixels);

        if (newZone !== null && newZone !== zone) {
          for (const idx of pixels) {
            zoneGrid[idx] = newZone;
          }
          changed = true;
        }
      }
    }

    return changed;
  };

  let hasChanges: boolean;
  do {
    collectComponents();
    hasChanges = generalizeSmallComponents();
  } while (hasChanges);

  return new Uint8ClampedArray(zoneGrid);
};
