import { useSatellitesRgb } from '@/composables/satellites/useSatellitesRgb';
import { useMapContainers } from '@/composables/useMapContainers';
import { useSatellites } from '@/composables/useSatellites';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SatelliteCounterStatusEnums } from '@/constants/enums/SatelliteCounterStatusEnums';
import { SatelliteTaskStageEnums } from '@/constants/enums/SatelliteTaskStageEnums';
import { SocketMessageEnums } from '@/constants/enums/SocketMessageEnums';
import { SatelliteFetchProcess } from '@/constants/types/SatelliteFetchProcess';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import FieldsList from '@/modules/fields/FieldsList';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import { SatelliteCounterDto } from '@/services/api/dto/satellites/SatelliteCounterDto';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import { ElNotification } from 'element-plus';

class SatellitesService {
  constructor() {
    const { fetchingIndexesProcesses } = useSatellites();
    EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
      if (message.event === SocketMessageEnums.SATELLITE_COUNTER_CHANGE) {
        await this.fetchCounter();
      }
      if (message.payload?.process_id) {
        const fetchProcess = fetchingIndexesProcesses.value.find((v) => v.processId === message.payload.process_id);
        if (fetchProcess) {
          if (message.event === SocketMessageEnums.SATELLITE_TASK_DONE) {
            // eslint-disable-next-line default-case
            switch (fetchProcess.type) {
            case 'msf':
              await this.fetchMsfFiles(fetchProcess.fieldId);
              break;
            case 'ndvi':
              // await this.fetchNdviFiles(fetchProcess.fieldId);
              break;
            }
            fetchingIndexesProcesses.value.splice(fetchingIndexesProcesses.value.indexOf(fetchProcess));
          } else if (message.event === SocketMessageEnums.SATELLITE_TASK_ERROR) {
            ElNotification({
              message: `В процессе создания индекса ${fetchProcess.type.toUpperCase()} произошла ошибка. Попробуйте повторить запрос позже.`,
              type: 'error',
              position: 'bottom-right',
            });
            fetchingIndexesProcesses.value.splice(fetchingIndexesProcesses.value.indexOf(fetchProcess));
          }
        }
      }
    });

    EventBus.$on(EventsEnum.SocketMessage, (message: SocketMessageType) => {
      if (message.event === SocketMessageEnums.SATELLITE_TASK_DONE) {
        if (message.payload.stage === SatelliteTaskStageEnums.NDVI) {
          this.fetchNdviFiles().then(() => {
            (message.payload.fields || []).forEach((fieldId: number) => {
              const field = FieldsList.getFieldModel(fieldId);
              if (field) {
                field.animateLoading = false;
              }
            });
            // EventBus.$emit(EventsEnum.SatelliteFieldIndexRedraw);
          });
        }
      }
    });

    // this.fetchNdviFiles();
    // this.fetchIndexesColors();
  }

  async fetchNdviFiles() {
    const { indexesFiles } = useSatellites();

    try {
      const { data } = await ApiService.satellites.getNdviFiles();
      indexesFiles.value = [
        ...indexesFiles.value.filter((file) => file.index_name !== 'ndvi'),
        ...data,
      ];
    } catch (e) {
      LoggerService.error(e);
    }
  }

  async fetchMsfFiles(fieldId: number) {
    const { activeIndexes } = useSatellites();
    const { data } = await ApiService.satellites.getCompositeIndexes(fieldId);
    activeIndexes.value = data;
  }

  async fetchCounter() {
    if (StructList.activeStruct.value?.id) {
      const { data } = await ApiService.satellites.fetchUsageCounter(StructList.activeStruct.value?.id);
      data.forEach((dto: SatelliteCounterDto) => {
        const fieldModel = FieldsList.getFieldModel(dto.field);
        if (fieldModel) {
          fieldModel.counter = dto;
        }
      });

      const { showingBlock } = useSatellites();

      if (useMapContainers().activeField.value?.counter) {
        switch (useMapContainers().activeField.value?.counter?.status.progress) {
        case SatelliteCounterStatusEnums.PROGRESS:
        case SatelliteCounterStatusEnums.ERROR:
        case SatelliteCounterStatusEnums.QUEUE:
          showingBlock.value = 'progress';
          break;
        case SatelliteCounterStatusEnums.DONE:
          showingBlock.value = 'estimation';
          break;
        default:
          showingBlock.value = 'search';
        }
      } else {
        showingBlock.value = undefined;
      }
    }
  }

  async fetchIndexesColors() {
    if (useSatellitesRgb().colors.value.length === 0) {
      // const { data } = await ApiService.satellites.getColors();
      // useSatellitesRgb().colors.value = data;
    }
  }

  async startDownloadImages(fields: number[]) {
    await ApiService.satellites.tasksMany(fields, [
      SatelliteTaskStageEnums.SEARCH_RGB,
      SatelliteTaskStageEnums.DOWNLOAD_RGB,
      SatelliteTaskStageEnums.CROP_RGB,
      SatelliteTaskStageEnums.ESTIMATIONS,
    ]);
  }

  async calculateIndexes(fields: number[]) {
    const { data } = await ApiService.satellites.tasksMany(fields, [SatelliteTaskStageEnums.MERGE_MSF]);
  }

  async generateMsfFile(fieldId: number) {
    const { activeIndexes, fetchingIndexesProcesses } = useSatellites();
    if (useMapContainers().activeField.value?.id) {
      activeIndexes.value = [];
      const process: SatelliteFetchProcess = {
        type: 'msf',
        fieldId,
        processId: '0',
      };
      fetchingIndexesProcesses.value.push(process);
      const { data } = await ApiService.satellites.generateMsfFile(useMapContainers().activeField.value?.id || 0);
      if (data?.process_id) {
        process.processId = data.process_id;
      }
    }
  }

  async generateNdviFile(fieldId: number, sceneId: number) {
    await ApiService.satellites.generateNdviFile(fieldId, sceneId);
  }
}

export default new SatellitesService();
