export const SocketMessageEnums = {
  BAD_TOKEN: 'BAD_TOKEN',
  FIELD_ADD: 'FIELD_ADD',
  FIELD_CHANGE: 'FIELD_CHANGE',
  FIELD_DELETE: 'FIELD_DELETE',
  UNIT_ADD: 'UNIT_ADD',
  UNIT_DELETE: 'UNIT_DELETE',
  SATELLITE_COUNTER_CHANGE: 'SATELLITE_COUNTER_CHANGE',
  SATELLITE_TASK_DONE: 'SATELLITE_TASK_DONE',
  SATELLITE_TASK_CHANGE: 'SATELLITE_TASK_CHANGE',
  SATELLITE_TASK_ERROR: 'SATELLITE_TASK_ERROR',
  SATELLITE_TASK_CREATE: 'SATELLITE_TASK_CREATE',
  SATELLITE_MSF_CREATE: 'SATELLITE_MSF_CREATE',
  NOTIFICATION: 'NOTIFICATION',
};
