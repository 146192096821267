import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createBlock(_component_svg_icon, {
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' ,color: _ctx.color}),
    width: _ctx.size,
    height: _ctx.size,
    fill: _ctx.color,
    path: _ctx.mdi[_ctx.name],
    type: "mdi"
  }, null, 8, ["style", "width", "height", "fill", "path"]))
}