import { ref } from 'vue';
import { RastersDto } from '@/services/api/dto/gis/RastesDto';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { VectorDto } from '@/services/api/dto/gis/VectorDto';
import { RasterModel } from '@/models/RasterModel';

const rasters = ref<RasterModel[]>([]);
const rasterEdit = ref<RasterModel>();

const activeRasters = ref<RasterModel[]>([]);
const blockActiveControl = ref(false);

export const useRasterVector = () => {
  const setRastersEditItem = (item: RasterModel) => {
    rasterEdit.value = item;
    rasters.value.map((v, index) => (rasterEdit.value?.id === v.id ? rasterEdit.value : v));
  };

  return {
    setRastersEditItem,

    activeRasters,
    blockActiveControl,
  };
};
