export enum TableKeyEnum {
    string = 'string',
    number = 'number',
    stars = 'stars',
    selector = 'selector',
    int = 'int',
    select = 'select',
    soilIndex = 'soilIndex',
    view = 'view',
    photo = 'photo',
    poiName = 'name',
}
