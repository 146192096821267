<template src="./NavigationAdmin.pug" lang="pug"/>
<style src="./NavigationAdmin.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, ref,
} from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { useNavigation } from '@/composables/useNavigation';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import { NavigationMenuItems } from '@/assets/data/NavigationMenuItems';

export default defineComponent({
  name: 'NavigationAdmin',
  setup() {
    const {
      clearArea,
    } = useMapLayout();
    const { activeSector } = useNavigation();

    const item = NavigationMenuItems.find((v) => v.name === 'settings');
    const sector = ref(item);

    const onClick = () => {
      clearArea(MapLayoutAreaEnum.LEFT);
      clearArea(MapLayoutAreaEnum.RIGHT);
      if (sector.value?.name === activeSector.value?.name) {
        activeSector.value = undefined;
      } else {
        activeSector.value = sector.value;
      }
    };

    return {
      activeSector,
      sector,
      onClick,
    };
  },
});
</script>
