import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createBlock(_component_el_color_picker, {
    "color-format": _ctx.colorFormat,
    "show-alpha": _ctx.showAlpha,
    "model-value": _ctx.color,
    onActiveChange: _ctx.onChange,
    onClose: _ctx.onBlur
  }, null, 8, ["color-format", "show-alpha", "model-value", "onActiveChange", "onClose"]))
}