import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex-row flex-row-justify-between flex-col-align-center" }
const _hoisted_2 = { class: "pt-8" }
const _hoisted_3 = { class: "fs-16" }
const _hoisted_4 = {
  class: "POITableModal",
  ref: "table"
}
const _hoisted_5 = { class: "POITableModal-body" }

export function render(_ctx, _cache) {
  const _component_POITable = _resolveComponent("POITable")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_ui_dialog, {
      "model-value": !!_ctx.poiGroupId,
      onClose: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close'))),
      width: "81%",
      center: "",
      "modal-class": "el-dialog-table"
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.PoisList.getGroupModel(_ctx.computedPoiId)?.name), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('poi-group-type-' + _ctx.PoisList.getGroupModel(_ctx.computedPoiId)?.type, { ns: 'poi' })), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.computedData)
              ? (_openBlock(), _createBlock(_component_POITable, {
                  key: 0,
                  keys: _ctx.computedKeys,
                  data: _ctx.computedData,
                  onChange: _ctx.handlerChangeTable,
                  onClick: _ctx.handlerClickTable,
                  onAddColumn: _ctx.addNewParam,
                  onDeleteColumn: _ctx.deleteParam,
                  onRenameColumn: _ctx.handlerColumnRename
                }, null, 8, ["keys", "data", "onChange", "onClick", "onAddColumn", "onDeleteColumn", "onRenameColumn"]))
              : _createCommentVNode("", true)
          ])
        ], 512)
      ]),
      _: 1
    }, 8, ["model-value"])
  ]))
}