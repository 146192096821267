import { TaskCollection } from '@/collection/TaskCollection';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import {
  taskMapCollectionLayerDef,
  taskMapCollectionSourceDef,
} from '@/models/map/Layers/defs/TaskMapCollectionDef';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

export class MapLayerTaskMapCollectionModel extends MapLayerModel implements IMapLayerModel {
  readonly data: TaskCollection;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'task-map-collection', input.uuid);
    this.data = input as TaskCollection;
    this.init();
  }

  async init(): Promise<void> {
    let wait = 0;
    this.data.collection.forEach((task) => {
      if (task.geojson === undefined) {
        wait++;
        task.fetchData().finally(() => wait--);
      }
    });

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (wait === 0) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    });

    if (this._mapModel?.map && this.data.collection) {
      this._mapModel.map
        .addSource(this.sourceId, taskMapCollectionSourceDef((this.data.collection.flatMap(((task) => task.geojson?.features ?? [])) as Feature<Geometry, GeoJsonProperties>[])))
        .addLayer(taskMapCollectionLayerDef(this.sourceId, this.layerId))
        .moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);

      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
    }
    this.setOpacity(this.opacity);
  }
}
