import ApiService from '@/services/api/ApiService';
import { useStorage } from '@/composables/useStorage';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import LoggerService from '@/services/logger/LoggerService';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { StorageValuesType } from '@/constants/types/storage/StorageTypes';
import { StorageMaxSize } from '@/assets/data/StorageMaxSize';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { StorageDto } from '@/services/api/dto/user/StorageDto';

class StorageService {
  async fetchStorage() {
    await fetcher(LoadingNamesEnum.STORAGE, 'default', true, async () => {
      const { data } = await ApiService.user.getStorage();
      data.forEach((dto: StorageDto) => {
        if (Object.values(StorageKeyEnum).includes(dto.key)) {
          const body: StorageValuesType = JSON.parse(dto.body.toString()) as StorageValuesType;
          const key: StorageKeyEnum = dto.key;
          useStorage().replaceItem(key, body);
        }
      });
      EventBus.$emit(EventsEnum.StorageLoaded);
    });
  }

  async setItem(key: StorageKeyEnum, body: StorageValuesType) {
    const zip: string = JSON.stringify(body, null, '');
    try {
      await ApiService.user.postStorage({ key, body: zip });
      useStorage().replaceItem(key, body);
      EventBus.$emit(EventsEnum.UpdateStorage);
    } catch (e) {
      LoggerService.error(e);
    }
  }

  async updateItem(key: StorageKeyEnum, body: any) {
    const zip: string = JSON.stringify(body, null, '');
    ApiService.user.patchStorage(key, zip).then(() => {
      useStorage().replaceItem(key as StorageKeyEnum, body);
      EventBus.$emit(EventsEnum.UpdateStorage);
    });
  }

  itCanSave(object: StorageValuesType) {
    return JSON.stringify(object, null, '').length < StorageMaxSize;
  }
}
export default new StorageService();
