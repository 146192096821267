import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import { SatelliteSceneModel } from '@/models/satellite/SatelliteSceneModel';
import { getCorrectCoordinates } from '@/lib/convertors/getCorrectCoordinates';
import Coordinates from '@/constants/types/mapbox/Coordinates';
import { Model } from '@/models/Model';

export class FieldNirModel extends Model {
  get fieldId(): number | null {
    return this._fieldId;
  }

  get geomCoordinates(): Coordinates {
    return this._geomCoordinates;
  }

  get thumbnailUrl(): string {
    return this._thumbnailUrl;
  }

  get cloudiness(): number {
    return this._cloudiness;
  }

  get id(): number {
    return this._id;
  }

  get url(): string {
    return this._url;
  }

  get tiffUrl(): string {
    return this._tiffUrl;
  }

  get scene(): SatelliteSceneModel {
    return this._scene;
  }

  private readonly _id: number;

  private readonly _url: string;

  private readonly _thumbnailUrl: string;

  private readonly _tiffUrl: string;

  private readonly _scene: SatelliteSceneModel;

  private readonly _cloudiness: number;

  private readonly _geomCoordinates: Coordinates;

  private readonly _fieldId: number;

  constructor(file: SatelliteRgbFileDto, fieldId: number) {
    super();
    this._id = file.id;
    this._url = file.img_file;
    this._tiffUrl = file.tif_file;
    this._fieldId = fieldId;
    this._thumbnailUrl = file.thumbnail_file;
    this._scene = new SatelliteSceneModel(file.scene);
    this._cloudiness = file.cloudiness;
    this._geomCoordinates = getCorrectCoordinates(file.geom_envelope.coordinates[0]);
  }
}
