import { useAuth } from '@/composables/useAuth';

export const getTransformRequest = (url: string) => {
  const parsedURL = new URL(url);
  if (!parsedURL.host.includes('truefields.ru') && !parsedURL.host.includes('agronote.ru')) {
    return {
      url,
    };
  }

  const config = {
    url: url.replaceAll('http://', 'https://'),
  };

  if (parsedURL.host.includes('service')) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${useAuth().serviceToken.value}`,
      },
    };
  }

  if (parsedURL.host.includes('storage')) {
    return {
      ...config,
    };
  }

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${useAuth().accessToken.value}`,
    },
  };
};
