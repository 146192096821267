<template src="./CropEditForm.pug" lang="pug"/>
<style src="./CropEditForm.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import { CropModel } from '@/models/crop/CropModel';

import { Delete, Edit } from '@element-plus/icons-vue';
import ApiService from '@/services/api/ApiService';
import CropList from '@/modules/crop/CropList';
import { ApiResponse } from '@/services/api/ApiResponse';
import { useApp } from '@/composables/useApp';
import DictionaryEvents from '@/modules/dictionary/DictionaryEvents';
import FieldsList from '@/modules/fields/FieldsList';
import CropRotationList from '@/modules/cropRotation/CropRotationList';
import PublicCropList from '@/modules/crop/PublicCropList';

export default defineComponent({
  name: 'CropEditForm',
  props: {
    crop: {
      type: Object as PropType<CropModel>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const form = ref({
      color: props.crop.color || '#FF0000',
      id: props.crop.id,
      isPerennial: !!props.crop.isPerennial,
      isGreenManure: !!props.crop.isGreenManure,
      hybrids: JSON.parse(JSON.stringify(props.crop.hybrids)),
    });

    const doDelete = (hybrid: { id: number, name: string}) => {
      if (hybrid.id) {
        ApiService.crop.deleteHybrid(props.crop.id, hybrid.id);
      }
      form.value.hybrids.splice(form.value.hybrids.indexOf(hybrid), 1);
    };

    const save = async () => {
      const promises: Promise<ApiResponse<void>>[] = [];

      form.value.hybrids.forEach((v: { id: number, name: string }) => {
        if (v.id) {
          const bh = props.crop.hybrids.find((h) => h.id === v.id);
          if (bh?.name !== v.name) {
            if (!v.name) {
              promises.push(ApiService.crop.deleteHybrid(props.crop.id, v.id));
            } else {
              promises.push(ApiService.crop.updateHybrid(props.crop.id, v.id, v.name));
            }
          }
        } else {
          promises.push(ApiService.crop.addHybrid(props.crop.id, v.name));
        }
      });

      await Promise.all(promises);

      const { data } = await ApiService.crop.updateCrop(props.crop.id, {
        color: form.value.color,
        is_green_manure: form.value.isGreenManure,
        is_perennial: form.value.isPerennial,
      });

      await CropList.patchCrop(data);

      await CropRotationList.fetchRotation(true);

      emit('close');
    };

    const deleteCrop = async () => {
      useApp().setLoadingScreen('crop-edit-form-delete', 'Удаление данных о культуре');
      await ApiService.crop.deleteCrop(props.crop.id);
      await CropList.fetchCrops(true);
      await FieldsList.fetchCropRates(true);
      DictionaryEvents.emitCropDeleted();
      emit('close');
      useApp().setLoadingScreen('crop-edit-form-delete');
    };

    const handlerColorPicker = (e: string) => {
      if (e === null) {
        const publicCrop = PublicCropList.publicCrops.find((pc) => pc.name === props.crop.name);
        form.value.color = publicCrop?.color || '#ffffff';
      }
    };

    return {
      Delete,
      Edit,
      form,
      save,
      doDelete,
      deleteCrop,
      handlerColorPicker,
    };
  },
});
</script>
