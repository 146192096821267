<template src="./NavigationMenu.pug" lang="pug" />
<style src="./NavigationMenu.scss" lang="scss" />

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { NavigationMenuItems } from '@/assets/data/NavigationMenuItems';
import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlocksConfig } from '@/assets/data/BlocksConfig';
import { useNavigation } from '@/composables/useNavigation';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { BlockNameType } from '@/constants/types/BlockNameType';
import { useTranslate } from '@tolgee/vue';
import { useApp } from '@/composables/useApp';

export default defineComponent({
  name: 'NavigationMenu',
  setup() {
    const items = NavigationMenuItems;
    const {
      showBlock, hideBlock, clearArea, areaComponents,
    } = useMapLayout();
    const { activeSector } = useNavigation();

    const onClick = (item: NavigationMenuItemType) => {
      clearArea(MapLayoutAreaEnum.LEFT);
      clearArea(MapLayoutAreaEnum.RIGHT);

      if (item.block && areaComponents.value[BlocksConfig[item.block].placement].includes(item.block)) {
        hideBlock(item.block);
        activeSector.value = undefined;
      } else if (item.block) {
        if (item.loader) {
          useApp().setLoadingScreen(item.loader.name, item.loader.message);
          const block = item.block;
          setTimeout(() => {
            showBlock(block);
          }, 250);
        } else {
          showBlock(item.block);
        }
        activeSector.value = item;
      } else if (activeSector.value?.name === item.name) {
        activeSector.value = undefined;
      } else {
        activeSector.value = item;
      }
    };

    const { t } = useTranslate('navigation');

    const blockClosedHandler = (block: BlockNameType) => {
      if (block === activeSector.value?.block) {
        activeSector.value = undefined;
      }
    };

    onMounted(() => {
      EventBus.$on(EventsEnum.BlockClosed, blockClosedHandler);
    });

    onUnmounted(() => {
      EventBus.$off(EventsEnum.BlockClosed, blockClosedHandler);
    });

    return {
      items,
      activeSector,
      onClick,
      t,
    };
  },
});
</script>
