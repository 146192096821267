<template lang="pug" src="./UiIcon.pug"/>
<style src="./UiIcon.scss" lang="scss" />

<script lang="ts">
import MDI from '@/components/ui/Icon/MDI/MDI.vue';
import Path from '@/components/ui/Icon/Path/Path.vue';
import {
  defineComponent, ref, toRefs, watch,
} from 'vue';

export default defineComponent({
  name: 'UiIcon',
  props: {
    name: {
      type: String,
    },
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
  components: {
    MDI,
    Path,
  },
  setup(props) {
    const type = ref('');
    const { name } = toRefs(props);

    const calculateType = () => {
      if (props.name?.startsWith('mdi')) {
        type.value = 'mdi';
      } else if (props.name?.includes('/')) {
        type.value = 'path';
      } else {
        type.value = 'element';
      }
    };

    watch(name, calculateType);
    calculateType();

    return {
      type,
    };
  },
});
</script>
