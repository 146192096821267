<template>
  <router-view/>
</template>

<script lang="ts">
import BackendService from '@/services/backend/BackendService';
import SocketService from '@/services/socket/SocketService';
import { defineComponent, watch } from 'vue';
import { useApp } from '@/composables/useApp';

export default defineComponent({
  setup() {
    BackendService.pingPong();
    SocketService.ping();

    const { loadingScreen } = useApp();

    const screen = document.getElementById('loaderScreen');

    const container = document.getElementById('loader-messages');

    const loaderMessages = () => {
      if (container) {
        container.innerHTML = '';
        Object.keys(loadingScreen).forEach((key: string) => {
          const message = document.createElement('div');
          message.className = 'loader-message';
          message.innerText = loadingScreen[key];
          container.appendChild(message);
        });
      }
    };

    if (Object.keys(loadingScreen).length === 0) {
      if (screen) {
        screen.classList.remove('active');
      }
    } else {
      loaderMessages();
    }

    watch(loadingScreen, () => {
      if (Object.keys(loadingScreen).length === 0) {
        if (screen) {
          screen.classList.remove('active');
        }
      } else if (screen) {
        if (!screen.classList.contains('active')) {
          screen.classList.add('active');
        }
        loaderMessages();
      }
    }, {
      deep: true,
    });
  },
});
</script>
