import { Model } from '@/models/Model';

export class FileModel extends Model {
  public date: Date;

  constructor(date: string) {
    super();
    this.date = new Date(date);
  }
}
