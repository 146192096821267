/**
 * Модель исторического индекса для поля (ASF, MSF, RVI, etc..)
 */
import { FieldIndexModel } from '@/models/field/FieldIndexModel';
import { SatelliteFieldIndexFileDto } from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';

export class FieldIndexHistoryModel extends FieldIndexModel {
  private _estimations: number[] = [];

  constructor(dto: SatelliteFieldIndexFileDto) {
    super(dto);

    this._estimations = dto.estimations as number[];
  }
}
