import { TimerDto } from '@/services/api/dto/shared/TimerDto';
import { useStatus } from '@/composables/useStatus';

export const timerPercentage = (timer: TimerDto) => {
  const { serverTime } = useStatus();
  if (serverTime.value) {
    const startedAt = new Date(timer.started_at);
    const finishAt = new Date(timer.finish_at);

    const delta = finishAt.getTime() / 1000 - startedAt.getTime() / 1000;
    const passTime = serverTime.value.getTime() / 1000 - startedAt.getTime() / 1000;
    let percent = 100 * (passTime / delta);
    if (percent > 99) percent = 99;
    if (percent < 0) percent = 0;
    return Math.floor(percent);
  }
  return 0;
};

export const formatSecondsToTime = (seconds: number) => {
  if (Number.isNaN(seconds)) seconds = 0;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds - 3600 * h) / 60);
  const s = Math.floor(seconds - 3600 * h - m * 60);
  return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
};

export const timerLeft = (timer: TimerDto) => {
  const startedAt = new Date(timer.started_at);
  const finishAt = new Date(timer.finish_at);
  const { serverTime } = useStatus();
  if (serverTime.value) {
    const delta = finishAt.getTime() / 1000 - startedAt.getTime() / 1000;
    const passTime = serverTime.value.getTime() / 1000 - startedAt.getTime() / 1000;
    return delta - passTime < 0 ? 0 : delta - passTime;
  }
  return 0;
};
