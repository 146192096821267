import { computed, ref } from 'vue';
import { StorageTypes } from '@/constants/types/storage/StorageTypes';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { StoragePaletteType } from '@/constants/types/storage/StoragePaleteType';

const data = ref<{[key: string]: any}>({});

const _storage = ref<StorageTypes>({
  [StorageKeyEnum.PALETTE]: [],
  [StorageKeyEnum.CHANGELOG]: {},
  [StorageKeyEnum.NOTIFICATIONS]: {},
  [StorageKeyEnum.MAPTEXT]: {},
  [StorageKeyEnum.RASTER_ORDER]: [],
});

export const useStorageStruct = () => {
  const storage = computed({
    get: () => _storage.value,
    set: (s) => {
      _storage.value = s;
    },
  });

  const hasItem = (key: keyof StorageTypes) => !!_storage.value[key];

  const replaceItem = (key: StorageKeyEnum, value: any) => {
    _storage.value[key] = value;
  };

  const deleteItem = (key: keyof StorageTypes) => hasItem(key) && delete _storage.value[key];

  const getPalette = computed(() => {
    const arr: StoragePaletteType[] = [];
    Object.keys(_storage.value).forEach((key) => {
      const arrName = key.split('_');
      if (arrName[0] === 'palette' && arrName[1]) {
        // @ts-ignore
        arr.push(_storage.value[key] as StoragePaletteType);
      }
    });
    return arr;
  });

  const cleanStorage = () => {
    storage.value = {
      [StorageKeyEnum.PALETTE]: [],
      [StorageKeyEnum.CHANGELOG]: {},
      [StorageKeyEnum.NOTIFICATIONS]: {},
      [StorageKeyEnum.MAPTEXT]: {},
      [StorageKeyEnum.RASTER_ORDER]: [],
    };
  };

  return {
    data,
    storage,
    hasItem,
    replaceItem,
    deleteItem,
    getPalette,
    cleanStorage,
  };
};
