import { Model } from '@/models/Model';
import type { FieldModel } from '@/models/field/FieldModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { getColorZone } from '@/utils/getColorZone';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';
import { reactive } from 'vue';

type ZoneConfigType = {
    // зона 1.1 , 1.3 , 2.3, или 0
    zone: string;
    // обсолютное значение  pctValue * material.norma
    value: number | null;
    color: string;
    area: number;
    // процентные значения
    pctValue: number;
    optName?: string;
}
export class CreatingTaskMapModel extends Model {
  // какие зоны {{}}
    public zones: ZoneConfigType[] = reactive([]);

    // какие опт
    public opts: ZoneConfigType[] = reactive([]);

    public field: FieldModel;

    public baseMap: FieldTaskMapBaseModel;

    public material: {
      norma: number | null,
      name: string,
      unit: string,
      type: TaskMapMaterialTypeEnum,
    }

    constructor(field: FieldModel, baseMap: FieldTaskMapBaseModel, material: {
        norma: number | null,
        name: string,
        unit: string,
        type: TaskMapMaterialTypeEnum,
    }) {
      super();

      this.field = field;
      this.baseMap = baseMap;
      this.material = material;
      this.calculateZoneConfig();
    }

    calculateZoneConfig = () => {
      (this.baseMap.geojson?.features || [])
        .forEach((feature) => {
          if (!feature.properties?.exp_name) {
            const zone = feature.properties?.zone;

            const d = this.zones.find((v) => v.zone === zone);
            if (d) {
              d.area += Number(feature.properties?.area_ga || 0);
            } else {
              const value = this.material.norma !== null ? Number(((((Number(this.material.type) !== Number(TaskMapMaterialTypeEnum.SEED) ? feature.properties?.pct_fert : feature.properties?.pct_seed) || 0) * (this.material.norma || 0)) / 100).toFixed(0)) : null;
              this.zones.push({
                zone,
                area: Number(feature.properties?.area_ga || 0),
                value,
                color: rgbaToHex(getColorZone(zone)),
                pctValue: (this.material.type !== TaskMapMaterialTypeEnum.SEED ? feature.properties?.pct_fert : feature.properties?.pct_seed || 0),
              });
            }
          } else {
            const zone = feature.properties?.zone;
            this.opts.push({
              zone,
              area: Number(feature.properties?.area_ga || 0),
              value: Number(((((Number(this.material.type) !== Number(TaskMapMaterialTypeEnum.SEED) ? feature.properties?.pct_fert : feature.properties?.pct_seed) || 0) * (this.material.norma || 0)) / 100).toFixed(0)),
              color: feature.properties?.color || rgbaToHex(getColorZone(zone)),
              optName: feature.properties?.exp_name || '',
              pctValue: (this.material.type !== TaskMapMaterialTypeEnum.SEED ? feature.properties?.pct_fert : feature.properties?.pct_seed || 0),
            });
          }
        });
      this.opts.sort((a, b) => (a.zone > b.zone ? 1 : -1));
      this.zones.sort((a, b) => (a.zone > b.zone ? 1 : -1));
    }

    setValueZone(value: number, zone: string) {
      const zoneEdit = this.zones.find((a) => a.zone === zone);
      if (zoneEdit !== undefined) {
        zoneEdit.value = value || 0;
      }
    }

    setValueOpt(value: number, optName: string) {
      const optEdit = this.opts.find((a) => a.optName === optName);
      if (optEdit !== undefined) {
        optEdit.value = value || 0;
      }
    }

    toJSON() {
      const dp: {
        zone: string,
        'exp_name'?: string,
        value: number | null,
      }[] = [];

      this.zones.forEach((v) => {
        dp.push({
          zone: v.zone,
          value: v.value,
        });
      });
      this.opts.forEach((v) => {
        dp.push({
          zone: v.zone,
          exp_name: v.optName,
          value: v.value,
        });
      });

      return {
        name: this.material.name,
        zone_config: dp,
      };
    }
}
