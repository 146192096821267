import { computed, ref } from 'vue';
import { UserPropertiesDto } from '@/services/api/dto/user/UserPropertiesDto';
import { UserDto } from '@/services/api/dto/user/UserDto';
import { UserModel } from '@/models/UserModel';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlockNameType } from '@/constants/types/BlockNameType';

const user = ref<UserModel>();
const userSubject = ref<UserPropertiesDto>();
const subject = ref<UserDto[]>([]);
const isLoaded = ref(false);
const _showChangelog = ref(false);

EventBus.$on(EventsEnum.UserLogout, () => {
  user.value = undefined;
  userSubject.value = undefined;
  subject.value = [];
  isLoaded.value = false;
  window.sessionStorage.removeItem('userId');
  window.sessionStorage.removeItem('userFirstName');
  window.sessionStorage.removeItem('userLastName');
  window.sessionStorage.removeItem('userEmail');
});

export const useUser = () => {
  const setUser = (userInfo: UserModel) => {
    user.value = userInfo;
    isLoaded.value = true;
    EventBus.$emit(EventsEnum.UserLoaded);
    userInfo.email;
    window.sessionStorage.setItem('userId', userInfo.id.toString());
    window.sessionStorage.setItem('userFirstName', userInfo.firstName);
    window.sessionStorage.setItem('userLastName', userInfo.lastName);
    window.sessionStorage.setItem('userEmail', userInfo.email);
  };
  const setSubject = (SubjectList: UserDto[]) => {
    subject.value = SubjectList;
  };
  const setSubjectUser = (item: UserPropertiesDto) => {
    userSubject.value = item;
  };

  const showChangelog = computed({
    get: () => _showChangelog.value,
    set: (v: boolean) => {
      if (v && !useMapLayout().areaComponents.value.modal.some((block: BlockNameType) => block === 'AboutProgramBlock')) {
        useMapLayout().showBlock('AboutProgramBlock');
        _showChangelog.value = true;
      }
      if (!v) {
        _showChangelog.value = false;
      }
    },
  });

  return {
    setUser,
    setSubject,
    userSubject,
    user,
    setSubjectUser,
    showChangelog,
    subject,
    isLoaded,
  };
};
