import LoggerService from '@/services/logger/LoggerService';
import FieldsEvents from '@/modules/fields/FieldsEvents';

export class FieldLoaderModel {
  get isLoading(): boolean {
    return this._isLoading;
  }

  private _isLoading = false;

  private _counter = 0;

  private _fieldId: number;

  private _operations: {
    id: number,
    description: string,
    timeout: ReturnType<typeof setTimeout>
  }[] = [];

  constructor(fieldId: number) {
    this._fieldId = fieldId;
  }

  private redrawLoader() {
    FieldsEvents.emitFieldLoader(this._fieldId, this._operations.length > 0);
  }

  /**
   * Удалить операцию загрузки в loader.
   * @param id - ID операции (возвращается при добавлении операции функцией add)
   */
  remove(id: number) {
    const operation = this._operations.find((op) => op.id === id);
    if (operation) {
      clearTimeout(operation.timeout);
      this._operations = this._operations.filter((op) => op.id !== id);
      this.redrawLoader();
    } else {
      LoggerService.error('Попытка удалить не существующий loader для поля.');
    }
  }

  /**
   * Добавить операцию загрузки в loader.
   * Возвращает ID операции, которое нужно использовать для удаления операции загрузки remove(id).
   * @param description - Описание причины операции загрузки
   * @param timeout - время ожидания (в секундах) когда операция будет завершена принудительно, не дожидаясь вызова функции remove(). По умолчанию 15 секунд.
   */
  add(description: string, timeout = 15) {
    const _id = this._counter++;
    this._operations.push({
      id: _id,
      description,
      timeout: setTimeout(() => {
        this._operations = this._operations.filter((op) => op.id !== _id);
        this.redrawLoader();
      }, timeout * 1000),
    });
    this.redrawLoader();
    return _id;
  }
}
