import { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import { BoundsType } from '@/constants/types/BoundsType';

const calculate = (coordinates: number[][][][]) => {
  const bounds: BoundsType = {
    top: Infinity,
    left: Infinity,
    right: -Infinity,
    bottom: -Infinity,
    width: 0,
    height: 0,
    bbox: [0, 0, 0, 0],
  };

  if (coordinates) {
    coordinates.forEach((polygon) => {
      polygon.forEach((coords) => {
        coords.forEach((lngLat) => {
          if (lngLat[0] < bounds.left) bounds.left = lngLat[0];
          if (lngLat[1] < bounds.top) bounds.top = lngLat[1];
          if (lngLat[0] > bounds.right) bounds.right = lngLat[0];
          if (lngLat[1] > bounds.bottom) bounds.bottom = lngLat[1];
        });
      });
    });
  }
  bounds.width = bounds.right - bounds.left;
  bounds.height = bounds.bottom - bounds.top;
  bounds.bbox = [bounds.left, bounds.top, bounds.right, bounds.bottom];
  return bounds;
};

export const mapFeatureSquare = (data: FieldFeatureDto | number[][][][]) => {
  if (Array.isArray(data)) {
    return calculate(data);
  }
  return calculate(data.geometry.coordinates);
};
