<template lang="pug" src="./FieldCard.pug"/>
<style lang="scss" src="./FieldCard.scss"/>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import { UiSizesType } from '@/constants/types/UiSizesType';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import { FieldModel } from '@/models/field/FieldModel';

export default defineComponent({
  name: 'FieldCard',
  props: {
    /** Массив полей */
    field: {
      type: Object as PropType<FieldModel>,
      required: true,
    },
    /** Возможность выбора полей */
    selectActive: {
      type: Boolean,
      default: true,
    },
    /** Размер */
    size: {
      type: String as PropType<UiSizesType>,
      default: 'm',
    },
    /** Отобразить надпись площади поля */
    showArea: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FieldView,
  },
  emits: ['select', 'leave'],
  setup(props, { emit }) {
    const { user } = useUser();
    const eventBuffer = ref<MouseEvent>();
    const featureBuffer = ref<FieldFeatureDto>();

    const clickElement = () => {
      emit('select', featureBuffer.value, eventBuffer.value);
    };
    const enterElement = (feature: FieldFeatureDto, event: MouseEvent) => {
      featureBuffer.value = feature;
      eventBuffer.value = event;
    };
    const leaveElement = () => {
      emit('leave');
    };

    return {
      user,
      enterElement,
      leaveElement,
      clickElement,
    };
  },
});
</script>
