export enum LoggerGroupsEnum {
  CREATE_BASE_MAP = 'CREATE BASE MAP',
  COMPARE_MAP = 'COMPARE MAP',
  FIELDS = 'FIELDS',
  EXPERIMENT = 'EXPERIMENT',
  SOCKET = 'SOCKET',
  USER = 'USER',
  API = 'API',
  MAP_MODEL = 'MAP MODEL',
  FETCHER_STATUS = 'FETCHER STATUS',
  ROUTER = 'ROUTER',
  PERMISSIONS = 'PERMISSIONS'
}
