import { BlocksConfig } from '@/assets/data/BlocksConfig';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import { BlockNameType } from '@/constants/types/BlockNameType';
import type { MapModel } from '@/models/map/MapModel';
import EventBus from '@/services/eventBus/EventBus';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import { ref, watch } from 'vue';

const areaComponents = ref<Record<MapLayoutAreaEnum, BlockNameType[]>>({
  modal: [],
  bottom: [],
  left: [],
  right: [],
  top: [],
  widgets: [],
  toolsRight: [],
  toolsLeft: [],
  mapTopLeft: [],
  mapTopCenter: [],
  mapTopRight: [],
  mapRightTop: [],
  mapRightCenter: [],
  mapRightBottom: [],
  mapBottomLeft: [],
  mapBottomCenter: [],
  mapBottomRight: [],
  mapLeftTop: [],
  mapLeftCenter: [],
  mapLeftBottom: [],
  mapWorkspace: [],
  mapCalendar: [],
});

const paddings = ref({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const margins = ref({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const showProgress = ref(false);

const showCropManage = ref(false);

const showMapLayerSettings = ref<MapModel | undefined>();

const showContent = ref(true);

type AreaConfigType = {
  width?: number,
  height?: number,
  top?: number,
  right?: number,
  bottom?: number,
  left?: number,
}

const areaConfig: Record<MapLayoutAreaEnum, AreaConfigType> = {
  [MapLayoutAreaEnum.TOP]: {
    height: 46,
  },
  [MapLayoutAreaEnum.RIGHT]: {
    width: 600,
  },
  [MapLayoutAreaEnum.BOTTOM]: {
    height: 30,
  },
  [MapLayoutAreaEnum.LEFT]: {
    width: 250,
  },
  [MapLayoutAreaEnum.WIDGETS]: {
    width: 400,
  },
  [MapLayoutAreaEnum.TOOLS_RIGHT]: {
    top: 20,
  },
  [MapLayoutAreaEnum.TOOLS_LEFT]: {
    top: 80,
  },
  [MapLayoutAreaEnum.MODAL]: {
    width: 600,
  },
  [MapLayoutAreaEnum.MAP_TOP_LEFT]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_TOP_CENTER]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_TOP_RIGHT]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_RIGHT_TOP]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_RIGHT_CENTER]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_RIGHT_BOTTOM]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_BOTTOM_LEFT]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_BOTTOM_CENTER]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_BOTTOM_RIGHT]: {
    height: 40,
  },
  [MapLayoutAreaEnum.MAP_LEFT_TOP]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_LEFT_CENTER]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_LEFT_BOTTOM]: {
    width: 40,
  },
  [MapLayoutAreaEnum.MAP_WORKSPACE]: {
  },
  [MapLayoutAreaEnum.MAP_CALENDAR]: {
    height: 70,
  },
};
EventBus.$on(EventsEnum.UserLogout, () => {
  areaComponents.value.modal = [];
  areaComponents.value.bottom = [];
  areaComponents.value.left = [];
  areaComponents.value.right = [];
  areaComponents.value.top = [];
  areaComponents.value.widgets = [];
  areaComponents.value.toolsRight = [];
  areaComponents.value.toolsLeft = [];
  areaComponents.value.mapTopLeft = [];
  areaComponents.value.mapTopCenter = [];
  areaComponents.value.mapTopRight = [];
  areaComponents.value.mapRightTop = [];
  areaComponents.value.mapRightCenter = [];
  areaComponents.value.mapRightBottom = [];
  areaComponents.value.mapBottomLeft = [];
  areaComponents.value.mapBottomCenter = [];
  areaComponents.value.mapBottomRight = [];
  areaComponents.value.mapLeftTop = [];
  areaComponents.value.mapLeftCenter = [];
  areaComponents.value.mapLeftBottom = [];
  areaComponents.value.mapWorkspace = [];
  areaComponents.value.mapCalendar = [];
});

export const useMapLayout = () => {
  const soloAreas = [
    MapLayoutAreaEnum.TOP,
    MapLayoutAreaEnum.RIGHT,
    MapLayoutAreaEnum.BOTTOM,
    MapLayoutAreaEnum.LEFT,
    MapLayoutAreaEnum.MAP_CALENDAR,
  ];

  function calculateVars() {
    const root = document.querySelector(':root');
    let submenuWidth = 0;
    let innerLeftPadding = 0;
    if (root) {
      const style = getComputedStyle(root);
      submenuWidth = parseInt(style.getPropertyValue('--map-area-submenu-width'), 10);
      innerLeftPadding = parseInt(style.getPropertyValue('--map-area-inner-left-padding'), 10);
    }
    let top = 0;
    areaComponents.value.top.forEach((block) => {
      const config = BlocksConfig[block];
      top += config.height || areaConfig.top.height || 0;
    });
    document.documentElement.style.setProperty('--map-area-top-height', `${top}px`);
    paddings.value.top = top;

    let right = 0;
    areaComponents.value.right.forEach((block) => {
      const config = BlocksConfig[block];
      right += config.width || areaConfig.right.width || 0;
    });
    document.documentElement.style.setProperty('--map-area-right-width', `${right}px`);
    paddings.value.right = right;

    let bottom = 0;
    areaComponents.value.bottom.forEach((block) => {
      const config = BlocksConfig[block];
      bottom += config.height || areaConfig.bottom.height || 0;
    });
    document.documentElement.style.setProperty('--map-area-bottom-height', `${bottom}px`);
    paddings.value.bottom = bottom;

    let left = submenuWidth + innerLeftPadding;
    areaComponents.value.left.forEach((block) => {
      const config = BlocksConfig[block];
      left += config.width || areaConfig.left.width || 0;
    });
    document.documentElement.style.setProperty('--map-area-left-width', `${left}px`);
    paddings.value.left = left;

    let calendar = 0;
    areaComponents.value.mapCalendar.forEach((block) => {
      const config = BlocksConfig[block];
      calendar += config.height || areaConfig.bottom.height || 0;
    });
    document.documentElement.style.setProperty('--map-area-calendar-height', `${calendar}px`);
    paddings.value.bottom += calendar;
  }

  function hideBlock(block: BlockNameType) {
    const config = BlocksConfig[block];
    if (config.placement === MapLayoutAreaEnum.RIGHT) {
      MyTrackerService.send('Close RIGHT module', { module: block });
    }
    if (config.placement === MapLayoutAreaEnum.LEFT) {
      MyTrackerService.send('Close LEFT module', { module: block });
    }
    if (config.placement === MapLayoutAreaEnum.MODAL) {
      MyTrackerService.send('Close MODAL module', { module: block });
    }
    if (areaComponents.value[config.placement].includes(block)) {
      try {
        areaComponents.value[config.placement].splice(areaComponents.value[config.placement].indexOf(block), 1);
        calculateVars();
        EventBus.$emit(EventsEnum.MapLayoutChanged);
        EventBus.$emit(EventsEnum.BlockClosed, block);
      } catch (e) {
        console.error(e);
      }
    }
  }

  function renderBlock(block: BlockNameType) {
    const config = BlocksConfig[block];
    if (config.placement === MapLayoutAreaEnum.RIGHT) {
      MyTrackerService.send('Open RIGHT module', { module: block });
    }
    if (config.placement === MapLayoutAreaEnum.LEFT) {
      MyTrackerService.send('Open LEFT module', { module: block });
    }
    if (config.placement === MapLayoutAreaEnum.MODAL) {
      MyTrackerService.send('Open MODAL module', { module: block });
    }

    if (soloAreas.includes(config.placement)) {
      areaComponents.value[config.placement].forEach((v) => {
        hideBlock(v);
      });
      areaComponents.value[config.placement] = [block];
    } else {
      areaComponents.value[config.placement].push(block);
    }

    calculateVars();
    EventBus.$emit(EventsEnum.BlockRendered, block);
  }

  EventBus.$on(EventsEnum.BlockReRender, calculateVars);

  async function showBlock(block: BlockNameType | BlockNameType[]) {
    if (Array.isArray(block)) {
      block.forEach((v) => renderBlock(v));
    } else {
      renderBlock(block);
    }
    EventBus.$emit(EventsEnum.MapLayoutChanged);
  }

  function clearArea(areas: MapLayoutAreaEnum | MapLayoutAreaEnum[]) {
    // if (!Array.isArray(areas)) {
    //   areas = [areas];
    // }
    // areas.forEach((area) => {
    //   areaComponents.value[area].forEach((block) => {
    //     EventBus.$emit(EventsEnum.BlockClosed, block);
    //   });
    //   areaComponents.value[area] = [];
    // });
    //
    // calculateVars();
    // EventBus.$emit(EventsEnum.MapLayoutChanged);
  }

  watch(margins, () => {
    document.documentElement.style.setProperty('--map-area-margin-left', `${margins.value.left}px`);
    document.documentElement.style.setProperty('--map-area-margin-top', `${margins.value.top}px`);
    document.documentElement.style.setProperty('--map-area-margin-right', `${margins.value.right}px`);
    document.documentElement.style.setProperty('--map-area-margin-bottom', `${margins.value.bottom}px`);
  }, { deep: true });

  function isBlockOpened(block: BlockNameType) {
    const config = BlocksConfig[block];
    if (soloAreas.includes(config.placement)) {
      return areaComponents.value[config.placement].includes(block);
    }
    return false;
  }

  return {
    areaComponents,
    clearArea,
    showBlock,
    hideBlock,
    isBlockOpened,
    paddings,
    margins,
    showProgress,
    showCropManage,
    showMapLayerSettings,
    showContent,
  };
};
