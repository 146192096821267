import { ref } from 'vue';
import { LocatoinInfoDto } from '@/services/api/dto/gis/LocatoinInfoDto';
import { AnyObject } from '@/constants/types/BasedTypes';

const cords = ref<[number, number]>([0, 0]);
const hoverProperties = ref();
const hoverActive = ref(false);
const hoverLayer = ref('');
const rasterValue = ref<LocatoinInfoDto[]>([]);
const color = ref<[number, number, number, number]>([0, 0, 0, 0]);

export const useMapHover = () => {
  const setCords = (arrNumber: [number, number]) => {
    cords.value = arrNumber;
  };
  const setProperties = (properties: AnyObject) => {
    hoverProperties.value = properties;
  };

  return {
    setCords,
    setProperties,
    hoverActive,
    cords,
    hoverLayer,
    hoverProperties,
    rasterValue,
    color,
  };
};
