export const OrderedPoiKeys = [
  'relief',
  'soil_index',
  'soil_type',
  'humus_dept',
  'hcl',
  'soiltextur',
  'parent_mat',
  'compact_dg',
  'compact_h',
  'compact_l',
  'water',
  'add_hydrat',
  'waterlog',
  'ins_hydrat',
  'erosion',
  'deflation',
  'stones',
  'sandiness',
  'solonetz',
  'salinizat',
  'par_mat',
  'hms_p',
  'hms_t',
  'ph_kcl',
  'ph_h2o',
  'hr',
  'p2o5_m',
  'p2o5_ch',
  'p2o5_k',
  'k2o_m',
  'k2o_ch',
  'k2o_k',
  's',
  'spo',
  'eko',
  'sno',
  'n_tk',
  'n_k',
  'no3',
  'nh4',
  'zpv',
  'mgo',
  'cu',
  'zn',
  'mn',
  'co',
  'mo',
  'b',
];
