import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WindowBox_Header" }
const _hoisted_2 = { class: "WindowBox_Header-title" }
const _hoisted_3 = { class: "WindowBox_Header-titleText" }

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["WindowBox", { ['--shaded']: _ctx.shaded }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title")
        ])
      ]),
      _createElementVNode("div", {
        class: "WindowBox_Header-close",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_el_button, {
          icon: _ctx.Close,
          circle: "",
          size: "large",
          type: "danger"
        }, null, 8, ["icon"])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["WindowBox_Body", { compact: _ctx.compact }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}