<template lang="pug" src="./FieldsSelector.pug"/>
<style lang="scss" src="./FieldsSelector.scss"/>

<script lang="ts">
import FieldsSelectorState from '@/components/shared/FieldsSelector/FieldsSelectorState';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { UiSizesType } from '@/constants/types/UiSizesType';
import { FieldModel } from '@/models/field/FieldModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldsList from '@/modules/fields/FieldsList';
import { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import EventBus from '@/services/eventBus/EventBus';
import { Search, Select } from '@element-plus/icons-vue';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  Slots,
  useSlots,
  watch,
} from 'vue';

export default defineComponent({
  name: 'FieldsSelector',
  props: {
    /** Массив полей */
    features: {
      type: Array as PropType<FieldFeatureDto[]>,
      default: (): Array<FieldFeatureDto> => [],
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    /** Массив полей */
    fields: {
      type: Array as PropType<FieldModel[]>,
      default: (): Array<FieldModel> => [],
    },
    /** Возможность выбора полей */
    selectAble: {
      type: Boolean,
      default: true,
    },
    /** Возможность установки активного поля */
    activeAble: {
      type: Boolean,
      default: true,
    },

    /** Максимальное число выбора полей */
    maxSelectCount: {
      type: Number,
      default: -1,
    },
    /** Размер */
    size: {
      type: String as PropType<UiSizesType>,
      default: 'm',
    },
    /** Отобразить надпись площади поля */
    showArea: {
      type: Boolean,
      default: true,
    },
    /** Скрыть поля, которые передаются в массиве disabled-fields */
    hideDisabled: {
      type: Boolean,
      default: false,
    },
    /** Массив полей не доступных для выбора */
    disabledFeatures: {
      type: Array as PropType<FieldFeatureDto[]>,
      default: (): Array<FieldFeatureDto> => [],
    },
    disabledFields: {
      type: Array as PropType<FieldModel[]>,
      default: (): Array<FieldModel> => [],
    },
    searchRow: {
      type: String,
      default: '',
    },

    internalSearch: {
      type: Boolean,
      default: false,
    },

    showTotalRow: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FieldView,
    Select,
  },
  emits: ['selected', 'select', 'notfound'],
  setup(props, { emit }) {
    const slots = useSlots();

    const {
      activeField,
      setActiveField,
      selectedFields,
      hasSelectedField,
      addSelectedField,
      removeSelectedField,
      cleanSelectedFields,
      disabledFields,
      addDisabledField,
      removeDisabledField,
      cleanDisabledFields,
      isFieldActive,
      fitField,
      selectState,
      hoverField,

    } = useMapContainers(props.mapContainer);

    const top = ref(0);

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const hasNotice = computed(() => hasSlot('notice'));

    const hasIcon = computed(() => hasSlot('icon'));

    onMounted(() => {
      FieldsSelectorState.isActiveAble = props.activeAble;
      FieldsSelectorState.isSelectAble = props.selectAble;
      FieldsSelectorState.isActive = true;
    });

    onUnmounted(() => {
      FieldsSelectorState.isActive = false;
    });

    watch(props, (v) => {
      FieldsSelectorState.isActiveAble = v.activeAble;
      FieldsSelectorState.isSelectAble = v.selectAble;
    });

    const onMouseEnter = (field: FieldModel) => {
      EventBus.$emit(EventsEnum.HoverField, props.mapContainer, field.id);
    };

    const onMouseLeave = (field: FieldModel) => {
      EventBus.$emit(EventsEnum.HoverField, props.mapContainer, undefined);
    };

    if (props.disabledFeatures?.length > 0) {
      // setDisabledFields(props.disabledFeatures.map((f) => FieldsList.getFieldModel(f.properties?.pk)).filter((v) => !!v) as FieldModel[]);
    }
    if (props.disabledFields?.length > 0) {
      // FieldsList.setDisabledFields(props.disabledFields as FieldModel[]);
    }

    const internalSearchValue = ref('');

    const searchFilter = (fields: FieldModel[]) => {
      if (props.searchRow) {
        return fields.filter((field) => field.name.indexOf(props.searchRow) !== -1);
      }
      if (internalSearchValue.value.length > 0) {
        return fields.filter((field) => field.name.indexOf(internalSearchValue.value) !== -1);
      }
      return fields;
    };

    const disabledFilter = (fields: FieldModel[]) => {
      if (props.hideDisabled) {
        return fields.filter((field) => !disabledFields.value.some((v) => v.id === field.id));
      }
      return fields;
    };
    const computedAllFields = computed(() => {
      if (props.fields?.length > 0) {
        return props.fields as FieldModel[];
      }
      if (props.features?.length > 0) {
        return props.features.map((v: FieldFeatureDto) => {
          const model = FieldsList.getFieldModel(v.properties.pk);
          if (model) model.dto = v;
          return model;
        }) as FieldModel[];
      }
      return [];
    });

    const computedFields = computed(() => searchFilter(disabledFilter(computedAllFields.value)).sort((a, b) => (a.name > b.name ? 1 : -1)));

    /** Проверка на возможность выбора поля */
    const isDisabledFeature = computed(() => (value: FieldModel | FieldFeatureDto) => {
      const id = ('properties' in value) ? Number((value as FieldFeatureDto).properties.pk) : (value as FieldModel).id;

      return disabledFields.value.some((m) => m?.id === id);
    });
    // todo пререписать ограничение на количесво выбранных полей
    const selectFieldFeature = (field: FieldModel) => {
      if (selectState.value === 'multiple' && !isDisabledFeature.value(field)) {
        if (props.maxSelectCount >= 0
          && props.maxSelectCount <= selectedFields.value.length
          && !selectedFields.value.find((v) => v.id === field.id)
        ) {
          return;
        }
        if (!selectedFields.value.some((a) => a.id === field.id)) {
          addSelectedField(field);
        } else {
          removeSelectedField(field);
        }

        emit('selected', selectedFields.value.map((f) => f.dto));
      }

      if (selectState.value === 'single' && !isDisabledFeature.value(field) && field.id !== activeField.value?.id) {
        if (props.activeAble) {
          setActiveField(field);
          fitField(field);
          emit('select', field.dto);
          EventBus.$emit(EventsEnum.FieldToggleActivate, field.id, true);
        }
      }
    };

    // const onSelect = (field: FieldModel) => {
    //   if (!FieldsList.blockChange.value) {
    //     if (props.maxSelectCount >= 0
    //       && props.maxSelectCount <= selectedFields.value.length
    //       && !selectedFields.value.find((v) => v.id === field.id)
    //     ) {
    //       return;
    //     }
    //     if (FieldsSelectorState.isActiveAble && isFieldActive(field)) {
    //       FieldsEvents.emitChangeActiveField(field.id);
    //     } else {
    //       FieldsEvents.emitChangeActiveField(field.id);
    //     }
    //     if (selectState.value === 'single') {
    //       fitField(field);
    //       setActiveField(field);
    //     } else if (selectState.value === 'multiple') {
    //       addSelectedField(field);
    //     }
    //     emit('select', field);
    //   } else {
    //     FieldsEvents.emitTryChangeActiveField(field.id);
    //   }
    // };

    const featureClassList = computed(() => (field: FieldModel) => ({
      selected: hasSelectedField(field?.id),
      active: FieldsSelectorState.isActiveAble && isFieldActive(field?.id),
      selectActive: FieldsSelectorState.isActiveAble,
      hover: field.id === hoverField.value?.id,
      [`--size-${props.size}`]: true,
    }));

    const clearData = () => {
      emit('select', undefined);
      setActiveField(undefined);
      cleanSelectedFields();
      FieldsEvents.emitChangeActiveField(undefined);
    };

    onMounted(() => {
      clearData();
      if (!props.activeAble) {
        setActiveField(undefined);
      }
    });

    onUnmounted(() => {
      selectedFields.value.forEach((f) => {
        EventBus.$emit(EventsEnum.FieldToggleActivate, f.id, false);
      });
      cleanSelectedFields();
      cleanDisabledFields();
      emit('selected', selectedFields.value);
      // FieldsEvents.offChangeActiveField(selectFieldHandler);
      FieldsSelectorState.isSelectAble = false;
      FieldsSelectorState.isActiveAble = true;
    });

    return {
      activeField,
      selectedFields,
      hasSelectedField,
      computedFields,
      featureClassList,
      onMouseEnter,
      onMouseLeave,
      hasNotice,
      hasIcon,
      isDisabledFeature,
      top,
      Search,
      internalSearchValue,
      selectFieldFeature,
      hoverField,
    };
  },
});
</script>
