export const RasterValue = [
  {
    name: '---',
    value: {},
  },
  {
    name: 'ASF 9',
    value: {
      stops: [
        { color: '#da1f2700', value: 0 },
        { color: '#da1f27', value: 1 },
        { color: '#e14f23', value: 2 },
        { color: '#e8801f', value: 3 },
        { color: '#efb11b', value: 4 },
        { color: '#f6e118', value: 5 },
        { color: '#cdd61e', value: 6 },
        { color: '#93b627', value: 7 },
        { color: '#5a9631', value: 8 },
        { color: '#21763b', value: 9 },
        { color: '#21763b00', value: 10 },
      ],
      epsilon: '0.001',
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
    },
  },
  {
    name: 'ASF 15',
    value: {
      stops: [
        { color: '#da1f2700', value: 0 },
        { color: '#da1f27', value: 1 },
        { color: '#e14f23', value: 3 },
        { color: '#e8801f', value: 5 },
        { color: '#efb11b', value: 7 },
        { color: '#f6e118', value: 9 },
        { color: '#cdd61e', value: 11 },
        { color: '#93b627', value: 13 },
        { color: '#5a9631', value: 14 },
        { color: '#21763b', value: 15 },
        { color: '#21763b00', value: 16 },
      ],
      epsilon: '0.001',
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
    },
  },
  {
    name: 'MSF 9',
    value: {
      stops: [
        { color: '#DB6E7300', value: 0 },
        { color: '#DB6E73', value: 1 },
        { color: '#DD8C74', value: 2 },
        { color: '#E1A772', value: 3 },
        { color: '#E5C370', value: 4 },
        { color: '#E9DD6E', value: 5 },
        { color: '#D5DA6B', value: 6 },
        { color: '#B5CE67', value: 7 },
        { color: '#92BF68', value: 8 },
        { color: '#659C57', value: 9 },
        { color: '#659C5700', value: 10 },
      ],
      epsilon: '0.001',
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
    },
  },
  {
    name: 'MSF 15',
    value: {
      stops: [
        { color: '#DB6E7300', value: 0 },
        { color: '#DB6E73', value: 1 },
        { color: '#DD8C74', value: 3 },
        { color: '#E1A772', value: 5 },
        { color: '#E5C370', value: 7 },
        { color: '#E9DD6E', value: 9 },
        { color: '#D5DA6B', value: 11 },
        { color: '#B5CE67', value: 13 },
        { color: '#92BF68', value: 14 },
        { color: '#659C57', value: 15 },
        { color: '#659C5700', value: 16 },
      ],
      epsilon: '0.001',
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
    },
  },
  {
    name: 'DEM',
    value: {
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
      epsilon: '0.001',
      stops:
        [
          { value: '0%', color: '#37349a' },
          { value: '27%', color: '#46a111' },
          { value: '50%', color: '#ffff73' },
          { value: '100%', color: '#873f0f' },
        ],
    },
  },
  {
    name: 'NDVI normal',
    value: {
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
      epsilon: '0.001',
      stops:
            [
              { value: -1, color: '#04123C' },
              { value: 0, color: '#04123C' },
              { value: 0.033, color: '#FFFFFF' },
              { value: 0.066, color: '#CCBAAC' },
              { value: 0.1, color: '#B4956B' },
              { value: 0.133, color: '#A4824C' },
              { value: 0.166, color: '#94723E' },
              { value: 0.2, color: '#CDD711' },
              { value: 0.25, color: '#94B614' },
              { value: 0.3, color: '#74AA04' },
              { value: 0.35, color: '#64A204' },
              { value: 0.4, color: '#549604' },
              { value: 0.45, color: '#74AA04' },
              { value: 0.5, color: '#1C7204' },
              { value: 0.6, color: '#046004' },
              { value: 0.7, color: '#044804' },
              { value: 0.8, color: '#043804' },
              { value: 0.9, color: '#042804' },
              { value: 1, color: '#041204' },
            ],
    },
  },
  {
    name: 'NDVI contrast',
    value: {
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
      epsilon: '0.001',
      stops:
        [
          { value: -1, color: '#000000' },
          { value: 0, color: '#000000' },
          { value: 0.1, color: '#330000' },
          { value: 0.15, color: '#440000' },
          { value: 0.2, color: '#5F0000' },
          { value: 0.233, color: '#A50000' },
          { value: 0.266, color: '#BE0000' },
          { value: 0.3, color: '#CF4000' },
          { value: 0.333, color: '#DD7600' },
          { value: 0.366, color: '#E8A500' },
          { value: 0.4, color: '#F5D900' },
          { value: 0.433, color: '#F1F800' },
          { value: 0.466, color: '#B5DA00' },
          { value: 0.5, color: '#83C100' },
          { value: 0.533, color: '#549604' },
          { value: 0.566, color: '#60AF00' },
          { value: 0.6, color: '#0A9E00' },
          { value: 0.65, color: '#038700' },
          { value: 0.7, color: '#007000' },
          { value: 0.75, color: '#005500' },
          { value: 0.8, color: '#004600' },
          { value: 0.9, color: '#003300' },
          { value: 1, color: '#002800' },
        ],
    },
  },
  {
    name: 'OSS',
    value: {
      defaultMode: 'linear',
      defaultColor: '#FFFFFF',
      epsilon: '0.001',
      stops:
      [
        { value: 0, color: '#d9d9d9' },
        { value: 1, color: '#1f5798' },
        { value: 51, color: '#098881' },
        { value: 102, color: '#04d60b' },
        { value: 154, color: '#fcf403' },
        { value: 203, color: '#e69b1a' },
        { value: 254, color: '#bd582d' },
      ],
    },
  },
];
