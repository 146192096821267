/**
 * Увеличить размер картинки tiff данных в increment раз.
 * @param data - tiff data
 * @param width - tiff source width
 * @param increment - tiff scale number
 */
const tiffResize = (data: Uint8ClampedArray, width: number, increment: number) => {
  const _data = Array.from(data);
  const newData: number[] = [];
  let row: number[] = [];
  for (let i = 0; i < _data.length; i++) {
    for (let b = 0; b < increment; b++) {
      row.push(_data[i]);
    }
    if ((i + 1) % width === 0) {
      for (let b = 0; b < increment; b++) {
        newData.push(...row);
      }
      row = [];
    }
  }
  return new Uint8ClampedArray(newData);
};
export default tiffResize;
