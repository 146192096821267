import { ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

const accessToken = ref('');
const refreshToken = ref('');
const serviceToken = ref('');
const expiresIn = ref<Date>();

const interval = ref();

EventBus.$on(EventsEnum.UserLogout, () => {
  expiresIn.value = undefined;
  interval.value = undefined;
});

export const useAuth = () => {
  function getBearer() {
    return `Bearer ${accessToken.value}`;
  }

  function isAuth() {
    return !!accessToken.value;
  }

  function isAccessTokenExpired() {
    return expiresIn.value ? expiresIn.value <= new Date(Date.now() + 100) : false;
  }

  function hasRefreshToken() {
    return !!refreshToken.value;
  }

  function hasAccessToken() {
    return !!accessToken.value;
  }

  function setTokens(data: {
    accessToken: string,
    refreshToken: string
    expiresIn: Date,
  }) {
    accessToken.value = data.accessToken;
    refreshToken.value = data.refreshToken;
    expiresIn.value = data.expiresIn;

    if (!interval.value) {
      setInterval(() => {
        if (isAccessTokenExpired()) {
          EventBus.$emit(EventsEnum.TokenRenewRequire);
        }
      }, 1000);
    }
  }

  function revokeToken() {
    accessToken.value = '';
    refreshToken.value = '';
    expiresIn.value = new Date(Date.now());
  }

  return {
    getBearer,
    isAuth,
    isAccessTokenExpired,
    hasRefreshToken,
    setTokens,
    revokeToken,
    accessToken,
    refreshToken,
    serviceToken,
  };
};
