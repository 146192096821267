/* eslint-disable camelcase */
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { IntegrationActionDto } from '@/services/api/dto/integration/IntegrationActionDto';
import { IntegrationDto } from '@/services/api/dto/integration/IntegrationDto';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getIntegrations: (farmunit: number): string => `/api/v1/integrations/${farmunit}/card/`,
  getActions: (farmunit_id: number, service_name: string): string => `/api/v1/integrations/${farmunit_id}/card/${service_name}/action/`,
  activateService: (unit: number, service_name: string): string => `/api/v1/integrations/${unit}/activate/${service_name}/`,
};

export class ApiIntegrations extends ApiHandler {
  public async getIntegrations(): Promise<ApiResponse<IntegrationDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<IntegrationDto[]>(endpoints.getIntegrations(unit));
  }

  public async getActions(serviceName: string): Promise<ApiResponse<IntegrationActionDto[]>> {
    const unit = useStruct().structId.value;

    return await this.request({ auth: true }).get<IntegrationActionDto[]>(endpoints.getActions(unit, serviceName));
  }

  public async activate(serviceName: string, data: Record<string, string | number>): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<void>(endpoints.activateService(unit, serviceName), data);
  }
}
