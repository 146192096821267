import { computed, ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';

const _activeSector = ref<NavigationMenuItemType | undefined>();

EventBus.$on(EventsEnum.UserLogout, () => {
  _activeSector.value = undefined;
});

EventBus.$on(EventsEnum.SelectStructUnit, () => {
  _activeSector.value = undefined;
});

export const useNavigation = () => {
  const activeSector = computed({
    get: () => _activeSector.value,
    set: (v) => {
      _activeSector.value = v;
    },
  });

  return {
    activeSector,
  };
};
