import { SliderMarkerProps } from 'element-plus/es/components/slider/src/marker';

type SliderMarks = Record<number, string | SliderMarkerProps['mark']>

export const SliderMarks0to100: SliderMarks = {
  0: {
    style: {
      color: '#666666',
    },
    label: '0%',
  },
  25: {
    style: {
      color: '#666666',
    },
    label: '25%',
  },
  50: {
    style: {
      color: '#666666',
    },
    label: '50%',
  },
  75: {
    style: {
      color: '#666666',
    },
    label: '75%',
  },
  100: {
    style: {
      color: '#666666',
    },
    label: '100%',
  },
};
