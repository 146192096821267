import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { DictionaryCatalogDto } from '@/services/api/dto/dictionary/DictionaryCatalogDto';
import { CropRecordDto } from '@/services/api/dto/dictionary/CropRecordDto';
import { useStruct } from '@/composables/useStruct';

const prefix = '/api/v1/books';

export const endpoints = {
  getRecords: (farmunit: number): string => `${prefix}/catalog/${farmunit}/`,
  postCropRecord: (farmunit: number): string => `${prefix}/catalog/${farmunit}/crop/`,
  patchCropRecords: (farmunit: number): string => `${prefix}/catalog/${farmunit}/crop/many/`,
  deleteCropRecord: (farmunit: number, cropId: number): string => `${prefix}/catalog/${farmunit}/crop/${cropId}/`,
};

export class ApiDictionary extends ApiHandler {
  public async getRecords(): Promise<ApiResponse<DictionaryCatalogDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<DictionaryCatalogDto>(endpoints.getRecords(unit));
  }

  public async postCropRecord(farmunit: number, data: { name: string, order: number, color?: string}): Promise<ApiResponse<CropRecordDto>> {
    return await this.request({ auth: true }).post<CropRecordDto>(endpoints.postCropRecord(farmunit), data);
  }

  public async deleteCropRecord(farmunit: number, cropId: number): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deleteCropRecord(farmunit, cropId));
  }

  public async patchCropRecords(farmunit: number, data: {
    id: number
    color?: string,
    order?: number,
    name?: string,
  }[]): Promise<ApiResponse<CropRecordDto[]>> {
    return await this.request({ auth: true }).patch<CropRecordDto[]>(endpoints.patchCropRecords(farmunit), data);
  }
}
