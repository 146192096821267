import { useStorage } from '@/composables/useStorage';
import { useUser } from '@/composables/useUser';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import StorageService from '@/services/storage/StorageService';
import { Router } from 'vue-router';

export const changelogMiddleware = async (router: Router) => {
  if (router.currentRoute.value.fullPath === '/') {
    const { data } = await ApiService.billing.getChangelogLinks();

    EventBus.$on(EventsEnum.StorageLoaded, async () => {
      const link = data[data.length - 1];
      if (useStorage().getChangelog.value?.version !== link.version) {
        useUser().showChangelog.value = true;
        await StorageService.setItem(StorageKeyEnum.CHANGELOG, {
          version: link.version,
        });
      }
    });
  }
};
