<template lang="pug" src="./TaskMapZoneFormBlock.pug"/>
<style lang="scss" src="./TaskMapZoneFormBlock.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref, shallowRef,
} from 'vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import {
  ElMessageBox, ElNotification, FormInstance, FormRules,
} from 'element-plus';
import { useUser } from '@/composables/useUser';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import { Delete, Plus } from '@element-plus/icons-vue';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';
import { CreatingTaskMapModel } from '@/models/taskMap/CreatingTaskMapModel';
import { FieldModel } from '@/models/field/FieldModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { formatNumber } from '@/lib/convertors/formatNumber';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { techType } from '@/constants/constants/techTupe';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import ApiService from '@/services/api/ApiService';
import StructList from '@/modules/struct/StructList';
import BillingService from '@/modules/billing/BillingService';
import { useBilling } from '@/composables/useBilling';
import TaskProgressCard from '@/modules/taskMap/ui/TaskProgressCard/TaskProgressCard.vue';
import hexToRgba from '@/lib/convertors/hexToRgba';
import { InternalRuleItem, Value } from 'async-validator';
import { SyncValidateResult } from 'async-validator/dist-types/interface';
import { RuleMaterialName } from '@/constants/rules/RuleMaterialName';
import { FormItemRule } from 'element-plus/es/components/form/src/types';
import { useTranslate } from '@tolgee/vue';
import TaskFieldsStats from '@/modules/taskMap/ui/TaskFieldsStats.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useRouter } from 'vue-router';
import Content from '@/components/shared/Content/Content.vue';
import CreateTaskMapFormDialog
  from '@/modules/taskMap/ui/CreateTaskMapFormDialog/CreateTaskMapFormDialog.vue';

export default defineComponent({
  name: 'TaskMapZoneFormBlock',
  components: {
    UiIcon,
    RightPanel,
    Delete,
    Plus,
    TaskProgressCard,
    TaskFieldsStats,
    Content,
    CreateTaskMapFormDialog,
  },
  setup() {
    const { user } = useUser();
    const { stepCreateTask } = useTaskMap();
    const router = useRouter();
    const { contract } = useBilling();
    const {
      activeModeCreateTask,
      selectBaseTaskMaps,
    } = useTaskMap();
    const { t } = useTranslate('taskMap');
    const {
      fields,
      selectedFields,
      activeField,
      disabledFields,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const value = ref(0);
    const modalActiveFormMaterial = ref(false);
    const isModal = ref(false);
    const activeMaterialName = ref<string>('create-materials-pane');
    const collapseActive = ref<string>('');
    const ruleFormRef = shallowRef<FormInstance>();
    const materialForm = ref<{
          norma: number | null,
          name: string,
          unit: string,
          type: TaskMapMaterialTypeEnum,
      }>(
        {
          norma: null,
          name: '',
          unit: 'кг',
          type: 0,
        },
      );
    const form = ref({
      name: 'Task',
      fieldsId: [] as number[],
      materials: [

      ] as {
          norma: number | null,
          name: string,
          unit: string,
          type: TaskMapMaterialTypeEnum,
      }[],
    });

    const userList = ref<{email: string, type: number}[]>([{
      email: JSON.parse(JSON.stringify(user.value?.email)),
      type: 0,
    }]);
    const data = ref< CreatingTaskMapModel[]>([]);

    const zones = ref<{
      name: string,
      value: number | null,
      area: number,
      color: string,
      materialName: string,

    }[]>([]);
    const opts = ref<{
          name: string,
          value: number,
          area: number,
          color: string,
          materialName: string,
          fieldName: string,
      }[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const uniqueName = (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void => {
      if (form.value.materials.some((a) => a.name === materialForm.value.name)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const rules = ref<FormRules>({
      ...RuleMaterialName,
    });

    (rules.value.name as FormItemRule[]).push({
      validator: uniqueName,
      trigger: ['blur', 'change'],
    } as FormItemRule);
    const deleteMaterial = (index: number) => {
      form.value.materials.splice(index, 1);
    };

    const nextStep = () => {
      isModal.value = true;
    };

    const calculateZone = () => {
      const updatedZones: {
        name: string,
        value: number | null,
        area: number,
        color: string,
        materialName: string,

      }[] = [];

      data.value.forEach((a) => {
        a.zones.forEach((zone) => {
          if (!updatedZones.some((z) => z.materialName === a.material.name && z.name === zone.zone)) {
            updatedZones.push({
              area: zone.area,
              color: zone.color,
              materialName: a.material.name,
              value: zone.value,
              name: zone.zone,
            });
          } else {
            const existingIndex = updatedZones.findIndex((obj) => obj.name === zone.zone && obj.materialName === a.material.name);

            if (existingIndex !== -1) {
              // Обновляем существующий объект
              updatedZones[existingIndex].area += zone.area;
            } else {
              // Добавляем новый объект
              updatedZones.push({
                area: zone.area,
                color: zone.color,
                materialName: a.material.name,
                value: 0,
                name: zone.zone,
              });
            }
          }
        });
      });
      // Заменяем старый массив новым
      zones.value = updatedZones;

      // Сортируем новый массив
      zones.value.sort((a, b) => (a.name > b.name ? 1 : -1));
    };

    const calculateOpt = () => {
      data.value.forEach((a) => {
        a.opts.forEach((opt) => {
          if (!opts.value.some((b) => b.fieldName === a.field.name && b.materialName === a.material.name && b.name === opt.optName)) {
            opts.value.push({
              area: opt.area,
              color: opt.color,
              materialName: a.material.name,
              value: 0,
              name: opt.optName || '',
              fieldName: a.field.name,
            });
          }
        });
      });

      opts.value.sort((a, b) => (a.name > b.name ? 1 : -1));

      collapseActive.value = opts.value[0]?.fieldName || '';
    };

    const pushMaterial = (formEl: FormInstance | undefined) => {
      if (!formEl || LoadingStatus.isLoading.value(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT)) return;
      formEl.validate((valid) => {
        if (valid) {
          selectBaseTaskMaps.value.forEach((task) => {
            const field = selectedFields.value.find((a) => a.id === task.field);
            if (field) {
              data.value.push(new CreatingTaskMapModel(field as FieldModel, task as FieldTaskMapBaseModel, materialForm.value));
            }
          });

          materialForm.value.norma = 0;
          form.value.materials.push(materialForm.value);
          calculateZone();
          calculateOpt();

          activeMaterialName.value = materialForm.value.name;
          materialForm.value = {
            norma: null,
            name: '',
            unit: 'кг',
            type: 0,
          };
          modalActiveFormMaterial.value = false;
          ruleFormRef.value = undefined;
        }
      });
    };

    const inputZoneValue = (inputValue: number, materialName: string, zone: string) => {
      const zoneSearch = zones.value.find((element) => element.materialName === materialName && element.name === zone);

      zoneSearch && (zoneSearch.value = inputValue);

      data.value.forEach((element) => {
        if (element.material.name === materialName) {
          element.setValueZone(Number(inputValue), zone);
        }
      });
    };

    const inputOptValue = (inputValue: number, materialName: string, opt: string) => {
      const zoneSearch = opts.value.find((element) => element.materialName === materialName && element.name === opt);

      zoneSearch && (zoneSearch.value = inputValue);

      const item = data.value.find((a) => a.material.name === materialName);
      item?.setValueOpt(Number(inputValue), opt);
    };

    const computedStats = computed(() => {
      const stats: {
          name: string,
          usage: number,
          materialName: string,
      }[] = [];
      zones.value.forEach((zone) => {
        const element = stats.find((a) => a.name === zone.name && a.materialName === zone.materialName);
        if (element !== undefined) {
          element.usage += ((zone.value || 0) * zone.area);
        } else {
          stats.push({
            name: zone.name,
            materialName: zone.materialName,
            usage: (zone.value || 0) * zone.area,
          });
        }
      });
      opts.value.forEach((opt) => {
        stats.push({
          name: opt.name,
          materialName: opt.materialName,
          usage: opt.value * opt.area,
        });
      });

      return stats;
    });

    const computedMaterialStats = computed(() => computedStats.value.filter((a) => a.materialName === activeMaterialName.value));
    const computedAllFieldsSq = computed(() => selectBaseTaskMaps.value.reduce((acc, f) => acc + f.area, 0));

    const createTask = () => {
      const acc: Array<any> = [];
      selectBaseTaskMaps.value.forEach((f) => {
        const dp = {
          task_map: f.id || 0,
          material_config: [],
        };

        data.value.forEach((task) => {
          if (task.baseMap.id === f.id) {
            // @ts-ignore
            dp.material_config.push(task.toJSON());
          }
        });

        acc.push(dp);
      });
      const tasks = {
        task_name: form.value.name,
        users: userList.value,
        fields: acc,
        materials: [
          ...form.value.materials,
        ],
      };
      isModal.value = false;
      ApiService.taskMap.postCreateTask(StructList.activeStruct.value?.id || 0, tasks).then((resp) => {
        ElNotification({
          title: 'Успешно',
          message: resp.data.description,
          type: 'success',
          position: 'bottom-right',
          customClass: 'tasksMap',
        });
        if (contract.value?.isDemo) {
          BillingService.fetchStructBilling();
        }
        LoadingStatus.success(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT);
      }).catch((e) => {
        LoadingStatus.error(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT, e);
      });
    };

    const removeTab = (targetName: string) => {
      ElMessageBox.confirm(
        t.value('remove-material', { ns: 'taskMap' }),
        t.value('remove-material-title', { ns: 'taskMap' }),
        {
          confirmButtonText: t.value('confirm-remove-material', { ns: 'taskMap' }),
          cancelButtonText: t.value('cancel'),
        },
      ).then(() => {
        const index = form.value.materials.findIndex((a) => a.name === targetName);
        const material = form.value.materials.find((a) => a.name === targetName);
        opts.value = opts.value.filter((a) => a.materialName !== material?.name);
        zones.value = zones.value.filter((a) => a.materialName !== material?.name);
        form.value.materials.splice(index, 1);
        data.value = data.value.filter((a) => a.material.name !== targetName);
      });
    };

    const toSelectField = () => {
      ElMessageBox.confirm(
        t.value('go-back-to-selection'),
        t.value('go-back-to-selection-title'),
        {
          confirmButtonText: t.value('confirm-go-back'),
          cancelButtonText: t.value('cancel'),
        },
      ).then(() => {
        activeModeCreateTask.value = 0;
      });
    };
    onMounted(() => {
      fields.value.forEach((a) => {
        disabledFields.value.push(a as FieldModel);
      });
    });

    const getUnitLabel = computed(() => (v: string) => (['п.е', 'шт', 'тыс.шт'].includes(v) ? `${v}.` : v));

    const addUser = () => {
      userList.value.push({ email: JSON.parse(JSON.stringify(user.value?.email)), type: 0 });
    };

    const removeElement = (index: number) => {
      userList.value.splice(index, 1);
    };

    return {
      modalActiveFormMaterial,
      stepCreateTask,
      form,
      rules,
      deleteMaterial,
      pushMaterial,
      Delete,
      Plus,
      nextStep,
      calculateZone,
      value,
      materialForm,
      TaskMapMaterialTypeEnum,
      zones,
      opts,
      inputZoneValue,
      inputOptValue,
      computedStats,
      computedMaterialStats,
      activeMaterialName,
      computedAllFieldsSq,
      formatNumber,
      techType,
      LoadingStatus,
      LoadingNamesEnum,
      isModal,
      createTask,
      hexToRgba,
      removeTab,
      toSelectField,
      collapseActive,
      ruleFormRef,
      getUnitLabel,
      data,
      addUser,
      removeElement,
    };
  },
});

</script>
