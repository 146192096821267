import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SortCaret" }
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1024 1024"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("path", {
        fill: _ctx.sort === _ctx.SortDirectionEnum.ASCENDING ? _ctx.activeColor : _ctx.inactiveColor,
        d: "m 384,96 c 0,-17.673124 14.32689,-31.999986 32,-31.999986 17.67311,0 32,14.326862 32,31.999986 v 786.752 c -0.003,12.93245 -7.78947,24.59197 -19.73414,29.54908 -11.94468,4.95711 -25.69895,2.23723 -34.85786,-6.89308 L 95.936,608 c -12.534936,-12.50329 -12.534936,-32.80871 0,-45.312 h 0.128 c 12.48863,-12.44963 32.69537,-12.44963 45.184,0 L 384,805.632 Z m 192,832 c -384,-618.66667 -192,-309.33333 0,0 z"
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        fill: _ctx.sort === _ctx.SortDirectionEnum.DESCENDING ? _ctx.activeColor : _ctx.inactiveColor,
        d: "m 574.76478,141.248 c 0.0285,-12.92081 7.82474,-24.5567 19.76364,-29.49742 11.93891,-4.94071 25.6777,-2.21672 34.82836,6.90542 L 926.82878,416 c 12.53494,12.50329 12.53494,32.80871 0,45.312 h -0.128 c -12.48863,12.44963 -32.69537,12.44963 -45.184,0 L 638.76478,218.496 V 928 c 0,17.67312 -14.32689,31.99999 -32,31.99999 -17.67311,0 -32,-14.32687 -32,-31.99999 z"
      }, null, 8, _hoisted_4)
    ]))
  ]))
}