export const mapLLToWebUTM = (lat: number, lon: number): {x: number, y: number} => {
  const a = 6378137.0;

  if (lat > 85.05) lat = 85.05;
  if (lat < -85.05) lat = -85.05;

  // Make sure the longitude is between -180.00 .. 179.9
  const LonTemp = (lon + 180) - Math.trunc((lon + 180) / 360) * 360 - 180;

  const LatRad = lat * (Math.PI / 180);
  const LonRad = LonTemp * (Math.PI / 180);

  return {
    x: a * LonRad,
    y: a * Math.log(Math.tan(Math.PI / 4 + LatRad / 2)),
  };
};
