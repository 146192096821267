<template lang="pug" src="./TaskMapContent.pug"/>
<style lang="scss" src="./TaskMapContent.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import FieldsList from '@/modules/fields/FieldsList';
import FieldCard from '@/components/shared/FieldCard/FieldCard.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import DialogBox from '@/components/shared/DialogBox/DialogBox.vue';
import { formatRuDate } from '@/utils/formatRuDate';
import { FieldModel } from '@/models/field/FieldModel';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import StructList from '@/modules/struct/StructList';
import TaskMapSelector from '@/components/shared/TaskMapSelector/TaskMapSelector.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useRoute, useRouter } from 'vue-router';
import Content from '@/components/shared/Content/Content.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';

export default defineComponent({
  name: 'TaskMapContent',
  components: {
    DialogBox,
    UiIcon,
    RightPanel,
    FieldCard,
    TaskMapSelector,
    Content,
  },
  setup() {
    const {
      activeModeCreateTask,
    } = useTaskMap();

    const {
      mapModel,
      selectedFields,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const activeInfo = ref(false);
    const router = useRouter();
    const isOpenDrawer = ref(false);
    const activeDrawerFieldId = ref(0);
    const showModal = ref(false);
    const fieldDrawer = ref<FieldModel>();
    const route = useRoute();

    const openDrawer = (id: number) => {
      activeDrawerFieldId.value = id;
      fieldDrawer.value = FieldsList.getFieldModel(id);
      isOpenDrawer.value = true;
    };

    const basetaskmapPermission = computed(() => PermissionsList.hasPermission('basetaskmap', 'add'));

    const basetaskmapexperimentPermission = computed(() => PermissionsList.hasPermission('basetaskmapexperiment', 'add'));

    const worktaskmapPermission = computed(() => PermissionsList.hasPermission('worktaskmap', 'add'));

    const desiccationPermission = computed(() => PermissionsList.hasPermission('task_desiccation', 'add'));

    const openModel = () => {
      showModal.value = true;
    };

    const fetchData = () => {
      if (StructList.activeStruct.value?.id) {
        FieldsList.fetchAllBaseTask();
        LoadingStatus.awaitLoad(LoadingNamesEnum.ALL_BASE_TASK).then(() => {
          setTimeout(() => {
            // disabled();
          });
        });
      }
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      fetchData();
    });

    const select = (name: string) => {
      router.push({ name });
    };

    return {
      selectedFields,
      mapModel,
      isOpenDrawer,
      openDrawer,
      activeModeCreateTask,
      showModal,
      openModel,
      activeInfo,
      formatRuDate,
      fieldDrawer,
      route,
      select,
      basetaskmapPermission,
      basetaskmapexperimentPermission,
      worktaskmapPermission,
      desiccationPermission,
    };
  },
});
</script>
