<template lang="pug" src="./AsfOrderConfirmContent.pug"/>
<style lang="scss" src="./AsfOrderConfirmContent.scss"/>

<script lang="ts">
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import { FieldModel } from '@/models/field/FieldModel';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import { Delete } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, onUnmounted, ref, watch,
} from 'vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import Content from '@/components/shared/Content/Content.vue';

export default defineComponent({
  name: 'AsfOrderConfirmContent',
  components: {
    RightPanel,
    FieldView,
    Content,
  },
  setup() {
    const { hideBlock, areaComponents } = useMapLayout();

    const {
      selectedFields,
      removeSelectedField,
      cleanSelectedFields,
      setSelectState,
      setActiveField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const comment = ref('');

    const removeField = (field: FieldModel) => {
      removeSelectedField(field);
    };

    const isActive = computed(() => areaComponents.value[MapLayoutAreaEnum.LEFT].includes('AsfOrderBlock'));

    onUnmounted(() => {
      cleanSelectedFields();
      hideBlock('AsfOrderBlock');
    });

    watch(isActive, (v) => {
      hideBlock('AsfOrderConfirmBlock');
    });

    const confirm = () => {
      const payload: { fields: {id: number, name: string}[] } = { fields: [] };
      selectedFields.value.forEach((field) => {
        payload.fields.push({
          id: field.id,
          name: field.name,
        });
      });

      ApiService.communications.createOrder({
        subject: 'создание ASF',
        comment: comment.value,
        payload,
        unit: StructList.activeStruct.value?.id || 0,
      });
      ElNotification({
        message: 'Ваш запрос на создание ASF отправлен. Ожидайте обратной связи после обработки заказа.',
        type: 'success',
        position: 'bottom-right',
      });
    };

    onMounted(() => {
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP).then(() => {
        setSelectState('multiple');
        setActiveField();
      });
    });
    onUnmounted(() => {
      setSelectState('single');
    });

    return {
      selectedFields,
      removeSelectedField,
      removeField,
      comment,
      Delete,
      confirm,
    };
  },
});
</script>
