import { Model } from '@/models/Model';
import { PaletteType } from '@/constants/types/palette/PaletteType';
import { BorderValuesType, UniqValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { ShapeStandardDto } from '@/services/api/dto/assets/shapeStandard/ShapeStandardDto';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';
import { ElNotification } from 'element-plus';

export class ShapeStandardModel extends Model {
  private readonly _id: string;

  private _rev: string | undefined;

  constructor(dto: ShapeStandardDto) {
    super();
    this._id = dto._id;
    this._rev = dto._rev;
    this._alias = dto.alias;
    this._type = dto.type;
    this._units = dto.units;
    this._data = dto.data;
    this.convertInfinity();
  }

  get id(): string {
    return this._id;
  }

  get rev(): string | undefined {
    return this._rev;
  }

  private _type: PaletteType;

  get type(): PaletteType {
    return this._type;
  }

  set type(value: PaletteType) {
    this._type = value;
  }

  private _alias: string;

  get alias(): string {
    return this._alias;
  }

  set alias(value: string) {
    this._alias = value;
  }

  private _units: string;

  get units(): string {
    return this._units;
  }

  set units(value: string) {
    this._units = value;
  }

  private _data: UniqValuesType[] | BorderValuesType[];

  get data(): UniqValuesType[] | BorderValuesType[] {
    return this._data;
  }

  set data(value: UniqValuesType[] | BorderValuesType[]) {
    this._data = value;
  }

  update(dto: ShapeStandardDto): void {
    if (this._alias !== dto.alias) {
      this._alias = dto.alias;
    }
    if (this._type !== dto.type) {
      this._type = dto.type;
    }
    if (this._type !== dto.type) {
      this._units = dto.units;
    }
    if (this._rev !== dto._rev) {
      this._rev = dto._rev;
    }
    if (JSON.stringify(this._data) !== JSON.stringify(dto.data)) {
      this._data = dto.data;
    }
    this.convertInfinity();
  }

  toJSON(): ShapeStandardDto {
    this.convertInfinity(true);
    const json: ShapeStandardDto = {
      _id: this.id,
      type: this.type,
      units: this.units,
      alias: this.alias,
      data: this.data,
    };
    if (this._rev) {
      json._rev = this._rev;
    }
    this.convertInfinity();
    return json;
  }

  async save(): Promise<void> {
    const { data } = await ApiService.assets.insertShapeStandards(this.toJSON());
    if (data.ok && data.id === this.id) {
      this._rev = data.rev;
    } else {
      ElNotification({
        title: 'Ошибка синхронизации',
        message: 'Локальная версия настройки слоя не совпадает с версией на нашем сервере. Возможно кто-то ещё изменил настройки слоя или вы давно не обновляли страницу. Попробуйте обновить страницу и повторить настройку.',
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  async delete(): Promise<void> {
    if (this.rev) {
      await ApiService.assets.destroyShapeStandards(this.id, this.rev);
    } else {
      LoggerService.error('Try to delete shape standard without rev.');
    }
  }

  private convertInfinity(toString = false) {
    if (this._type === 'borderValues') {
      this._data.forEach((v) => {
        if (toString) {
          if ((v as BorderValuesType).range.from === -Infinity) {
            // @ts-ignore
            (v as BorderValuesType).range.from = '-Infinity';
          }
          if ((v as BorderValuesType).range.to === Infinity) {
            // @ts-ignore
            (v as BorderValuesType).range.to = 'Infinity';
          }
        } else {
          // @ts-ignore
          if ((v as BorderValuesType).range.from === '-Infinity') {
            (v as BorderValuesType).range.from = -Infinity;
          }
          // @ts-ignore
          if ((v as BorderValuesType).range.to === 'Infinity') {
            (v as BorderValuesType).range.to = Infinity;
          }
        }
      });
    }
  }
}
