<template src="./POIAddBlock.pug" lang="pug"/>
<style src="./POIAddBlock.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, onBeforeUnmount, onUnmounted, reactive, ref, shallowRef,
} from 'vue';
import { FormInstance, FormRules } from 'element-plus';
import { useMapLayout } from '@/composables/useMapLayout';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useFormatter } from '@/composables/useFormatter';
import { usePoi } from '@/composables/usePoi';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import { RulePoi } from '@/constants/rules/RulePoi';
import PoisList from '@/modules/poi/PoisList';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';

export default defineComponent({
  name: 'POIAddBlock',
  components: {
    UiDrawer,
    RightPanel,
  },

  setup() {
    // region Composables
    const { hideBlock } = useMapLayout();
    const { formatPointEwkt } = useFormatter();
    const {
      createPoiCoordinates, formPoi,
    } = usePoi();

    const {
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    // endregion

    const formPOIRef = shallowRef<FormInstance>();
    const disabled = ref(false);

    // region Закрыть окно
    const closeEdit = () => {
      hideBlock('POIAddBlock');
    };
    // endregion

    onBeforeUnmount(() => {
      EventBus.$emit(EventsEnum.CreatePOIEnded);
    });

    const rules = reactive<FormRules>(RulePoi);

    const save = async () => {
      if (createPoiCoordinates.value[0] && createPoiCoordinates.value[1] && PoisList.activeGroup.value) {
        // const formData = new FormData();
        // const params = {
        //   x: createPoiCoordinates.value[0],
        //   y: createPoiCoordinates.value[1],
        //   n_prob: formPoi.value.name,
        // };
        // formData.append('farmunit', (StructList.activeStruct.value?.id || 0).toString());
        // formData.append('name', formPoi.value.name);
        // // @ts-ignore
        // formData.append('geom', formatPointEwkt([createPoiCoordinates.value[0], createPoiCoordinates.value[1]]));
        // formData.append('poigroup', (formPoi.value.group || 0).toString());
        // formData.append('descr', formPoi.value.descr);
        // formData.append('params', JSON.stringify(params));
        // disabled.value = true;
        // const { data } = await ApiService.scouting.addPoi(formData);
        // const newPoi = new PoiModel(data);
        // PoisList.addPoi(newPoi);
        // PoisList.collection.collection.push(newPoi);
        // EventBus.$emit(EventsEnum.MapPoisUpdated);
        // disabled.value = false;
        // newPoi.isActive = true;
        //
        // mapModel.value?.render(PoisList.collection as PoiCollection);
        // EventBus.$emit(EventsEnum.PoiToggleActivePoi, data.id, true);

        await PoisList.fetchPois();
      }
    };
    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          save();
        }
      });
    };
    onUnmounted(() => {
      // formPoi.value.name = '';
      // formPoi.value.descr = '';
    });

    return {
      formPOIRef,
      closeEdit,
      formPoi,
      rules,
      onSubmit,
      disabled,
    };
  },
});
</script>
