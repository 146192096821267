<template lang="pug" src="./RatingNdviContent.pug"/>
<style lang="scss" src="./RatingNdviContent.scss"/>

<script lang="ts">
import Content from '@/components/shared/Content/Content.vue';
import CustomCheckbox from '@/components/shared/CustomCheckbox/CustomCheckbox.vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import SortCaret from '@/components/ui/SortCaret/SortCaret.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { DAYS, RATING_NDVI_FILTERING_DAYS } from '@/constants/constants/constants';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { SortDirectionEnum } from '@/constants/enums/SortDirectionEnum';
import { formatNumber } from '@/lib/convertors/formatNumber';
import { CropModel } from '@/models/crop/CropModel';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldModel } from '@/models/field/FieldModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import CropRotationList from '@/modules/cropRotation/CropRotationList';
import FieldsList from '@/modules/fields/FieldsList';
import StructFilesList from '@/modules/struct/StructFilesList';
import NdviCheckbox from '@/pages/RatingNdviContent/NdviCheckbox.vue';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { formatRuDate } from '@/utils/formatRuDate';
import { ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useUnload } from '@/composables/useUnload';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';

export default defineComponent({
  name: 'RatingNdviContent',
  components: {
    RightPanel,
    CustomCheckbox,
    NdviCheckbox,
    SortCaret,
    Content,
  },
  setup() {
    const { mapModel, fields, activeField } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const table = ref();
    const ratesTableData = ref<{
      name: string,
      indexes: FieldAverageIndexModel[],
      selectedNDVI: FieldAverageIndexModel | undefined,
      showRGB: boolean,
      model: FieldModel,
    }[]>([]);
    const searchField = ref('');
    const tableHeader = ref({
      name: SortDirectionEnum.ASCENDING,
      ndviAvg: SortDirectionEnum.NONE,
    });
    const showNir = ref(false);

    const activePaintType = ref<'avg'|'contrast'|'rating'>('avg');

    const activeCrop = ref(-1);

    const activeSeason = computed({
      get: () => CropRotationList.activeSeason.value?.id || 0,
      set: (id) => CropRotationList.setActiveSeason(id),
    });

    const isDateWithinSeson = (firs: Date, last: Date) => {
      const now = new Date();
      return now <= last && now >= firs;
    };

    const seasonsOptions = computed(() => CropRotationList.seasons.value.map((v) => ({
      value: v.id,
      label: v.name,
    })));

    const fieldsLayer = ref<MapLayerFieldsModel>();
    const calculateTable = () => {
      ratesTableData.value = fields.value
        .map((f) => {
          const indexes = f.averageIndexes.filter((a) => Date.now() - (a.dataDate?.valueOf() || 0) <= RATING_NDVI_FILTERING_DAYS * DAYS);

          return {
            name: f.name,
            selectedNDVI: indexes.find((a) => {
              if (a.estimationUser === -1) {
                return false;
              } if (a.estimationUser === 0) {
                return a.estimationAuto === 1;
              }

              return true;
            }),
            indexes,
            showRGB: false,
            model: f,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    };

    const crops = computed(() => CropRotationList.rotations.reduce((acc, v) => {
      if (v.seasonId === CropRotationList.activeSeason?.value?.id && !acc.find((acv) => acv.id === v.cropItem.id)) {
        acc.push(v.cropItem as CropModel);
      }
      return acc;
    }, [] as CropModel[]));

    const computedCrop = computed(() => {
      ratesTableData.value.forEach((a) => {
        a.model.feature.properties && (a.model.feature.properties.avg_ndvi = a.selectedNDVI?.avg !== undefined ? a.selectedNDVI.avg : 'no data');
      });
      if (activeCrop.value === -1) {
        return ratesTableData.value;
      }
      return ratesTableData.value.filter((a) => {
        if (CropRotationList.getFieldData(a.model.id)?.cropItem.id === activeCrop.value) {
          return true;
        }
        a.model.feature.properties && (a.model.feature.properties.avg_ndvi = 'no data');
        return false;
      });
    });

    let timer: ReturnType< typeof setTimeout>;
    const calculateOpacity = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!mapModel.value?.map) return;

        const isZoomHigh = mapModel.value.map.getZoom() >= 13;
        const isRatingActive = activePaintType.value === 'rating';
        const averageIndexLayers = mapModel.value.getLayers(MapLayerTypeEnum.AVERAGE_INDEX);

        if (isRatingActive) {
          fieldsLayer.value?.setOpacity(100);
          averageIndexLayers.forEach((layer: MapLayerIndexModel) => {
            const selected = computedCrop.value.some((crop) => crop?.selectedNDVI?.id === layer.data.id);
            layer.setOpacity(0);
          });
          return;
        }

        if (isZoomHigh) {
          fieldsLayer.value?.setOpacity(0);
          averageIndexLayers.forEach((layer: MapLayerIndexModel) => {
            const selected = computedCrop.value.some((crop) => crop?.selectedNDVI?.id === layer.data.id);
            layer.setOpacity(selected ? 100 : 0);
          });
        } else {
          fieldsLayer.value?.setOpacity(100);
          averageIndexLayers.forEach((layer: MapLayerIndexModel) => {
            const selected = computedCrop.value.some((crop) => crop?.selectedNDVI?.id === layer.data.id);
            layer.setOpacity(selected ? 0 : layer.opacity);
          });
        }
      }, 100);
    };

    const calculateGroup = () => {
      const validFields = computedCrop.value
        .filter((a) => a.model.feature.properties?.avg_ndvi !== 'no data')
        .sort((a, b) => a.model.feature.properties!.avg_ndvi - b.model.feature.properties!.avg_ndvi)
        .map((a) => a.model);

      const length = validFields.length;

      fields.value.forEach((field) => {
        if (field.feature.properties) {
          field.feature.properties.ndvi_group = 'no data';
        }
      });

      if (length === 0) return;

      const allEqual = validFields.every(
        (field) => field.feature.properties!.avg_ndvi === validFields[0].feature.properties!.avg_ndvi,
      );

      if (allEqual) {
        validFields.forEach((field) => {
          if (field.feature.properties) {
            field.feature.properties.ndvi_group = 1; // Или другое значение группы, если нужно
          }
        });
        return;
      }

      const firstGroupEnd = Math.floor(length / 4);
      const secondGroupEnd = Math.floor((length * 3) / 4);

      const firstGroup = validFields.slice(0, firstGroupEnd);
      const secondGroup = validFields.slice(firstGroupEnd, secondGroupEnd);
      const thirdGroup = validFields.slice(secondGroupEnd);

      firstGroup.forEach((a) => {
        a.feature.properties && (a.feature.properties.ndvi_group = 1);
      });
      secondGroup.forEach((a) => {
        a.feature.properties && (a.feature.properties.ndvi_group = 2);
      });
      thirdGroup.forEach((a) => {
        a.feature.properties && (a.feature.properties.ndvi_group = 3);
      });
    };

    const calculateContrast = () => {
      fields.value.forEach((field) => {
        delete field.feature.properties?.__ndviContrast;
      });

      const filterCorpArr = ratesTableData.value.filter((a) => a.model.feature.properties?.avg_ndvi !== 'no data');
      if (filterCorpArr.length === 0) return;

      const sortedArr = filterCorpArr
        .map((a) => a.model.feature.properties?.avg_ndvi)
        .sort((a, b) => a - b);

      const [min, max] = [sortedArr[0], sortedArr[sortedArr.length - 1]];

      const isConstant = min === max;
      const mid = (min + max) / 2;

      const startColor = { r: 255, g: 0, b: 0 };
      const midColor = { r: 255, g: 255, b: 0 };
      const endColor = { r: 21, g: 162, b: 0 };

      const interpolateColor = (value: number) => {
        if (isConstant) {
          return `rgb(${endColor.r}, ${endColor.g}, ${endColor.b})`;
        }

        const ratio = (value <= mid)
          ? (value - min) / (mid - min)
          : (value - mid) / (max - mid);

        const colorStart = value <= mid ? startColor : midColor;
        const colorEnd = value <= mid ? midColor : endColor;

        const r = Math.round(colorStart.r + (colorEnd.r - colorStart.r) * ratio);
        const g = Math.round(colorStart.g + (colorEnd.g - colorStart.g) * ratio);
        const b = Math.round(colorStart.b + (colorEnd.b - colorStart.b) * ratio);

        return `rgb(${r}, ${g}, ${b})`;
      };

      filterCorpArr.forEach((a) => {
        const ndvi = a.model.feature.properties?.avg_ndvi;
        if (ndvi !== undefined) {
          a.model.feature.properties.__ndviContrast = interpolateColor(ndvi);
        }
      });
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP).then(async () => {
        await FieldsList.fetchAllNdvi();
        await CropRotationList.fetchRotation();
        await CropRotationList.fetchSeasons();
      });

      await LoadingStatus.awaitLoad(LoadingNamesEnum.AVERAGE_INDEX_NDVI).then(() => {
        calculateTable();
      });

      fieldsLayer.value = (mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel);
      ratesTableData.value.forEach((a) => {
        a.model.feature.properties && (a.model.feature.properties.avg_ndvi = a.selectedNDVI?.avg !== undefined ? a.selectedNDVI.avg : 'no data');
        if (a.selectedNDVI) {
          mapModel.value?.render(a.selectedNDVI as FieldAverageIndexModel);
          const layer = mapModel.value?.getLayer((a.selectedNDVI as FieldAverageIndexModel).uuid) as MapLayerIndexModel;
          layer?.setContrast(activePaintType.value === 'contrast');
          layer?.setOpacity(0);
        }
      });
      fieldsLayer.value.selectedPaint = 'rating-ndvi';
      fieldsLayer.value?.handlerFieldPaint();

      calculateGroup();
      calculateContrast();

      mapModel.value?.events.emitFieldUpdated();
      mapModel.value?.events.emitFieldPaint();

      fieldsLayer.value.setOpacity(100);

      CropRotationList.seasons.value.some((a) => {
        if (isDateWithinSeson(a.start as Date, a.end as Date)) {
          CropRotationList.setActiveSeason(a.id);
          return true;
        }
        return false;
      });
    });

    const sortTable = (sortKey: 'name' | 'ndviAvg') => {
      ratesTableData.value = ratesTableData.value.sort((a, b) => {
        if (sortKey === 'ndviAvg') {
          switch (tableHeader.value.ndviAvg) {
          case SortDirectionEnum.ASCENDING:
            if (a.selectedNDVI?.avg === undefined) return 1;
            if (b.selectedNDVI?.avg === undefined) return -1;
            return a.selectedNDVI.avg < b.selectedNDVI.avg ? 1 : -1;
          case SortDirectionEnum.DESCENDING:
            if (a.selectedNDVI?.avg === undefined) return -1;
            if (b.selectedNDVI?.avg === undefined) return 1;
            return a.selectedNDVI.avg > b.selectedNDVI.avg ? 1 : -1;
          case SortDirectionEnum.NONE:
            return a.name.localeCompare(b.name);
          default:
            return 0;
          }
        } else {
          switch (tableHeader.value.name) {
          case SortDirectionEnum.ASCENDING:
          case SortDirectionEnum.NONE:
            return a.name.localeCompare(b.name);
          case SortDirectionEnum.DESCENDING:
            return b.name.localeCompare(a.name);
          default:
            return 0;
          }
        }
      });
    };
    const toggleSort = (sortKey: 'name' | 'ndviAvg') => {
      if (tableHeader.value[sortKey] === 2) {
        tableHeader.value[sortKey] = 0;
      } else if (tableHeader.value[sortKey] === 0) {
        if (sortKey === 'name') {
          tableHeader.value.name = 1;
          tableHeader.value.ndviAvg = 0;
        } else {
          tableHeader.value.ndviAvg = 1;
          tableHeader.value.name = 0;
        }
      } else {
        tableHeader.value[sortKey] += 1;
      }
      sortTable(sortKey);
    };

    const filterTag = (value: string, row: any) => row.tag === value;

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      mapModel.value?.map?.on('zoomend', calculateOpacity);
    });

    useUnload(() => {
      mapModel.value?.removeLayer(MapLayerTypeEnum.HISTORY_INDEX);
      mapModel.value?.removeLayer(MapLayerTypeEnum.AVERAGE_INDEX);
      mapModel.value.removeLayer(MapLayerTypeEnum.NIR_FILE);
      mapModel.value?.map?.off('zoomend', calculateOpacity);
      fieldsLayer.value && fieldsLayer.value.setOpacity(0);
      fieldsLayer.value && (fieldsLayer.value.selectedPaint = 'none');
      mapModel.value?.events.emitFieldPaint();
    });

    const createSelectFilteredDateRaster = async () => {
      const postData = {
        name: '',
        indices: ratesTableData.value.map((r) => ({
          field: r.model.id,
          index: r.selectedNDVI?.id || 0,
        })),
      };
      const { data } = await ApiService.satellites.postCreateRasterAssemble('ndvi', postData);
      StructFilesList.fetchRasters();
      ElNotification({
        title: 'Растр создан',
        message: 'Новый файл находится среди растров',
        type: 'info',
      });
    };

    const creatLastDateRaster = async () => {
      const { data } = await ApiService.satellites.postCreateRasterAssembleLast('ndvi');
      StructFilesList.fetchRasters();
      ElNotification({
        title: 'Растр создан',
        message: 'Новый файл находится среди растров',
        type: 'info',
      });
    };

    watch(computedCrop, (a) => {
      calculateContrast();
      calculateOpacity();
      calculateGroup();
      mapModel.value?.events.emitFieldUpdated();
      mapModel.value?.events.emitFieldPaint();
    });

    watch(activePaintType, (a) => {
      if (fieldsLayer.value) {
        if (a === 'avg') {
          fieldsLayer.value.selectedPaint = 'rating-ndvi';
        } else if (a === 'rating') {
          fieldsLayer.value.selectedPaint = 'rating-ndvi-group';
        } else if (a === 'contrast') {
          fieldsLayer.value.selectedPaint = 'rating-ndvi-contrast';
        }
        calculateOpacity();
        mapModel.value?.events.emitFieldPaint();
      }
    });

    const getColorByNDVI = (ndviValue) => {
      if (ndviValue > 0.99) return 'rgba(4, 18, 4, 1)';
      if (ndviValue >= 0.9) return 'rgba(4, 40, 4, 1)';
      if (ndviValue >= 0.8) return 'rgba(4, 56, 4, 1)';
      if (ndviValue >= 0.7) return 'rgba(4, 72, 4, 1)';
      if (ndviValue >= 0.2) return 'rgba(205, 215, 17, 1)';
      if (ndviValue >= 0.6) return 'rgba(4, 96, 4, 1)';
      if (ndviValue >= 0.5) return 'rgba(28, 114, 4, 1)';
      if (ndviValue >= 0.45) return 'rgba(60, 134, 4, 1)';
      if (ndviValue >= 0.4) return 'rgba(84, 150, 4, 1)';
      if (ndviValue >= 0.3) return 'rgba(205, 215, 17, 1)';
      if (ndviValue >= 0.25) return 'rgba(148, 182, 20, 1)';
      if (ndviValue >= 0.2) return 'rgba(205, 215, 17, 1)';
      if (ndviValue >= 0.17) return 'rgba(148, 114, 62, 1)';
      if (ndviValue >= 0.13) return 'rgba(164, 130, 76, 1)';
      if (ndviValue >= 0.1) return 'rgba(180, 149, 107, 1)';
      if (ndviValue >= 0.07) return 'rgba(204, 186, 172, 1)';
      if (ndviValue >= 0.03) return 'rgba(254, 254, 254, 1)';
      if (ndviValue < 0.03) return 'rgba(4, 18, 60, 1)';
      return 'rgba(255, 255, 255, 0)';
    };

    const computedColorField = computed(() => (model: FieldModel) => {
      if (activePaintType.value === 'contrast') {
        return model.feature.properties.__ndviContrast;
      } if (activePaintType.value === 'avg') {
        return getColorByNDVI(model.feature.properties.avg_ndvi);
      } if (activePaintType.value === 'rating') {
        const arr = {
          1: '#ff0000',
          2: '#ffd900',
          3: '#15a203',
        };
        return arr[model.feature.properties.ndvi_group];
      }
      return '#ffffff';
    });

    const renderNir = async () => {
      if (mapModel.value && activeField.value) {
        if (!showNir.value) {
          mapModel.value.removeLayer(MapLayerTypeEnum.NIR_FILE);
        } else {
          await LoadingStatus.awaitLoad(LoadingNamesEnum.FIELD_NIR_FILES, activeField.value.id);
          setTimeout(() => {
            const selectNDVI = ratesTableData.value.find((a) => a.model.id === activeField.value.id).selectedNDVI;
            const selectNir = activeField.value.nirFiles.find((a) => a.scene.id === selectNDVI.dto.scene);
            const layerRender = mapModel.value.render(selectNir);
            layerRender.moveTo(MapAnchorEnum.FIELD_FILL);
          }, 50);
        }
      }
    };

    watch([showNir, activeField], (a, b) => {
      renderNir();
    });

    const changeDate = (field: FieldModel, index: FieldAverageIndexModel) => {
      const indexsOnMap = mapModel.value?.allLayers.filter((a) => a.layerType === MapLayerTypeEnum.AVERAGE_INDEX);
      const indexOnMap = field.averageIndexes.find((a) => indexsOnMap?.some((f) => f.data?.uuid === a.uuid));
      if (indexOnMap !== undefined) {
        mapModel.value?.removeLayer(indexOnMap.uuid);
      }
      field.feature.properties && (field.feature.properties.avg_ndvi = index.avg);
      mapModel.value?.render(index).setOpacity(
        mapModel.value?.map.getZoom() >= 13 ? 100 : 0,
      );
      const layer = mapModel.value?.getLayer(index.uuid) as MapLayerIndexModel;
      layer?.setContrast(activePaintType.value === 'contrast');
      calculateContrast();
      calculateOpacity();
      calculateGroup();
      renderNir();
      mapModel.value?.events.emitFieldUpdated();
      mapModel.value?.events.emitFieldPaint();
    };

    return {
      ratesTableData,
      formatNumber,
      changeDate,
      table,
      searchField,
      filterTag,
      tableHeader,
      toggleSort,
      seasonsOptions,
      activeSeason,
      crops,
      activeCrop,
      CropRotationList,
      createSelectFilteredDateRaster,
      creatLastDateRaster,
      fieldsLayer,
      activePaintType,
      computedCrop,
      formatRuDate,
      computedColorField,
      showNir,
    };
  },
});
</script>
