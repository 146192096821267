import { ASFSourceZoneType } from '@/constants/types/ASFSourceZoneType';

export const ASFSourceZones: readonly ASFSourceZoneType[] = Object.freeze([
  {
    value: 1,
    zone: '1.1',
    proc: 90,
    proc_seed: 90,
  },
  {
    value: 2,
    zone: '1.2',
    proc: 90,
    proc_seed: 90,
  },
  {
    value: 3,
    zone: '1.3',
    proc: 90,
    proc_seed: 90,
  },
  {
    value: 4,
    zone: '2.1',
    proc: 100,
    proc_seed: 100,
  },
  {
    value: 5,
    zone: '2.2',
    proc: 100,
    proc_seed: 100,
  },
  {
    value: 6,
    zone: '2.3',
    proc: 100,
    proc_seed: 100,
  },
  {
    value: 7,
    zone: '3.1',
    proc: 110,
    proc_seed: 100,
  },
  {
    value: 8,
    zone: '3.2',
    proc: 110,
    proc_seed: 100,
  },
  {
    value: 9,
    zone: '3.3',
    proc: 110,
    proc_seed: 100,
  },
]);
