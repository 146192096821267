import { FeatureCollection } from 'geojson';
import { VectorTypeEnum } from '@/constants/enums/VectorTypeEnum';
import { VectorsName } from '@/services/api/dto/gis/VectorsName';
import { Model } from '@/models/Model';
import ApiService from '@/services/api/ApiService';
import { VectorDto } from '@/services/api/dto/gis/VectorDto';
import { fetcher } from '@/lib/tools/fetcher';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

export class VectorModel extends Model {
  get columns(): string[] {
    return this._columns;
  }

  get alias(): string {
    return this._alias;
  }

  get farmUnitId(): number {
    return this._farmUnitId;
  }

  get type(): VectorTypeEnum {
    return this._type;
  }

  get bound(): [[number, number], [number, number], [number, number], [number, number]] {
    return this._bound;
  }

  get geometry(): FeatureCollection | undefined {
    return this._geometry;
  }

  get id(): number {
    return this._id;
  }

  private _geometry: FeatureCollection | undefined

  private _id: number;

  private _bound: [[number, number], [number, number], [number, number], [number, number]];

  private _alias: string;

  private _type: VectorTypeEnum;

  private _farmUnitId: number;

  private _columns: string[];

  constructor(dto: VectorsName) {
    super();
    this._id = dto.id;
    this._bound = dto.bound;
    this._alias = dto.alias;
    this._type = dto.vector_type;
    this._farmUnitId = dto.farmunit;
    this._columns = dto.columns;
  }

  update(dto: VectorsName) {
    this._id = dto.id;
    this._bound = dto.bound;
    this._alias = dto.alias;
    this._type = dto.vector_type;
    this._farmUnitId = dto?.farmunit;
    this._columns = dto?.columns;
  }

  setGeometry(geom: FeatureCollection) {
    this._geometry = geom;
  }

  async fetchGeometryVector() {
    fetcher(LoadingNamesEnum.VECTOR_GEOMETRY, this._id, false, async () => {
      const { data } = await ApiService.gis.getVectorGeometry(this._id);
      this.setGeometry(data.geojson);
    });
  }
}
