import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "DialogBox" }
const _hoisted_2 = { class: "DialogBox_Head" }
const _hoisted_3 = { class: "DialogBox_Body" }
const _hoisted_4 = { class: "DialogBox_Footer" }

export function render(_ctx, _cache) {
  const _component_UiButton = _resolveComponent("UiButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('accept')))
      }),
      _createVNode(_component_UiButton, {
        color: "primary",
        label: "Отмена",
        size: "s",
        width: "extended",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('closed')))
      })
    ])
  ]))
}