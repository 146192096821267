import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "Content" }
const _hoisted_2 = { class: "Content-header" }
const _hoisted_3 = { class: "Content-header-label" }
const _hoisted_4 = {
  key: 0,
  class: "Content-actions"
}
const _hoisted_5 = { class: "Content-body" }
const _hoisted_6 = {
  key: 1,
  class: "Content-footer"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", {
              class: "Content-header-close",
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClose && _ctx.onClose(...args)))
            })
          ]),
          (_ctx.hasSlot('actions'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "actions")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (_ctx.hasSlot('footer'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}