<template src="./UiInput.pug" lang="pug"/>
<style src="./UiInput.scss" lang="scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiInput',
  props: {
    placeholder: String,
    prependIcon: String,
    appendIcon: String,
  },

});
</script>
