import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c835272"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "MapLegend"
}
const _hoisted_2 = { class: "MapLegend-block bg-block-white" }
const _hoisted_3 = { class: "MapLegend-content pt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_UnifiedVectorLegend = _resolveComponent("UnifiedVectorLegend")!
  const _component_MonitoringLegend = _resolveComponent("MonitoringLegend")!
  const _component_RasterLegend = _resolveComponent("RasterLegend")!

  return (_ctx.layers?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.selectedLayer,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLayer) = $event)),
            style: {"pointer-events":"auto"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layers, (layer) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: layer.label,
                  value: layer.value
                }, null, 8, ["label", "value"]))
              }), 256))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layers, (layer) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (layer.type === _ctx.MapLayerTypeEnum.UNIFIED_VECTOR && layer.layer.uuid === _ctx.selectedLayer)
                  ? (_openBlock(), _createBlock(_component_UnifiedVectorLegend, {
                      key: 0,
                      mapContainer: _ctx.mapContainer,
                      layer: layer.layer as MapLayerUnifiedVectorModel
                    }, null, 8, ["mapContainer", "layer"]))
                  : _createCommentVNode("", true),
                ([ _ctx.MapLayerTypeEnum.MONITORING_INDEX, _ctx.MapLayerTypeEnum.AVERAGE_INDEX ].some((a) => a === layer.type) && layer.layer.uuid === _ctx.selectedLayer)
                  ? (_openBlock(), _createBlock(_component_MonitoringLegend, {
                      key: 1,
                      mapContainer: _ctx.mapContainer,
                      layer: layer.layer as MapLayerIndexModel
                    }, null, 8, ["mapContainer", "layer"]))
                  : _createCommentVNode("", true),
                ([ _ctx.MapLayerTypeEnum.RASTER_FILE ].some((a) => a === layer.type) && layer.layer.uuid === _ctx.selectedLayer)
                  ? (_openBlock(), _createBlock(_component_RasterLegend, {
                      key: 2,
                      mapContainer: _ctx.mapContainer,
                      layer: layer.layer as MapLayerRasterModel
                    }, null, 8, ["mapContainer", "layer"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}