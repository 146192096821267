import { computed, ref } from 'vue';
import { SatelliteIndexColorsDto } from '@/services/api/dto/satellites/SatelliteIndexColorsDto';

const _colors = ref<SatelliteIndexColorsDto[]>([]);

export const useSatellitesRgb = () => {
  const colors = computed({
    get: () => _colors.value,
    set: (v) => {
      _colors.value = v;
    },
  });

  return {
    colors,
  };
};
