export const MAX_TASK_MAP_NORMA = 10000000;

export const MAX_UNIQUE_VALUES = 100;

export const BASE_MAP_IMAGE_SCALE_SIZE = 10;

export const RATING_NDVI_FILTERING_DAYS = 30; // Кол-во дней снимков которые отображаем в рейтинге по NDVI

export const SECONDS = 1000;

export const MINUTES = 60 * SECONDS;

export const HOURS = 60 * MINUTES;

export const DAYS = 24 * HOURS;
