/* eslint-disable camelcase */
import { SatelliteTaskStageEnums } from '@/constants/enums/SatelliteTaskStageEnums';
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { FieldEstimationDto } from '@/services/api/dto/satellites/FieldEstimationDto';
import { GeneralTaskDto } from '@/services/api/dto/satellites/GeneralTaskDto';
import { SatelliteCounterDto } from '@/services/api/dto/satellites/SatelliteCounterDto';
import {
  SatelliteFieldIndexFileDto,
} from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';
import { SatelliteIndexColorsDto } from '@/services/api/dto/satellites/SatelliteIndexColorsDto';
import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import { SatellitesTaskDto } from '@/services/api/dto/satellites/SatellitesTaskDto';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  usageCounter: (): string => '/api/v1/satellites/usage/counter/',
  task: (): string => '/api/v1/satellites/tasks/task/',
  tasksMany: (): string => '/api/v1/satellites/tasks/task/many/',
  field: (fieldId: number): string => `/api/v1/satellites/indices/field/${fieldId}/`,
  filesRgb: (): string => '/api/v1/satellites/files/rgb/',
  fieldMsf: (): string => '/api/v1/satellites/indices/field/msf/',
  fieldRsf: (): string => '/api/v1/satellites/indices/field/rsf/',
  fieldRvi: (): string => '/api/v1/satellites/indices/field/rvi/',
  fieldNdvi: (): string => '/api/v1/satellites/indices/field/ndvi/',
  compositeIndexes: (fieldId: string | number): string => `/api/v1/satellites/indices/field/${fieldId}/`,
  fieldEstimation: (): string => '/api/v1/satellites/indices/tools/estimation/',
  estimationChange: (id: number): string => `/api/v1/satellites/indices/tools/estimation/${id}/`,
  colors: (farmuntId: number): string => `/api/v1/satellites/indices/tools/${farmuntId}/colors/`,
  postCreateRasterAssemble: (farmunitId: number, index_name: string): string => `/api/v1/satellites/monitoring/${farmunitId}/all-avg-indices/${index_name}/assemble/`,
  postCreateRasterAssembleLast: (farmunitId: number, index_name: string): string => `/api/v1/satellites/monitoring/${farmunitId}/all-avg-indices/${index_name}/assemble-last/`,
};

export class ApiSatellites extends ApiHandler {
  public async fetchUsageCounter(farmunit: number): Promise<ApiResponse<SatelliteCounterDto[]>> {
    return await this.request({ auth: true, freeze: false }).get<SatelliteCounterDto[]>(endpoints.usageCounter(), {
      unit: farmunit,
    });
  }

  public async tasksMany(fields: number[], stages: number[]): Promise<ApiResponse<SatellitesTaskDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<any>(endpoints.tasksMany(), {
      unit,
      fields,
      stages,
    });
  }

  public async getRgbFiles(fieldId: number): Promise<ApiResponse<SatelliteRgbFileDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SatelliteRgbFileDto[]>(endpoints.filesRgb(), {
      unit,
      field: fieldId,
      limit: 9999,
    });
  }

  public async getNdviFiles(data?: {field: number}): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, passError: true, ignoreLog: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.fieldNdvi(), {
      ...data,
      unit,
      limit: 9999,
    });
  }

  public async getEstimation(fieldId: string | number): Promise<ApiResponse<FieldEstimationDto[]>> {
    return await this.request({ auth: true }).get<FieldEstimationDto[]>(endpoints.fieldEstimation(), {
      field: fieldId,
      limit: 9999,
    });
  }

  public async getIndexFile(fieldId: number, scene: number, kind: number): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.field(fieldId), {
      scene,
      kind,
    });
  }

  public async getFieldMsf(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.fieldMsf(), {
      field: Number(fieldId),
    });
  }

  public async getFieldRsf(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.fieldRsf(), {
      field: Number(fieldId),
    });
  }

  public async getFieldRvi(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.fieldRvi(), {
      field: Number(fieldId),
    });
  }

  public async getCompositeIndexes(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.compositeIndexes(fieldId));
  }

  public async patchEstimation(estimationId: number, estimation: -1 | 0 | 1): Promise<ApiResponse<FieldEstimationDto>> {
    return await this.request({ auth: true }).patch<FieldEstimationDto>(endpoints.estimationChange(estimationId), { estimation_user: estimation });
  }

  public async generateMsfFile(fieldId: number) {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<GeneralTaskDto>(endpoints.task(), {
      stage: SatelliteTaskStageEnums.MSF,
      unit,
      fields: [fieldId],
    });
  }

  public async generateNdviFile(fieldId: number, sceneId: number) {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<GeneralTaskDto>(endpoints.task(), {
      stage: SatelliteTaskStageEnums.NDVI,
      unit,
      fields: [fieldId],
      params: {
        scenes: [sceneId],
      },
    });
  }

  public async getTasks(): Promise<ApiResponse<SatellitesTaskDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SatellitesTaskDto[]>(endpoints.task(), {
      unit,
    });
  }

  public async getFieldTasks(fieldId: number): Promise<ApiResponse<SatellitesTaskDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SatellitesTaskDto[]>(endpoints.task(), {
      unit,
      field: fieldId,
    });
  }

  public async getColors(): Promise<ApiResponse<SatelliteIndexColorsDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SatelliteIndexColorsDto[]>(endpoints.colors(unit));
  }

  public async postCreateRasterAssemble(index: string, data: {
    'name': string,
    'indices': {
        'field': number,
        'index': number
      }[]
  }): Promise<ApiResponse<any>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<SatelliteIndexColorsDto[]>(endpoints.postCreateRasterAssemble(unit, index), data);
  }

  public async postCreateRasterAssembleLast(index: string): Promise<ApiResponse<any>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<SatelliteIndexColorsDto[]>(endpoints.postCreateRasterAssembleLast(unit, index));
  }
}
