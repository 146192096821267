import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["NdviCheckbox", { checked: _ctx.computedEstimation,  userEstimation: _ctx.ndvi.estimationUser !== 0 }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["stop"]))
  }, [
    _createVNode(_component_el_icon, { size: 15 }, {
      default: _withCtx(() => [
        (_ctx.computedEstimation)
          ? (_openBlock(), _createBlock(_component_Select, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}