import { VectorColors } from '@/assets/data/VectorColors';
import hexToRgba from '@/lib/convertors/hexToRgba';

export const getColorZone = (zone: string) => {
  const arr = VectorColors.find((palette) => palette.name === 'zone');
  const color = arr?.palette.find((item) => item.value === zone)?.color;
  if (color) {
    return hexToRgba(color);
  }
  return [0, 0, 0, 0];
};
