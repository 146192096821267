<template lang="pug" src="./VectorStandardsBlock.pug"/>
<style lang="scss" src="./VectorStandardsBlock.scss"/>

<script lang="ts">
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { ColorPicker } from 'vue3-colorpicker';
import ShapeStandardList from '@/modules/assets/shapeStandard/ShapeStandardList';
import { ShapeStandardModel } from '@/models/assets/ShapeStandardModel';
import UniqValuesForm from '@/modules/admin/ui/VectorStandardsBlock/UniqValuesForm.vue';
import BorderValuesForm from '@/modules/admin/ui/VectorStandardsBlock/BorderValuesForm.vue';
import { ElNotification } from 'element-plus';
import { BorderValuesType, UniqValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { tableToExcel } from '@/lib/tools/tableToExcel';

export default defineComponent({
  name: 'VectorStandardsBlock',
  components: {
    BorderValuesForm, UniqValuesForm, ColorPicker, UiDialog,
  },
  setup() {
    const filterRow = ref('');

    const reactiveData = reactive(ShapeStandardList.data);

    const data = computed(() => reactiveData.filter((value) => (filterRow.value.length > 0 ? (value.id.includes(filterRow.value) || value.alias.includes(filterRow.value)) : true)));

    const editRow = ref<ShapeStandardModel | undefined>();

    const rowClick = (row: ShapeStandardModel) => {
      editRow.value = row;
    };

    const create = () => {
      // TODO create form
      ElNotification({
        title: 'Not implemented yet.',
        message: 'Форма добавления пока не сделана. Новые стандарты добавляем в базу ручками.',
        type: 'error',
        duration: 3500,
        position: 'bottom-right',
      });
    };

    const saveBorderValues = (values: BorderValuesType[]) => {
      editRow.value.data = values;
      editRow.value.save();
      editRow.value = undefined;
    };

    const saveUniqValues = (values: BorderValuesType[]) => {
      editRow.value.data = values;
      editRow.value.save();
      editRow.value = undefined;
    };

    const handlerDelete = () => {
      editRow.value.delete();
      editRow.value = undefined;
    };

    const createXlsx = () => {
      tableToExcel('VectorStandardsTable', 'Геошаблоны SHP', 'Геошаблоны SHP');
    };

    const formatValue = (row: UniqValuesType | BorderValuesType) => {
      if ('value' in row) {
        const _row = row as UniqValuesType;
        return `X = ${_row.value}`;
      }
      if ('range' in row) {
        const _row = row as BorderValuesType;
        if (_row.range.from?.toString() === '-Infinity') {
          return `X < ${_row.range?.to}`;
        } if (_row.range.to?.toString() === 'Infinity') {
          return `X >= ${_row.range?.from}`;
        }
        return `${_row.range?.from} >= X < ${_row.range?.to}`;
      }
      return '';
    };

    return {
      filterRow,
      data,
      reactiveData,
      rowClick,
      editRow,
      create,
      saveBorderValues,
      handlerDelete,
      createXlsx,
      formatValue,
      saveUniqValues,
    };
  },
});
</script>
