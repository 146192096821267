<template src="./FieldView.pug" lang="pug"/>
<style src="./FieldView.scss" lang="scss"/>

<script lang="ts">
import FieldsList from '@/modules/fields/FieldsList';
import { Position } from 'geojson';
import {
  computed,
  defineComponent, PropType,
} from 'vue';
import { UiSizesType } from '@/constants/types/UiSizesType';
import { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import { mapLLToWebUTM } from '@/utils/mapLLToWebUTM';

const sizes = {
  xs: 16,
  sm: 24,
  m: 32,
  lg: 42,
  xl: 72,
  xxl: 92,
};

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
    size: {
      type: String as PropType<UiSizesType>,
      default: 'm',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const coordinates = computed(() => {
      let _coordinates: Position[][][] = [];
      const fieldModel = FieldsList.getFieldModel(props.id);
      if (fieldModel?.dto?.geometry?.coordinates) {
        _coordinates = fieldModel.dto.geometry.coordinates;
      }

      // eslint-disable-next-line max-len
      const mapCooridanates = _coordinates.map((polygons) => polygons.map((polygon) => polygon.map((point) => {
        const { x, y } = mapLLToWebUTM(point[1], point[0]);
        return [x, y];
      })));

      let top = Infinity;
      let left = Infinity;
      // eslint-disable-next-line max-len
      mapCooridanates.forEach((polygons) => polygons.forEach((polygon) => polygon.forEach((point) => {
        if (top > point[1]) top = point[1];
        if (left > point[0]) left = point[0];
      })));

      // eslint-disable-next-line max-len
      return mapCooridanates.map((polygons) => polygons.map((polygon) => polygon.map((point) => {
        const hk = 1000000;
        const x = Math.round((point[0] - left) * hk);
        const y = Math.round((point[1] - top) * hk);
        return [x, y];
      })));
    });

    const bounds = computed(() => {
      const _bounds = {
        top: Infinity,
        right: -Infinity,
        bottom: -Infinity,
        left: Infinity,
        width: 0,
        height: 0,
      };
      coordinates.value.forEach((polygons) => {
        polygons.forEach((polygon) => {
          polygon.forEach((point) => {
            if (point[0] > _bounds.right) _bounds.right = point[0];
            if (point[0] < _bounds.left) _bounds.left = point[0];
            if (point[1] < _bounds.top) _bounds.top = point[1];
            if (point[1] > _bounds.bottom) _bounds.bottom = point[1];
          });
        });
      });
      _bounds.width = _bounds.right - _bounds.left;
      _bounds.height = _bounds.bottom - _bounds.top;
      return _bounds;
    });

    // eslint-disable-next-line max-len
    const sizeCoefficient = computed(() => sizes[props.size] / Math.max(bounds.value.width, bounds.value.height));

    // eslint-disable-next-line max-len
    const fields = computed(() => coordinates.value.map((field) => field.map((polygon: number[][]) => polygon.reduce((acc: string[], point: number[]) => {
      acc.push(`${Math.round(point[0] * sizeCoefficient.value)},${Math.round(point[1] * sizeCoefficient.value)}`);
      return acc;
    }, []).join(' '))));

    const viewbox = computed(() => `0 0 ${Math.round(bounds.value.width * sizeCoefficient.value)} ${Math.round(bounds.value.height * sizeCoefficient.value)}`);

    const width = computed(() => Math.round(bounds.value.width * sizeCoefficient.value));
    const height = computed(() => Math.round(bounds.value.height * sizeCoefficient.value));

    const classList = () => ({
      [`--size-${props.size}`]: true,
      '--active': props.active,
    });

    return {
      classList,
      viewbox,
      fields,
      height,
      width,
    };
  },
});
</script>
