2<template src="./itemMap.pug" lang="pug"/>
<style src="./itemMap.scss" lang="scss"/>

<script>
import {
  ref, computed, defineComponent,
} from 'vue';
import { ElImage } from 'element-plus';
import { Picture as IconPicture } from '@element-plus/icons-vue';
import { useAuth } from '@/composables/useAuth';
import AdminService from '@/modules/admin/AdminService';

export default defineComponent({
  name: 'ItemMap',
  components: { ElImage, IconPicture },
  props: {
    name: String,
    alias: String,
    uuid: String,
    chek: String,
  },
  setup(props) {
    const { accessToken } = useAuth();
    const show = ref(AdminService.imgParameters.value.solt);

    const img = computed(() => (props.chek
      ? `${process.env.VUE_APP_BASE_URL}/api/v1/gis/export_raster/?format=png&width=50&height=50&source=${props.uuid}&srs=epsg%3A3857&access_token=${accessToken.value}&soil=${AdminService.imgParameters.value.solt}}` : ''));
    return {
      show,
      IconPicture,
      img,
      accessToken,
    };
  },
});
</script>
