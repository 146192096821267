import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Navigation" }
const _hoisted_2 = { class: "Navigation_left" }
const _hoisted_3 = { class: "Navigation_center" }
const _hoisted_4 = { class: "Navigation_right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationLogo = _resolveComponent("NavigationLogo")!
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_NavigationStructUnits = _resolveComponent("NavigationStructUnits")!
  const _component_NavigationAccount = _resolveComponent("NavigationAccount")!
  const _component_NavigationMobile = _resolveComponent("NavigationMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavigationLogo),
      _createVNode(_component_NavigationMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NavigationStructUnits)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NavigationAccount),
      _createVNode(_component_NavigationMobile)
    ])
  ]))
}