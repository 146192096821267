import { AnyLayer } from 'mapbox-gl';

export const RosreestrMapLayers = (source: string): AnyLayer[] => ([
  {
    id: 'Кадастровые кварталы/0',
    type: 'line',
    source,
    'source-layer': 'Кадастровые кварталы',
    minzoom: 10,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#D20404',
      'line-width': {
        stops: [
          [
            0,
            0.933333,
          ],
          [
            10,
            0.933333,
          ],
          [
            13,
            2.66667,
          ],
          [
            20,
            2.66667,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые районы:2',
    type: 'line',
    source,
    'source-layer': 'Кадастровые районы:2',
    minzoom: 6,
    maxzoom: 10,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#D20404',
      'line-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые районы:1/1',
    type: 'line',
    source,
    'source-layer': 'Кадастровые районы:1',
    minzoom: 10,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#FFFFFF',
      'line-width': {
        stops: [
          [
            10,
            6.66667,
          ],
          [
            12,
            8,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые районы:1/0',
    type: 'line',
    source,
    'source-layer': 'Кадастровые районы:1',
    minzoom: 10,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#D20404',
      'line-width': {
        stops: [
          [
            10,
            4,
          ],
          [
            12,
            4.8,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые округа:2',
    type: 'line',
    source,
    'source-layer': 'Кадастровые округа:2',
    maxzoom: 6,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#D20404',
      'line-width': {
        stops: [
          [
            4,
            1.33333,
          ],
          [
            6,
            2.66667,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые округа:1/1',
    type: 'line',
    source,
    'source-layer': 'Кадастровые округа:1',
    minzoom: 6,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#FFFFFF',
      'line-width': {
        stops: [
          [
            6,
            5.33333,
          ],
          [
            7,
            6.66667,
          ],
          [
            8,
            8,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые округа:1/0',
    type: 'line',
    source,
    'source-layer': 'Кадастровые округа:1',
    minzoom: 6,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#D20404',
      'line-width': {
        stops: [
          [
            6,
            2.66667,
          ],
          [
            7,
            3.33333,
          ],
          [
            8,
            4,
          ],
        ],
      },
    },
  },
  {
    id: 'Кадастровые районы:2/label/Default',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые районы:2/label',
    filter: [
      '==',
      '_label_class',
      0,
    ],
    minzoom: 6,
    maxzoom: 8,
    layout: {
      'text-size': 14.6667,
      'text-field': '{_name}',
      'text-optional': true,
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые районы:1/label/Default',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые районы:1/label',
    filter: [
      '==',
      '_label_class',
      0,
    ],
    minzoom: 10,
    maxzoom: 12,
    layout: {
      'text-size': 16,
      'text-field': '{_name}',
      'text-optional': true,
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые округа:2/label/Default',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые округа:2/label',
    minzoom: 4,
    maxzoom: 6,
    layout: {
      'text-size': 16,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые округа:1/label/Default',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые округа:1/label',
    filter: [
      '==',
      '_label_class',
      0,
    ],
    minzoom: 6,
    maxzoom: 8,
    layout: {
      'text-size': 21.3333,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/564',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      5,
    ],
    minzoom: 19,
    layout: {
      'text-size': 24,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/1k',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      4,
    ],
    minzoom: 18,
    maxzoom: 19,
    layout: {
      'text-size': 21.3333,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/2k',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      3,
    ],
    minzoom: 17,
    maxzoom: 18,
    layout: {
      'text-size': 18.6667,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/4k',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      2,
    ],
    minzoom: 16,
    maxzoom: 17,
    layout: {
      'text-size': 16,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/9k',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      1,
    ],
    minzoom: 15,
    maxzoom: 16,
    layout: {
      'text-size': 13.3333,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые кварталы/label/36k',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые кварталы/label',
    filter: [
      '==',
      '_label_class',
      0,
    ],
    minzoom: 13,
    maxzoom: 15,
    layout: {
      'text-size': 12,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые районы:2/label/1',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые районы:2/label',
    filter: [
      '==',
      '_label_class',
      1,
    ],
    minzoom: 8,
    maxzoom: 10,
    layout: {
      'text-size': 16,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые районы:1/label/1',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые районы:1/label',
    filter: [
      '==',
      '_label_class',
      1,
    ],
    minzoom: 12,
    layout: {
      'text-size': 21.3333,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.33333,
    },
  },
  {
    id: 'Кадастровые округа:1/label/1',
    type: 'symbol',
    source,
    'source-layer': 'Кадастровые округа:1/label',
    filter: [
      '==',
      '_label_class',
      1,
    ],
    minzoom: 8,
    layout: {
      'text-size': 26.6667,
      'text-field': '{_name}',
      'text-optional': true,
      'text-font': [
        'literal',
        ['Inter Regular'],
      ],
    },
    paint: {
      'text-color': '#A80401',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 2.66667,
    },
  },
]);
