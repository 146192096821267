import { fromUrl, ReadRasterResult } from 'geotiff';
import { ref } from 'vue';
import LoggerService from '@/services/logger/LoggerService';

class TiffReader {
  public data = ref<ReadRasterResult | undefined>();

  readFile= async (url: string) => {
    const tiff = await fromUrl(url);
    const img = await tiff.getImage();
    await img.readRasters()
      .then((resp: ReadRasterResult) => {
        this.data.value = resp as ReadRasterResult;
      }).catch((e) => {
        LoggerService.error(e);
      });
  };

  clear() {
    this.data.value = undefined;
  }
}
export default new TiffReader();
