import * as turf from '@turf/turf';
import { BASE_MAP_IMAGE_SCALE_SIZE } from '@/constants/constants/constants';
import { MultiPolygon, Position } from 'geojson';

class MaskProcessor {
  private readonly bbox: [number, number, number, number];

  private fieldGeoJSON: MultiPolygon;

  private readonly sourceWidth: number;

  private readonly sourceHeight: number;

  private readonly scaledWidth: number;

  private readonly scaledHeight: number;

  constructor(
    bbox: [number, number, number, number],
    fieldGeoJSON: MultiPolygon,
    sourceWidth: number,
    sourceHeight: number,
    scaledWidth: number,
    scaledHeight: number,
  ) {
    this.bbox = bbox;
    this.fieldGeoJSON = fieldGeoJSON;
    this.sourceWidth = sourceWidth;
    this.sourceHeight = sourceHeight;
    this.scaledWidth = scaledWidth;
    this.scaledHeight = scaledHeight;
  }

  static applyPackedMask(packedMask: string[], imageData: Uint8ClampedArray): Uint8ClampedArray {
    let index = 0;
    for (const block of packedMask) {
      const [value, count] = block.split(':').map(Number);
      if (value === 0) {
        for (let i = 0; i < count; i++) {
          imageData[index++] = 0;
        }
      } else {
        index += count; // Пропускаем непрозрачные пиксели
      }
    }
    return imageData;
  }

  // Метод для вычисления маски
  public computeMasks(): { clearMask: Uint8Array, packedMask: string[] } {
    const [minLon, minLat, maxLon, maxLat] = this.bbox;

    // Обрабатываем все полигоны в мультиполигоне
    const simplifiedPolygons = this.fieldGeoJSON.coordinates.map((polygon) => turf.simplify(turf.polygon(polygon), { tolerance: 0.0000025, highQuality: true }).geometry.coordinates);

    const mainPolygons = simplifiedPolygons.map((polygon) => polygon[0]); // Внешние контуры
    const holes = simplifiedPolygons.flatMap((polygon) => polygon.slice(1)); // Все внутренние контуры (дыры)

    const preliminaryMask = new Uint8Array(this.sourceWidth * this.sourceHeight);
    const clearMask = new Uint8Array(this.scaledWidth * this.scaledHeight);

    const sourceLonStep = (maxLon - minLon) / this.sourceWidth;
    const sourceLatStep = (maxLat - minLat) / this.sourceHeight;
    const lonStep = (maxLon - minLon) / this.scaledWidth;
    const latStep = (maxLat - minLat) / this.scaledHeight;

    // Шаг 1: Вычисление предварительной маски
    for (let y = 0; y < this.sourceHeight; y++) {
      for (let x = 0; x < this.sourceWidth; x++) {
        const lon1 = minLon + x * sourceLonStep;
        const lat1 = maxLat - y * sourceLatStep;
        const lon2 = lon1 + sourceLonStep;
        const lat2 = lat1 - sourceLatStep;

        const pixelPolygon: [number, number][] = [
          [lon1, lat1],
          [lon2, lat1],
          [lon2, lat2],
          [lon1, lat2],
        ];

        const verticesInsideMain = pixelPolygon.filter((point) => mainPolygons.some((polygon) => this.isPointInPolygon(point, polygon)));

        const verticesInsideHoles = pixelPolygon.filter((point) => holes.some((hole) => this.isPointInPolygon(point, hole)));

        if (verticesInsideMain.length === 4 && verticesInsideHoles.length === 0) {
          preliminaryMask[y * this.sourceWidth + x] = 1; // Все вершины внутри mainPolygon, но не внутри holes
        } else if (verticesInsideMain.length === 0 || verticesInsideHoles.length === 4) {
          preliminaryMask[y * this.sourceWidth + x] = 0; // Полностью вне mainPolygon или внутри holes
        } else {
          preliminaryMask[y * this.sourceWidth + x] = 2; // Частично пересекает mainPolygon
        }
      }
    }

    // Шаг 2: Увеличение предварительной маски
    for (let y = 0; y < this.sourceHeight; y++) {
      for (let x = 0; x < this.sourceWidth; x++) {
        const value = preliminaryMask[y * this.sourceWidth + x];
        for (let dy = 0; dy < BASE_MAP_IMAGE_SCALE_SIZE; dy++) {
          for (let dx = 0; dx < BASE_MAP_IMAGE_SCALE_SIZE; dx++) {
            const scaledY = y * BASE_MAP_IMAGE_SCALE_SIZE + dy;
            const scaledX = x * BASE_MAP_IMAGE_SCALE_SIZE + dx;
            clearMask[scaledY * this.scaledWidth + scaledX] = value;
          }
        }
      }
    }

    // Шаг 3: Уточнение пикселей с типом 2
    for (let y = 0; y < this.scaledHeight; y++) {
      for (let x = 0; x < this.scaledWidth; x++) {
        const index = y * this.scaledWidth + x;

        if (clearMask[index] === 2) {
          const lon1 = minLon + x * lonStep;
          const lat1 = maxLat - y * latStep;
          const lon2 = lon1 + lonStep;
          const lat2 = lat1 - latStep;

          const pixelPolygon: [number, number][] = [
            [lon1, lat1],
            [lon2, lat1],
            [lon2, lat2],
            [lon1, lat2],
          ];

          const anyInsideMain = pixelPolygon.some((point) => mainPolygons.some((polygon) => this.isPointInPolygon(point, polygon)));

          const anyInsideHoles = pixelPolygon.some((point) => holes.some((hole) => this.isPointInPolygon(point, hole)));

          clearMask[index] = anyInsideMain && !anyInsideHoles ? 1 : 0;
        }
      }
    }

    return {
      clearMask,
      packedMask: this.packMask(clearMask),
    };
  }

  // Вспомогательная функция для проверки принадлежности точки полигону
  private isPointInPolygon(point: [number, number], polygon: Position[]): boolean {
    const [lon, lat] = point;
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const [xi, yi] = polygon[i];
      const [xj, yj] = polygon[j];

      const intersect = yi > lat !== yj > lat && lon < ((xj - xi) * (lat - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  private packMask(mask: Uint8Array): string[] {
    const packedMask: string[] = [];
    let currentValue = mask[0];
    let count = 1;

    for (let i = 1; i < mask.length; i++) {
      if (mask[i] === currentValue) {
        count++;
      } else {
        packedMask.push(`${currentValue}:${count}`);
        currentValue = mask[i];
        count = 1;
      }
    }

    // Добавляем последний блок
    packedMask.push(`${currentValue}:${count}`);
    return packedMask;
  }
}

export default MaskProcessor;
