import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(utc);

type DayUnitType = 'millisecond' | 'second' | 'minute' | 'hour'| 'year' | 'month' | 'week' | 'day'

class Day {
  private _date: dayjs.Dayjs = dayjs();

  constructor(date?: dayjs.ConfigType) {
    if (date) {
      this._date = dayjs(date);
    }
  }

  public static duration(input: string | number, unit: DayUnitType): Duration {
    return dayjs.duration(Number(input), unit);
  }

  public static utc(config?: dayjs.ConfigType, format?: string): dayjs.Dayjs {
    return dayjs.utc(config, format);
  }

  public diff(date: dayjs.ConfigType | Day, unit?: dayjs.UnitType, float?: boolean): number {
    return this._date.diff(date instanceof Day ? date._date : date, unit, float);
  }

  public format(template?: string): string {
    return this._date.format(template);
  }

  public to(compared: Day, withoutSuffix?: boolean): string {
    return this._date.to(compared._date, withoutSuffix);
  }

  public from(withoutSuffix?: boolean): string {
    return this._date.fromNow(withoutSuffix);
  }

  /**
   * Returns Day value in unix timestamp (milliseconds)
   */
  public currentTime() {
    return this._date.valueOf();
  }

  public isSameOrAfter(date: dayjs.ConfigType | Day, unit?: dayjs.OpUnitType): boolean {
    return this._date.isSameOrAfter(date instanceof Day ? date._date : date, unit);
  }

  public utc(): dayjs.Dayjs {
    return this._date.utc();
  }

  public toString(): string {
    return this._date.toString();
  }

  public valueOf(): number {
    return this._date.valueOf();
  }
}

export default new Day();
