import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_dialog = _resolveComponent("ui-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      link: "",
      type: "primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isModel = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('stats-for-field',{ns:'taskMap'})), 1)
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.isModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModel) = $event)),
        header: _ctx.$t('stats-for-field-title-modal',{ns:'taskMap'}),
        width: "90%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table, {
            data: _ctx.tableData,
            "summary-method": _ctx.calculateSum,
            "show-summary": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "fieldName",
                label: "Поля",
                "min-width": 150
              }),
              _createVNode(_component_el_table_column, {
                prop: "area",
                label: "Плошадь, га",
                "min-width": 150
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.formatNumber(scope.row.area)), 1)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.materials, (m) => {
                return (_openBlock(), _createBlock(_component_el_table_column, {
                  label: `${m.name}, ${_ctx.getUnitLabel(m.unit)}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('stat-table-title-norma','Cплошное внесение нормой',{ns:'taskMap'}),
                      property: `${m.name}.norma`,
                      "min-width": 200
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.norma)), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "property"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('stat-table-title-diff','Дифф. внесение',{ns:'taskMap'}),
                      property: `${m.name}.diff`,
                      "min-width": 150
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.diff)), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "property"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('stat-table-title-delta','Дельта',{ns:'taskMap'}),
                      property: `${m.name}.delta`,
                      "min-width": 150
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.delta)), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "property"])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 256))
            ]),
            _: 1
          }, 8, ["data", "summary-method"])
        ]),
        _: 1
      }, 8, ["modelValue", "header"])
    ]))
  ], 64))
}