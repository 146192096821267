import { computed, ref } from 'vue';
import { RasterModel } from '@/models/RasterModel';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

class MapRastersList {
  private _rasters= ref<RasterModel[]>([])

  private _activeEditRaster =ref<RasterModel>()

  rasters = computed({
    get: () => this._rasters.value,
    set: (r) => {
      r.sort((a, b) => (a.raster_type < b.raster_type ? -1 : 1)).forEach((raster, index) => { r[index].zIndex = index + 1; });
      this._rasters.value = r;

      EventBus.$emit(EventsEnum.UpdateRaster);
    },
  });

  activeEditRaster = computed({
    get: () => this._activeEditRaster.value,
    set: (v) => {
      this._activeEditRaster.value = v;
    },
  })

  setEditRaster(id: number) {
    const object = this.rasters.value?.find((item) => item.id === id);
    if (object) {
      this.activeEditRaster.value = object as RasterModel;
    }
    return (object as RasterModel) || undefined;
  }

  getRasterBySource = (source: string) => this._rasters.value.find((r) => r.source === source);
}
export default new MapRastersList();
