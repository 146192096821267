<template src="./SidePanelContent.pug" lang="pug"/>
<style src="./SidePanelContent.scss" lang="scss"/>

<script lang="ts">
import { defineComponent, Slots, useSlots } from 'vue';

export default defineComponent({
  name: 'SidePanelContent',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const slots = useSlots();

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    return {
      hasSlot,
    };
  },
});
</script>
