import { Bus } from '@/lib/classes/Bus';
import { CropRotationSeasonModel } from '@/models/cropRotation/CropRotationSeasonModel';

enum Events {
  SELECT_ACTIVE_SEASON,
}

class CropRotationEvents extends Bus {
  onSelectActiveSeason(callback: (season: CropRotationSeasonModel | undefined) => void, once = false) {
    this.registerListener(Events.SELECT_ACTIVE_SEASON, callback, once);
  }

  offSelectActiveSeason(callback: (season: CropRotationSeasonModel | undefined) => void) {
    this.unregisterListener(Events.SELECT_ACTIVE_SEASON, callback);
  }

  emitSelectActiveSeason(season: CropRotationSeasonModel | undefined) {
    this.emitListeners(Events.SELECT_ACTIVE_SEASON, season);
  }
}

export default new CropRotationEvents();
