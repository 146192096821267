<template lang="pug" src="./ZoomControlBlock.pug"/>
<style lang="scss" src="./ZoomControlBlock.scss"/>

<script lang="ts">
import { MapModel } from '@/models/map/MapModel';
import mapboxgl from 'mapbox-gl';
import {
  defineComponent, PropType, ref, UnwrapRef,
} from 'vue';

export default defineComponent({
  name: 'ZoomControlBlock',
  components: {},
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
  },
  setup(props) {
    const zoom = ref(props.map.map.getZoom().toFixed(2));

    (props.map.map as mapboxgl.Map).on('zoom', () => {
      zoom.value = props.map.map.getZoom().toFixed(2);
    });

    return {
      zoom,
    };
  },
});
</script>
