import { useAuth } from '@/composables/useAuth';
import AppLayout from '@/modules/core/layouts/AppLayout/AppLayout.vue';
import AuthLayout from '@/modules/core/layouts/AuthLayout/AuthLayout.vue';
import UnavailableLayout from '@/modules/core/layouts/UnavailableLayout/UnavailableLayout.vue';
import AdminListContent from '@/pages/AdminListContent/AdminListContent.vue';
import AsfOrderConfirmContent from '@/pages/AsfOrderConfirmContent/AsfOrderConfirmContent.vue';
import FieldCropRateManageContent
  from '@/pages/FieldCropRateManageContent/FieldCropRateManageContent.vue';
import MapListContent from '@/pages/MapListContent/MapListContent.vue';
import RatingNdviContent from '@/pages/RatingNdviContent/RatingNdviContent.vue';
import SatelliteContent from '@/pages/SatelliteContent/SatelliteContent.vue';
import StructListContent from '@/pages/StructListContent/StructListContent.vue';
import CreateBaseMapContent from '@/pages/task-map/create/base-map/CreateBaseMapContent.vue';
import TaskMapIndexContent from '@/pages/task-map/create/desiccation-map/TaskMapIndexContent.vue';
import ExperimentContent
  from '@/pages/task-map/create/experiments/ui/ExperimentContent/ExperimentContent.vue';
import TaskMapContent from '@/pages/task-map/create/TaskMapContent.vue';
import WorkMapContent from '@/pages/task-map/create/work/WorkMapContent.vue';
import UserListContent from '@/pages/UserListContent/UserListContent.vue';
import UserPage from '@/pages/UserPage/UserPage.vue';
import { changelogMiddleware } from '@/router/middlewares/changelog';
import { loggerMiddleware } from '@/router/middlewares/logger';
import { structIdChecker } from '@/router/middlewares/structId';
import { titleMiddleware } from '@/router/middlewares/title';
import AuthService from '@/services/auth/AuthService';
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main-page',
    component: () => import(/* webpackChunkName: "main-page" */ '@/pages/MainPage/MainPage.vue'),
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      await AuthService.tryStorageLogin();
      if (useAuth().isAuth()) {
        next({ name: 'app' });
      } else {
        next({ name: 'login' });
      }
    },
  },
  {
    path: '/login',
    redirect: '/user/login/',
  },
  {
    path: '/struct-select',
    name: 'struct-select',
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      await AuthService.tryStorageLogin();
      next();
    },
    component: () => import(/* webpackChunkName: "struct-select" */ '@/pages/StructSelect/StructSelect.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: AppLayout,
    beforeEnter: async (to, from, next) => {
      await AuthService.tryStorageLogin();
      if (!useAuth().isAuth()) {
        next({ name: 'login' });
      } else {
        next();
      }
    },
    children: [

      {
        path: 'struct/:structId(\\d+)',
        name: 'struct',
        meta: {
          authRequire: true,
        },
        children: [
          {
            name: 'crop-rotation',
            path: 'crop-rotation',
            component: () => import(/* webpackChunkName: "crop-rotation" */ '@/pages/CropRotationContent/CropRotationContent.vue'),
            meta: {
              width: 600,
            },
          },
          {
            name: 'integration',
            path: 'integration',
            component: () => import(/* webpackChunkName: "integration" */ '@/pages/IntegrationContent/IntegrationContent.vue'),
            meta: {
              width: 600,
            },
          },
          {
            name: 'satellite',
            path: 'fields-satellite',
            component: SatelliteContent,
            meta: {
              authRequire: true,
              width: 300,
            },
          },
          {
            name: 'fields-crop-rate',
            path: 'fields-crop-rate',
            component: FieldCropRateManageContent,
            meta: {
              authRequire: true,
              width: 300,
            },
          },
          {
            name: 'asf-order',
            path: 'asf-order',
            component: AsfOrderConfirmContent,
            meta: {
              authRequire: true,
              width: 300,
            },
          },
          {
            name: 'task-map',
            path: 'task-map',
            meta: {
              authRequire: true,
              width: 400,
            },
            children: [
              {
                path: 'create',
                children: [
                  {
                    name: 'task-map-create',
                    path: '',
                    component: TaskMapContent,
                  },
                  {
                    name: 'work-map-create',
                    path: 'work',
                    component: WorkMapContent,
                  },
                  {
                    name: 'desiccation-map-create',
                    path: 'desiccation-map',
                    component: TaskMapIndexContent, // создание рабочей карты по индексу
                    meta: {
                      authRequire: true,
                      width: 450,
                    },
                  },
                  {
                    name: 'BaseMap-create',
                    path: 'BaseMap',
                    component: CreateBaseMapContent, // создание шаблон карты
                    meta: {
                      authRequire: true,
                      width: 400,
                    },
                  },
                  {
                    name: 'experiments-create',
                    path: 'experiments',
                    component: ExperimentContent, // создание эксперементов
                    meta: {
                      authRequire: true,
                      width: 400,
                    },
                  },
                ],
              },
            ],
          },
          // todo подумать насчет шагов потому что сейчас нельзя положить в родителя
          // {
          //   name: 'task-map-norma',
          //   path: 'task-map-norma',
          //   component: TaskMapNormaFormBlock,
          //   meta: {
          //     width: 400,
          //   },
          // },
          // {
          //   name: 'task-map-zona',
          //   path: 'task-map-zona',
          //   component: TaskMapZoneFormBlock,
          //   meta: {
          //     width: 400,
          //   },
          // },
          {
            name: 'task-map-index',
            path: 'task-map-index',
            component: TaskMapIndexContent,
            meta: {
              authRequire: true,
              width: 450,
            },
          },
          {
            name: 'rating-ndvi',
            path: 'rating-ndvi',
            component: RatingNdviContent,
            meta: {
              authRequire: true,
              width: 500,
            },
          },
          {
            name: 'administration',
            path: 'settings',
            component: AdminListContent,
            meta: {
              authRequire: true,
              width: 300,
            },
            children: [
              {
                name: 'SettingsStruct',
                path: 'settings-structs',
                component: StructListContent,
                meta: {
                  authRequire: true,
                  width: 300,
                },
              },
              {
                name: 'SettingsUsers',
                path: 'settings-users',
                component: UserListContent,
                meta: {
                  authRequire: true,
                  width: 300,
                },
              },
              {
                name: 'SettingsMaps',
                path: 'settings-maps',
                component: MapListContent,
                meta: {
                  authRequire: true,
                  width: 300,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    component: AuthLayout,
    beforeEnter: async (to, from, next) => {
      await AuthService.tryStorageLogin();
      if (useAuth().isAuth()) {
        next({ name: 'app' });
      }
      next();
    },
    children: ['login', 'recovery', 'recovered', 'reset', 'resetDone'].map((k) => ({
      path: k, name: k, component: UserPage, meta: { mode: k },
    })),
  },
  {
    path: '/maintenance',
    name: 'unavailable',
    component: UnavailableLayout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

titleMiddleware(router);

changelogMiddleware(router);

loggerMiddleware(router);

structIdChecker(router);

export default router;
