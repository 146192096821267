import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { RosreestrMapLayers } from '@/assets/data/RosreestrMapLayers';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';

export class MapLayerRosreestrModel extends MapLayerModel {
  constructor(mapModel: MapModel) {
    super(mapModel, MapLayerTypeEnum.ROSREESTR, 'rosreestr', 'rosreestr');
    this.createSourceRosreestr();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  createSourceRosreestr(): void {
    // https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/tile/{z}/{y}/{x}.pbf
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'vector',
      bounds: [
        -180,
        -85.0511,
        180,
        85.0511,
      ],
      minzoom: 0,
      maxzoom: 19,
      scheme: 'xyz',
      tiles: [process.env.VUE_APP_ROSREESTR_TILES],
      // url: 'https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/resources',
    });

    const layers = RosreestrMapLayers(this.sourceId);

    layers.forEach((layer) => {
      this._mapModel?.map?.addLayer(layer);
      this._mapModel?.map?.moveLayer(layer.id, MapAnchorEnum.VECTOR);
    });
  }

  remove(): void {
    const layers = RosreestrMapLayers(this.sourceId);
    layers.forEach((layer) => {
      this._mapModel?.map?.removeLayer(layer.id);
    });
  }
}
