<template lang="pug"  src="./ToolSearch.pug">
</template>

<style src="./ToolSearch.scss" lang="scss"></style>

<script lang="ts">
import {
  defineComponent, PropType, reactive, ref, UnwrapRef,
} from 'vue';
import { Aim, Search, Switch } from '@element-plus/icons-vue';
import { AddressDto } from '@/services/api/dto/streetMap/AddressDto';
import ApiService from '@/services/api/ApiService';
import UiLoading from '@/components/ui/Loading/UiLoading.vue';
import WindowBox from '@/components/shared/WindowBox/WindowBox.vue';
import { MapModel } from '@/models/map/MapModel';

export default defineComponent({

  components: {
    UiLoading,
    WindowBox,
    Search,
  },
  name: 'ToolSearch',
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
  },
  setup(props) {
    const search = ref<string>('');
    const addressSearch = ref<AddressDto[]| any>([]);
    const active = ref<boolean>(false);
    const searchValue = ref(false);
    let timer: ReturnType<typeof setTimeout>;
    const loading = ref(false);
    const map = reactive(props.map);

    const flyTo = (lat: string, lng: string) => {
      // @TODO
      map.map.flyTo({
        center: [
          Number(lng),
          Number(lat),
        ],
        essential: true,
        zoom: 15,
      });
    };

    const calculateCoordinates = () => {
      const t = search.value.toLowerCase().trim();
      const removeWords = ['longitude', 'lang', 'lng', 'latitude', 'lat', 'll', 'lt'];
      const coords = t.split(' ').filter((w) => !removeWords.includes(w));
      if (coords.length === 2 && coords.every((n: any) => !Number.isNaN(n) && n >= -180 && n <= 180)) {
        return coords;
      }
      return undefined;
    };

    const getlist = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const coords = calculateCoordinates();
        if (coords) {
          searchValue.value = true;
          addressSearch.value = [{
            lat: coords[0], lng: coords[1], place_id: 'cords',
          }];
        } else {
          loading.value = true;
          addressSearch.value.splice(0, addressSearch.value.length);
          searchValue.value = true;
          const list = await ApiService.streetMap.getAddress({ q: search.value });
          if (list.status === 200) {
            loading.value = false;
          }
          addressSearch.value.push(...list.data);
        }
      }, 1000);
    };

    const switchCoords = (item: any) => {
      const lng = item.lng;
      const lat = item.lat;
      addressSearch.value = [{
        lng: lat, lat: lng, place_id: 'cords',
      }];
    };

    return {
      search,
      getlist,
      addressSearch,
      flyTo,
      active,
      searchValue,
      loading,
      Aim,
      Switch,
      switchCoords,
    };
  },
});
</script>
