import { useAuth } from '@/composables/useAuth';
import { useUser } from '@/composables/useUser';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import { fetcher } from '@/lib/tools/fetcher';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import { SocketEventsRegister } from '@/services/socket/events/SocketEventsRegister';

class SocketService {
  private Socket: WebSocket | undefined;

  constructor() {
    SocketEventsRegister();
  }

  async fetchServiceToken() {
    if (!useAuth().serviceToken.value && useUser().isLoaded.value) {
      await fetcher(LoadingNamesEnum.SERVICE_TOKEN, 'default', true, async () => {
        LoggerService.from(LoggerGroupsEnum.SOCKET).group('Try to connect to socket.');
        const { data } = await ApiService.user.getServiceToken();

        LoggerService.from(LoggerGroupsEnum.SOCKET).group(`Service token: ${data?.service_token}`);
        useAuth().serviceToken.value = data.service_token;
      }, true);
    }
  }

  setEvents() {
    if (!this.Socket) {
      return;
    }
    this.Socket.onopen = () => {
      LoggerService.from(LoggerGroupsEnum.SOCKET).group('[open] Connection established. ');
    };
    this.Socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data) as SocketMessageType;
        LoggerService.from(LoggerGroupsEnum.SOCKET).group('[message] Data received from server: ', message);
        EventBus.$emit(EventsEnum.SocketMessage, message);
      } catch (e) {
        LoggerService.error('Socket data is invalid.', e);
      }
    };
    this.Socket.onclose = (event) => {
      if (event.wasClean) {
        LoggerService.from(LoggerGroupsEnum.SOCKET).warn(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}.`);
        this.Socket = undefined;
      } else {
        LoggerService.from(LoggerGroupsEnum.SOCKET).error('[close] Connection died.');
        this.Socket = undefined;
      }
    };
    this.Socket.onerror = (error) => {
      LoggerService.from(LoggerGroupsEnum.SOCKET).error('[error]: ', error);
      this.Socket = undefined;
    };
  }

  ping() {
    setInterval(() => {
      if (!this.Socket) {
        this.connect();
      }
    }, 2000);
  }

  async connect() {
    if (useUser().isLoaded.value) {
      await this.fetchServiceToken();
      if (useAuth().serviceToken.value && process.env.VUE_APP_WS_HOST) {
        const url = `wss://${process.env.VUE_APP_WS_HOST}/ws?token=${useAuth().serviceToken.value}`;

        LoggerService.from(LoggerGroupsEnum.SOCKET).group(`Connection URL: ${url}`);
        this.Socket = new WebSocket(url);
        this.setEvents();
      }
    }
  }
}

export default new SocketService();
