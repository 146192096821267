export const RuleFieldName = [
  {
    required: true,
    message: 'Введите краткое наименование поля',
    trigger: 'blur',
  },
  {
    min: 2,
    max: 80,
    message: 'Длина краткого наименования должна быть от 2-ух до 80-ти символов',
    trigger: 'blur',
  },
  {
    pattern: /^[a-z0-9-_]*$/gi,
    message: 'Наименование может содержать только латинские символы, знак подчеркивания и тире. И не должно содержать пробелов.',
    trigger: 'blur',
  },
];
