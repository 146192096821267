<template lang="pug"></template>
<script lang="ts">
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import { BrushFilled, Grid, Pointer } from '@element-plus/icons-vue';
import {
  defineComponent, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'BaseMapPreview',
  components: {
    Pointer,
    Grid,
    BrushFilled,
  },
  setup() {
    const {
      activeCandidate,
    } = useCreateBaseMap();

    const {
      activeTool,
    } = useBaseMapEditor();

    const {
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const width = ref(0);
    const height = ref(0);

    const activeZone = ref(1);

    const layer = ref<MapLayerCanvasModel>();

    const canvasId = 'CreateBaseMap-preview';

    const canvasElement = ref<HTMLCanvasElement>();

    const canvasCtx = ref<CanvasRenderingContext2D>();

    /** Обновить изображение canvas при изменении данных в модели изображения */
    const updateImage = () => {
      if (canvasCtx.value) {
        const buffer = (canvasCtx.value.getImageData(0, 0, width.value || 0, height.value || 0)).data.buffer;
        const data = new Uint8ClampedArray(buffer);

        activeCandidate.value.taskImage.imageDataZoned.forEach((pxl: number, i: number) => {
          data[i * 4] = BaseMapColors[pxl][0];
          data[i * 4 + 1] = BaseMapColors[pxl][1];
          data[i * 4 + 2] = BaseMapColors[pxl][2];
          data[i * 4 + 3] = BaseMapColors[pxl][3];
        });
        canvasCtx.value.clearRect(0, 0, width.value, height.value);
        canvasCtx.value.putImageData(new ImageData(data, width.value, height.value), 0, 0);
        layer.value.updateImage();
      }
    };

    const initImageData = () => {
      if (activeCandidate.value?.taskImage?.zones
        && activeCandidate.value.taskImage.zones.length
        && canvasElement.value
      ) {
        width.value = activeCandidate.value.taskImage.scaledWidth;
        height.value = activeCandidate.value.taskImage.scaledHeight;
        activeZone.value = activeCandidate.value.taskImage.zones[0].value;
        canvasElement.value.width = width.value;
        canvasElement.value.height = height.value;
        updateImage();
      }
    };

    const createCanvas = () => {
      const canvas = document.createElement('canvas');
      canvas.id = canvasId;
      canvas.style.display = 'none';
      document.body.querySelector('.MapContainer-main-map')?.appendChild(canvas);

      const cBbox = activeCandidate.value.taskImage.bbox;
      const model = new MapCanvasModel(canvasId, [[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);

      layer.value = mapModel.value?.render(model) as MapLayerCanvasModel;
      canvasElement.value = model.canvas;
      canvasCtx.value = model.ctx;
    };

    onMounted(() => {
      createCanvas();
      initImageData();
    });

    onBeforeUnmount(() => {
      document.getElementById(canvasId).outerHTML = '';
      mapModel.value?.removeLayer(layer.value?.uuid);
    });

    watch(() => activeCandidate.value.taskImage.imageDataZoned, () => {
      updateImage();
    });

    // endregion

    return {
      activeCandidate,
      activeTool,
      width,
      height,
      activeZone,
      BaseMapColors,
    };
  },
});
</script>
