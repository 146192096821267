import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_ctx.item.block || _ctx.item.route || _ctx.item.callback)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["NavigationPanelItem", { active: _ctx.isActive(_ctx.item) }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open(_ctx.item)))
      }, [
        (_ctx.mode === 'collapse')
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              content: _ctx.$t(`${_ctx.item.name}`, _ctx.item.name, { ns: 'navigation' }),
              placement: "right"
            }, {
              default: _withCtx(() => [
                (_ctx.item?.icon)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["NavigationPanelItem-itemIcon", { active: _ctx.isActive(_ctx.item) }])
                    }, [
                      _createVNode(_component_ui_icon, {
                        name: _ctx.icon,
                        size: 32
                      }, null, 8, ["name"])
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["content"]))
          : _createCommentVNode("", true),
        (_ctx.mode === 'expand')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.item?.icon)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["NavigationPanelItem-itemIcon", { active: _ctx.isActive(_ctx.item) }])
                  }, [
                    _createVNode(_component_ui_icon, {
                      name: _ctx.icon,
                      size: 32
                    }, null, 8, ["name"])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["NavigationPanelItem-itemName", { active: _ctx.isActive(_ctx.item) }])
              }, _toDisplayString(_ctx.$t(`${_ctx.item.name}`, _ctx.item.name, { ns: 'navigation' })), 3)
            ], 64))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}