import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ImagesPanel" }
const _hoisted_2 = { class: "ImagesPanel-actions" }
const _hoisted_3 = {
  key: 0,
  class: "ImagesPanel-container"
}

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_ImagesTree = _resolveComponent("ImagesTree")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_tree_select = _resolveComponent("el-tree-select")
  const _component_el_form = _resolveComponent("el-form")
  const _component_ui_dialog = _resolveComponent("ui-dialog")
  const _component_ImagesContainer = _resolveComponent("ImagesContainer")
  const _component_SidePanelContent = _resolveComponent("SidePanelContent")

  return (_openBlock(), _createBlock(_component_SidePanelContent, {
    title: _ctx.$t('images-panel', { ns: 'side-panel'}),
    onClose: _cache[7] || (_cache[7] = $event => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tooltip, {
            content: _ctx.$t('create-folder', { ns: 'side-panel' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                circle: "",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.showCreateFolder = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, {
                    name: "mdiFolderPlusOutline",
                    color: "currentColor"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["content"]),
          _createVNode(_component_el_tooltip, {
            content: _ctx.$t('show-images-container', { ns: 'side-panel' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                circle: "",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.showImagesContainer = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, {
                    name: "mdiFolderTableOutline",
                    color: "currentColor"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["content"])
        ]),
        (_ctx.StructFilesList.groups.value && _ctx.StructFilesList.vectors && _ctx.StructFilesList.rasters)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ImagesTree, {
                group: _ctx.StructFilesList.groups.value,
                "root-group": true,
                "map-container": _ctx.mapContainer,
                "is-compare": _ctx.isCompare
              }, null, 8, ["group", "map-container", "is-compare"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(_component_ui_dialog, {
          modelValue: _ctx.showCreateFolder,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.showCreateFolder) = $event)),
          header: _ctx.$t('create-folder', { ns: 'side-panel' }),
          width: 400
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = $event => (_ctx.showCreateFolder = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _ctx.createFolder,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, { "label-position": "top" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('create-folder-form-name', { ns: 'side-panel' })
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.createFolderForm.name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.createFolderForm.name) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('create-folder-form-parent', { ns: 'side-panel' })
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tree_select, {
                      modelValue: _ctx.createFolderForm.parent,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.createFolderForm.parent) = $event)),
                      "check-strictly": "",
                      data: _ctx.groupsTree
                    }, null, 8, ["modelValue", "data"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "header"]),
        _createVNode(_component_ui_dialog, {
          modelValue: _ctx.showImagesContainer,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.showImagesContainer) = $event)),
          header: _ctx.$t('images-container', 'Загруженные векторы и растры', { ns: 'side-panel' }),
          width: 800
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ImagesContainer, {
              "map-container": _ctx.mapContainer,
              "is-compare": _ctx.isCompare
            }, null, 8, ["map-container", "is-compare"])
          ]),
          _: 1
        }, 8, ["modelValue", "header"])
      ]))
    ]),
    _: 1
  }, 8, ["title"]))
}