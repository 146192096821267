import { IntegrationModel } from '@/models/integration/IntegrationModel';
import ApiService from '@/services/api/ApiService';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { TechModel } from '@/models/tech/TechModel';
import { fetcher } from '@/lib/tools/fetcher';
import { Ref, ref, UnwrapRef } from 'vue';

class IntegrationsList {
  get integrations(): Ref<UnwrapRef<IntegrationModel[]>> {
    return this._integrations;
  }

  get tech(): TechModel[] {
    return this._tech;
  }

  private _integrations = ref<IntegrationModel[]>([]);

  private _tech: TechModel[] = [];

  async fetchIntegrations(force = false) {
    await fetcher(LoadingNamesEnum.INTEGRATIONS, 'default', force, async () => {
      this._integrations.value.splice(0, this._integrations.value.length);
      const { data } = await ApiService.integrations.getIntegrations();
      data.forEach((dto) => this._integrations.value.push(new IntegrationModel(dto)));
    });
  }

  async fetchTech(force = false) {
    await fetcher(LoadingNamesEnum.INTEGRATION_TECH, 'default', force, async () => {
      this._tech.splice(0, this._tech.length);
      const { data } = await ApiService.tech.getTechDevices();
      data.forEach((dto) => this._tech.push(new TechModel(dto)));
    });
  }
}

export default new IntegrationsList();
