import { VectorKeyType } from '@/constants/types/VectorKeyType';

export const analyzeVector = (data: { properties: Record<string, string | number | null> }[]): Record<string, VectorKeyType> => {
  const analysis: Record<string, { isNumber: boolean; precision: number; min: number; max: number; values: Map<string | number | null, number> }> = {};

  data.forEach((item) => {
    const properties = item.properties;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in properties) {
      const value = properties[key];
      const isNumber = value === null || value === '' || !Number.isNaN(Number(value));

      if (!analysis[key]) {
        analysis[key] = {
          isNumber: true,
          precision: 0,
          min: Infinity,
          max: -Infinity,
          values: new Map<string | number | null, number>(),
        };
      }

      const currentAnalysis = analysis[key];

      // Update isNumber flag
      currentAnalysis.isNumber = currentAnalysis.isNumber && isNumber;

      // Calculate precision, min, and max if the value is a number
      if (isNumber && value !== null && value !== '') {
        const numValue = Number(value);
        const decimalPlaces = (numValue.toString().split('.')[1] || '').length;
        currentAnalysis.precision = Math.max(currentAnalysis.precision, decimalPlaces);
        currentAnalysis.min = Math.min(currentAnalysis.min, numValue);
        currentAnalysis.max = Math.max(currentAnalysis.max, numValue);
      }

      const normalizeValue = (v: string | number | null) => {
        if (v === null) return null;
        if (isNumber) {
          return v === '' ? null : Number(v);
        }
        return v;
      };

      // Update values map
      const normalizedValue = normalizeValue(value);
      currentAnalysis.values.set(normalizedValue, (currentAnalysis.values.get(normalizedValue) || 0) + 1);
    }
  });

  // Convert Map to array of values and prepare the final result
  const result: Record<string, VectorKeyType> = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in analysis) {
    const {
      isNumber, precision, min, max, values,
    } = analysis[key];
    result[key] = {
      number: isNumber && min !== Infinity && max !== -Infinity ? { precision, min, max } : null,
      values: Array.from(values.entries()).map(([value, count]) => ({ value, count })),
    };
  }

  return result;
};
