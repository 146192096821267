import { SatelliteFieldIndexFileDto } from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';
import { Polygon } from 'geojson';

export class MonitoringFileModel {
  get geometry(): Polygon {
    return this._geometry;
  }

  get tiffFileUrl(): string {
    return this._tiffFileUrl;
  }

  get contrastFileUrl(): string | undefined {
    return this._contrastFileUrl;
  }

  get coloredFileUrl(): string | undefined {
    return this._coloredFileUrl;
  }

  get kind(): number {
    return this._kind;
  }

  get field(): number {
    return this._field;
  }

  get scene(): number {
    return this._scene;
  }

  get date(): Date {
    return this._date;
  }

  private readonly _date: Date;

  private readonly _coloredFileUrl: string | undefined;

  private readonly _contrastFileUrl: string | undefined;

  private readonly _tiffFileUrl: string;

  private readonly _geometry: Polygon;

  private readonly _kind: number;

  private readonly _field: number;

  private readonly _scene: number;

  constructor(dto: SatelliteFieldIndexFileDto) {
    this._date = new Date(dto.data_date);
    this._coloredFileUrl = dto.img_colored_file;
    this._contrastFileUrl = dto.img_contrast_file;
    this._tiffFileUrl = dto.tif_file;
    this._geometry = dto.geom_envelope;
    this._kind = dto.kind;
    this._field = dto.field;
    this._scene = dto.scene || 0;
  }
}
