import { computed } from 'vue';

export const useFormatter = () => {
  const formatValue = computed(() => (value: unknown) => {
    if (typeof value === 'boolean') {
      return value ? 'да' : 'нет';
    }
    if (value === 'true' || value === 'false') {
      return value === 'true' ? 'да' : 'нет';
    }
    if (typeof value === 'number') {
      return value.toString();
    }
    if (typeof value === 'undefined') {
      return '';
    }
    if (value === null) {
      return '';
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value;
  });

  const ucFirst = computed(() => (value: string) => value.charAt(0).toUpperCase() + value.slice(1));

  const formatEwkt = (coordinates: number[][]) => {
    const points: string[] = [];
    coordinates.forEach((point: number[]) => {
      points.push(point.join(' '));
    });
    if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] || coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
      points.push(coordinates[0].join(' '));
    }
    return `SRID=4326;MULTIPOLYGON (((${points.join(', ')})))`;
  };

  const formatPointEwkt = (coordinates: []) => `SRID=4326;POINT (${coordinates.join(' ')})`;

  return {
    formatValue,
    formatEwkt,
    formatPointEwkt,
    ucFirst,
  };
};
