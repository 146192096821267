import { computed, ref } from 'vue';
import { MapBackingType } from '@/constants/types/MapBackingType';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { DrawerStatusComposableType } from '@/constants/types/DrawerStatusComposableType';
import MapStyleSpecification from '@/constants/types/mapbox/MapStyleSpecification';

const mapBacking = ref<MapBackingType>('openstreetmap1');

const showContour = ref(true);

const showFieldsLabel = ref(true);

type LabelStructKeysType = 'name' | 'name_rus' | 'square';

const labelStruct = ref({
  name: true,
  name_rus: true,
  square: true,
});

const rulerActive = ref(false);

const mapLoaded = ref(false);

const vectorData = ref();

const mapStyle = ref<MapStyleSpecification>();

const isFlying = ref(false);

const hoveredFieldId = ref<string | number | undefined>();
const activeFieldId = ref<number | undefined>();
const hoveredPOIId = ref<string | number | undefined>();

const currentCenter = ref({ lng: 0, lat: 0 });
const currentZoom = ref(0);

const rasterLayerActive = ref('');

const rotate = ref(0);

const blockRulerTool = ref(false);

const drawerStatus = ref<DrawerStatusComposableType>({
  mode: 'inactive',
});
const activeCanvasNDVI = ref(false);

const areaActive = ref<boolean>(false);

EventBus.$on(EventsEnum.UserLogout, () => {
  currentCenter.value = { lng: 0, lat: 0 };
  activeFieldId.value = undefined;
  hoveredPOIId.value = undefined;
  currentZoom.value = 0;
  rulerActive.value = false;
  areaActive.value = false;
});

// todo удалить полностью

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMap = () => {
  const setMapBacking = (layer: MapBackingType) => {
    mapBacking.value = layer;
    EventBus.$emit(EventsEnum.MapBackingChanged);
  };

  const toggleRulerActive = () => {
    rulerActive.value = !rulerActive.value;
    EventBus.$emit(EventsEnum.MapToggleRuler);
  };

  const rulerClose = (silence = false) => {
    rulerActive.value = false;
    if (!silence) {
      EventBus.$emit(EventsEnum.MapToggleRuler);
    }
  };

  const toggleAreaActive = () => {
    if (drawerStatus.value.mode === 'create') {
      drawerStatus.value.mode = 'inactive';
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
    } else {
      drawerStatus.value.mode = 'create';
      EventBus.$emit(EventsEnum.MapPolygonDrawStart);
    }
  };

  const areaClose = (silence = false) => {
    // drawerStatus.value.mode = 'inactive';
    // if (!silence) {
    //   EventBus.$emit(EventsEnum.MapToggleArea);
    // }
  };

  const isToolActivated = () => rulerActive.value || drawerStatus.value.mode !== 'inactive';

  const toggleLabelStruct = (key: LabelStructKeysType) => {
    // labelStruct.value[key] = !labelStruct.value[key];
    // EventBus.$emit(EventsEnum.MapRedrawLabels);
  };

  const getLabelStruct = computed(() => (key: LabelStructKeysType) => labelStruct.value[key]);

  const toggleAreaTollsActive = () => {
    if (drawerStatus.value.mode === 'inactive') {
      EventBus.$emit(EventsEnum.MapPolygonDrawStart);
      areaActive.value = true;
    } else {
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
      areaActive.value = false;
    }
  };

  return {
    setMapBacking,
    toggleRulerActive,
    toggleAreaActive,
    rulerClose,
    areaClose,
    isToolActivated,
    drawerStatus,
    rasterLayerActive,
    mapLoaded,
    mapBacking,
    showContour,
    showFieldsLabel,
    rulerActive,
    vectorData,
    mapStyle,
    isFlying,
    hoveredFieldId,
    activeFieldId,
    hoveredPOIId,
    currentCenter,
    currentZoom,
    rotate,
    toggleLabelStruct,
    getLabelStruct,
    labelStruct,
    areaActive,
    toggleAreaTollsActive,
    activeCanvasNDVI,
    blockRulerTool,
  };
};
