<template lang='pug' src='./ColorGradientPicker.pug'/>
<style lang='scss' src='./ColorGradientPicker.scss'/>

<script lang='ts'>
/**
 * Color picker with predefined colors.
 * Predefined colors генерируются из выбранного градиента (palette).
 * На вход принимает:
 * color (v-model) - текущий выбранный цвет в пикере
 * palettes (массив градиентов)
 * activePalette (v-model:active-palette) - выбранный градиент
 * count (число цветов которые должны быть сгенерированы из выбранного градиента)
 * prefixColors массив цветов (e.g. ['#FF0000']) которые добавляются в predefined colors в начало.
 * postfixColors массив цветов (e.g. ['#FF0000']) которые добавляются в predefined colors в конец.
 */

import hexToRgba from '@/lib/convertors/hexToRgba';
import { GradientModel } from '@/models/assets/GradientModel';
import StructList from '@/modules/struct/StructList';
import chroma from 'chroma-js';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ColorGradientPicker',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    gradients: {
      type: Array as PropType<GradientModel[]>,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    activeGradient: {
      type: Object as PropType<GradientModel>,
      required: false,
    },
    prefixColors: {
      type: Array as PropType<string[]>,
      required: false,
      default: [] as string[],
    },
    postfixColors: {
      type: Array as PropType<string[]>,
      required: false,
      default: [] as string[],
    },
    clearAble: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  emits: ['update:modelValue', 'update:activeGradient'],
  setup(props, { emit }) {
    const predefineColors = computed<string[]>(() => {
      const colors = [...props.prefixColors];
      if (props.activeGradient) {
        const stops = [];
        for (let i = 0; i <= props.count - 1; i++) {
          stops.push(i / (props.count - 1));
        }
        const f = chroma
          .scale(props.activeGradient.positions.map((c) => c.color))
          .domain(props.activeGradient.positions.map((c) => c.position / 100));
        stops.forEach((v) => colors.push(f(v).toString()));
      }
      return [
        ...colors,
      ];
    });

    const setColor = ($event: string) => {
      emit('update:modelValue', $event);
    };

    const setGradient = ($event: GradientModel) => {
      emit('update:activeGradient', $event);
    };

    return {
      StructList,
      hexToRgba,
      setColor,
      setGradient,
      predefineColors,
    };
  },
});
</script>
