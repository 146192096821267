import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { CropRateDto } from '@/services/api/dto/cropRate/CropRateDto';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getStructCropRate: (farmunit: number): string => `/api/v1/books/${farmunit}/suitability/crop/`,
  postFieldCropRate: (farmunit: number): string => `/api/v1/books/${farmunit}/suitability/crop/`,
  postSuitability: (farmunit: number): string => `/api/v1/books/catalog/${farmunit}/suitability/crop/`,
  addCropRate: (farmunit: number): string => `/api/v1/books/catalog/${farmunit}/crop/`,
};

export class ApiCropRate extends ApiHandler {
  public async getFieldsCropRate(): Promise<ApiResponse<CropRateDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<CropRateDto[]>(endpoints.getStructCropRate(unit));
  }

  public async createFieldCropRate(farmunit: number, fieldId: number, cropName: string, rate: number): Promise<ApiResponse<CropRateDto>> {
    return await this.request({ auth: true }).post<CropRateDto>(endpoints.postFieldCropRate(farmunit), {
      name: cropName,
      rating: rate,
      field: fieldId,
    });
  }
}
