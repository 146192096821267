<template lang='pug' src='./TaskMapSelector.pug'/>
<style lang='scss' src='./TaskMapSelector.scss'/>

<script lang='ts'>
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import {
  computed, defineComponent, onMounted, PropType, ref, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import FieldCard from '@/components/shared/FieldCard/FieldCard.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import FieldsList from '@/modules/fields/FieldsList';
import TaskMapCard from '@/components/shared/TaskMapCard/TaskMapCard.vue';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { useMapContainers } from '@/composables/useMapContainers';
import { TaskCollection } from '@/collection/TaskCollection';

export default defineComponent({
  name: 'TaskMapSelector',
  props: {
    // Массив рабочих карт
    taskMaps: {
      type: Object as PropType<FieldTaskMapModel[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<FieldTaskMapModel | undefined>,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    actions: {
      type: Array as PropType<string[]>,
      default: ['opacity', 'select-fill'] as string[],
    },
    render: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    position: { // Позиция el-drawer для выбора
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
    unSelect: {
      type: Boolean,
      default: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    // Флаг ожидания загрузки данных
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiDrawer,
    TaskMapCard,
    UiIcon,
    FieldCard,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { user } = useUser();

    const drawer = ref(false);
    const { mapModel } = useMapContainers(props.mapContainer);

    const taskMap = ref<FieldTaskMapModel | undefined>();

    const mainCard = ref();

    const taskTypeList = ref([]);

    const selectTaskMap = (_taskMap: FieldTaskMapModel) => {
      if (props.modelValue?.id !== _taskMap?.id) {
        setTimeout(() => {
          mainCard.value?.toggleRender(true);
        }, 100);
      }
      emit('update:modelValue', _taskMap);

      drawer.value = false;
    };
    watch(() => props.taskMaps, (a) => {
      const set = new Set();
      props.taskMaps.forEach((el) => {
        if (el instanceof FieldTaskMapBaseModel) {
          if (el.isExperiment) {
            set.add('experiments-task-map');
          } else {
            set.add('base-task-map');
          }
        } if (el instanceof FieldTaskMapWorkModel) {
          set.add('work-task-map');
        }
      });
      taskTypeList.value = Array.from(set);
    });
    onMounted(() => {
      const set = new Set();
      props.taskMaps.forEach((el) => {
        if (el instanceof FieldTaskMapBaseModel) {
          if (el.isExperiment) {
            set.add('experiments-task-map');
          } else {
            set.add('base-task-map');
          }
        } if (el instanceof FieldTaskMapWorkModel) {
          set.add('work-task-map');
        }
      });
      taskTypeList.value = Array.from(set);
      if (props.modelValue !== undefined
            && !mapModel.value.allLayers.some((a) => a.data.uuid === props.modelValue.uuid || (a.data as TaskCollection).collection.some((b) => b.uuid === props.modelValue.uuid))) {
        setTimeout(() => {
          mainCard.value?.toggleRender(true);
        }, 100);
      }
    });

    const filterTask = computed(() => (filterValue: string, element: FieldTaskMapWorkModel | FieldTaskMapBaseModel) => {
      if (element instanceof FieldTaskMapWorkModel && filterValue === 'work-task-map') {
        return true;
      }
      if (element instanceof FieldTaskMapBaseModel && (element as FieldTaskMapBaseModel).isExperiment && filterValue === 'experiments-task-map') {
        return true;
      }
      if (element instanceof FieldTaskMapBaseModel && !(element as FieldTaskMapBaseModel).isExperiment && filterValue === 'base-task-map') {
        return true;
      }
      return false;
    });

    const cleanSelectTask = () => {
      mainCard.value.toggleRender(true);

      emit('update:modelValue', undefined);
    };
    return {
      drawer,
      taskMap,
      selectTaskMap,
      FieldsList,
      mainCard,
      taskTypeList,
      filterTask,
      cleanSelectTask,
    };
  },
});
</script>
