import { computed, ref } from 'vue';
import { NotificationModel } from '@/models/NotificationModel';

const _notifications = ref<NotificationModel[]>([]);

export const useNotifications = () => {
  const notifications = computed({
    get: () => _notifications.value,
    set: (v) => {
      _notifications.value = v;
    },
  });

  return {
    notifications,
  };
};
