import { Feature } from 'geojson';
import { GeoJSONSourceSpecification, LayerSpecification } from 'mapbox-gl';

export const taskMapCollectionSourceDef = (features: Feature[]): GeoJSONSourceSpecification => ({
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features,
  },
});

export const taskMapCollectionLayerDef = (sourceId: string, layerId: string): LayerSpecification => ({
  id: layerId,
  type: 'fill',
  source: sourceId,
  layout: {},
  metadata: { type: 'task-map-collection' },
  paint: {
    'fill-color':
      ['case',
        ['==', ['get', 'zone'], '1.1'], '#BE0000',
        ['==', ['get', 'zone'], '1.2'], '#E51F02',
        ['==', ['get', 'zone'], '1.3'], '#FD5502',
        ['==', ['get', 'zone'], '2.1'], '#FCE502',
        ['==', ['get', 'zone'], '2.2'], '#FBFC02',
        ['==', ['get', 'zone'], '2.3'], '#E2FB03',
        ['==', ['get', 'zone'], '3.1'], '#65B424',
        ['==', ['get', 'zone'], '3.2'], '#2A7F01',
        ['==', ['get', 'zone'], '3.3'], '#236400',
        ['==', ['get', 'zone'], 1.1], '#BE0000',
        ['==', ['get', 'zone'], 1.2], '#E51F02',
        ['==', ['get', 'zone'], 1.3], '#FD5502',
        ['==', ['get', 'zone'], 2.1], '#FCE502',
        ['==', ['get', 'zone'], 2.2], '#FBFC02',
        ['==', ['get', 'zone'], 2.3], '#E2FB03',
        ['==', ['get', 'zone'], 3.1], '#65B424',
        ['==', ['get', 'zone'], 3.2], '#2A7F01',
        ['==', ['get', 'zone'], 3.3], '#236400',
        ['get', 'color'],
      ],
    'fill-opacity': 1,
  },
});
