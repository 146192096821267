<template src="./UserPage.pug" lang="pug"/>
<style src="./UserPage.scss" lang="scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth/AuthService';
import LoggerService from '@/services/logger/LoggerService';
import ApiService from '@/services/api/ApiService';
import { useAuth } from '@/composables/useAuth';
import { Hide, View } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'UserPage',
  components: {
    Hide,
    View,
  },
  setup() {
    const router = useRouter();

    const _username = process.env.VUE_APP_USERNAME || '';
    const _password = process.env.VUE_APP_PASSWORD || '';

    const username = ref(_username);
    const password = ref(_password);

    const wrongCredentials = ref(false);

    const mode = computed<'login' | 'recovery' | 'recovered' | 'reset' | 'resetDone'>(() => router.currentRoute.value.meta?.mode as 'login' | 'recovery' | 'recovered' | 'reset' | 'resetDone' || 'login');

    const passwordType = ref('password');

    const goto = async (page: 'login' | 'recovery' | 'recovered' | 'reset' | 'resetDone') => {
      await router.push({ name: page });
    };

    const login = async () => {
      try {
        await AuthService.authPassword(username.value, password.value);
        wrongCredentials.value = !useAuth().isAuth();
        if (!wrongCredentials.value) {
          await router.push({ name: 'app' });
        }
      } catch (e) {
        LoggerService.error(e);
      }
    };

    const submitRecovery = () => {
      if (/^\S+@\S+\.\S+$/.test(username.value)) {
        ApiService.user.resetPassword({ email: username.value });
        goto('recovered');
      }
    };

    watch(username, () => { wrongCredentials.value = false; });
    watch(password, () => { wrongCredentials.value = false; });

    const submitReset = () => {
      ApiService.user.setPassword({
        code: router.currentRoute.value?.query?.code,
        uid: router.currentRoute.value?.query?.uid,
        new_password: password.value,
      });
      goto('resetDone');
    };

    return {
      username,
      password,
      login,
      submitRecovery,
      submitReset,
      wrongCredentials,
      mode,
      passwordType,
      goto,
    };
  },
});
</script>
