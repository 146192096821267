import { freezeObject } from '@/utils/freezeObject';
import { isObject } from '@/utils/isObject';
import { mapFeatureBounds } from '@/utils/mapFeatureBounds';
import { mapFeatureSquare } from '@/utils/mapFeatureSquare';
import { mapFeatureCenter } from '@/utils/mapFeatureCenter';
import { mapLLToWebUTM } from '@/utils/mapLLToWebUTM';

export const Utils = {
  freezeObject,
  isObject,
  mapFeatureBounds,
  mapFeatureSquare,
  mapFeatureCenter,
  mapLLToWebUTM,
};
