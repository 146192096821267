import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "UiButton" }
const _hoisted_2 = ["animationend"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.classList())
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_TransitionGroup, null, {
        default: _withCtx(() => [
          (_ctx.val.show === true)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.ripples, (val, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "ripple",
                  key: 'ripple' + i,
                  style: _normalizeStyle({'top': val.y + 'px', 'left': val.x + 'px'}),
                  animationend: _ctx.rippleEnd(i)
                }, null, 12, _hoisted_2))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2)
  ]))
}