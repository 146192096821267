import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import ApiService from '@/services/api/ApiService';
import { TaskMapMaterialType } from '@/constants/types/taskMap/TaskMapMaterialType';
import { TaskMapFactDto } from '@/services/api/dto/taskMap/TaskMapFactDto';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';

export class FieldTaskMapFactModel extends FieldTaskMapModel {
  get activeMaterial(): TaskMapMaterialType | undefined {
    return this._activeMaterial;
  }

  get materials(): TaskMapMaterialType[] {
    return this._materials;
  }

  private readonly _materials: TaskMapMaterialType[] = [];

  private _activeMaterial: TaskMapMaterialType | undefined;

  constructor(dto: TaskMapFactDto) {
    super(dto);
    dto.materials.forEach((r) => {
      this._materials.push({
        id: r.id,
        task: r.task,
        name: r.name,
        key: r.key,
        borders: r.borders,
        order: r.order,
        dataType: r.data_type,
        differentialDetected: r.differential_detected,
      });
    });
    if (this._materials.length > 0) {
      this._activeMaterial = this._materials[0];
    }
  }

  setActiveMaterial(key: string | undefined): void {
    if (!key) {
      this._activeMaterial = undefined;
    } else {
      this._activeMaterial = this._materials.find((m) => m.key === key);
    }
  }

  async delete(): Promise<void> {
    await ApiService.taskMap.deleteTaskMap('fact', this.id);
  }

  async fetchData(): Promise<void> {
    if (!this.geojson) {
      const { data } = await ApiService.taskMap.getData('fact', this.id);
      this.setGeoJson(data.geojson);
    }
  }

  setSelected(value: boolean): void {
    if (value) {
      EventBus.$emit(EventsEnum.SetSelectedTaskMap, this);
    }
    super.setSelected(value);
  }
}
