import { SatelliteFieldIndexFileDto } from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';
import { MonitoringFileModel } from '@/models/monitoring/MonitoringFileModel';
import { formatQueryDate } from '@/utils/formatQueryDate';

export class MonitoringFilesList {
  private _files: Readonly<MonitoringFileModel[]> = [];

  private _uuids: string[] = [];

  get files(): Readonly<MonitoringFileModel[]> {
    return this._files;
  }

  getIndexesFiles(fieldId: number, kind: number) {
    return this._files.filter((file) => file.field === fieldId && file.kind === kind);
  }

  getIndexFileByDate(fieldId: number, kind: number, date: Date) {
    return this._files.find((file) => file.field === fieldId && file.kind === kind && formatQueryDate(file.date) === formatQueryDate(date));
  }

  hasFiles(fieldId: number, kind: number) {
    return this.getIndexesFiles(fieldId, kind).length > 0;
  }

  addFiles(files: SatelliteFieldIndexFileDto[]) {
    const _data: MonitoringFileModel[] = [];
    files.forEach((file) => {
      if (!this._uuids.includes(file.uuid)) {
        this._uuids.push(file.uuid);
        _data.push(new MonitoringFileModel(file));
      }
    });

    this._files = Object.freeze([
      ...this._files,
      ..._data,
    ].sort((a, b) => (a.date < b.date ? 1 : -1)));
  }
}
