import { TechDeviceDto } from '@/services/api/dto/tech/TechDeviceDto';
import { TechDeviceStatisticsDto } from '@/services/api/dto/tech/TechDeviceStatisticsDto';
import { TechDeviceVehicleDto } from '@/services/api/dto/tech/TechDeviceVehicleDto';
import { TechDeviceModemDto } from '@/services/api/dto/tech/TechDeviceModemDto';
import { TechDeviceJobTagDto } from '@/services/api/dto/tech/TechDeviceJobTagDto';
import { TechDeviceMonitorDto } from '@/services/api/dto/tech/TechDeviceMonitorDto';

export class TechModel {
  get purposeIcon(): string {
    return this._purposeIcon;
  }

  get monitor(): TechDeviceMonitorDto {
    return this._monitor;
  }

  get vehicle(): TechDeviceVehicleDto {
    return this._vehicle;
  }

  get modem(): TechDeviceModemDto {
    return this._modem;
  }

  get statistics(): TechDeviceStatisticsDto {
    return this._statistics;
  }

  get tags(): TechDeviceJobTagDto[] {
    return this._tags;
  }

  get user(): number {
    return this._user;
  }

  get farmunit(): number {
    return this._farmunit;
  }

  get isTokenRequired(): boolean {
    return this._isTokenRequired;
  }

  get isAuthAvailable(): boolean {
    return this._isAuthAvailable;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get cpuId(): string {
    return this._cpuId;
  }

  get updateDate(): Date {
    return this._updateDate;
  }

  get createDate(): Date {
    return this._createDate;
  }

  get uuid(): string {
    return this._uuid;
  }

  get serviceName(): string {
    return this._serviceName;
  }

  get id(): number {
    return this._id;
  }

  get techName(): string {
    return this._techName;
  }

  private readonly _id: number;

  private readonly _serviceName: string;

  private readonly _uuid: string;

  private readonly _createDate: Date;

  private readonly _updateDate: Date;

  private readonly _cpuId: string;

  private readonly _isActive: boolean;

  private readonly _isAuthAvailable: boolean;

  private readonly _isTokenRequired: boolean;

  private readonly _farmunit: number;

  private readonly _user: number;

  private readonly _tags: TechDeviceJobTagDto[];

  private readonly _statistics: TechDeviceStatisticsDto;

  private readonly _modem: TechDeviceModemDto

  private readonly _vehicle: TechDeviceVehicleDto;

  private readonly _monitor: TechDeviceMonitorDto;

  private readonly _purposeName: string;

  private readonly _purposeIcon: string;

  private readonly _techName: string

  constructor(dto: TechDeviceDto) {
    this._id = dto.id;
    this._tags = dto.tags;
    this._serviceName = dto.service_name;
    this._uuid = dto.uuid;
    this._createDate = new Date(dto.create_date);

    this._updateDate = new Date(dto.update_date);
    this._cpuId = dto.cpu_id;
    this._isActive = dto.is_active;
    this._isAuthAvailable = dto.is_auth_available;
    this._isTokenRequired = dto.is_token_required;
    this._farmunit = dto.farmunit;
    this._user = dto.user;

    this._purposeName = dto.purpose_name;
    this._purposeIcon = dto.purpose_icon;

    this._statistics = dto.statistics;
    this._monitor = dto.monitor;
    this._modem = dto.modem;
    this._vehicle = dto.vehicle;

    this._techName = dto.name;
  }
}
