import { Map } from 'mapbox-gl';
import { BlockNameType } from '@/constants/types/BlockNameType';
import { useMapLayout } from '@/composables/useMapLayout';
import LoggerService from '@/services/logger/LoggerService';

class MapToolsFunction {
  private _map: Map;

  private _tools: BlockNameType[] = [];

  private _hiddenTools: BlockNameType[] = []

  constructor(map: Map) {
    this._map = map;
  }

  append(tool: BlockNameType, hidden = false) {
    if (!this._tools.includes(tool)) {
      if (hidden) {
        this._hiddenTools.push(tool);
      } else {
        useMapLayout().showBlock(tool);
      }
      this._tools.push(tool);
    } else {
      LoggerService.error(`Try to append existing tool: ${tool}`);
    }
  }

  hide(tool: BlockNameType) {
    if (!this._hiddenTools.includes(tool)) {
      useMapLayout().hideBlock(tool);
      this._hiddenTools.push(tool);
    }
  }

  show(tool: BlockNameType) {
    if (this._hiddenTools.includes(tool)) {
      useMapLayout().showBlock(tool).then(() => {
        this._hiddenTools.splice(this._hiddenTools.indexOf(tool), 1);
      });
    }
  }

  remove(tool: BlockNameType) {
    if (this._tools.includes(tool)) {
      if (this._hiddenTools.includes(tool)) {
        this.hide(tool);
      }
      this._tools.splice(this._tools.indexOf(tool), 1);
    }
  }
}

export default MapToolsFunction;
