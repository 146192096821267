import { Bus } from '@/lib/classes/Bus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

enum Events {
  LOADING_STARTS,
  LOADING_ENDS,
}

class LoadingEvents extends Bus {
  onLoadingStart(callback: (name: LoadingNamesEnum, key?: string | number | undefined) => void, once = false) {
    this.registerListener(Events.LOADING_STARTS, callback, once);
  }

  offLoadingStart(callback: (name: LoadingNamesEnum, key?: string | number | undefined) => void, once = false) {
    this.unregisterListener(Events.LOADING_STARTS, callback);
  }

  emitLoadingStart(name: LoadingNamesEnum, key: string | number | undefined) {
    this.emitListeners(Events.LOADING_STARTS, name, key);
  }

  onLoadingEnds(callback: (name: LoadingNamesEnum, key?: string | number | undefined) => void, once = false) {
    this.registerListener(Events.LOADING_ENDS, callback, once);
  }

  offLoadingEnds(callback: (name: LoadingNamesEnum, key?: string | number | undefined) => void, once = false) {
    this.unregisterListener(Events.LOADING_ENDS, callback);
  }

  emitLoadingEnds(name: LoadingNamesEnum, key: string | number | undefined) {
    this.emitListeners(Events.LOADING_ENDS, name, key);
  }
}

export default new LoadingEvents();
