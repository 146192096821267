const formatAnyParams = (params: any[]) => `<span class='NotificationStatus accent'>${params[1]}</span>`;

const formatA = (params: string[]) => {
  const title = params.pop();
  const url = params.join(':');
  return `<a target="_blank" href="${url}" class='NotificationStatus accent'>${title}</a>`;
};

export const notificationFormatMessage = (message: string) => message.split(/(\[\[.*\]\])/).reduce((acc, v) => {
  if (v.startsWith('[[')) {
    const [action, ...params] = v.slice(2, v.length - 2).split(':');
    switch (action) {
    case 'field': acc += formatAnyParams(params); break;
    case 'accent': acc += formatAnyParams(params); break;
    case 'raster': acc += formatAnyParams(params); break;
    case 'poi_group': acc += formatAnyParams(params); break;
    case 'poi': acc += formatAnyParams(params); break;
    case 'user': acc += formatAnyParams(params); break;
    case 'billing': acc += formatAnyParams(params); break;
    case 'vector': acc += formatAnyParams(params); break;
    case 'farmunit': acc += formatAnyParams(params); break;
    case 'taskmap': acc += formatAnyParams(params); break;
    case 'satellites': acc += formatAnyParams(params); break;
    case 'base_task_map': acc += formatAnyParams(params); break;
    case 'tech_device': acc += formatAnyParams(params); break;
    case 'crop_rotation_season': acc += formatAnyParams(params); break;
    case 'monitoring': acc += formatAnyParams(params); break;
    case 'poi_photo': acc += formatAnyParams(params); break;
    case 'harvest_task_map': acc += formatAnyParams(params); break;
    case 'fact_task_map': acc += formatAnyParams(params); break;
    case 'a': acc += formatA(params); break;
    default: acc += formatAnyParams(params); break;
    }
  } else {
    acc += v;
  }
  return acc;
}, '');
