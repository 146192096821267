/* eslint-disable camelcase */
import { useStatus } from '@/composables/useStatus';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { AllAverageIndexDto } from '@/services/api/dto/monitoring/AllAverageIndexDto';
import { MonitoringIndexColorDto } from '@/services/api/dto/monitoring/MonitoringIndexColorDto';
import { MonitoringIndexKindDto } from '@/services/api/dto/monitoring/MonitoringIndexKindDto';
import {
  SatelliteFieldIndexFileDto,
} from '@/services/api/dto/satellites/SatelliteFieldIndexFileDto';
import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import { formatQueryDate } from '@/utils/formatQueryDate';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getRgb: (fieldId: number | string) => `/api/v1/satellites/monitoring/${fieldId}/rgb/`,
  getIndexes: (fieldId: number | string) => `/api/v1/satellites/monitoring/${fieldId}/indices/`,
  getLastMonitoringIndexes: (fieldId: number | string) => `/api/v1/satellites/monitoring/${fieldId}/indices/last/`,
  getSceneRgb: (fieldId: number | string) => `/api/v1/satellites/monitoring/${fieldId}/rgb/`,
  getKinds: (structId: number | string) => `/api/v1/satellites/monitoring/${structId}/kinds/`,
  getColors: (structId: number | string) => `/api/v1/satellites/monitoring/${structId}/colors/`,
  getAllAvgIndexes: (structId: number | string, index: string) => `/api/v1/satellites/monitoring/${structId}/all-avg-indices/${index}/`,
  getStructRgb: () => '/api/v1/satellites/files/rgb/',
  patchAverageIndexes: (fieldId: number | string, index: number) => `/api/v1/satellites/monitoring/${fieldId}/indices/${index}/cloud-estimation/`,
};

export class ApiMonitoring extends ApiHandler {
  public async getRgb(fieldId: number | string, data?: { datetime_after: string} | { [key: string]: any }): Promise<ApiResponse<SatelliteRgbFileDto[]>> {
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteRgbFileDto[]>(endpoints.getRgb(fieldId), data);
  }

  public async getIndexes(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteFieldIndexFileDto[]>(endpoints.getIndexes(fieldId));
  }

  public async getMonthIndexes(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    const data = {
      datetime_after: formatQueryDate(new Date(Date.now() - 4 * 7 * 24 * 3600 * 1000)),
    };
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteFieldIndexFileDto[]>(endpoints.getIndexes(fieldId), data);
  }

  public async getStructRgb(unit: number | string): Promise<ApiResponse<any>> {
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteFieldIndexFileDto[]>(endpoints.getStructRgb(), {
      unit,
    });
  }

  public async getNdviIndexes(fieldId: number | string, kind: FileIndexKindEnums = FileIndexKindEnums.NDVI): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    const { serverTime } = useStatus();
    let data = {};
    if (serverTime.value) {
      const time: number = serverTime.value.getTime() - 52 * 7 * 24 * 3600 * 1000;
      data = {
        kind,
        datetime_after: formatQueryDate(new Date(time)),
      };
    }
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteFieldIndexFileDto[]>(endpoints.getIndexes(fieldId), data);
  }

  public async getAllAvgIndexes(index: 'ndvi'): Promise<ApiResponse<AllAverageIndexDto>> {
    const structId = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<AllAverageIndexDto>(endpoints.getAllAvgIndexes(structId, index));
  }

  public async getLastMonitoringIndexes(fieldId: number | string): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    return await this.request({ auth: true }).get<SatelliteFieldIndexFileDto[]>(endpoints.getLastMonitoringIndexes(fieldId));
  }

  public async getKindIndexes(fieldId: number | string, kind: number): Promise<ApiResponse<SatelliteFieldIndexFileDto[]>> {
    const data = {
      kind,
    };
    return await this.request({ auth: true, pagination: 9999 }).get<SatelliteFieldIndexFileDto[]>(endpoints.getIndexes(fieldId), data);
  }

  public async getSceneRgb(fieldId: number | string, sceneId: number | string): Promise<ApiResponse<SatelliteRgbFileDto[]>> {
    const data = {
      scene: sceneId,
    };
    return await this.request({ auth: true }).get<SatelliteRgbFileDto[]>(endpoints.getSceneRgb(fieldId), data);
  }

  public async getKinds(): Promise<ApiResponse<MonitoringIndexKindDto[]>> {
    const structId = useStruct().structId.value;
    return await this.request({ auth: true }).get<MonitoringIndexKindDto[]>(endpoints.getKinds(structId));
  }

  public async patchAverageIndexes(value: number, fieldId: number |string, indexId: number): Promise<ApiResponse<void>> {
    const data = {
      estimation_user: value,
    };
    return await this.request({ auth: true }).patch<void>(endpoints.patchAverageIndexes(fieldId, indexId), data);
  }

  public async getColors(): Promise<ApiResponse<MonitoringIndexColorDto[]>> {
    const structId = useStruct().structId.value;
    return await this.request({ auth: true }).get<MonitoringIndexColorDto[]>(endpoints.getColors(structId));
  }
}
