import { SocketSatelliteEvents } from '@/services/socket/events/SocketSatelliteEvents';
import { SocketFieldEvents } from '@/services/socket/events/SocketFieldEvents';
import { SocketStructEvents } from '@/services/socket/events/SocketStructEvents';
import { SocketNotificationEvents } from '@/services/socket/events/SocketNotificationEvents';
import { SocketPoiEvents } from '@/services/socket/events/SocketPoiEvents';

export const SocketEventsRegister = () => {
  SocketSatelliteEvents();
  SocketFieldEvents();
  SocketStructEvents();
  SocketNotificationEvents();
  SocketPoiEvents();
};
