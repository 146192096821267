export enum FileIndexKindEnums {
  NONE = 0,
  MASK = 1,
  NDVI = 10,
  NDVI_C = 11,
  MSF = 20,
  RSF = 30,
  RVI = 40,
  PVI = 50,
  EVI = 60,
  mSAVI2 = 70,
  SBI = 80,
  NDMI = 90,
  LAI = 100,
  GNDVI = 110,
  IPVI = 120,
  GCI = 130,
  WDRVI = 150,
  MSI = 160,
  CCCI = 170,
  MCARI = 180,
  TCARI = 190,
  NDWI = 200,
  NDSI = 210,
  NDRE = 240,
  RECL = 250,
  VARI = 260,
}
