<template src="./AdminListContent.pug" lang="pug"/>
<style src="./AdminListContent.scss" lang="scss"/>
<script lang="ts">
import { defineComponent } from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlockNameType } from '@/constants/types/BlockNameType';
import LeftPanel from '@/components/shared/LeftPanel/LeftPanel.vue';

export default defineComponent({
  name: 'AdminListContent',
  components: {
    LeftPanel,
  },
  setup() {
    const { showBlock } = useMapLayout();

    const click = (component: BlockNameType) => {
      showBlock(component);
    };

    return {
      click,
    };
  },
});
</script>
