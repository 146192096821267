<template src="./DialogBox.pug" lang="pug"/>
<style src="./DialogBox.scss" lang="scss"/>

<script lang="ts">
import UiButton from '@/components/ui/Button/UiButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DialogBox',
  components: { UiButton },
  emits: ['accept', 'closed'],
});
</script>
